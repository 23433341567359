import "./style.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import {
	getEspelhoByEmpreendimento,
	getPlanoPagamento,
} from "redux/Allotment/AllotmentAction";
import { getAllContatos } from "redux/Contact/ContactAction";
import { getDialogoImovelVenda } from "redux/Immobile/ImmobileAction";

import DialogImovel from "../../components/imovel/dialog";
import Loader from "../../components/Loader";
import Titulo from "../../components/titulo";
import Quadra from "./Block";

class EspelhoEmpreendimento extends Component {
	componentDidMount() {
		const { id } = this.props.match.params;
		this.props.dispatch(
			getEspelhoByEmpreendimento({
				cod_empreendimento: id,
				disponivel: "SIM",
			})
		);
		this.props.dispatch(getPlanoPagamento({ cod_empreendimento: id }));
		this.props.dispatch(getAllContatos());
	}

	handleOpenDialog = (codImovel) => {
		this.props.dispatch(getDialogoImovelVenda(codImovel));
	};

	render() {
		const { empreendimento } = this.props.store;
		return (
			<>
				<Loader {...this.props} />
				<Titulo {...this.props} espelho />
				<div className="quadra-container">
					{empreendimento.espelhoEmpreendimento.quadras
						? empreendimento.espelhoEmpreendimento.quadras.map((value) => (
								<Quadra
									{...this.props}
									key={value.quadra}
									imovel={value}
									click={this.handleOpenDialog}
								/>
						  ))
						: null}
				</div>

				<DialogImovel {...this.props} />
			</>
		);
	}
}

const mapStateToProps = (state) => ({ store: state });
export default EspelhoEmpreendimento = connect(mapStateToProps)(
	EspelhoEmpreendimento
);
