import { hideLoading, showLoading } from "components/Loader";
import { call, put, takeEvery } from "redux-saga/effects";
import { API } from "utils/API";

import { history } from "../../App";
import {
	ADDCONTATO,
	ADDCONTATOSAGA,
	CURRENTCONTATO,
	CURRENTCONTATOSAGA,
	DELETECONTATO,
	DELETECONTATOSAGA,
	EDITCONTATO,
	EDITCONTATOSAGA,
	GETALLCONTATOS,
	GETALLCONTATOSSAGA,
	SEARCHCONTATOS,
	SEARCHCONTATOSSAGA,
} from "./ContactType";

function* getAllContatos() {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "contato");

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETALLCONTATOS, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* setCurrentContato({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "contato", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: CURRENTCONTATO, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* addContato({ data }) {
	try {
		yield put(showLoading());

		const response = yield call(API.post, "contato", JSON.stringify(data));

		let payload = response.data.result;
		if (payload.message !== "success") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: ADDCONTATO, payload: payload.data });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* editContato({ data }) {
	try {
		yield put(showLoading());

		const response = yield call(API.put, `contato/${data.cod_contato}`, data);

		let payload = response.data.result;
		if (payload.message !== "success") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: EDITCONTATO, payload: payload.data });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* deleteContato({ id }) {
	try {
		yield put(showLoading());

		const response = yield call(API.delete, `contato/${id}`);

		let payload = response.data.result;
		if (payload.message !== "success") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: DELETECONTATO, payload: id });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* searchContatos({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "contato", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: SEARCHCONTATOS, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

export default function* ContactSaga() {
	yield takeEvery(GETALLCONTATOSSAGA, getAllContatos);
	yield takeEvery(CURRENTCONTATOSAGA, setCurrentContato);
	yield takeEvery(ADDCONTATOSAGA, addContato);
	yield takeEvery(EDITCONTATOSAGA, editContato);
	yield takeEvery(DELETECONTATOSAGA, deleteContato);
	yield takeEvery(SEARCHCONTATOSSAGA, searchContatos);
}
