import { put, takeEvery } from "redux-saga/effects";

import {
	CLOSEMODALRESPONSEAPI,
	CLOSEMODALRESPONSEAPISAGA,
	OPENMODALRESPONSEAPI,
	OPENMODALRESPONSEAPISAGA,
} from "./ModalResponseApiType";

function* openModalResponseApi(action) {
	yield put({ type: OPENMODALRESPONSEAPI, payload: action.payload });
}
function* closeModalResponseApi() {
	yield put({ type: CLOSEMODALRESPONSEAPI });
}

export default function* GlobalSaga() {
	yield takeEvery(OPENMODALRESPONSEAPISAGA, openModalResponseApi);
	yield takeEvery(CLOSEMODALRESPONSEAPISAGA, closeModalResponseApi);
}
