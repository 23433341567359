import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	IconButton,
	Typography,
} from "@material-ui/core";
import { FileCopySharp } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";

import { setPageSearch } from "../../../../redux/QueryProperty/QueryPropertyAction";
import { getAllSelects } from "../../../../redux/Selects/SelectsActions";
import style from "./style.module.css";

function GridView({ selects, dispatch, imovelSearch = [], pageSearch }) {
	const [expanded] = useState(true);

	useEffect(() => {
		dispatch(getAllSelects());
		//eslint-disable-next-line
	}, []);

	function findInObject(value, object, property, propertyReturn) {
		var result = object.find((prop) => prop[property] === value);
		return result[propertyReturn];
	}
	const items = [];
	items.push(
		imovelSearch.map((d, index) => (
			<Card className={style.root} key={index}>
				<CardMedia
					className={style.cover}
					image="https://upload.wikimedia.org/wikipedia/commons/6/65/Imagem_n%C3%A3o_dispon%C3%ADvel.svg"
					title="Live from space album cover"
				/>

				<div className={clsx(style.details, style.full)}>
					<CardHeader
						className={style.full}
						action={
							<Button variant="contained" color="primary">
								Abrir
							</Button>
						}
						title={d.empreend_nome}
					/>
					<CardContent className={clsx(style.content, style.full)}>
						<Typography component="h5" variant="h5">
							<li>
								{findInObject(
									d.cod_imoveltipo,
									selects.tipo,
									"cod_imoveltipo",
									"nome"
								)}
							</li>
						</Typography>
						<Typography variant="subtitle1" color="textSecondary">
							{d.bairro}
						</Typography>
						<Typography variant="subtitle1" color="textSecondary">
							Lote: {d.lote} / Quadra: {d.quadra}
						</Typography>
						<Typography variant="subtitle1" color="textSecondary">
							<FileCopySharp /> {d.area_metro}m²
						</Typography>
					</CardContent>

					<CardActions className={clsx(style.full, style.betw)}>
						<Typography className={clsx(style.red)}>
							R$
							{d.preco_total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
						</Typography>
						<IconButton
							//onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<ExpandMoreIcon />
						</IconButton>
					</CardActions>
				</div>
			</Card>
		))
	);
	const [teste] = useState(true);

	function loadMore(page) {
		dispatch(setPageSearch(page));
	}
	return (
		<React.Fragment>
			<InfiniteScroll
				pageStart={1}
				loadMore={loadMore}
				hasMore={teste}
				useCapture={true}
				threshold={10}
				initialLoad={false}
			>
				<div className="tracks">{items}</div>
			</InfiniteScroll>

			{imovelSearch === "Não possui dados" && <h1>sem dados</h1>}
		</React.Fragment>
	);
}

export default connect((store) => ({
	selects: store.selects,
	imovelSearch: store.consultaImoveis.imovelSearch,
	pageSearch: store.consultaImoveis.pageSearch,
}))(GridView);
