import "./style.css";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import { Check, Error } from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { setShowAlertConfirm } from "../../../redux/Global/GlobalAction";

function Alert({ showAlertConfirm, dispatch }) {
	const [redirect, isRedirect] = useState(false);
	const handleClose = () => {
		//isRedirect(true)
		dispatch(
			setShowAlertConfirm({
				show: false,
				title: "",
				text: "",
				callback: "",
				icon: "",
			})
		);
		window.location.reload(false);
	};

	const toHome = () => {
		isRedirect(true);
		dispatch(
			setShowAlertConfirm({
				show: false,
				title: "",
				text: "",
				callback: "",
				icon: "",
			})
		);
	};

	return (
		<>
			<Dialog
				open={showAlertConfirm.show}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className="bg-gray--light">
					<DialogContent>
						<DialogTitle
							id="alert-dialog-title"
							className={showAlertConfirm.icon === "error" ? "orange" : "green"}
						>
							{showAlertConfirm.icon === "error" && (
								<Error className="alert-question__title-icon" />
							)}
							{showAlertConfirm.icon === "check" && (
								<CheckCircleIcon className="alert-question__title-icon" />
							)}

							{showAlertConfirm.title}
						</DialogTitle>
						<DialogContentText
							id="alert-dialog-description"
							className="alert-confirm__description"
						>
							{showAlertConfirm.text}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						{showAlertConfirm.icon === "error" ? (
							<div className="alert-confirm__btn-container">
								<Button
									onClick={handleClose}
									autoFocus
									className="btn__blue--medium"
								>
									<Check className="alert-confirm__icon" />
									OK
								</Button>
							</div>
						) : (
							<div className="alert-confirm__btn-container">
								<Button onClick={handleClose} autoFocus className="btn__blue">
									<Check className="alert-confirm__icon" />
									Novo upload
								</Button>
								<Button onClick={toHome} autoFocus className="btn__blue">
									<Check className="alert-confirm__icon" />
									{redirect && <Redirect to="/" />}
									Voltar para home
								</Button>
							</div>
						)}
					</DialogActions>
				</div>
				{redirect && showAlertConfirm.callback}
			</Dialog>
		</>
	);
}

export default connect((state) => ({
	showAlertConfirm: state.global.showAlertConfirm,
}))(Alert);
