import {
	ADDEMPREENDIMENTOSAGA,
	//PAINELEMPREENDIMENTOSAGA,
	//ESPELHOEMPREENDIMENTOSAGA,
	MAPAEMPREENDIMENTOSAGA,
} from "../constants";

export function painelEmpreendimento() {}

export function espelhoEmpreendimento() {}

export function getMapasEmpreendimento() {
	return {
		type: MAPAEMPREENDIMENTOSAGA,
	};
}

export function addEmpreendimento(json) {
	return {
		type: ADDEMPREENDIMENTOSAGA,
		payload: json,
	};
}
