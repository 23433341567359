import D3Funnel from "d3-funnel";
import React from "react";

export default (props) => {
	const { data } = props;

	React.useEffect(() => {
		updateChartFunnel(data);
	}, [data]);

	const updateChartFunnel = (value) => {
		let scale = value.map((value) => value.fase_cor);

		let data = value.map((item) => ({
			label: item.grupo_funil,
			value: item.quant,
		}));

		let options = {
			chart: {
				height: 200,
				width: 285,
				curve: {
					enabled: true,
					height: 20,
					shade: -0.3,
				},
			},
			block: {
				dynamicHeight: false,
				minHeight: 15,
				fill: {
					type: "solid",
					scale,
				},
			},
		};

		const chart = new D3Funnel("#funnel");
		chart.draw(data, options);
	};

	return (
		<>
			<div style={{ width: "285px", height: "100px" }}>
				<div id="funnel"></div>
			</div>
		</>
	);
};
