import {
	SHOWALERT,
	SHOWALERTALTERTERM,
	SHOWALERTCONFIRM,
	SHOWALERTQUESTION,
	SHOWALERTQUESTIONCONTATO,
	SHOWALERTQUESTIONREASON,
	SHOWTOAST,
} from "./GlobalType";

const INITIAL_STATE = {
	showAlert: false,
	valueToDelete: 0,
	showToast: {
		show: false,
		severity: "",
		text: "",
	},
	showAlertConfirm: {
		show: false,
		title: "",
		text: "",
	},
	showAlertQuestion: {
		show: false,
		title: "",
		text: "",
	},
	showAlertQuestionContato: {
		show: false,
		title: "",
		text: "",
	},
	showAlertQuestionReason: {
		show: false,
		title: "",
		text: "",
		reason: "",
	},
	showAlertAlterTerm: {
		show: false,
		title: "",
		text: "",
		data: "",
		hora: "",
		data_completa: "",
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SHOWALERT:
			return {
				...state,
				showAlert: action.payload.show,
				valueToDelete: action.payload.value,
			};
		case SHOWTOAST:
			return {
				...state,
				showToast: { ...state.showToast, ...action.payload },
			};
		case SHOWALERTCONFIRM:
			return {
				...state,
				showAlertConfirm: { ...state.showAlertConfirm, ...action.payload },
			};
		case SHOWALERTQUESTION:
			return {
				...state,
				showAlertQuestion: { ...state.showAlertQuestion, ...action.payload },
			};
		case SHOWALERTQUESTIONCONTATO:
			return {
				...state,
				showAlertQuestionContato: {
					...state.showAlertQuestionContato,
					...action.payload,
				},
			};
		case SHOWALERTQUESTIONREASON:
			return {
				...state,
				showAlertQuestionReason: {
					...state.showAlertQuestionReason,
					...action.payload,
				},
			};
		case SHOWALERTALTERTERM:
			const data = { ...state.showAlertAlterTerm, ...action.payload };

			let newData = data.data;
			let newHora = data.hora;

			if (newData.toString() !== "Invalid Date")
				newData = newData.split("T").shift();
			if (newHora.toString() !== "Invalid Date")
				newHora = newHora.split("T").pop();

			const data_completa = `${newData}T${newHora}`;

			return {
				...state,
				showAlertAlterTerm: {
					...data,
					data_completa,
				},
			};
		default:
			return state;
	}
};
