import React from "react";

import { Grid, Button, ListItem } from "@material-ui/core";

import "./style.css";

function ProposalTemplates(props) {
	return (
		<>
			<Grid container spacing={2} className="proposal-templates__container">
				<Grid item xs={4} md={4} lg={3} className="proposal-templates__model">
					<Grid item xs className="proposal-templates__models">
						<div className="center column">
							<ListItem
								className="proposal-templates__list"
								dense
								button
							></ListItem>

							<ListItem
								className="proposal-templates__list"
								dense
								button
							></ListItem>

							<ListItem
								className="proposal-templates__list"
								dense
								button
							></ListItem>
						</div>
					</Grid>
					<Button className="btn__green--outlined proposal-templates__btn--show">
						SOLICITAR NOVO
					</Button>
				</Grid>

				<Grid
					item
					xs={8}
					md={8}
					lg={9}
					className="proposal-templates__presentation"
				>
					<div className="proposal-templates__wrapper">
						<p className="proposal-templates__text">
							Clique no botão abaixo para selecionar o modelo.
						</p>
						<Button className="btn__green proposal-templates__btn">
							SELECIONAR MODELO
						</Button>
					</div>
					<div className="proposal-templates__model-show"></div>

					<div className="proposal-templates__wrapper--new">
						<p className="proposal-templates__text">
							Clique no botão abaixo para solicitar um novo o modelo.
						</p>
						<Button className="btn__green--outlined proposal-templates__btn--new">
							SOLICITAR NOVO
						</Button>
					</div>
				</Grid>
			</Grid>
		</>
	);
}

export default ProposalTemplates;
