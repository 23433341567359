import "./style.css";

import {
	Avatar,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";

export default (props) => {
	const { contato } = props.store;

	return (
		<>
			<div className="container-elevation">
				<TableContainer component={Paper} className="table-container">
					<Table className="table-container__table">
						<TableHead className="table-container__table--header">
							<TableRow className="hide-xs">
								<TableCell className="hide-sm" />
								<TableCell />
								<TableCell className="table-container__table--title">
									Nome
								</TableCell>
								<TableCell className="table-container__table--title">
									Matrícula
								</TableCell>
								<TableCell className="table-container__table--title">
									Data Matrícula
								</TableCell>
								<TableCell className="table-container__table--title">
									Área
								</TableCell>
								<TableCell className="hide-xs" />
								<TableCell className="hide-xs" />
							</TableRow>
						</TableHead>

						<TableBody>
							{contato.allContatos.map((value, index) => (
								<TableRow hover key={index}>
									<TableCell className="hide-sm"></TableCell>

									<TableCell
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										<Avatar alt="avatar" src={value.contato_foto} />
									</TableCell>

									<TableCell
										component="th"
										className="table-container__table--show contact__data"
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										<span className="black">{value.contato_nome}</span>
										<span className="table-container__info blue--greyish">
											32.001
										</span>
										<span className="table-container__info blue--greyish">
											142 Lotes
										</span>
									</TableCell>

									<TableCell
										className="hide-xs blue--greyish"
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										32.001
									</TableCell>

									<TableCell
										className="hide-xs blue--greyish"
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										17/12/2018
									</TableCell>

									<TableCell className="hide-xs blue--greyish">
										142 Lotes
									</TableCell>

									<TableCell
										className="hide-xs"
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										<IconButton className="btn__circular hide-xs">
											<Edit className="icon__edit hide-xs" />
										</IconButton>
									</TableCell>

									<TableCell className="contact__delete">
										<IconButton
											className="btn__circular"
											onClick={() =>
												props.handleClickConfirmDelete &&
												props.handleClickConfirmDelete(value)
											}
										>
											<Delete className="icon__delete" />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
};
