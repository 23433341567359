import "./style.css";

import {
	Button,
	CardActions,
	CardContent,
	Collapse,
	Typography,
} from "@material-ui/core";
import { Add, HelpOutline, Reorder } from "@material-ui/icons";
import React from "react";
import { NavLink } from "react-router-dom";

import { SearchInput } from "../../../components";

export default (props) => {
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<div>
			<CardActions disableSpacing className="header-bottom">
				<div>
					<h2 className="header__title">Pessoa</h2>
					<SearchInput />
				</div>

				<div className="header__buttons-container">
					<Button
						id="header__btn-help"
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<HelpOutline className="icon__help" />
					</Button>

					<div className="header__wrapper">
						<Button
							className="header__btn-plus"
							component={NavLink}
							to={`/pessoa/cadastro`}
						>
							<Add className="icon__plus" />
							Novo
						</Button>
						<Button className="header__btn-options">
							<Reorder className="icon__options" />
							Opções
						</Button>
					</div>
				</div>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit className="intro">
				<CardContent>
					<Typography paragraph>Method:</Typography>
					<Typography paragraph>
						O incentivo ao avanço tecnológico, assim como o aumento do diálogo
						entre os diferentes setores produtivos ainda não demonstrou
						convincentemente que vai participar na mudança das novas
						proposições.
					</Typography>
				</CardContent>
			</Collapse>
		</div>
	);
};
