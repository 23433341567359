import React from "react";

import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

export default (props) => {
	let timeOut = 0;

	const handleOnChange = (e) => {
		var searchText = e.target.value;

		if (timeOut) clearTimeout(timeOut);
		timeOut = setTimeout(() => {
			props.onChangeSearch(searchText);
		}, 500);
	};

	return (
		<div className="header__search">
			<div className="header__icon">
				<SearchIcon />
			</div>
			<InputBase
				className="font-input-white"
				placeholder="Buscar…"
				onChange={handleOnChange}
				inputProps={{ "aria-label": "search" }}
			/>
		</div>
	);
};
