import React, { Component } from "react";
import { connect } from "react-redux";

import Titulo from "components/titulo";
import FiltroAvancado from "components/oportunidade/filtro-avancado";
import Grid from "components/oportunidade/grid";
import Cadastro from "./DialogRegister";
import Loader from "components/Loader";

import "./style.css";

import { resetLead } from "redux/Negotiation/NegotiationAction";

class CadastroOportunidade extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openDialog: false,
		};

		this.handleClickAdd = this.handleClickAdd.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClickAdd() {
		this.setState({ ...this.state, openDialog: true });
	}

	handleClose() {
		this.props.dispatch(resetLead());
		this.setState({ openDialog: false });
	}

	render() {
		return (
			<>
				<Loader {...this.props} />

				<Titulo
					{...this.props}
					title="Oportunidade"
					standardAddOption
					onClickAdd={this.handleClickAdd}
				/>

				<div className="container-elevation opportunity-container">
					<FiltroAvancado {...this.props} textInfoShow={true} />

					<Grid {...this.props} />
				</div>

				<Cadastro
					{...this.props}
					open={this.state.openDialog}
					handleClose={this.handleClose}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({ store: state });
export default CadastroOportunidade = connect(mapStateToProps)(
	CadastroOportunidade
);
