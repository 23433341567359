import React, { createContext, useEffect, useState } from "react";

import { history } from "../App";
import { API } from "../utils/API";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [signed, setSigned] = useState(false);

	useEffect(() => {
		const storagedToken = localStorage.getItem("@YouNectImob:token");
		setSigned(storagedToken ? true : false);
	}, []);

	const logIn = async (body) => {
		const resp = await API.post(`login`, JSON.stringify(body));
		const { data } = resp;

		if (data.message === "warning" || data.message === "error")
			return data.result;

		localStorage.setItem("@YouNectImob:user", JSON.stringify(data.result));
		localStorage.setItem("@YouNectImob:token", data.token);
		setSigned(data.message === "success" ? true : false);
		return data.message;
	};

	const logOut = () => {
		localStorage.removeItem("@YouNectImob:token");
		localStorage.removeItem("@YouNectImob:user");
		history.push("/");
		setSigned(false);
	};

	return (
		<AuthContext.Provider value={{ signed, logIn, logOut }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
