export const GETFASESSAGA = "GETFASESSAGA";
export const GETOPORTUNIDADESAGA = "GETOPORTUNIDADESAGA";
export const GETLEADSSAGA = "GETLEADSSAGA";
export const GETMORELEADSSAGA = "GETMORELEADSSAGA";
export const GETCORRETORESSAGA = "GETCORRETORESSAGA";
export const GETPROSPECCAOSAGA = "GETPROSPECCAOSAGA";
export const ADDOPORTUNIDADESAGA = "ADDOPORTUNIDADESAGA";

export const SETSELECTEDPESSOA = "SETSELECTEDPESSOA";
export const RESETLEADS = "RESETLEADS";
export const GETOPORTUNIDADE = "GETOPORTUNIDADE";
export const GETFASES = "GETFASES";
export const GETLEADS = "GETLEADS";
export const GETMORELEADS = "GETMORELEADS";
export const SETETAPAACAO = "SETETAPAACAO";
export const OPENDIALOGOPORTUNIDADE = "OPENDIALOGOPORTUNIDADE";
export const OPENDIALOGOASSINATURA = "OPENDIALOGOASSINATURA";
export const GETASSINATURA = "GETASSINATURA";
export const GETORCAMENTOBYETAPA = "GETORCAMENTOBYETAPA";
export const GETCORRETORES = "GETCORRETORES";
export const GETPROSPECCAO = "GETPROSPECCAO";
