import {
	CHANGETIPO,
	GETDASHBOARDSAGA,
	GETFASEDIALOGOSAGA,
	GETMOREPAINELOPORTUNIDADESAGA,
	GETPAINELOPORTUNIDADESAGA,
	OPENDIALOGOPAINELVENDASAGA,
} from "./DashboardType";

export function getDashboard() {
	return { type: GETDASHBOARDSAGA };
}

export function getPainelOportunidade(params) {
	return { type: GETPAINELOPORTUNIDADESAGA, params };
}

export function getMorePainelOportunidade(params) {
	return { type: GETMOREPAINELOPORTUNIDADESAGA, params };
}

export const changeTipo = (value) => {
	return { type: CHANGETIPO, payload: value };
};

export function openDialogPainelVenda() {
	return { type: OPENDIALOGOPAINELVENDASAGA };
}

export function getFaseDialogo(cod_opor_fase) {
	return { type: GETFASEDIALOGOSAGA, payload: cod_opor_fase };
}
