import React, { Component } from "react";
import { connect } from "react-redux";

import { Paper, Tabs, Tab, List } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

import {
	changeTipo,
	getPainelOportunidade,
	getMorePainelOportunidade,
} from "redux/Dashboard/DashboardAction";

import Loader from "components/Loader";

import Titulo from "./Title";
import Lista from "./List";

import "./style.css";

class DashboardPainel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tipo: "preciso_resolver",
			page: 1,
		};
	}

	componentDidMount() {
		const { tipo, page } = this.state;
		this.props.dispatch(getPainelOportunidade({ tipo, page }));
	}

	tipoAba(value) {
		this.props.dispatch(changeTipo(value));
		switch (value) {
			case 0:
				return "preciso_resolver";
			case 1:
				return "aguardando_resposta";
			case 2:
				return "negociacoes_concluidas";
			default:
				return "";
		}
	}

	handleChange(event, newValue) {
		const tipo = this.tipoAba(newValue);
		const page = 1;

		this.setState({
			...this.state,
			tipo,
			page,
		});

		this.props.dispatch(getPainelOportunidade({ tipo, page }));
	}

	fetchMoreData() {
		const { tipo, page } = this.state;
		const newPage = page + 1;

		this.setState({
			...this.state,
			tipo,
			page: newPage,
		});

		this.props.dispatch(getMorePainelOportunidade({ tipo, page: newPage }));
	}

	render() {
		const { dashboard } = this.props.store;

		return (
			<>
				<Loader {...this.props} />
				<div>
					<Titulo />

					<Paper>
						<Tabs
							className="blue"
							value={dashboard.tipo}
							onChange={this.handleChange.bind(this)}
							indicatorColor="primary"
							variant="fullWidth"
						>
							<Tab label="Preciso Resolver" wrapped />
							<Tab label="Aguardando Resposta" wrapped />
						</Tabs>
					</Paper>

					<List>
						<InfiniteScroll
							dataLength={dashboard.getPainel.length}
							next={this.fetchMoreData.bind(this)}
							hasMore={true}
						>
							{dashboard.getPainel.map((value, index) => (
								<Lista {...this.props} key={index} painel={value} />
							))}
						</InfiniteScroll>
					</List>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({ store: state });
export default DashboardPainel = connect(mapStateToProps)(DashboardPainel);
