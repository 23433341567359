import { Avatar, Button, Grid, TextField } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import React, { useState } from "react";

const initialForm = {
	pessoa_nome: "",
	pessoa_email: "",
	pessoa_celular1: "",
	pessoa_foto: null,
};

export default (props) => {
	const [form, setForm] = useState(initialForm);

	const handleOnChange = (e) => {
		let { name, value } = e.target;
		setForm({ ...form, [name]: value });
	};

	const handleFileUpload = (e) => {
		let reader = new FileReader();

		if (e.target.files && e.target.files.length > 0) {
			reader.readAsDataURL(e.target.files[0]);

			reader.onload = (file) => {
				setForm({ ...form, pessoa_foto: file.target.result });
			};
		}
	};

	const handleCancelar = () => {
		props.setStep("busca");
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		props.addPessoa(form);
		props.getProspeccao();
		props.getCorretores();
		props.setStep("lead");
	};

	return (
		<form noValidate autoComplete="off" onSubmit={handleSubmit}>
			<Grid container direction="column">
				<div className="avatar-container">
					<Avatar
						alt="avatar"
						src={form.pessoa_foto || ""}
						className="avatar-container__avatar"
					/>
					<input
						type="file"
						accept="image/*"
						onChange={handleFileUpload}
						className="avatar-container__file"
					/>
				</div>

				<TextField
					className="input__primary user-register__input"
					autoComplete="off"
					label="Nome"
					name="pessoa_nome"
					value={form.pessoa_nome || ""}
					onChange={handleOnChange}
					// onBlur={handleOnBlur}
					// error={formValidade.nome ? true : false}
					// helperText={formValidade.nome}
				/>

				<TextField
					className="input__primary user-register__input"
					autoComplete="off"
					label="E-Mail"
					name="pessoa_email"
					value={form.pessoa_email || ""}
					onChange={handleOnChange}
					// onBlur={handleOnBlur}
					// error={formValidade.email ? true : false}
					// helperText={formValidade.email}
				/>

				<TextField
					className="input__primary user-register__input"
					autoComplete="off"
					label="Celular"
					name="pessoa_celular1"
					value={form.pessoa_celular1 || ""}
					onChange={handleOnChange}
					// onBlur={handleOnBlur}
					// error={formValidade.celular ? true : false}
					// helperText={formValidade.celular}
					// inputProps={{ maxLength: 17 }}
				/>
			</Grid>

			<div style={{ paddingTop: "20px" }}>
				<Button
					onClick={handleCancelar}
					className="btn__blue btn__blue--outlined"
					startIcon={<Close />}
				>
					Cancelar
				</Button>
				<Button type="submit" className="btn__blue" startIcon={<Check />}>
					Salvar
				</Button>
			</div>
		</form>
	);
};
