import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import Titulo from "../../components/titulo";
import Grid from "./Grid";
import AlertDelete from "../../components/global/AlertDelete";
import { history } from "../../App";

function EnterpriseSgi(props) {
	const handleClickAdd = () => {
		history.push("/empreendimentosgi/cadastroempreendimento");
	};

	const handleClickEdit = () => {
		history.push("/empreendimentosgi/editarempreendimento");
	};

	const handleClickOpen = (value) => {
		this.props.setCurrentContato(value.cod_contato || "0");
		this.props.setShowDialog(true);
	};

	const handleClickConfirmDelete = (value) => {
		this.props.setShowAlertDialog({
			show: true,
			value: value.cod_contato,
		});
	};

	const handleClickDelete = () => {
		this.props.deleteContato(global.valueToDelete);

		this.props.setShowAlertDialog({
			show: false,
			value: 0,
		});
	};

	return (
		<>
			<Loader {...props} />
			<Titulo
				{...props}
				title="Empreendimentos"
				standardAddOption
				onClickAdd={handleClickAdd}
				onClickEdit={handleClickEdit}
			/>

			<Grid
				{...props}
				handleClickEdit={handleClickOpen}
				handleClickConfirmDelete={handleClickConfirmDelete}
			/>

			<AlertDelete {...props} handleClickDelete={handleClickDelete} />
		</>
	);
}

const mapStateToProps = (state) => {
	return { store: state };
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			// setShowAlertDialog,
			// // ContatoActions,
			// getAllContatos,
			// setShowDialog,
			// setCurrentContato,
			// handleChangeForm,
			// validateForm,
			// addContato,
			// editContato,
			// deleteContato,
			// handleChangeSearch,
			// searchContatos,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseSgi);
