import React from "react";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Slide,
	TextField,
	IconButton,
	MenuItem,
	Avatar,
} from "@material-ui/core";

import { Close, Check } from "@material-ui/icons";

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import {
	setShowDialog,
	validateForm,
	handleChangeForm,
	editContato,
	addContato,
} from "redux/Contact/ContactAction";

import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
	const { contato } = props.store;

	const handleClose = () => {
		props.dispatch(setShowDialog(false));
	};

	const handleSaveForm = () => {
		let error = false;
		let formErrors = Object.entries(contato.currentContato).map(
			([key, value]) => {
				return {
					name: key,
					value,
				};
			}
		);

		props.dispatch(validateForm(formErrors));

		Object.values(contato.validateForm).forEach((value) => {
			if (value !== "") error = true;
		});

		if (error) return;

		if (contato.currentContato && contato.currentContato.cod_contato) {
			props.dispatch(editContato(contato.currentContato));
		} else {
			props.dispatch(addContato(contato.currentContato));
		}

		props.dispatch(setShowDialog(false));
	};

	const handleFileUpload = (e) => {
		let reader = new FileReader();

		if (e.target.files && e.target.files.length > 0) {
			reader.readAsDataURL(e.target.files[0]);

			reader.onload = (file) => {
				props.dispatch(
					handleChangeForm({
						name: "contato_foto",
						value: file.target.result,
					})
				);
			};
		}
	};

	const handleOnChangeDate = (name, date) => {
		if (!date) return;
		if (date && date.toString() === "Invalid Date") return;

		const data = {
			name,
			value: date.toISOString(),
		};

		props.dispatch(validateForm([data]));
		props.dispatch(handleChangeForm(data));
	};

	const handleOnChange = (e) => {
		handleOnBlur(e);

		props.dispatch(
			handleChangeForm({
				name: e.target.name,
				value: e.target.value,
			})
		);
	};

	const handleOnBlur = (e) => {
		props.dispatch(
			validateForm([
				{
					name: e.target.name,
					value: e.target.value,
				},
			])
		);
	};

	return (
		<>
			<Dialog
				open={contato.showDialog}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
			>
				<div className="bg-gray--light">
					<DialogContent className="dialog-register dialog-container">
						<form
							className="dialog-register__form"
							noValidate
							autoComplete="off"
						>
							<div className="dialog-register__header">
								<DialogTitle>
									<div className="dialog-register__title">
										Novo Contato
										<IconButton
											className="dialog-register__btn-close"
											onClick={handleClose}
										>
											<Close className="icon__close" />
										</IconButton>
									</div>
								</DialogTitle>
								<div className="avatar-container">
									<Avatar
										className="avatar-container__avatar"
										alt="avatar"
										src={contato.currentContato.contato_foto || ""}
									/>
									<input
										className="avatar-container__file"
										type="file"
										accept="image/*, .pdf"
										onChange={handleFileUpload}
									/>
								</div>
							</div>
							<div className="dialog-register__input-container">
								<TextField
									className="dialog-register__input input__primary"
									autoComplete="off"
									required
									label="Nome"
									name="contato_nome"
									value={contato.currentContato.contato_nome || ""}
									onChange={handleOnChange}
									onBlur={handleOnBlur}
									error={contato.validateForm.contato_nome ? true : false}
									helperText={contato.validateForm.contato_nome}
								/>

								<TextField
									className="dialog-register__input input__primary"
									autoComplete="off"
									label="CPF / CNPJ"
									name="contato_cpfcnpj"
									value={contato.currentContato.contato_cpfcnpj || ""}
									onChange={handleOnChange}
									onBlur={handleOnBlur}
									error={contato.validateForm.contato_cpfcnpj ? true : false}
									helperText={contato.validateForm.contato_cpfcnpj}
									inputProps={{ maxLength: 18 }}
								/>

								<TextField
									className="dialog-register__input input__primary"
									autoComplete="off"
									label="E-Mail"
									name="contato_email"
									value={contato.currentContato.contato_email || ""}
									onChange={handleOnChange}
									onBlur={handleOnBlur}
									error={contato.validateForm.contato_email ? true : false}
									helperText={contato.validateForm.contato_email}
								/>

								<TextField
									className="dialog-register__input input__primary"
									autoComplete="off"
									label="Celular"
									name="contato_celular"
									value={contato.currentContato.contato_celular || ""}
									onChange={handleOnChange}
									onBlur={handleOnBlur}
									error={contato.validateForm.contato_celular ? true : false}
									helperText={contato.validateForm.contato_celular}
									inputProps={{ maxLength: 17 }}
								/>

								<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
									<KeyboardDatePicker
										className="dialog-register__input--date input__primary"
										autoOk
										disableToolbar
										format="dd/MM/yyyy"
										disableFuture={true}
										margin="normal"
										label="Data de Nascimento"
										invalidDateMessage="Data em formato inválido"
										maxDateMessage="A data não pode ser maior ou igual a data atual"
										value={contato.currentContato.contato_nasc_dt || null}
										onChange={(date) =>
											handleOnChangeDate("contato_nasc_dt", date)
										}
										cancelLabel={null}
										okLabel={null}
									/>
								</MuiPickersUtilsProvider>

								<TextField
									className="dialog-register__input input__primary"
									autoComplete="off"
									label="Profissão"
									name="contato_profissao"
									value={contato.currentContato.contato_profissao || ""}
									onChange={handleOnChange}
								/>

								<TextField
									className="dialog-register__input--marital-estate input__primary"
									autoComplete="off"
									label="Estado Civil"
									name="contato_estado_civil"
									value={contato.currentContato.contato_estado_civil || ""}
									onChange={handleOnChange}
									select
								>
									<MenuItem value="">
										<em>Nenhum</em>
									</MenuItem>
									{contato.arrayEstadoCivil.map((data, index) => (
										<MenuItem key={index} value={data.value}>
											{data.name}
										</MenuItem>
									))}
								</TextField>
							</div>
						</form>
					</DialogContent>

					<DialogActions className="dialog-register__actions">
						<div className="dialog-register__btn-container">
							<Button
								onClick={handleClose}
								className="btn__blue btn__blue--outlined"
							>
								<Close className="dialog-register__icon-close" />
								Cancelar
							</Button>
							<Button onClick={handleSaveForm} className="btn__blue">
								<Check className="icon__check dialog-register__icon-check" />
								Salvar
							</Button>
						</div>
					</DialogActions>
				</div>
			</Dialog>
		</>
	);
};
