import {
	Avatar,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { Delete, Edit, StarBorder } from "@material-ui/icons";
import React from "react";

export default (props) => {
	return (
		<>
			<div className="container-elevation">
				<TableContainer component={Paper} className="table-container">
					<Table className="table-container__table">
						<TableHead className="table-container__table--header">
							<TableRow className="hide-xs">
								<TableCell />
								<TableCell />
								<TableCell className="table-container__table--title">
									Nome
								</TableCell>
								<TableCell className="table-container__table--title">
									CPF/CNPJ
								</TableCell>
								<TableCell className="table-container__table--title">
									Email
								</TableCell>
								<TableCell className="table-container__table--title">
									Celular
								</TableCell>
								<TableCell />
								<TableCell />
								<TableCell />
							</TableRow>
						</TableHead>

						<TableBody>
							<TableRow hover /*key={index}*/>
								<TableCell className="hide-xs"></TableCell>

								<TableCell>
									<Avatar alt="avatar" /*src={value.contato_foto}*/ />
								</TableCell>

								<TableCell
									component="th"
									className="table-container__table--show"
								>
									Fernando Gracia
									<span className="table-container__info">CPF</span>
									<span className="table-container__info">
										<span className="table-container__info">16 99999-0011</span>
									</span>
								</TableCell>

								<TableCell>
									<span className="hide-xs">CPF</span>
								</TableCell>

								<TableCell className="hide-xs">
									bernardo@viscom.com.br
								</TableCell>

								<TableCell className="hide-xs">16 99999-1122</TableCell>

								<TableCell className="hide-xs">
									<StarBorder
										className="hide-sm"
										style={{
											/*"#ffb300",*/ color: "#bdbdbd",
										}}
									/>
								</TableCell>

								<TableCell
									className="hide-sm"
									onClick={() =>
										props.handleClickEdit && props.handleClickEdit()
									}
								>
									<IconButton className="btn__circular">
										<Edit className="icon__edit" />
									</IconButton>
								</TableCell>

								<TableCell
									onClick={() =>
										props.handleClickConfirmDelete &&
										props.handleClickConfirmDelete()
									}
								>
									<IconButton className="btn__circular">
										<Delete className="icon__delete" />
									</IconButton>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
};
