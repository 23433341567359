import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Grid } from "@material-ui/core";
// import { AspectRatio } from "@material-ui/icons";
// import { formatNumber } from "../../../../utils/funcoes";

import ImovelCard from "components/imovel/card";
import { Tabs, Tab } from "components";
import Painel from "pages/PanelAllotment/Panel";

import { getPainelEmpreendimentos } from "redux/Allotment/AllotmentAction";

function Qualification(props) {
	const { empreendimento, oportunidade } = props.store;
	const imovel = oportunidade.getOportunidade.opor_imovel;
	const { dispatch } = props;

	useEffect(() => {
		dispatch(getPainelEmpreendimentos());
	}, [dispatch]);

	return (
		<Grid container direction="column">
			<Tabs value={1}>
				<Tab label="Imóvel Avulso" wrapped disabled />
				<Tab label="Empreendimento" wrapped />
			</Tabs>

			<Grid item container direction="row">
				{empreendimento.allEmpreendimentos.map((value, index) => (
					<Grid item key={index}>
						<Painel
							{...props}
							data={value}
							cod_opor={oportunidade.getOportunidade.cod_opor}
							graphic={false}
						/>
					</Grid>
				))}
			</Grid>

			{/* <Grid item>
				Perfil de Interesse
				<Divider />
			</Grid>

			<Grid item>
				Listagem de Imóveis
				<Divider />
			</Grid> */}

			{imovel && (
				<Grid container item direction="column" style={{ marginTop: "30px" }}>
					<Grid item style={{ marginLeft: "20px" }}>
						Imóvel selecionado
					</Grid>

					<Grid item>
						<ImovelCard {...props} />
					</Grid>
				</Grid>
			)}
		</Grid>

		// <div className="qualification-step">
		// 	<div className="qualification-step__content">
		// 		<div className="step__wrapper-qualificacao">
		// 			<p className="step__label">Pretensão</p>
		// 			<p className="step__value">Finalidade</p>
		// 			<p className="step__value--no-line-height">Compra</p>
		// 		</div>

		// 		<div className="step__wrapper-qualificacao">
		// 			<div>
		// 				<p className="step__label">Tipos</p>
		// 				<p className="step__value">
		// 					{imovel && imovel.imovel_empreendimento.empreend_nome}
		// 				</p>
		// 			</div>

		// 			<div>
		// 				<p className="step__label">Caracteristicas Mínimas</p>
		// 				<p className="step__value--no-line-height qualificacao-margin-top">
		// 					{imovel && `Quadra: ${imovel.quadra} - Lote: ${imovel.lote}`}
		// 				</p>
		// 			</div>

		// 			<div>
		// 				<p className="step__label">Localização</p>
		// 				<p className="step__value">{imovel && imovel.localizacao}</p>
		// 			</div>

		// 			<div>
		// 				<p className="step__label">Confrontações</p>
		// 				<p className="step__value">{imovel && imovel.confrontacoes}</p>
		// 			</div>
		// 		</div>

		// 		<div className="step__wrapper-qualificacao">
		// 			<div>
		// 				<p className="step__label">Área</p>
		// 				<p className="step__value">
		// 					{imovel && `${formatNumber(imovel.area_metro)} m²`}
		// 				</p>
		// 			</div>
		// 		</div>

		// 		<div className="step__wrapper-qualificacao">
		// 			<div>
		// 				<p className="step__label">Valores</p>
		// 				<p className="step__value">{` R$ ${formatNumber(
		// 					imovel.preco_total
		// 				)}`}</p>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
	);
}

const mapStateToProps = (state) => ({ store: state });
export default connect(mapStateToProps)(Qualification);
