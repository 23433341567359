import "./style.css";

import {
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
} from "@material-ui/core";
import {
	Cancel,
	Check,
	CheckCircle,
	Clear,
	Error,
	MonetizationOnOutlined,
	MoreVert,
} from "@material-ui/icons";
import Assinatura from "components/oportunidade/dialog/assinatura";
import CondicoesPagamento from "components/oportunidade/dialog/condicoes-pagamento";
import AnexarDocumento from "components/venda/anexar-documento";
import React from "react";

import { formatDateToLocaleString } from "../../../../utils/funcoes";

export default (props) => {
	const { oportunidade } = props.store;
	const { fases } = oportunidade.getOportunidade;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [options, setOptions] = React.useState(null);
	const proposta = fases.find((value) => value.fase_sigla === "P");
	const { etapas } = proposta;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuSelected = (option) => {
		const { tipo, value } = option;

		if (tipo === "aprovar") {
			if (value.dialogo_tipo === "PROPOSTA") {
				const achou = etapas.find(
					(value) =>
						value.fase_etapa_descricao === "Condições de Pagamento" &&
						value.situacao === "Aguardando"
				);

				if (achou) {
					props.setShowAlertConfirm({
						show: true,
						title: "Atenção",
						text:
							"Só é possível aprovar uma Proposta se existir uma Condição de Pagamento Aprovada",
					});
				} else {
					props.setShowAlertQuestion({
						show: true,
						title: "Atenção",
						text: "Deseja realmente APROVAR a proposta?",
						dados: {
							tipo: "proposta",
							value,
						},
					});
				}
			} else {
				handleClick(value);
			}
		} else if (tipo === "reprovar") {
			if (value.dialogo_tipo === "PROPOSTA") {
				props.setShowAlertQuestionReason({
					show: true,
					title: "Atenção",
					text: "Deseja realmente REPROVAR a proposta?",
					dados: {
						tipo: "proposta",
						value,
					},
				});
			} else {
				handleClick(value);
			}
		} else if (tipo === "incluir-valores") {
			props.openDialogOportunidade({
				show: true,
				tipo: value.dialogo_tipo,
				inclusao: true,
				cod_opor_fase_etapa: value.cod_opor_fase_etapa,
			});
		}

		setAnchorEl(null);
	};

	const handleClick = (value) => {
		if (value.dialogo_tipo === "DOCUMENTO") {
			const { cod_opor, cod_fase } = proposta;
			props.getFaseDocumentos({ cod_fase, cod_opor });
		} else if (value.dialogo_tipo === "ASSINATURA") {
			const { cod_opor, cod_fase } = proposta;
			props.getAssinaturas({ cod_fase, cod_opor });
		} else {
			props.getOrcamentoByEtapa({
				show: true,
				tipo: value.dialogo_tipo,
				cod_opor_fase_etapa: value.cod_opor_fase_etapa,
			});
		}
	};

	const handleMore = (e, value) => {
		setAnchorEl(e.currentTarget);

		let newOptions = [];
		let optionsDefault = [
			{
				icon: <Check fontSize="small" />,
				tipo: "aprovar",
				texto: "Aprovar",
				value,
			},
			{
				icon: <Clear fontSize="small" />,
				tipo: "reprovar",
				texto: "Reprovar",
				value,
			},
		];

		switch (value.situacao_descricao) {
			case "Aguardando cadastro de valor e condição de pagamento":
				newOptions = [
					{
						icon: <MonetizationOnOutlined fontSize="small" />,
						tipo: "incluir-valores",
						texto: "Propor Valores",
						value,
					},
				];
				break;
			default:
				newOptions = optionsDefault;
				break;
		}

		setOptions(newOptions);
	};

	const iconSituacao = (value) => {
		switch (value.situacao) {
			case "Aguardando":
				return <Error className="proposed-step__icon orange" />;
			case "Aprovado":
				return <CheckCircle className="proposed-step__icon green" />;
			case "Reprovado":
				return <Cancel className="proposed-step__icon red" />;
			default:
				return <Error className="proposed-step__icon orange" />;
		}
	};

	const colorDescription = (situacao) => {
		switch (situacao) {
			case "Aguardando":
				return "orange";
			case "Aprovado":
				return "green";
			case "Reprovado":
				return "red";
			default:
				return "orange";
		}
	};

	return (
		<>
			<div className="proposed-step">
				<div className="proposed-step__content">
					<List className="proposed-step__inner bg-white">
						{etapas.map((value, index) => (
							<ListItem
								dense
								button
								key={index}
								onClick={() => handleClick(value)}
								className="proposed-step__item"
							>
								<ListItemAvatar>
									{value.situacao && iconSituacao(value)}
								</ListItemAvatar>

								<div>
									<p className="proposed-step__step">
										{value.fase_etapa_descricao}
									</p>
									<p
										className={`${colorDescription(
											value.situacao
										)} proposed-step__spacing`}
									>
										{value.situacao}
										{value.finalizacao_dt
											? `: ${formatDateToLocaleString(value.finalizacao_dt)}`
											: null}
									</p>
									{value.situacao_descricao && (
										<p className="proposed-step__description">{`${value.situacao_descricao}`}</p>
									)}
									{value.situacao_motivo && (
										<p className="proposed-step__spacing">{`Motivo: ${value.situacao_motivo}`}</p>
									)}
								</div>

								<ListItemSecondaryAction>
									{value.situacao === "Aguardando" ? (
										<IconButton
											edge="end"
											onClick={(e) => handleMore(e, value)}
										>
											<MoreVert />
										</IconButton>
									) : null}

									<Menu
										elevation={0}
										getContentAnchorEl={null}
										anchorEl={anchorEl}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "left",
										}}
										keepMounted
										open={Boolean(anchorEl)}
										onClose={handleClose}
									>
										{options &&
											options.map((option, index) => (
												<MenuItem
													key={index}
													onClick={() => handleMenuSelected(option)}
												>
													<ListItemIcon>{option.icon}</ListItemIcon>
													<ListItemText primary={option.texto} />
												</MenuItem>
											))}
									</Menu>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</div>
			</div>

			<CondicoesPagamento
				{...props}
				opor_imovel={oportunidade.getOportunidade.opor_imovel}
			/>

			<AnexarDocumento {...props} />
			<Assinatura {...props} />
		</>
	);
};
