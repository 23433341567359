import { Button } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import React from "react";
import readXlsxFile from "read-excel-file";

import staticTable from "../../../static/Planilha_de_Importacao_de_Imoveis_de_Empreendimento_-_YouNect .xlsx";
import tableRowModel from "../model/tableRowModel";
import style from "../style.module.css";

function DownloadUpload(prop) {
	var file = null;
	var tableArray = null;
	var tableHeaderArrayKey = null;
	var tableHeaderArray = null;
	var tableAfterProcess = null;
	//var tableJSONToApi = null

	async function handleUpload(target) {
		return target.files[0];
	}

	async function handleConvert({ target }) {
		file = await handleUpload(target);

		try {
			await readXlsxFile(file).then((rows) => {
				tableHeaderArray = rows[2];
				tableHeaderArrayKey = rows[4];
				var tableGeneric = rows.splice(3);
				tableGeneric.splice(1, 1);
				tableArray = tableGeneric;
			});

			tableAfterProcess = await tableArray.map(
				(tableRow) => new tableRowModel(tableRow, tableHeaderArrayKey)
			);

			//tableJSONToApi = JSON.stringify(await tableAfterProcess)

			prop.returnJson([tableHeaderArray, tableArray, tableAfterProcess]);
		} catch (e) {
			console.log("error", e);
		}
	}

	return (
		<div className={style.blockDownloadUpload}>
			<div className={style.downloadUploadBox}>
				<div className={style.downloadUploadIcon}>icon</div>
				<div className={style.downloadUploadTitle}>
					Download planilha importação v.001
				</div>
				<p className={style.downloadUploadText}>
					Se você ainda não possui a planilha de esboço para importar os dados
					do imóvel, clique no botão abaixo para fazer o download.
				</p>
				<Button
					variant="contained"
					className={style.downloadUploadButton}
					href={staticTable}
					startIcon={<CloudDownloadIcon />}
				>
					BAIXAR PLANILHA
				</Button>
			</div>

			<div className={style.downloadUploadBox}>
				<div className={style.downloadUploadIcon}>icon</div>
				<div className={style.downloadUploadTitle}>
					Upload planilha importação v.001
				</div>
				<p className={style.downloadUploadText}>
					Se você já possui o modelo de planilha v.001, e preencheu os dados
					corretamente, clique no botão abaixo para enviar e carregar os dados
					no sistema.
				</p>
				<input
					accept=".xlsx"
					className={style.inputUpload}
					id="contained-button-file"
					type="file"
					onChange={handleConvert}
				/>
				<label htmlFor="contained-button-file">
					<Button
						variant="contained"
						component="span"
						className={style.downloadUploadButton}
						startIcon={<CloudUploadIcon />}
					>
						ENVIAR PLANILHA PREENCHIDA
					</Button>
				</label>
			</div>
		</div>
	);
}

export default DownloadUpload;
