import "./style.css";

import isValidCep from "@brazilian-utils/is-valid-cep";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import phone from "phone";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import MaskedInput from "react-text-mask";

import ModalResponseApi from "../../../components/global/ModalResponseApi";
import {
	editCompany,
	getCompany,
	getResponsibles,
} from "../../../redux/Company/CompanyAction";

const initialValues = {
	nome: "",
	cnpj: "",
	fones: "",
	fax: "",
	cep: "",
	endereco: "",
	num_end: "",
	complemento: "",
	bairro: "",
	comarca: "",
	distrito: "",
	obs: "",
	email: "",
	responsavel: "",
	autocomplete: "",
};

function TextMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				"(",
				/[1-9]/,
				/\d/,
				")",
				" ",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				/\d/,
			]}
			placeholderChar={"\u2000"}
			showMask
		/>
	);
}

function MyBusiness({ company, dispatch }) {
	const { register, handleSubmit, control, errors, reset } = useForm();

	const [form, setForm] = useState(initialValues);
	const [responsibles, setResponsibles] = useState([]);

	useEffect(() => {
		dispatch(getCompany());
		dispatch(getResponsibles());
		//  eslint-disable-next-line
	}, []);

	useLayoutEffect(() => {
		setForm(company);
		console.log("company", company);
		reset(company);
		setResponsibles(company.responsibles);
		// eslint-disable-next-line
	}, [company]);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setForm({
			...form,
			[name]: value,
		});
	};

	async function saveForm(data) {
		// try {
		// 	let isValid = await schema.isValid(form);
		// 	//console.log("isvalid", isValid);

		// 	// if (isValid) {
		// 	// 	console.log("cep valido", isValidCep(form.cep));
		// 	// 	console.log("fone válido", phone(form.fones, "", true));
		// 	// 	console.log("celular válido", phone(form.fax, "", true));
		// 	// }
		// } catch (error) {
		// 	console.log(error);
		// }
		data.cod_empresa = form.cod_empresa;
		console.log("teste submit", data);
		dispatch(editCompany(data));
	}

	const [init, setInit] = useState("");

	useEffect(() => {
		setInit(company.responsavel);
	}, [company, responsibles]);

	return (
		<React.Fragment>
			<ModalResponseApi />
			<div className="container">
				<Card className="card--small">
					<h1 className="card_title">Minha Empresa</h1>
				</Card>
				<form onSubmit={handleSubmit(saveForm)}>
					<div className="group group--data">
						<div className="title">
							<span className="group_title">Dados da empresa</span>
						</div>
						<ListItem divider />

						<Grid className="grid_input" container spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.nome !== undefined ? true : false}
									fullWidth
									className="input__primary input--small"
									id="name-input"
									label="Nome"
									InputLabelProps={{
										shrink: true,
									}}
									name="nome"
									defaultValue={form.none}
									inputRef={register({ required: true })}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.cnpj !== undefined ? true : false}
									defaultValue={form.cnpj}
									fullWidth
									className="input__primary input--small"
									id="cnpj-input"
									name="cnpj"
									label="CNPJ"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.fones !== undefined ? true : false}
									defaultValue={form.fones}
									fullWidth
									className="input__primary input--small"
									id="telephone-input"
									name="fones"
									label="Telefone"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputProps={{
										inputComponent: TextMaskCustom,
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.fax !== undefined ? true : false}
									defaultValue={form.fax}
									fullWidth
									className="input__primary input--small"
									id="cellphone-input"
									name="fax"
									label="Celular"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputProps={{
										inputComponent: TextMaskCustom,
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.email !== undefined ? true : false}
									defaultValue={form.email}
									fullWidth
									className="input__primary input--small"
									id="email-input"
									name="email"
									label="Email"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Controller
									name="responsavel"
									error
									as={
										<Autocomplete
											id="contentTypes"
											options={responsibles}
											getOptionLabel={(option) => option.pessoa_nome}
											inputValue={init ? init : ""}
											onInputChange={() => {
												setInit("");
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													className="input__primary input--small"
													label="Responsavel"
													fullWidth
												/>
											)}
										/>
									}
									control={control}
									onChange={([, data]) => {
										console.log("datasss", data);
										if (data !== "" && data !== null) {
											setInit(data.pessoa_nome);
											return data.pessoa_nome;
										}
									}}
									rules={{ required: true }}
								/>
							</Grid>
						</Grid>
					</div>
					<div className="group group--address">
						<div className="title">
							<span className="group_title group_title--address">Endereço</span>
						</div>
						<ListItem divider />
						<Grid className="grid_input" container spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.cep !== undefined ? true : false}
									defaultValue={form.cep}
									fullWidth
									className="input__primary input--small"
									id="cep-input"
									name="cep"
									label="CEP"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.endereco !== undefined ? true : false}
									defaultValue={form.endereco}
									fullWidth
									className="input__primary input--small"
									id="street-input"
									name="endereco"
									label="Logradouro"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.num_end !== undefined ? true : false}
									defaultValue={form.num_end}
									fullWidth
									className="input__primary input--small"
									id="number-input"
									name="num_end"
									label="Número"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.complemento !== undefined ? true : false}
									defaultValue={form.complemento}
									fullWidth
									className="input__primary input--small"
									id="complement-input"
									name="complemento"
									label="Complemento"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.bairro !== undefined ? true : false}
									defaultValue={form.bairro}
									fullWidth
									className="input__primary input--small"
									id="neighborhood-input"
									name="bairro"
									label="Bairro"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.comarca !== undefined ? true : false}
									defaultValue={form.comarca}
									fullWidth
									className="input__primary input--small"
									id="uf-input"
									name="comarca"
									label="UF"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									error={errors.distrito !== undefined ? true : false}
									defaultValue={form.distrito}
									fullWidth
									className="input__primary input--small"
									id="city-input"
									name="distrito"
									label="Cidade"
									onChange={handleChange}
									inputRef={register({ required: true })}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					</div>
					<div className="group group--note">
						<div className="title">
							<span className="group_title">Observações</span>
						</div>
						<ListItem divider />
						<Grid className="grid_input" item xs={12} md={12}>
							<TextField
								error={errors.obs !== undefined ? true : false}
								defaultValue={form.obs}
								fullWidth
								className="input__primary input--big"
								id="note-input"
								name="obs"
								label="Observação"
								onChange={handleChange}
								inputRef={register({ required: false })}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</div>
					<Button
						type="submit"
						className="btn_blue btn--save"
						color="primary"
						variant="contained"
					>
						Salvar
					</Button>
				</form>
			</div>
		</React.Fragment>
	);
}

export default connect((state) => ({
	company: state.company,
	store: state,
}))(MyBusiness);
