import "./style.css";

import TextField from "@material-ui/core/TextField";
import React from "react";

export default (props) => {
	const { form } = props;

	return (
		<>
			<div>
				<TextField
					autoComplete="off"
					label="Inscrição Estadual"
					name="pessoa_insc_estadual"
					value={form.pessoa_insc_estadual}
					onChange={props.handleOnChange}
				/>

				<TextField
					autoComplete="off"
					label="Data de Abertura"
					name="pessoa_firma_abertura_dt"
					value={form.pessoa_firma_abertura_dt}
					onChange={props.handleOnChange}
				/>

				<TextField
					autoComplete="off"
					label="CNAE"
					name="pessoa_cnae"
					value={form.pessoa_cnae}
					onChange={props.handleOnChange}
				/>
			</div>

			<div>
				<TextField
					autoComplete="off"
					label="Inscrição Municipal"
					name="rg"
					value={form.rg}
					onChange={props.handleOnChange}
				/>

				<TextField
					autoComplete="off"
					label="Ramo de Negócio"
					name="rg"
					value={form.rg}
					onChange={props.handleOnChange}
				/>
			</div>
		</>
	);
};
