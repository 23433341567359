import {
	ADDPESSOA,
	SEARCHMOREPESSOAS,
	SEARCHPESSOAS,
	SHOWDIALOGPESSOA,
} from "../../constants";
import { arrayEstadoCivil, arraySexo } from "../../utils/arrays";

const INITIAL_STATE = {
	getAllPessoas: [],
	currentPessoa: {},
	arrayEstadoCivil,
	arraySexo,
	showDialogPessoa: {
		show: false,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADDPESSOA:
			return {
				...state,
				currentPessoa: action.payload,
			};
		case SEARCHPESSOAS:
			return {
				...state,
				getAllPessoas: action.payload,
			};
		case SEARCHMOREPESSOAS:
			return {
				...state,
				getAllPessoas: [...state.getAllPessoas, ...action.payload],
			};
		case SHOWDIALOGPESSOA:
			return {
				...state,
				showDialogPessoa: action.payload,
			};
		default:
			return state;
	}
};
