import "./style.css";

import {
	Divider,
	IconButton,
	lighten,
	LinearProgress,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	withStyles,
} from "@material-ui/core";
import {
	Cancel,
	Check,
	CheckCircle,
	Clear,
	Error,
	MoreVert,
	Update,
} from "@material-ui/icons";
import React from "react";

import { formatDateToLocaleString } from "../../../../utils/funcoes";

export default (props) => {
	const { oportunidade } = props.store;
	const { fases } = oportunidade.getOportunidade;
	const { etapas } = fases.find((value) => value.fase_sigla === "R");
	const [anchorEl, setAnchorEl] = React.useState(null);

	const BorderLinearProgress = withStyles({
		root: {
			backgroundColor: ({ cor }) => lighten(`${cor}`, 0.5),
		},
		bar: {
			backgroundColor: ({ cor }) => `${cor}`,
		},
	})(LinearProgress);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (e, value) => {
		// if (value.finalizacao_dt) return;
		setAnchorEl(e.currentTarget);
	};

	const handleMenuSelected = (tipo, value) => {
		if (tipo === "aprovar") {
			props.setShowAlertQuestion({
				show: true,
				title: "Atenção",
				text: "Deseja realmente APROVAR a reserva?",
				dados: {
					tipo: "reserva",
					value,
				},
			});
		} else if (tipo === "reprovar") {
			props.setShowAlertQuestionReason({
				show: true,
				title: "Atenção",
				text: "Deseja realmente REPROVAR a reserva?",
				dados: {
					tipo: "reserva",
					value,
				},
			});
		} else if (tipo === "prazo") {
			props.setShowAlertAlterTerm({
				show: true,
				title: "Alteração de Prazo",
				text: "Selecione abaixo a nova data/hora de vencimento",
				data: value.vencimento_dt,
				hora: value.vencimento_dt,
				dados: {
					tipo: "reserva",
					value,
				},
			});
		}

		setAnchorEl(null);
	};

	const iconSituacao = (value) => {
		switch (value.situacao) {
			case "Aguardando":
				return <Error className="orange reservation-step__icon" />;
			case "Aprovado":
				return <CheckCircle className="green reservation-step__icon" />;
			case "Reprovado":
				return <Cancel className="red--light reservation-step__icon" />;
			default:
				return <Error className="orange reservation-step__icon" />;
		}
	};

	const colorDescription = (situacao) => {
		switch (situacao) {
			case "Aguardando":
				return "orange";
			case "Aprovado":
				return "green";
			case "Reprovado":
				return "red";
			default:
				return "orange";
		}
	};

	return (
		<div className="reservation-step">
			<div className="reservation-step__content">
				<List className="reservation-step__inner bg-white">
					{etapas.map((value, index) => (
						<ListItem key={index} className="reservation-step__item">
							<ListItemAvatar>
								{value.situacao && iconSituacao(value)}
							</ListItemAvatar>

							<div>
								<p className="reservation-step__step">
									{value.fase_etapa_descricao}
								</p>
								<p
									className={`${colorDescription(
										value.situacao
									)} reservation-step__spacing`}
								>
									{value.situacao}
									{value.finalizacao_dt
										? `: ${formatDateToLocaleString(value.finalizacao_dt)}`
										: null}
								</p>
								{value.situacao_descricao && (
									<p className="reservation-step__description">{`${value.situacao_descricao}`}</p>
								)}
								{value.situacao_motivo && (
									<p className="reservation-step__spacing">{`Motivo: ${value.situacao_motivo}`}</p>
								)}

								{value.situacao === "Aguardando" ? (
									<div>
										{value.tempo_restante && (
											<div>
												<span
													style={{
														color: `${value.tempo_cor}`,
														fontSize: "14px",
													}}
												>
													{value.tempo_restante}
												</span>
												<BorderLinearProgress
													variant="determinate"
													value={value.porc_restante}
													cor={`${value.tempo_cor}`}
												/>
											</div>
										)}
									</div>
								) : null}
							</div>
							<ListItemSecondaryAction>
								<IconButton
									edge="end"
									// ref={anchorRef}
									onClick={(e) => handleClick(e, value)}
								>
									<MoreVert />
								</IconButton>

								<Menu
									elevation={0}
									getContentAnchorEl={null}
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "left",
									}}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
								>
									<MenuItem onClick={() => handleMenuSelected("prazo", value)}>
										<ListItemIcon>
											<Update fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Alterar Prazo" />
									</MenuItem>

									<Divider />

									<MenuItem
										onClick={() => handleMenuSelected("aprovar", value)}
									>
										<ListItemIcon>
											<Check fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Aprovar" />
									</MenuItem>
									<MenuItem
										onClick={() => handleMenuSelected("reprovar", value)}
									>
										<ListItemIcon>
											<Clear fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Reprovar" />
									</MenuItem>
								</Menu>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
			</div>
		</div>
	);
};
