import "./style.css";

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getPainelEmpreendimentos } from "redux/Allotment/AllotmentAction";

import Loader from "../../components/Loader";
import Painel from "./Panel";

function Empreendimento(props) {
	useEffect(() => {
		props.dispatch(getPainelEmpreendimentos());
		// eslint-disable-next-line
	}, []);

	const { empreendimento } = props.store;
	return (
		<>
			<Loader {...props} />
			<div className="panel-container">
				{empreendimento.allEmpreendimentos &&
					empreendimento.allEmpreendimentos.map((value) => (
						<Painel
							key={value.cod_empreendimento}
							data={value}
							graphic={true}
						/>
					))}
			</div>
		</>
	);
}

export default Empreendimento = connect((store) => ({ store: store }))(
	Empreendimento
);
