import { hideLoading, showLoading } from "components/Loader";
import { call, put, takeEvery } from "redux-saga/effects";
import { API } from "utils/API";

import { history } from "../../App";
import {
	ESPELHOEMPREENDIMENTO,
	ESPELHOEMPREENDIMENTOSAGA,
	GETPLANOPAGAMENTO,
	GETPLANOPAGAMENTOSAGA,
	PAINELEMPREENDIMENTO,
	PAINELEMPREENDIMENTOSAGA,
} from "./AllotmentType";

function* getPainelEmpreendimentos() {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "painel");
		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: PAINELEMPREENDIMENTO, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getPlanoPagamento({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "planopagamento", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETPLANOPAGAMENTO, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getEspelhoByEmpreendimento({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "espelho", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: ESPELHOEMPREENDIMENTO, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

export default function* AllotmentSaga() {
	yield takeEvery(PAINELEMPREENDIMENTOSAGA, getPainelEmpreendimentos);
	yield takeEvery(GETPLANOPAGAMENTOSAGA, getPlanoPagamento);
	yield takeEvery(ESPELHOEMPREENDIMENTOSAGA, getEspelhoByEmpreendimento);
}
