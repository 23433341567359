import React from "react";

import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	IconButton,
	Checkbox,
	withStyles,
} from "@material-ui/core";

import Delete from "@material-ui/icons/Delete";
import "./style.css";

const CustomCheckbox = withStyles({
	root: {
		"&$checked": {
			color: "#0548a3",
		},
	},
	checked: {},
})(Checkbox);

export default (props) => {
	// const { contato } = props.store;

	return (
		<>
			<TableContainer component={Paper} className="opportunity-table-container">
				<Table className="table-container__table">
					<TableHead>
						<TableRow className="hide-xs">
							<TableCell />
							<TableCell className="table-container__table--title">
								Nome
							</TableCell>

							<TableCell />

							<TableCell className="table-container__table--title">
								Prazo
							</TableCell>
							<TableCell className="table-container__table--title">
								Etapa
							</TableCell>
							<TableCell className="table-container__table--title">
								Resp
							</TableCell>
							<TableCell className="table-container__table--title">
								Pretensão
							</TableCell>
							<TableCell />
							<TableCell />
						</TableRow>
					</TableHead>

					<TableBody>
						{/* {contato.allContatos.map((value, index) => ( */}
						<TableRow hover /*key={index}*/>
							<TableCell className="hide-xs">
								<CustomCheckbox
								// checked={isItemSelected}
								// inputProps={{ "aria-labelledby": labelId }}
								/>
							</TableCell>

							<TableCell className="table-container__table--show">
								<span className="hide-xs">Fernando Garcia</span>
								<br />
								<span className="hide-xs opportunity-table-container__color-primary">
									fernando@viscom.com.br
								</span>
								<span className="table-container__info">Fernando Garcia</span>
								<span className="table-container__info">
									5877-0000 / 5555-1111 /
								</span>
								<span className="table-container__info">4455-2222</span>
							</TableCell>

							<TableCell className="hide-xs">
								<span className="opportunity-table-container__color-primary">
									5877-0000
								</span>
							</TableCell>

							<TableCell>
								<span className="hide-xs">2 dias</span>
							</TableCell>

							<TableCell className="hide-xs">
								<span className="hide-xs">4. Negociação</span>
							</TableCell>

							<TableCell className="hide-xs">
								<span className="hide-xs opportunity-table-container__resp">
									BN
								</span>
							</TableCell>

							<TableCell className="hide-xs">
								<span className="hide-xs">C - Casa.</span>
								<br />
								<span className="hide-xs">Terr.</span>
							</TableCell>

							<TableCell className="hide-xs">
								<span className="hide-xs opportunity-table-container__italic ">
									Indiferente
								</span>
							</TableCell>

							<TableCell
							// onClick={() =>
							// 	props.handleClickConfirmDelete &&
							// 	props.handleClickConfirmDelete(value)
							// }
							>
								<IconButton className="btn__circular">
									<Delete className="icon__delete" />
								</IconButton>
							</TableCell>
						</TableRow>
						{/* ))} */}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
