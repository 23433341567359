import "date-fns";
import "./style.css";

import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import React from "react";
import {
	handleChangeProposta,
	validateFormProposta,
} from "redux/Sale/SaleAction";

export default (props) => {
	const { venda, imovel } = props.store;
	const { cod_opor } = props.match.params;

	const handleClickGerar = () => {
		let error = false;
		let formErrors = Object.entries(venda.proposta).map(([key, value]) => {
			return {
				name: key,
				value,
			};
		});

		props.dispatch(validateFormProposta(formErrors));

		Object.values(venda.validateForm).forEach((value) => {
			if (value !== "") error = true;
		});

		if (error) return;

		if (cod_opor) props.saveProposta(0, venda.proposta, cod_opor);
		else props.saveProposta(venda.proposta.contato, venda.proposta);

		props.setStep(props.nextStep);
	};

	const handleOnChangeDate = (name, date) => {
		if (!date) return;
		if (date && date.toString() === "Invalid Date") return;

		const data = {
			name,
			value: date.toISOString(),
			calculo: imovel.currentCalculo,
		};

		props.dispatch(handleChangeProposta(data));
		props.dispatch(validateFormProposta([data]));
	};

	return (
		<div className="proposal">
			<div className="proposal__header">
				<h2 className="proposal__title blue--light">Confirmação da proposta</h2>
				<h4 className="proposal__sub-title gray--younect">
					Para concluir a proposta, <br />
					preencha todos os campos abaixo
				</h4>
			</div>

			<form className="proposal__form">
				<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
					<KeyboardDatePicker
						className="input__primary proposal__input"
						required
						autoOk
						disableToolbar
						format="dd/MM/yyyy"
						margin="normal"
						label="Vencto da primeira entrada"
						invalidDateMessage="Data em formato inválido"
						// maxDateMessage="A data não pode ser maior ou igual a data atual"
						value={venda.proposta.data_entrada_inicial || null}
						onChange={(date) =>
							handleOnChangeDate("data_entrada_inicial", date)
						}
						cancelLabel={null}
						okLabel={null}
						error={venda.validateForm.data_entrada_inicial ? true : false}
						helperText={venda.validateForm.data_entrada_inicial}
					/>
				</MuiPickersUtilsProvider>

				<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
					<KeyboardDatePicker
						className="input__primary proposal__input"
						required
						autoOk
						disableToolbar
						format="dd/MM/yyyy"
						margin="normal"
						label="Vencto da primeira parcela"
						invalidDateMessage="Data em formato inválido"
						// maxDateMessage="A data não pode ser maior ou igual a data atual"
						value={venda.proposta.data_parcela_inicial || null}
						onChange={(date) =>
							handleOnChangeDate("data_parcela_inicial", date)
						}
						cancelLabel={null}
						okLabel={null}
						error={venda.validateForm.data_parcela_inicial ? true : false}
						helperText={venda.validateForm.data_parcela_inicial}
					/>
				</MuiPickersUtilsProvider>
			</form>

			<div className="proposal__btn-container">
				<Button className="btn__blue" onClick={handleClickGerar}>
					<Check className="proposal__icon-check" />
					Gerar
				</Button>
			</div>
		</div>
	);
};
