import { GETRELATORIOSAGA } from "./ReportType";

export function getRelatorio(id_relatorio, parametros) {
	let data = {
		id_relatorio,
		parametros,
	};

	return { type: GETRELATORIOSAGA, data };
}
