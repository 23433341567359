import React, { useState, useEffect } from "react";

import { Avatar, Grid, TextField, MenuItem, Button } from "@material-ui/core";
import { Close, Check } from "@material-ui/icons";

import { addOportunidade } from "redux/Negotiation/NegotiationAction";

const initialForm = {
	cod_usuario: "",
	cod_prospeccao: "",
};

export default (props) => {
	const { pessoa, oportunidade } = props.store;
	const [lead, setLead] = useState({});
	const [form, setForm] = useState(initialForm);
	const [prospeccao, setProspeccao] = useState([]);
	const [corretores, setCorretores] = useState([]);

	useEffect(() => {
		if (pessoa.currentPessoa)
			setLead({
				nome: pessoa.currentPessoa.pessoa_nome,
				email: pessoa.currentPessoa.pessoa_email,
				celular: pessoa.currentPessoa.pessoa_celular1,
				foto: pessoa.currentPessoa.pessoa_foto,
				codigo: pessoa.currentPessoa.cod_pessoa,
			});
	}, [pessoa.currentPessoa]);

	useEffect(() => {
		if (oportunidade.selectedPessoa) setLead(oportunidade.selectedPessoa);
	}, [oportunidade.selectedPessoa]);

	useEffect(() => {
		if (oportunidade.allProspeccao) setProspeccao(oportunidade.allProspeccao);
	}, [oportunidade.allProspeccao]);

	useEffect(() => {
		if (oportunidade.allCorretores) setCorretores(oportunidade.allCorretores);
	}, [oportunidade.allCorretores]);

	const handleOnChange = (e) => {
		let { name, value } = e.target;
		setForm({ ...form, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = {
			cod_pessoa: lead.codigo,
			cod_prospeccao: form.cod_prospeccao,
			corretores: [{ cod_usuario: form.cod_usuario }],
		};

		console.log(data);
		props.dispatch(addOportunidade(data));
	};

	return (
		<Grid container spacing={2} direction="column">
			<Grid container item wrap="nowrap" spacing={2}>
				<Grid item>
					<Avatar
						alt="avatar"
						src={lead.foto || ""}
						style={{ width: "70px", height: "70px" }}
					/>
				</Grid>

				<Grid container item direction="column" justify="center">
					<Grid item>{lead.nome}</Grid>
					<Grid item>{lead.email}</Grid>
					<Grid item>{lead.celular}</Grid>
				</Grid>
			</Grid>

			<form onSubmit={handleSubmit}>
				<Grid container item direction="column" spacing={4}>
					<Grid item xs>
						<TextField
							fullWidth
							className="input__primary"
							autoComplete="off"
							label="Corretor"
							name="cod_usuario"
							value={form.cod_usuario || ""}
							onChange={handleOnChange}
							select
						>
							<MenuItem value="">
								<em>Nenhum</em>
							</MenuItem>
							{corretores.map((data, index) => (
								<MenuItem key={index} value={data.cod_usuario}>
									<Grid container spacing={1} alignItems="center">
										<Grid item>
											<Avatar
												alt="corretor"
												src={data.usuario_pessoa.pessoa_foto || ""}
											/>
										</Grid>
										<Grid item>{data.usuario_pessoa.pessoa_nome}</Grid>
									</Grid>
								</MenuItem>
							))}
						</TextField>
					</Grid>

					<Grid item xs>
						<TextField
							fullWidth
							className="input__primary"
							autoComplete="off"
							label="Prospecção"
							name="cod_prospeccao"
							value={form.cod_prospeccao || ""}
							onChange={handleOnChange}
							select
						>
							{prospeccao.map((data, index) => (
								<MenuItem key={index} value={data.cod_prospeccao}>
									{data.nome}
								</MenuItem>
							))}
						</TextField>
					</Grid>

					<Grid item xs>
						<Button
							// onClick={handleCancelar}
							className="btn__blue btn__blue--outlined"
							startIcon={<Close />}
						>
							Cancelar
						</Button>
						<Button type="submit" className="btn__blue" startIcon={<Check />}>
							Salvar
						</Button>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};
