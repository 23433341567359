import "./style.css";

import {
	Button,
	CardActions,
	CardContent,
	Collapse,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Slide,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@material-ui/core";
import {
	AttachFile,
	Cancel,
	Check,
	Clear,
	Close,
	Error,
	HelpOutline,
	MoreVert,
	Visibility,
} from "@material-ui/icons";
import React from "react";

import { formatDateToLocaleString } from "../../../utils/funcoes";
import ToastConfirm from "../../global/Toast";
import VisualizarDocumento from "./visualizar-documento";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
	const { venda } = props.store;
	const [expanded, setExpanded] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuItens, setMenuItens] = React.useState(null);
	const inputFile = React.useRef(null);
	const [current, setCurrent] = React.useState({});

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleClose = () => {
		props.closeFaseDocumentos();
	};

	const handleAttach = (value) => {
		setCurrent(value);
		inputFile.current.click(value);
	};

	const handleChange = (e) => {
		let reader = new FileReader();

		if (e.target.files && e.target.files.length > 0) {
			reader.readAsDataURL(e.target.files[0]);

			reader.onload = (file) => {
				let data = {
					imagem: file.target.result,
					...current,
				};

				if (current.cod_opor_fase_etapa_doc) {
					props.editFaseDocumento(data);
				} else {
					props.addFaseDocumento(data);
				}

				props.setShowToast({
					show: true,
					severity: "success",
					text: `${current.descricao} enviado(a)!`,
				});

				inputFile.current.value = null;
			};
		}
	};

	const handleOpenImage = (value) => {
		props.getFaseDocumentoImagem({
			cod_opor_fase_etapa_doc: value.cod_opor_fase_etapa_doc,
		});
	};

	const renderButtonEnviar = (value, texto) => {
		return (
			<Button
				className="venda__btn-send"
				startIcon={<AttachFile className="venda__icon-send" />}
				onClick={() => handleAttach(value)}
			>
				{texto}
			</Button>
		);
	};

	const renderButtonMore = (value) => {
		return (
			<>
				<IconButton edge="end" onClick={(e) => handleOpenMenu(e, value)}>
					<MoreVert />
				</IconButton>

				<Menu
					elevation={0}
					getContentAnchorEl={null}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					{menuItens &&
						menuItens.map((option, index) => (
							<MenuItem key={index} onClick={() => handleMenuSelected(option)}>
								<ListItemIcon>{option.icon}</ListItemIcon>
								<ListItemText primary={option.texto} />
							</MenuItem>
						))}
				</Menu>
			</>
		);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleOpenMenu = (e, value) => {
		setAnchorEl(e.currentTarget);

		setMenuItens([
			{
				icon: <Check fontSize="small" />,
				tipo: "aprovar",
				texto: "Aprovar",
				value: value,
			},
			{
				icon: <Clear fontSize="small" />,
				tipo: "reprovar",
				texto: "Reprovar",
				value: value,
			},
		]);
	};

	const handleMenuSelected = (option) => {
		const { tipo, value } = option;

		if (tipo === "aprovar") {
			props.setShowAlertQuestion({
				show: true,
				title: "Atenção",
				text: `Deseja realmente APROVAR o documento: ${value.descricao}?`,
				type: "documentos",
				dados: {
					tipo: "documentos",
					value,
				},
			});
		} else if (tipo === "reprovar") {
			props.setShowAlertQuestionReason({
				show: true,
				title: "Atenção",
				text: `Deseja realmente REPROVAR o documento: ${value.descricao}?`,
				dados: {
					tipo: "documentos",
					value,
				},
			});
		}

		setAnchorEl(null);
	};

	// const handleConfirmDocumentos = () => {
	// 	console.log("entrou documento");
	// 	const { cod_opor_fase_etapa_doc } = documento;

	// 	if (option === "aprovar") {
	// 		props.editFaseDocumento({ acao: "Aprovado", cod_opor_fase_etapa_doc });
	// 		props.setShowAlertQuestion({ show: false });
	// 	} else if (option === "reprovar") {
	// 		props.editFaseDocumento({
	// 			acao: "Reprovado",
	// 			situacao_motivo: global.showAlertQuestionReason.reason,
	// 			cod_opor_fase_etapa_doc,
	// 		});
	// 		props.setShowAlertQuestionReason({ show: false });
	// 	}

	// 	setOption(null);
	// 	setDocumento(null);
	// };

	const colorsSituacao = (value) => {
		switch (value) {
			case "Aguardando Aprovação":
				return "#ffc866";
			case "Aprovado":
				return "#469c47";
			case "Reprovado":
				return "#e93f66";
			default:
				return "#f0f0";
		}
	};

	const iconsSituacao = (value) => {
		switch (value.situacao) {
			case "Aguardando Aprovação":
				return <Error className="icon__error venda__icon--error" />;
			case "Aprovado":
				return <Check className="venda__icon--check" />;
			case "Reprovado":
				return <Cancel className="venda__icon--disapproved" />;
			default:
				return <Error className="icon__error venda__icon--error" />;
		}
	};

	return (
		<>
			<Dialog
				open={venda.showFaseDocumentos}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
			>
				<div className="venda">
					<DialogTitle className="venda__header">
						<DialogActions
							className="venda__icon-container"
							onClick={handleClose}
						>
							<Close className="icon__close" />
						</DialogActions>

						<span className="dialog-header__title">ENVIO DE DOCUMENTOS</span>

						<div className="venda__subtitle">
							<p>
								Use esta tela para enviar seus documentos para análise.
								<br />
								Basta clicar em cada tipo de documento para poder anexá-lo.
								<br />
								Em caso de dúvidas sobre o envio, clique no botão de ajuda (?)
							</p>
						</div>
					</DialogTitle>

					<div className="container-elevation teste">
						{venda.getFaseDocumentos.map((grupo, idx) => (
							<TableContainer
								key={idx}
								component={Paper}
								className="container-elevatio venda__container"
							>
								<div className="venda__title-container">
									<div className="venda__title-wrapper">
										<h3 className="venda__title blue--light">
											{grupo.doc_grupo_descricao}
										</h3>
										<CardActions disableSpacing className="venda__title-icon">
											<IconButton
												onClick={handleExpandClick}
												aria-expanded={expanded}
												aria-label="show more"
											>
												<HelpOutline className="venda__icon" />
											</IconButton>
										</CardActions>
									</div>

									<Collapse
										in={expanded}
										timeout="auto"
										unmountOnExit
										className="intro venda-container__collapse"
									>
										<CardContent className="venda-container__card">
											<Typography paragraph>Method:</Typography>
											<Typography paragraph>
												O incentivo ao avanço tecnológico, <br />
												assim como o aumento do diálogo entre os diferentes
												setores
												<br /> produtivos ainda não demonstrou convincentemente
												que vai
												<br /> participar na mudança das novas proposições.
											</Typography>
										</CardContent>
									</Collapse>
								</div>
								<Table>
									<TableBody>
										{grupo.doc_grupo_descricao_itens.map((value, index) => (
											<TableRow hover key={index}>
												<TableCell className="venda__cell">
													{iconsSituacao(value)}
												</TableCell>
												<TableCell className="venda__cell">
													{value.descricao} <br />
													<span className="venda__date">
														{value.data_envio
															? `Enviado ${formatDateToLocaleString(
																	value.data_envio
															  )}`
															: null}
													</span>
													{value.situacao && (
														<>
															<br />
															<span
																style={{
																	color: colorsSituacao(value.situacao),
																}}
															>
																{value.situacao
																	? value.situacao
																	: "Aguardando envio"}
															</span>
														</>
													)}
													{value.situacao_motivo && (
														<>
															<br />
															<span>{value.situacao_motivo}</span>
														</>
													)}
												</TableCell>
												<TableCell className="venda__cell">
													{value.cod_opor_fase_etapa_doc ? (
														<IconButton onClick={() => handleOpenImage(value)}>
															<Visibility className="icon__visibility venda__icon--visibility" />
														</IconButton>
													) : null}
												</TableCell>
												<TableCell className="venda__cell venda__cell--icon-more">
													{value.situacao === "Aguardando Aprovação"
														? renderButtonMore(value)
														: null}

													{value.situacao === "Reprovado"
														? renderButtonEnviar(value, "Reenviar")
														: null}

													{!value.situacao
														? renderButtonEnviar(value, "Enviar")
														: null}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						))}
					</div>

					<div className="venda__btn-container">
						<Button className="btn__blue" onClick={handleClose}>
							<Check className="icon__check venda__icon--confirm" />
							Confirmar
						</Button>
					</div>
					<ToastConfirm {...props} />
				</div>
			</Dialog>

			<input
				className="venda__input"
				onChange={handleChange}
				type="file"
				accept="image/*, .pdf"
				ref={inputFile}
			/>

			<VisualizarDocumento {...props} />

			{/* <AlertQuestion {...props} handle={handleConfirmDocumentos} />
			<AlertQuestionReason {...props} handleConfirm={handleConfirmDocumentos} /> */}
		</>
	);
};
