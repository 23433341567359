import axios from "axios";

import { history } from "../App";

const BASE_URL_WEBSOCKET = "ws://www.viscom.serveusers.com:9998";
const baseURL = "http://www.viscom.serveusers.com:9998/api/";
const baseURL_REL = "http://www.viscom.serveusers.com:9996/api/";

const API = axios.create({
	baseURL: baseURL,
	responseType: "json",
});
const API_BLOB = axios.create({
	baseURL: baseURL,
	responseType: "blob",
});
const API_REL = axios.create({
	baseURL: baseURL_REL,
	responseType: "blob",
});

// HEADERS DEFAULT
const headersDefault = () => {
	let headers = {};

	headers["Content-Type"] = "application/json";

	const token = localStorage.getItem("@YouNectImob:token");

	if (token) {
		headers["x-access-token"] = token;
	}

	return headers;
};

// REQUEST INTERCEPTOR
API.interceptors.request.use((config) => {
	config.params = config.params || {};
	config.headers = headersDefault();
	return config;
});
API_BLOB.interceptors.request.use((config) => {
	config.params = config.params || {};
	config.headers = headersDefault();
	return config;
});
API_REL.interceptors.request.use((config) => {
	config.params = config.params || {};
	config.headers = headersDefault();
	return config;
});

// RETURN REPONSE
const responseInterceptor = (response) => {
	if (response.data && response.data.token)
		localStorage.setItem("@YouNectImob:token", response.data.token);

	return response;
};

// RETURN ERROR
const errorInterceptor = (error) => {
	if (error.response.status === 401) {
		localStorage.removeItem("@YouNectImob:token");
		history.push("/");
		window.location.reload(true);

		// originalRequest._retry = true;
		// return axios
		// 	.post(`${baseURL}/refresh_token`, {
		// 		refresh_token: token,
		// 	})
		// 	.then((res) => {
		// 		if (res.status === 200) {
		// 			localStorage.setItem("@YouNectImob:token", res.data.token);

		// 			axios.defaults.headers.common["x-access-token"] = res.data.token;

		// 			return axios(originalRequest);
		// 		}
		// 	});
	}

	return error.response;
};

//RESPONSE INTERCEPTOR
API.interceptors.response.use(
	(response) => responseInterceptor(response),
	(error) => errorInterceptor(error)
);

API_BLOB.interceptors.response.use(
	(response) => responseInterceptor(response),
	(error) => errorInterceptor(error)
);

export { API, API_REL, API_BLOB, BASE_URL_WEBSOCKET };
