import Loader from "components/Loader";
import Titulo from "components/titulo";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllUsuarios } from "redux/User/UserAction";

import { history } from "../../App";
import Grid from "./Grid";

class Usuario extends Component {
	componentDidMount() {
		this.props.dispatch(getAllUsuarios());
	}

	handleClickAdd() {
		history.push("/usuario/register");
	}

	render() {
		return (
			<>
				<Loader {...this.props} />
				<Titulo
					{...this.props}
					title="Usuários"
					standardAddOption
					onClickAdd={this.handleClickAdd}
				/>
				<Grid {...this.props} />
			</>
		);
	}
}

const mapStateToProps = (state) => ({ store: state });
export default Usuario = connect(mapStateToProps)(Usuario);
