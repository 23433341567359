import { Checkbox, withStyles } from "@material-ui/core";
import React from "react";

export default (props) => {
	const CustomCheckbox = withStyles({
		root: {
			"&$checked": {
				color: "#0548a3",
			},
		},
		checked: {},
	})(Checkbox);

	return <CustomCheckbox {...props} />;
};
