import "./style.css";

import {
	Avatar,
	Button,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Grid,
	Snackbar,
	Switch,
	TextField,
	Typography,
	withStyles,
} from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { Checkbox } from "components";
import React, { useEffect, useState } from "react";

import { history } from "../../../App";
import {
	CpfCnpjIsValid,
	emailIsValid,
	maskCelular,
	maskCpfCnpj,
} from "../../../utils/funcoes";

const initialErrors = {
	cpf: "",
	nome: "",
	email: "",
	celular: "",
};

const initialForm = {
	cpf: "",
	nome: "",
	email: "",
	celular: "",
	foto: null,
	cod_pessoa: null,
};

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomSwitch = withStyles({
	switchBase: {
		color: "white",
		"&$checked": {
			color: "#52d869",
		},
		"&$checked + $track": {
			backgroundColor: "#52d869",
		},
	},
	checked: {},
	track: {},
})(Switch);

export default (props) => {
	const { params } = props.match;
	const { getUsuarioByID, initialValues } = props;
	const { pessoa, resposta, funcoes, currentUser } = props.store.usuario;
	const [form, setForm] = useState(initialForm);
	const [formValidade, setFormValidate] = useState(initialErrors);
	const [errorFuncoes, setErrorsFuncoes] = useState(false);
	const [selectedFuncoes, setSelectedFuncoes] = useState([]);
	const [open, setOpen] = useState(false);
	const [messageError, setMessageError] = useState(false);
	const [emailOld, setEmailOld] = useState("");

	useEffect(() => {
		const newForm = {
			nome: pessoa.pessoa_nome ? pessoa.pessoa_nome : "",
			email: pessoa.pessoa_email ? pessoa.pessoa_email : "",
			celular: pessoa.fone_celular1 ? pessoa.fone_celular1 : "",
			cod_pessoa: pessoa.cod_pessoa ? pessoa.cod_pessoa : null,
			foto: pessoa.pessoa_foto ? pessoa.pessoa_foto : null,
		};

		setForm((oldForm) => ({
			...oldForm,
			...newForm,
		}));

		setFormValidate(initialErrors);
	}, [pessoa]);

	useEffect(() => {
		console.log(resposta);

		if (resposta.message === "success") {
			history.push("/usuario");
			initialValues();
		}

		if (resposta.errors) {
			setMessageError(resposta.errors);
			setOpen(true);
		}
	}, [resposta, initialValues]);

	useEffect(() => {
		if (params.id) getUsuarioByID({ cod_usuario: params.id });
		else getUsuarioByID({ cod_usuario: 0 });
	}, [params, getUsuarioByID]);

	useEffect(() => {
		const {
			nome,
			cpf,
			email,
			celular,
			cod_pessoa,
			cod_usuario,
			situacao,
			foto,
		} = currentUser;
		const { usuario_funcao } = currentUser;

		if (currentUser) {
			setForm({
				nome,
				cpf,
				email,
				celular,
				cod_pessoa,
				cod_usuario,
				situacao,
				foto,
			});
			setEmailOld(email);
		} else {
			setForm(initialForm);
			setEmailOld("");
		}

		if (usuario_funcao) {
			const funcoes = usuario_funcao
				.filter((value) => value.acesso === true)
				.map((value) => ({ cod_funcao: value.cod_funcao }));

			setSelectedFuncoes(funcoes);
		} else {
			setSelectedFuncoes([]);
		}
	}, [currentUser]);

	const validadeFuncoesUser = () => {
		let error = false;

		if (selectedFuncoes.length <= 0) {
			setErrorsFuncoes(true);
			error = true;
		}

		return error;
	};

	const handleCheck = (id) => {
		setErrorsFuncoes(false);
		const alreadySelected = selectedFuncoes.findIndex(
			(item) => item.cod_funcao === id
		);

		if (alreadySelected >= 0) {
			const filteredItems = selectedFuncoes.filter(
				(item) => item.cod_funcao !== id
			);
			setSelectedFuncoes(filteredItems);
		} else {
			setSelectedFuncoes([...selectedFuncoes, { cod_funcao: id }]);
		}
	};

	const handleOnChange = (e) => {
		let { name, value } = e.target;

		if (name === "cpf") value = maskCpfCnpj(value);
		if (name === "celular") value = maskCelular(value);

		validate([{ name, value }]);
		setForm({ ...form, [name]: value });

		if (name === "cpf" && value.length >= 14)
			props.getPessoaByCPF({ pessoa_cpf: value.replace(/[^\d]+/g, "").trim() });
	};

	const handleOnBlur = (e) => {
		const { name, value } = e.target;
		validate([{ name, value }]);
	};

	const validate = (form) => {
		let error = false;
		let formErros = formValidade;

		form.forEach((data) => {
			switch (data.name) {
				case "cpf": {
					let cpf = "";

					if (!data.value) {
						cpf = "Preencha um CPF válido";
						formErros = { ...formErros, cpf };
						error = cpf ? true : false;
					}

					if (data.value.length >= 14) {
						cpf = CpfCnpjIsValid(data.value) ? "" : "CPF Inválido";
						formErros = { ...formErros, cpf };
						error = cpf ? true : false;
					}

					break;
				}
				case "email": {
					let email = "";

					if (!data.value) {
						email = "Preencha um e-mail válido";
						formErros = { ...formErros, email };
						error = email ? true : false;
					}

					if (data.value.length >= 1) {
						email = emailIsValid(data.value) ? "" : "E-Mail Inválido";
						formErros = { ...formErros, email };
						error = email ? true : false;
					}

					break;
				}
				case "nome": {
					let nome = "";

					if (!data.value) {
						nome = "Preencha um nome válido";
					}

					formErros = { ...formErros, nome };
					error = nome ? true : false;

					break;
				}
				case "celular": {
					let celular = "";

					if (!data.value) {
						celular = "Preencha um celular válido";
					}

					formErros = { ...formErros, celular };
					error = celular ? true : false;

					break;
				}
				default:
					error = false;
			}
		});

		setFormValidate(formErros);
		return error;
	};

	const validateForm = () => {
		const { cpf, nome, email, celular } = form;
		let error = validate([
			{ name: "cpf", value: cpf },
			{ name: "nome", value: nome },
			{ name: "email", value: email },
			{ name: "celular", value: celular },
		]);

		return error;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validateForm()) return;
		if (validadeFuncoesUser()) return;

		let data = {
			...form,
			funcoes: selectedFuncoes,
		};

		if (data.cod_usuario) {
			let mudou_email = "NÃO";

			if (emailOld !== data.email) mudou_email = "SIM";

			data = { ...data, mudou_email };
			console.log(data);
			props.editUsuario(data);
		} else props.addUsuario(data);
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	const handleCancel = () => {
		history.push("/usuario");
	};

	const handleChangeSwitch = () => {
		setForm({
			...form,
			situacao: form.situacao === "Ativo" ? "Inativo" : "Ativo",
		});
	};

	const handleFileUpload = (e) => {
		let reader = new FileReader();

		if (e.target.files && e.target.files.length > 0) {
			reader.readAsDataURL(e.target.files[0]);

			reader.onload = (file) => {
				setForm({ ...form, foto: file.target.result });
			};
		}
	};

	return (
		<div className="container-elevation user-register bg-gray--bluish">
			<form
				className="user-register__form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<div className="user-register__container">
					<div className="column">
						<div className="user-register__inner">
							{form.cod_usuario && form.situacao !== "Pendente" && (
								<Typography component="div">
									<Grid component="label" container alignItems="center">
										<Grid item className="user-register__label-status">
											Inativo
										</Grid>
										<Grid item>
											<CustomSwitch
												checked={form.situacao === "Ativo" ? true : false}
												onChange={handleChangeSwitch}
											/>
										</Grid>
										<Grid item className="user-register__label-status">
											Ativo
										</Grid>
									</Grid>
								</Typography>
							)}

							<div className="avatar-container">
								<Avatar
									alt="avatar"
									src={form.foto || ""}
									className="avatar-container__avatar user-register__avatar"
								/>
								<input
									type="file"
									accept="image/*"
									onChange={handleFileUpload}
									className="avatar-container__file user-register__file"
								/>
							</div>
						</div>

						<TextField
							className="input__primary user-register__input"
							autoComplete="off"
							type="text"
							label="CPF"
							name="cpf"
							value={form.cpf || ""}
							onChange={handleOnChange}
							onBlur={handleOnBlur}
							error={formValidade.cpf ? true : false}
							helperText={formValidade.cpf}
							inputProps={{ maxLength: 14 }}
							disabled={!!form.cod_usuario}
						/>

						<TextField
							className="input__primary user-register__input"
							autoComplete="off"
							label="Nome"
							name="nome"
							value={form.nome || ""}
							onChange={handleOnChange}
							onBlur={handleOnBlur}
							error={formValidade.nome ? true : false}
							helperText={formValidade.nome}
						/>

						<TextField
							className="input__primary user-register__input"
							autoComplete="off"
							label="E-Mail"
							name="email"
							value={form.email || ""}
							onChange={handleOnChange}
							onBlur={handleOnBlur}
							error={formValidade.email ? true : false}
							helperText={formValidade.email}
						/>

						<TextField
							className="input__primary user-register__input"
							autoComplete="off"
							label="Celular"
							name="celular"
							value={form.celular || ""}
							onChange={handleOnChange}
							onBlur={handleOnBlur}
							error={formValidade.celular ? true : false}
							helperText={formValidade.celular}
							inputProps={{ maxLength: 17 }}
						/>
					</div>
					<FormControl
						fullWidth
						error={errorFuncoes}
						required
						component="fieldset"
						className="user-register__checkbox-container"
					>
						<h4 className="user-register__sub-title">Privilégios de Acesso</h4>

						<FormGroup>
							{funcoes &&
								funcoes.length > 0 &&
								funcoes.map((value) => (
									<React.Fragment key={value.cod_funcao}>
										<FormControlLabel
											control={
												<Checkbox
													className="user-register__checkbox"
													checked={selectedFuncoes.some(
														(item) => item.cod_funcao === value.cod_funcao
													)}
													name={value.funcao_nome}
													onChange={() => handleCheck(value.cod_funcao)}
												/>
											}
											label={value.funcao_nome}
										/>
										<span className="user-register__description">
											Descrição virá do backend
										</span>
									</React.Fragment>
								))}
						</FormGroup>

						{props.error ? (
							<FormHelperText>Selecione pelo menos uma função</FormHelperText>
						) : null}
					</FormControl>
				</div>

				<div className="user-register__btn-container">
					<Button
						onClick={handleCancel}
						className="btn__blue btn__blue--outlined user-register__btn"
					>
						<Close className="dialog-register__icon-close" />
						Cancelar
					</Button>

					<Button type="submit" className="btn__blue user-register__btn">
						<Check className="icon__check dialog-register__icon-check" />
						Salvar
					</Button>
				</div>
			</form>

			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={open}
				autoHideDuration={5000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity="error">
					{messageError}
				</Alert>
			</Snackbar>
		</div>
	);
};
