import React, { useState } from "react";
import { setShowAlertQuestionContato } from "redux/Global/GlobalAction";
import { getRelatorio } from "redux/Report/ReportAction";
import { addReserva, addReservaNegociacao } from "redux/Sale/SaleAction";

import Compartilhar from "../compartilhar";
import Contato from "../contato";
import BoolingConfirmation from "./BookingConfirmation";

export default (props) => {
	const { imovel, venda } = props.store;
	const [step, setStep] = useState("calculo");
	const [contato, setContato] = useState({});
	const { cod_opor } = props.match.params;

	const handleSetStep = (value) => {
		setStep(value);
	};

	const handleToShare = () => {
		const { id_relatorio, cod_opor, cod_fase } = venda.getReserva;
		if (id_relatorio)
			props.dispatch(getRelatorio(id_relatorio, { cod_opor, cod_fase }));
	};

	const handleConfirmReserva = () => {
		if (cod_opor) {
			props.dispatch(addReservaNegociacao(imovel.getImovel, cod_opor));
		} else {
			props.dispatch(addReserva(contato, imovel.getImovel));
		}

		handleSetStep("compartilhar");
		props.dispatch(setShowAlertQuestionContato({ show: false }));
	};

	const renderStep = () => {
		switch (step) {
			case "contato":
				return renderDefault();
			case "compartilhar":
				return (
					<Compartilhar
						{...props}
						toShare={handleToShare}
						shareText="Sua reserva foi enviada e está em análise pelo administrador!"
					/>
				);
			default:
				return renderDefault();
		}
	};

	const renderDefault = () => {
		if (cod_opor) {
			return (
				<BoolingConfirmation {...props} handleSave={handleConfirmReserva} />
			);
		} else {
			return (
				<Contato
					{...props}
					setContato={setContato}
					handleConfirmReserva={handleConfirmReserva}
					reserva
				/>
			);
		}
	};

	return <div className="dialog-body__budget">{renderStep()}</div>;
};
