import {
	Button,
	ButtonGroup,
	Chip,
	Collapse,
	FormControl,
	FormGroup,
	IconButton,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	Slider,
	Switch,
	TextField,
	Typography,
	useTheme,
} from "@material-ui/core";
import { Apps, SearchOutlined, ViewList } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { setTypeView } from "../../../redux/QueryProperty/QueryPropertyAction";
import { getImovelSearch } from "../../../redux/QueryProperty/QueryPropertyAction";
import { getAllSelects } from "../../../redux/Selects/SelectsActions";
import style from "./style.module.css";
function valuetext(value) {
	return `${value}°C`;
}
const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

function AdvancedFilters({ typeView, selects, dispatch, pageSearch }) {
	const [listOrGrid, setListOrGrid] = useState(false);
	const [expanded, setExpanded] = useState(true);
	const [select, setSelect] = useState({
		empreendimento: [],
		tipo: [],
		estado: [],
		situacao: [],
		filters: [],
	});
	const classes = useStyles();
	const theme = useTheme();

	useEffect(() => {
		dispatch(getAllSelects());
		// eslint-disable-next-line
	},[])

	useEffect(() => {
		setSelect({ ...selects });
		// eslint-disable-next-line
	},[selects])



	useEffect(() => {
		if (typeView === "list") {
			setListOrGrid(false);
		} else {
			setListOrGrid(true);
		}
	}, [typeView]);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const marks = [
		{
			value: 0,
			label: "R$ 250.000,00",
		},

		{
			value: 100,
			label: "Até 1 Milhão",
		},
	];

	const classesss = useStyles();

	function alterToGrid() {
		dispatch(setTypeView("grid"));
	}
	function alterToList() {
		dispatch(setTypeView("list"));
	}

	const [empreendimento, setEmpreendimento] = useState([]);
	const [proprietario, setProprietario] = useState([]);
	const [situacao, setSituacao] = useState([]);
	const [estadoImovel, setEstadoImovel] = useState([]);
	const [localizacao, setLocalizacao] = useState([]);
	const [terreno, setTerreno] = useState([]);
	const [quadra, setQuadra] = useState("");
	const [lote, setLote] = useState("");
	const [desmenbramento, setDesembargamento] = useState(false);
	const [metragem, setMetragem] = useState([0, 300]);
	const [precoTotal, setPrecoTotal] = useState([100000, 200000]);

	const handleChangeEmpreendimento = (event) => {
		setEmpreendimento(event.target.value || []);
	};

	const handleChangeProprietario = (event) => {
		setProprietario(event.target.value || []);
	};

	const handleChangeSituacao = (event) => {
		setSituacao(event.target.value || []);
	};

	const handleChangeEstadoImovel = (event) => {
		setEstadoImovel(event.target.value || []);
	};

	const handleChangeLocalizacao = (event) => {
		setLocalizacao(event.target.value || []);
	};

	const handleChangeTerreno = (event) => {
		setTerreno(event.target.value || []);
	};

	const handleChangeQuadra = (event) => {
		setQuadra(event.target.value);
	};

	const handleChangeLote = (event) => {
		setLote(event.target.value);
	};

	const handleChangeDesmembramento = (event) => {
		setDesembargamento(event.target.checked);
	};

	const handleChangeMetragem = (event, newValue) => {
		setMetragem(newValue);
	};

	const handleChangePrecoTotal = (event, newValue) => {
		setPrecoTotal(newValue);
	};
	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			setSearch();
		} else {
			didMountRef.current = true;
		}
		// eslint-disable-next-line
	}, [pageSearch]);

	const setSearch = () => {
		function setObjectSearch(array, datas) {
			datas.forEach((data) => {
				if (typeof data.state === "number") {
					array.push(`${data.name}=${data.state}`);
				} else {
					if (typeof data.state === "boolean") {
						array.push(`${data.name}=${data.state ? "SIM" : "NÃO"}`);
					} else {
						if (typeof data.state === "string" && data.state !== "") {
							array.push(`${data.name}=${data.state}`);
						} else {
							if (typeof data.state === "object") {
								data.state.map((d, index) => {
									array.push(
										`${data.propertyReturn}=${getIn(
											selects,
											data.name,
											data.property,
											data.propertyReturn,
											data.state[index]
										)}`
									);
								});
							}
						}
					}
				}
			});
		}

		function getIn(selects, selectName, property, propertyReturn, value) {
			var selec = selects[selectName].find((e) => e[property] === value);
			return selec[propertyReturn];
		}

		var searchFilter = [];

		const data = [
			{
				state: empreendimento,
				name: "empreendimento",
				property: "empreend_nome",
				propertyReturn: "cod_empreendimento",
			},
			{
				state: proprietario,
				name: "proprietario",
				property: "proprietario",
				propertyReturn: "",
			},
			{
				state: situacao,
				name: "situacao",
				property: "situacao_nome",
				propertyReturn: "cod_situacao",
			},
			{
				state: estadoImovel,
				name: "estado",
				property: "nome",
				propertyReturn: "cod_imovelestado",
			},
			{
				state: localizacao,
				name: "localizacao",
				property: "localizacao",
				propertyReturn: "",
			},
			{
				state: terreno,
				name: "terreno",
				property: "terreno",
				propertyReturn: "",
			},
			{ state: quadra, name: "quadra", property: "quadra", propertyReturn: "" },
			{ state: lote, name: "lote", property: "lote", propertyReturn: "" },
			{
				state: desmenbramento,
				name: "permite_desmembramento",
				property: "desembargamento",
				propertyReturn: "",
			},

			{
				state: metragem[0],
				name: "area_metro_min",
				property: "",
				propertyReturn: "",
			},
			{
				state: metragem[1],
				name: "area_metro_max",
				property: "",
				propertyReturn: "",
			},
			{
				state: precoTotal[0],
				name: "preco_total_min",
				property: "",
				propertyReturn: "",
			},
			{
				state: precoTotal[1],
				name: "preco_total_max",
				property: "",
				propertyReturn: "",
			},
		];
		setObjectSearch(searchFilter, data);
		searchFilter.push(`page=${pageSearch}`);
		dispatch(getImovelSearch(searchFilter));
	};

	return (
		<React.Fragment>
			<div
				className={clsx(
					style.collapseHeader,
					expanded && style.collapseHeaderBorderBottom
				)}
			>
				<div className={style.collapseHeaderLeft} onClick={handleExpandClick}>
					<Typography className={style.collapseHeaderTitle}>
						Consulta de Imóveis
					</Typography>

					<IconButton
						className={clsx(classes.expand, {
							[classes.expandOpen]: expanded,
						})}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon color="primary" />
					</IconButton>
				</div>

				<div>
					<ButtonGroup
						disableElevation
						variant="contained"
						className={style.buttonGroup}
					>
						<Button
							onClick={alterToList}
							className={clsx(style.buttons, { [style.activeB]: !listOrGrid })}
						>
							<ViewList color="primary" />
						</Button>
						<Button
							onClick={alterToGrid}
							className={clsx(style.buttons, { [style.activeB]: listOrGrid })}
						>
							<Apps color="primary" />
						</Button>
					</ButtonGroup>
				</div>
			</div>

			<Collapse
				in={expanded}
				timeout="auto"
				className={style.collapse}
				unmountOnExit
			>
				<div className={style.filterAdvance}>
					<div className={style.filterAdvanceInputs}>
						<FormControl variant="outlined" className={style.input}>
							<InputLabel id="demo-mutiple-chip-label">
								Empreendimento
							</InputLabel>
							<Select
								labelId="demo-mutiple-chip-label"
								id="demo-mutiple-chip"
								multiple
								value={empreendimento}
								onChange={handleChangeEmpreendimento}
								renderValue={(selected) => (
									<div className={classesss.chips}>
										{selected.map((value) => (
											<Chip
												className={classesss.chip}
												key={value}
												label={value}
												color="primary"
												onDelete={() => {
													setEmpreendimento(
														empreendimento.filter((item) => item !== value)
													);
												}}
												onMouseDown={(event) => {
													event.stopPropagation();
												}}
											/>
										))}
									</div>
								)}
							>
								{select.empreendimento.map((emp) => (
									<MenuItem
										key={emp.cod_empreendimento}
										value={emp.empreend_nome}
										style={getStyles(emp.empreend_nome, empreendimento, theme)}
									>
										{emp.empreend_nome}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl variant="outlined" className={style.input}>
							<InputLabel id="demo-mutiple-chip-label">Proprietário</InputLabel>
							<Select
								labelId="demo-mutiple-chip-label"
								id="demo-mutiple-chip"
								multiple
								value={proprietario}
								onChange={handleChangeProprietario}
								renderValue={(selected) => (
									<div className={classesss.chips}>
										{selected.map((value) => (
											<Chip
												className={classesss.chip}
												key={value}
												label={value}
												color="primary"
												onDelete={() =>
													setProprietario(
														proprietario.filter((item) => item !== value)
													)
												}
												onMouseDown={(event) => {
													event.stopPropagation();
												}}
											/>
										))}
									</div>
								)}
							>
								{/* {names.map((name) => (
									<MenuItem
										key={name}
										value={name}
										style={getStyles(name, personName, theme)}
									>
										{name}
									</MenuItem>
								))} */}
							</Select>
						</FormControl>

						<FormControl variant="outlined" className={style.input}>
							<InputLabel id="demo-mutiple-chip-label">Situação</InputLabel>
							<Select
								labelId="demo-mutiple-chip-label"
								id="demo-mutiple-chip"
								multiple
								value={situacao}
								onChange={handleChangeSituacao}
								renderValue={(selected) => (
									<div className={classesss.chips}>
										{selected.map((value) => (
											<Chip
												className={classesss.chip}
												key={value}
												label={value}
												color="primary"
												onDelete={() =>
													setSituacao(situacao.filter((item) => item !== value))
												}
												onMouseDown={(event) => {
													event.stopPropagation();
												}}
											/>
										))}
									</div>
								)}
							>
								{select.situacao.map((sit) => (
									<MenuItem
										key={sit.cod_situacao}
										value={sit.situacao_nome}
										style={getStyles(sit.situacao_nome, situacao, theme)}
									>
										{sit.situacao_nome}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl variant="outlined" className={style.input}>
							<InputLabel id="demo-mutiple-chip-label">
								Estado do Imóvel
							</InputLabel>
							<Select
								labelId="demo-mutiple-chip-label"
								id="demo-mutiple-chip"
								multiple
								value={estadoImovel}
								onChange={handleChangeEstadoImovel}
								renderValue={(selected) => (
									<div className={classesss.chips}>
										{selected.map((value) => (
											<Chip
												className={classesss.chip}
												key={value}
												label={value}
												color="primary"
												onDelete={() =>
													setEstadoImovel(
														estadoImovel.filter((item) => item !== value)
													)
												}
												onMouseDown={(event) => {
													event.stopPropagation();
												}}
											/>
										))}
									</div>
								)}
							>
								{select.estado.map((est) => (
									<MenuItem
										key={est.cod_imovelestado}
										value={est.nome}
										style={getStyles(est.nome, estadoImovel, theme)}
									>
										{est.nome}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl variant="outlined" className={style.input}>
							<InputLabel id="demo-mutiple-chip-label">Localização</InputLabel>
							<Select
								labelId="demo-mutiple-chip-label"
								id="demo-mutiple-chip"
								multiple
								value={localizacao}
								onChange={handleChangeLocalizacao}
								renderValue={(selected) => (
									<div className={classesss.chips}>
										{selected.map((value) => (
											<Chip
												className={classesss.chip}
												key={value}
												label={value}
												color="primary"
												onDelete={() =>
													setLocalizacao(
														localizacao.filter((item) => item !== value)
													)
												}
												onMouseDown={(event) => {
													event.stopPropagation();
												}}
											/>
										))}
									</div>
								)}
							>
								{/* {names.map((name) => (
									<MenuItem
										key={name}
										value={name}
										style={getStyles(name, personName, theme)}
									>
										{name}
									</MenuItem>
								))} */}
							</Select>
						</FormControl>

						<FormControl variant="outlined" className={style.input}>
							<InputLabel id="demo-mutiple-chip-label">Terreno</InputLabel>
							<Select
								labelId="demo-mutiple-chip-label"
								id="demo-mutiple-chip"
								multiple
								value={terreno}
								onChange={handleChangeTerreno}
								renderValue={(selected) => (
									<div className={classesss.chips}>
										{selected.map((value) => (
											<Chip
												className={classesss.chip}
												key={value}
												label={value}
												color="primary"
												onDelete={() =>
													setTerreno(terreno.filter((item) => item !== value))
												}
												onMouseDown={(event) => {
													event.stopPropagation();
												}}
											/>
										))}
									</div>
								)}
							>
								{/* {names.map((name) => (
									<MenuItem
										key={name}
										value={name}
										style={getStyles(name, personName, theme)}
									>
										{name}
									</MenuItem>
								))} */}
							</Select>
						</FormControl>

						<TextField
							label="Quadra"
							className={style.input}
							value={quadra}
							onChange={handleChangeQuadra}
							variant="outlined"
						/>
						<TextField
							label="Lote"
							className={style.input}
							value={lote}
							onChange={handleChangeLote}
							variant="outlined"
						/>
						<FormGroup className={style.input_desembargamento}>
							<Typography>Permite Desmembramento</Typography>
							<Switch
								checked={desmenbramento}
								onChange={handleChangeDesmembramento}
								name="metragem"
								color="primary"
							/>
						</FormGroup>
					</div>
					<div className={style.filterAdvanceVariable}>
						<div>
							<Typography id="range-slider" gutterBottom>
								Metragem
							</Typography>
							<Slider
								value={metragem}
								onChange={handleChangeMetragem}
								valueLabelDisplay="auto"
								aria-labelledby="range-slider"
								getAriaValueText={valuetext}
							/>
						</div>
						<div>
							<Typography id="range-slider" gutterBottom>
								Preço Total
							</Typography>
							<Slider
								value={precoTotal}
								onChange={handleChangePrecoTotal}
								valueLabelDisplay="auto"
								aria-labelledby="range-slider"
								getAriaValueText={valuetext}
								marks={marks}
							/>
						</div>
					</div>
				</div>
				<div className={style.filterAdvanceSearch}>
					<Button
						variant="contained"
						color="primary"
						className={style.buttonSearch}
						startIcon={<SearchOutlined />}
						onClick={setSearch}
					>
						Pesquisar
					</Button>
				</div>
			</Collapse>
		</React.Fragment>
	);
}

export default connect((state) => ({
	typeView: state.consultaImoveis.typeView,
	selects: state.selects,
	pageSearch: state.consultaImoveis.pageSearch,
}))(AdvancedFilters);
