import {
	makeStyles,
	Paper,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";

import { setPageSearch } from "../../../../redux/QueryProperty/QueryPropertyAction";

function ListView({ imovelSearch = [], dispatch }) {
	const [key, setKey] = useState([]);

	useEffect(() => {
		if (imovelSearch[0] !== undefined) {
			setKey(Object.keys(imovelSearch[0]));
		} else {
			setKey(Object.keys(imovelSearch));
		}
		// eslint-disable-next-line
	}, [imovelSearch]);

	const useStyles = makeStyles((theme) => ({
		table: {
			minWidth: 850,
			borderRadius: 0,
		},
		tableCell: {
			minWidth: 150,
			maxWidth: 300,
		},
		tableCellmax: {
			minWidth: 550,
		},
	}));
	const classes = useStyles();
	const items = [];
	items.push(
		imovelSearch.map((row, index) => (
			<TableRow key={index}>
				{/* {key.map((k, index) => (
					<TableCell align="center" key={index}>
						{row[k] === null ? "n/d" : row[k]}
					</TableCell>
				))} */}
			</TableRow>
		))
	);
	const [teste] = useState(true);

	function loadMore(page) {
		dispatch(setPageSearch(page));
	}
	return (
		<React.Fragment>
			{imovelSearch.length > 0 && (
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								{key.map((k, index) => (
									<TableCell
										className={
											k === "confrontacoes"
												? classes.tableCellmax
												: classes.tableCell
										}
										key={index}
										align="center"
									>
										{k.replace("_", " ")}
									</TableCell>
								))}
							</TableRow>
						</TableHead>

						<InfiniteScroll
							pageStart={1}
							loadMore={loadMore}
							hasMore={teste}
							useCapture={true}
							threshold={10}
							initialLoad={false}
							element="tbody"
							className={"MuiTableBody-root"}
						>
							{imovelSearch.map((row, index) => (
								<TableRow key={index}>
									{key.map((k, index) => (
										<TableCell align="center" key={index}>
											{row[k] === null ? "n/d" : row[k]}
										</TableCell>
									))}
								</TableRow>
							))}
						</InfiniteScroll>
					</Table>
				</TableContainer>
			)}
			{!imovelSearch.length && <h1>Não possui dados</h1>}
		</React.Fragment>
	);
}

export default connect((store) => ({
	imovelSearch: store.consultaImoveis.imovelSearch,
}))(ListView);
