import "./style.css";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Loader from "../../components/Loader";
import Titulo from "../../components/titulo";
import { editCompany, getCompany } from "../../redux/Company/CompanyAction";
import MyBusiness from "./MyBusiness";
import VerticalTabs from "./Tabs";
import MenuListComposition from "./ToggleMenu";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={4}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
}

function Configuration(props) {
	const [value, setValue] = React.useState(0);
	const { company } = props.store;
	return (
		<>
			<Loader {...props} />
			<Titulo {...props} standardHelper={true} title="Configurações" />
			<div className="container-elevation container_config">
				<Card className="card--tabs">
					<div className="configuration-group">
						<div className="container_avatar">
							<Avatar alt="avatar" src={company.imagem} />
						</div>
						<div className="container_title">
							<span className="title">CONFIGURAÇÕES GERAIS</span>
						</div>
						<VerticalTabs value={value} setValue={setValue} />
					</div>
					<div className="tab-panel">
						<MenuListComposition setValue={setValue} />
						<TabPanel value={value} index={0}>
							Visão Geral
						</TabPanel>
						<TabPanel value={value} index={1}>
							<MyBusiness />
						</TabPanel>
						<TabPanel value={value} index={2}>
							Permissões
						</TabPanel>
						<TabPanel value={value} index={3}>
							Ajuda e Suporte
						</TabPanel>
					</div>
				</Card>
			</div>
		</>
	);
}
const mapStateToProps = (state) => {
	return { store: state };
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			editCompany,
			getCompany,
		},
		dispatch
	);

export default Configuration = connect(
	mapStateToProps,
	mapDispatchToProps
)(Configuration);
