import { Button } from "@material-ui/core";
import { Add, HelpOutline, Reorder } from "@material-ui/icons";
import React from "react";

export default (props) => {
	const { help, add, option } = props;

	return (
		<div>
			<div className="header__buttons-container">
				{help && (
					<Button id="header__btn-help" onClick={props.onClickHelp}>
						<HelpOutline className="icon__help" />
					</Button>
				)}

				{(add || option) && (
					<div className="header__wrapper">
						{add && (
							<Button className="header__btn-plus" onClick={props.onClickAdd}>
								<Add className="icon__plus" />
								Novo
							</Button>
						)}
						{option && (
							<Button
								className="header__btn-options"
								onClick={props.onClickEdit}
							>
								<Reorder className="icon__options" />
								Opções
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
