import {
	GETIMOVELSEARCHSAGA,
	GETTYPEVIEWSAGA,
	SETPAGESEARCHSAGA,
	SETTYPEVIEWSAGA,
} from "./QueryPropertyType";

export function getTypeView() {
	return { type: GETTYPEVIEWSAGA };
}

export function setTypeView(value) {
	return { type: SETTYPEVIEWSAGA, payload: value };
}

export function getImovelSearch(value) {
	return { type: GETIMOVELSEARCHSAGA, payload: value };
}

export function setPageSearch(value) {
	return { type: SETPAGESEARCHSAGA, payload: value };
}
