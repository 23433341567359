import {
	ESPELHOEMPREENDIMENTO,
	GETPLANOPAGAMENTO,
	PAINELEMPREENDIMENTO,
	SETEXPANDED,
	UPDATECOLORIMOVEL,
} from "./AllotmentType";

const INITIAL_STATE = {
	allEmpreendimentos: [],
	espelhoEmpreendimento: [],
	allPlanoPagamentos: [],
	expanded: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ESPELHOEMPREENDIMENTO:
			return {
				...state,
				espelhoEmpreendimento: action.payload,
			};
		case PAINELEMPREENDIMENTO:
			return {
				...state,
				allEmpreendimentos: action.payload,
			};
		case SETEXPANDED:
			return {
				...state,
				expanded: action.payload,
			};
		case GETPLANOPAGAMENTO:
			return {
				...state,
				allPlanoPagamentos: action.payload,
			};
		case UPDATECOLORIMOVEL:
			let { array, imovel } = action.payload;

			array.quadras.forEach((data) => {
				data.quadra_itens.map((value) => {
					if (value.cod_imovel === imovel.cod_imovel) {
						value.situacao_venda_cor_html = imovel.situacao_venda_cor_html;
						value.situacao_venda_cor_html_fonte =
							imovel.situacao_venda_cor_html_fonte;
						value.situacao_sistema = imovel.situacao_sistema;
						value.situacao_venda_nome = imovel.situacao_venda_nome;
					}

					return value;
				});
			});

			return {
				...state,
				espelhoEmpreendimento: array,
			};
		default:
			return state;
	}
};
