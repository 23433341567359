import "date-fns";
import "./style.css";

import DateFnsUtils from "@date-io/date-fns";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import { Check, Event } from "@material-ui/icons";
import {
	KeyboardDatePicker,
	KeyboardTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import React from "react";

export default (props) => {
	const { global } = props.store;

	const handleClose = () => {
		props.setShowAlertAlterTerm({ show: false });
	};

	const handleOnChangeDate = (name, date) => {
		if (!date) return;

		let newDate;
		if (date.toString() === "Invalid Date") {
			newDate = date;
		} else {
			newDate = date.toISOString();
		}

		const data = {
			...global.showAlertAlterTerm,
			[name]: newDate,
		};

		props.setShowAlertAlterTerm(data);
	};

	return (
		<>
			<Dialog
				open={global.showAlertAlterTerm.show}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className="bg-gray--light">
					<DialogContent className="alert-term-change__container">
						<DialogTitle id="alert-dialog-title" className="blue--light">
							<Event className="alert-term-change__title-icon" />
							{global.showAlertAlterTerm.title}
						</DialogTitle>

						<DialogContentText
							id="alert-dialog-description"
							className="alert-term-change__description"
						>
							{global.showAlertAlterTerm.text}
						</DialogContentText>

						<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
							<KeyboardDatePicker
								className="input__primary"
								autoOk
								disableToolbar
								format="dd/MM/yyyy"
								disablePast={true}
								margin="normal"
								label="Data de Vencimento"
								invalidDateMessage="Data em formato inválido"
								minDateMessage="A data não pode ser anterior a data atual"
								value={global.showAlertAlterTerm.data || null}
								onChange={(date) => handleOnChangeDate("data", date)}
								cancelLabel={null}
								okLabel={null}
							/>

							<KeyboardTimePicker
								className="input__primary"
								ampm={false}
								margin="normal"
								label="Hora de Vencimento"
								invalidDateMessage="Hora em formato inválido"
								value={global.showAlertAlterTerm.hora || null}
								onChange={(date) => handleOnChangeDate("hora", date)}
								KeyboardButtonProps={{
									"aria-label": "change time",
								}}
							/>
						</MuiPickersUtilsProvider>
					</DialogContent>
					<DialogActions className="bg-gray--light">
						<div className="alert-term-change__btn-container">
							<Button
								onClick={props.handleConfirm}
								autoFocus
								className="btn__blue"
							>
								<Check className="alert-term-change__icon" />
								Confirmar
							</Button>
						</div>
					</DialogActions>
				</div>
			</Dialog>
		</>
	);
};
