import React from "react";

import {
	CardContent,
	CardActions,
	Collapse,
	Typography,
	Button,
} from "@material-ui/core";

import HelpOutline from "@material-ui/icons/HelpOutline";
import "./style.css";

export default (props) => {
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<div>
			<CardActions disableSpacing className="header-bottom header-bottom-panel">
				<h2 className="header-bottom-panel__title gray--light">
					Retornos de <br className="hide header-bottom-panel__title--break" />
					negociações
				</h2>

				<Button
					className="header-bottom-panel__btn-help"
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more"
				>
					<HelpOutline className="icon__help" />
				</Button>
			</CardActions>

			<Collapse in={expanded} timeout="auto" unmountOnExit className="intro">
				<CardContent>
					<Typography paragraph>Method:</Typography>
					<Typography paragraph>
						O incentivo ao avanço tecnológico, assim como o aumento do diálogo
						entre os diferentes setores produtivos ainda não demonstrou
						convincentemente que vai participar na mudança das novas
						proposições.
					</Typography>
				</CardContent>
			</Collapse>
		</div>
	);
};
