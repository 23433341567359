import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default (props) => {
	const { global } = props.store;

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		props.setShowToast({ show: false });
	};

	return (
		<Snackbar
			open={global.showToast.show}
			autoHideDuration={3000}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={global.showToast.severity}>
				{global.showToast.text}
			</Alert>
		</Snackbar>
	);
};
