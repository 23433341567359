export const PAINELEMPREENDIMENTO = "PAINELEMPREENDIMENTO";
export const ESPELHOEMPREENDIMENTO = "ESPELHOEMPREENDIMENTO";
export const MAPAEMPREENDIMENTO = "MAPAEMPREENDIMENTO";
export const ADDEMPREENDIMENTO = "ADDEMPREENDIMENTO";

export const PAINELEMPREENDIMENTOSAGA = "PAINELEMPREENDIMENTOSAGA";
export const ESPELHOEMPREENDIMENTOSAGA = "ESPELHOEMPREENDIMENTOSAGA";
export const MAPAEMPREENDIMENTOSAGA = "MAPAEMPREENDIMENTOSAGA";
export const ADDEMPREENDIMENTOSAGA = "ADDEMPREENDIMENTOSAGA";

export const GETDIALOGOIMOVELVENDA = "GETDIALOGOIMOVELVENDA";
export const OPENDIALOGOIMOVELVENDA = "OPENDIALOGOIMOVELVENDA";

export const GETOPCAOVENDA = "GETOPCAOVENDA";
export const SETOPCAOVENDA = "SETOPCAOVENDA";
export const GETPLANOPAGAMENTO = "GETPLANOPAGAMENTO";

export const GETDASHBOARD = "GETDASHBOARD";
export const GETPAINELOPORTUNIDADE = "GETPAINELOPORTUNIDADE";
export const GETMOREPAINELOPORTUNIDADE = "GETMOREPAINELOPORTUNIDADE";
export const OPENDIALOGOPAINELVENDA = "OPENDIALOGOPAINELVENDA";
export const SETEXPANDEDIMOVEL = "SETEXPANDEDIMOVEL";
export const UPDATECOLORIMOVEL = "UPDATECOLORIMOVEL";

export const GETFASEDIALOGO = "GETFASEDIALOGO";
export const CHANGETIPO = "CHANGETIPO";
export const SETEXPANDED = "SETEXPANDED";

export const LOADING = "LOADING";

export const GETALLCONTATOS = "GETALLCONTATOS";
export const CURRENTCONTATO = "CURRENTCONTATO";
export const SHOWDIALOG = "SHOWDIALOG";
export const CHANGEFORMCONTATO = "CHANGEFORMCONTATO";
export const ERRORSFORMCONTATO = "ERRORSFORMCONTATO";
export const ERRORSFORMFIELD = "ERRORSFORMFIELD";
export const ADDCONTATO = "ADDCONTATO";
export const EDITCONTATO = "EDITCONTATO";
export const DELETECONTATO = "DELETECONTATO";
export const SEARCHCONTATOS = "SEARCHCONTATOS";
export const CHANGESEARCH = "CHANGESEARCH";
export const SELECTEDCONTATO = "SELECTEDCONTATO";

export const GETALLCONTATOSSAGA = "GETALLCONTATOSSAGA";
export const CURRENTCONTATOSAGA = "CURRENTCONTATOSAGA";
export const SHOWDIALOGSAGA = "SHOWDIALOGSAGA";
export const CHANGEFORMCONTATOSAGA = "CHANGEFORMCONTATOSAGA";
export const ERRORSFORMCONTATOSAGA = "ERRORSFORMCONTATOSAGA";
export const ERRORSFORMFIELDSAGA = "ERRORSFORMFIELDSAGA";
export const ADDCONTATOSAGA = "ADDCONTATOSAGA";
export const EDITCONTATOSAGA = "EDITCONTATOSAGA";
export const DELETECONTATOSAGA = "DELETECONTATOSAGA";
export const SEARCHCONTATOSSAGA = "SEARCHCONTATOSSAGA";
export const CHANGESEARCHSAGA = "CHANGESEARCHSAGA";
export const SELECTEDCONTATSAGA = "SELECTEDCONTATOSAGA";

export const SEARCHPESSOAS = "SEARCHPESSOAS";
export const SEARCHMOREPESSOAS = "SEARCHMOREPESSOAS";
export const SHOWDIALOGPESSOA = "SHOWDIALGPESSOA";
export const GETPESSOABYCPF = "GETPESSOABYCPF";
export const GETPESSOABYCPFSAGA = "GETPESSOABYCPFSAGA";

export const ADDPESSOA = "ADDPESSOA";

export const SHOWALERT = "SHOWALERT";
export const SHOWTOAST = "SHOWTOAST";
export const SHOWALERTCONFIRM = "SHOWALERTCONFIRM";
export const SHOWALERTQUESTION = "SHOWALERTQUESTION";
export const SHOWALERTQUESTIONCONTATO = "SHOWALERTQUESTIONCONTATO";
export const SHOWALERTQUESTIONREASON = "SHOWALERTQUESTIONREASON";
export const SHOWALERTALTERTERM = "SHOWALERTALTERTERM";

export const SHOWALERTSAGA = "SHOWALERTSAGA";
export const SHOWTOASTSAGA = "SHOWTOASTSAGA";
export const SHOWALERTCONFIRMSAGA = "SHOWALERTCONFIRMSAGA";
export const SHOWALERTQUESTIONSAGA = "SHOWALERTQUESTIONSAGA";
export const SHOWALERTQUESTIONCONTATOSAGA = "SHOWALERTQUESTIONCONTATOSAGA";
export const SHOWALERTQUESTIONREASONSAGA = "SHOWALERTQUESTIONREASONSAGA";
export const SHOWALERTALTERTERMSAGA = "SHOWALERTALTERTERMSAGA";

export const CHANGEFORMPLANOPAGAMENTO = "CHANGEFORMPLANOPAGAMENTO";
export const CHANGEFORMCALCULO = "CHANGEFORMCALCULO";
export const ERRORSFORMCALCULO = "ERRORSFORMCALCULO";
export const SETLISTCALCULO = "SETLISTCALCULO";
export const NEWCALCULO = "NEWCALCULO";
export const REMOVECALCULO = "REMOVECALCULO";

export const SETORCAMENTO = "SETORCAMENTO";
export const SETPROPOSTA = "SETPROPOSTA";
export const SETRESERVA = "SETRESERVA";
export const SHAREORCAMENTO = "SHAREORCAMENTO";
export const SHAREPROPOSTA = "SHAREPROPOSTA";
export const CHANGEPROPOSTA = "CHANGEPROPOSTA";
export const ERRORSFORMDATAPROPOSTA = "ERRORSFORMDATAPROPOSTA";
export const GETFASEDOCUMENTOS = "GETFASEDOCUMENTOS";
export const EDITFASEDOCUMENTO = "EDITFASEDOCUMENTO";
export const GETFASEDOCUMENTOIMAGEM = "GETFASEDOCUMENTOIMAGEM";
export const CLOSEFASEDOCUMENTOIMAGEM = "CLOSEFASEDOCUMENTOIMAGEM";
export const CLOSEFASEDOCUMENTOS = "CLOSEFASEDOCUMENTOS";

export const SETSELECTEDPESSOA = "SETSELECTEDPESSOA";
export const RESETLEADS = "RESETLEADS";
export const GETOPORTUNIDADE = "GETOPORTUNIDADE";
export const GETFASES = "GETFASES";
export const GETLEADS = "GETLEADS";
export const GETMORELEADS = "GETMORELEADS";
export const SETETAPAACAO = "SETETAPAACAO";
export const OPENDIALOGOPORTUNIDADE = "OPENDIALOGOPORTUNIDADE";
export const OPENDIALOGOASSINATURA = "OPENDIALOGOASSINATURA";
export const GETASSINATURA = "GETASSINATURA";
export const GETORCAMENTOBYETAPA = "GETORCAMENTOBYETAPA";
export const GETCORRETORES = "GETCORRETORES";
export const GETPROSPECCAO = "GETPROSPECCAO";

export const GETUSUARIOS = "GETUSUARIOS";
export const POSTUSUARIO = "POSTUSUARIO";
export const PUTUSUARIO = "PUTUSUARIO";
export const GETFUNCAOUSUARIO = "GETFUNCAOUSUARIO";
export const ERROUSUARIO = "ERROUSUARIO";
export const ADDUSUARIO = "ADDUSUARIO";
export const EDITUSUARIO = "EDITUSUARIO";
export const SELECTEDUSUARIO = "SELECTEDUSUARIO";

export const GETUSUARIOSSAGA = "GETUSUARIOSSAGA";
export const POSTUSUARIOSAGA = "POSTUSUARIOSAGA";
export const PUTUSUARIOSAGA = "PUTUSUARIOSAGA";
export const GETFUNCAOUSUARIOSAGA = "GETFUNCAOUSUARIOSAGA";
export const ERROUSUARIOSAGA = "ERROUSUARIOSAGA";
export const ADDUSUARIOSAGA = "ADDUSUARIOSAGA";
export const EDITUSUARIOSAGA = "EDITUSUARIOSAGA";
export const SELECTEDUSUARIOSAGA = "SELECTEDUSUARIOSAGA";

export const LOGIN = "LOGIN";

export const RELATORIO = "RELATORIO";

export const UPLOAD = "UPLOAD";
export const GETUPLOAD = "GETUPLOAD";
