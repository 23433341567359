import "./style.css";

import { Button, IconButton, List, ListItem } from "@material-ui/core";
import { Add, ChevronRight, Delete } from "@material-ui/icons";
import React from "react";
import { removeListaCalculo } from "redux/Immobile/ImmobileAction";
import { formatNumber } from "utils/funcoes";

export default (props) => {
	const { imovel } = props.store;

	const handleOnClickNovo = () => {
		props.setStep(props.priorStep);
	};

	const handleOnClickProximo = () => {
		if (imovel.listCalculo.length <= 0) return;
		props.setStep(props.nextStep);
	};

	const handleRemoveItem = (index) => {
		props.dispatch(removeListaCalculo(index));
		if (imovel.listCalculo.length <= 0) props.setStep(props.priorStep);
	};

	return (
		<div className="recast bg-gray--light">
			{imovel.listCalculo &&
				imovel.listCalculo.map((value, index) => (
					<List key={index} className="recast__list bg-white">
						<div className="recast__title-container">
							<ListItem className="recast__title blue--light">{`${
								index + 1
							}º - ${value.plano.planovenda_nome}`}</ListItem>
							<IconButton
								className="btn__circular recast__icon-delete"
								onClick={() => handleRemoveItem(index)}
							>
								<Delete className="icon__delete" />
							</IconButton>
						</div>
						<ListItem>
							<span className="recast__label gray--younect">Sinal:</span>
							<span className="recast__value gray--younect">{` R$ ${formatNumber(
								value.entradaSinal
							)}`}</span>
						</ListItem>
						<ListItem>
							<span className="recast__label gray--younect">Entrada:</span>
							<span className="recast__value gray--younect">{` ${
								value.entradaQtde
							} x R$ ${formatNumber(value.entradaParcelada)}`}</span>
						</ListItem>
						<ListItem>
							<span className="recast__label gray--younect">Parcela:</span>
							<span className="recast__value gray--younect">{` ${
								value.parcelaQtde
							} x R$ ${formatNumber(value.parcelaValor)}`}</span>
						</ListItem>
						<ListItem>
							<span className="recast__label gray--younect">Valor Total: </span>
							<span className="recast__value--total gray--younect">
								{` R$ ${formatNumber(
									value.totalValor ? value.totalValor : value.totalEntrada
								)}`}
							</span>
						</ListItem>
					</List>
				))}

			<div className="recast__btn-container bg-gray--light">
				<Button onClick={handleOnClickNovo} className="btn__blue recast__btn">
					<Add className="icon__plus recast__icon-plus" />
					Novo
				</Button>
				<Button
					onClick={handleOnClickProximo}
					className="btn__blue recast__btn--next"
				>
					Próximo
					<ChevronRight className="icon__next recast__icon-next" />
				</Button>
			</div>
		</div>
	);
};
