import { GETCOMPANY, GETRESPONSIBLES } from "./CompanyType";

const INITIAL_STATE = {
	nome: "",
	fones: "",
	email: "",
	cnpj: "",
	fax: "",
	responsavel: "",
	cep: "",
	num_end: "",
	bairro: "",
	distrito: "",
	endereco: "",
	complemento: "",
	obs: "",
	imagem: "",
	comarca: "",
	cod_empresa: "",
	responsibles: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GETCOMPANY:
			return action.payload;
		case GETRESPONSIBLES:
			return {
				...state,
				responsibles: action.payload,
			};
		default:
			return state;
	}
};
