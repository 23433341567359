import "./style.css";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HelpIcon from "@material-ui/icons/Help";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

export default function VerticalTabs({ setValue, value }) {
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className="tabs-container">
			<Tabs
				orientation="vertical"
				value={value}
				onChange={handleChange}
				className="tabs-config"
			>
				<Tab
					key={0}
					className="tab-config"
					label={[<DashboardIcon />, "Visão Geral"]}
					{...a11yProps(0)}
				/>
				<Tab
					key={1}
					className="tab-config"
					label={[<PersonIcon />, "Minha Empresa"]}
					{...a11yProps(1)}
				/>
				<Tab
					key={2}
					className="tab-config"
					label={[<LockOpenIcon />, "Permissões"]}
					{...a11yProps(2)}
				/>
				<Tab
					key={3}
					className="tab-config"
					label={[<HelpIcon />, "Ajuda e Suporte"]}
					{...a11yProps(3)}
				/>
			</Tabs>
		</div>
	);
}
