import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { AuthProvider } from "./contexts/auth";
import store from "./redux/stores";
import Routes from "./routes";

const createBrowserHistory = require("history").createBrowserHistory;
export const history = createBrowserHistory();

export default function App() {
	return (
		<Provider store={store}>
			<AuthProvider>
				<Router history={history}>
					<Routes />
				</Router>
			</AuthProvider>
		</Provider>
	);
}
