import "./style.css";

import {
	Button,
	DialogActions,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
} from "@material-ui/core";
import { AttachMoney, ExpandMore } from "@material-ui/icons";
import React, { useEffect } from "react";
import { getOpcaoVenda } from "redux/Sale/SaleAction";
import { formatNumber } from "utils/funcoes";

const ImovelDados = (props) => {
	const { imovel } = props.store;
	const { dispatch } = props;
	const { cod_imovel } = imovel.getImovel;
	console.log("props do imovel dialog", props);

	const handleChange = (panel) => (event, isExpanded) => {
		console.log("handleChange", isExpanded);
		//props.setExpanded(isExpanded ? panel : false);
	};

	useEffect(() => {
		if (cod_imovel) dispatch(getOpcaoVenda({ cod_imovel }));
	}, [cod_imovel, dispatch]);

	return (
		<>
			<div className="residential-property bg-gray--light">
				<ExpansionPanel
					className="residential-property__panel"
					expanded={imovel.expanded === "panel1"}
					onChange={handleChange("panel1")}
				>
					<ExpansionPanelSummary expandIcon={<ExpandMore />}>
						<Typography className="residential-property__label gray--younect">
							valor:
							<span className="residential-property__value">
								{`R$ ${formatNumber(imovel.getImovel.preco_total)}`}
							</span>
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography className="residential-property__label gray--younect">
							valor por m²:
							<span className="residential-property__value">
								{`R$ ${formatNumber(imovel.getImovel.preco_metro)}`}
							</span>
						</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>

				<ExpansionPanel
					className="residential-property__panel"
					expanded={imovel.expanded === "panel2"}
					onChange={handleChange("panel2")}
				>
					<ExpansionPanelSummary expandIcon={<ExpandMore />}>
						<Typography className="residential-property__label gray--younect">
							metragens e limites:
							<span className="residential-property__value">
								{`${parseFloat(
									Number(imovel.getImovel.area_metro).toFixed(2)
								).toLocaleString("pt-BR")}`}
							</span>
							m²
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography className="residential-property__label gray--younect">
							frente:
							<span className="residential-property__value">
								{imovel.getImovel.frente_testada}
							</span>
							<br />
							lado:
							<span className="residential-property__value">
								{imovel.getImovel.lado}
							</span>
							<br />
							fundo:
							<span className="residential-property__value">
								{imovel.getImovel.fundo}
							</span>
							<br />
							posição:
							<span className="residential-property__value">
								{imovel.getImovel.posicao}
							</span>
						</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>

				<ExpansionPanel
					className="residential-property__panel"
					expanded={imovel.expanded === "panel3"}
					onChange={handleChange("panel3")}
				>
					<ExpansionPanelSummary expandIcon={<ExpandMore />}>
						<Typography className="residential-property__label gray--younect">
							confrontações
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography className="residential-property__label gray--younect">
							{imovel.getImovel.confrontacoes}
						</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>

				<ExpansionPanel
					className="residential-property__panel"
					expanded={imovel.expanded === "panel4"}
					onChange={handleChange("panel4")}
				>
					<ExpansionPanelSummary expandIcon={<ExpandMore />}>
						<Typography className="residential-property__label gray--younect">
							outras informações
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Typography className="residential-property__label gray--younect">
							fase da venda:
							<span className="residential-property__value">
								{imovel.getImovel.fase}
							</span>
							<br />
							permite desmembramento:
							<span className="residential-property__value">
								{imovel.getImovel.permite_desmembramento}
							</span>
							<br />
							localização:
							<span className="residential-property__value">
								{imovel.getImovel.localizacao}
							</span>
							<br />
							utilização:
							<span className="residential-property__value">
								{imovel.getImovel.utilizacao}
							</span>
						</Typography>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>

			<DialogActions className="residential-property__btn-container">
				{imovel.getImovel.situacao_permite_negociacao === "SIM" ? (
					<Button
						onClick={props.click}
						className="btn__blue residential-property__btn"
					>
						<AttachMoney className="btn-icon__dolar residential-property__icon" />
						Negociar
					</Button>
				) : null}
			</DialogActions>
		</>
	);
};

export default ImovelDados;
