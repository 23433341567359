import "./style.css";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from "@material-ui/core";
import { Check, Close, Error } from "@material-ui/icons";
import React from "react";

export default (props) => {
	const { global } = props.store;

	const handleClose = () => {
		props.setShowAlertQuestion({ show: false });
	};

	return (
		<>
			<Dialog
				open={global.showAlertQuestion.show}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className="bg-gray--light">
					<DialogContent>
						<DialogTitle
							id="alert-dialog-title"
							className="orange	alert-question__title"
						>
							<Error className="alert-question__title-icon" />
							{global.showAlertQuestion.title}
							<IconButton
								className="alert-question__close-container"
								onClick={handleClose}
							>
								<Close className="gray--younect" />
							</IconButton>
						</DialogTitle>
						<DialogContentText
							id="alert-dialog-description"
							className="alert-question__description"
						>
							{global.showAlertQuestion.text}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<div className="alert-question__btn-container ">
							<Button
								onClick={handleClose}
								autoFocus
								className="btn__gray--medium"
							>
								<Close className="alert-question__icon" />
								Não
							</Button>
							<Button
								onClick={props.handleConfirm}
								autoFocus
								className="btn__blue--medium"
							>
								<Check className="alert-question__icon" />
								Sim
							</Button>
						</div>
					</DialogActions>
				</div>
			</Dialog>
		</>
	);
};
