export const GETTYPEVIEW = "GETTYPEVIEW";
export const SETTYPEVIEW = "SETGETTYPEVIEW";

export const GETTYPEVIEWSAGA = "GETTYPEVIEWSAGA";
export const SETTYPEVIEWSAGA = "SETTYPEVIEWSAGA";

export const GETIMOVELSEARCH = "GETIMOVELSEARCH";
export const GETIMOVELSEARCHSAGA = "GETIMOVELSEARCHSAGA";

export const SETPAGESEARCH = "SETPAGESEARCH";
export const SETPAGESEARCHSAGA = "SETPAGESEARCHSAGA";
