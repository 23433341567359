import "./style.css";

import { Avatar, TextField } from "@material-ui/core";
import React from "react";

import PessoaFisica from "../../fisica";
import PessoaJuridica from "../../juridica";

export default (props) => {
	const [typePerson, setTypePerson] = React.useState("fisica");
	const [form, setForm] = React.useState({
		nome: "",
		cpf: "",
		email: "",
		pessoa_rg: "",
		pessoa_rg_orgao: "",
		pessoa_nasc_dt: "",
		pessoa_sexo: "",
	});

	const handleOnChange = (e) => {
		const { name, value } = e.target;
		handleOnChangeForm({ name, value });
	};

	const handleOnChangeDate = (name, date) => {
		if (!date) return;
		if (date && date.toString() === "Invalid Date") return;

		const data = {
			name,
			value: date.toISOString(),
		};

		handleOnChangeForm(data);
	};

	const handleOnChangeForm = (data) => {
		const { name, value } = data;

		setForm({
			...form,
			[name]: value,
		});

		if (name === "cpf") {
			setTypePerson(value.length <= 11 ? "fisica" : "juridica");
		}
	};

	return (
		<div className="container-elevation">
			<h3 className="title-cadastro">Dados Básicos</h3>
			<div className="cadastro-container">
				<div
					className="cadastro-container__form--avatar"
					noValidate
					autoComplete="off"
				>
					<div className="avatar-container">
						<Avatar className="avatar-container__avatar" alt="avatar" />
						{/* <input
							className="cadastro-container__file"
							type="file"
							accept="image/*, .pdf"
							onChange={handleFileUpload}
						/> */}
					</div>
				</div>

				<div
					className="cadastro-container__form--name"
					noValidate
					autoComplete="off"
				>
					<div className="cadastro-container__input-container">
						<TextField
							className="cadastro-container__input--large"
							autoComplete="off"
							required
							label="Nome"
							name="nome"
							value={form.nome}
							onChange={handleOnChange}
						/>

						<TextField
							className="cadastro-container__input"
							autoComplete="off"
							label="CPF / CNPJ"
							name="cpf"
							value={form.cpf}
							onChange={handleOnChange}
						/>
					</div>
				</div>

				<div
					className="cadastro-container__form--genero"
					noValidate
					autoComplete="off"
				>
					<div className="cadastro-container__input-container">
						<TextField
							className="cadastro-container__input"
							autoComplete="off"
							label="E-Mail"
							name="email"
							value={form.email}
							onChange={handleOnChange}
						/>
					</div>
				</div>

				{typePerson === "fisica" ? (
					<PessoaFisica
						{...props}
						form={form}
						handleOnChange={handleOnChange}
						handleOnChangeDate={handleOnChangeDate}
					/>
				) : (
					<PessoaJuridica
						{...props}
						form={form}
						handleOnChange={handleOnChange}
						handleOnChangeDate={handleOnChangeDate}
					/>
				)}
			</div>
		</div>
	);
};
