import React from "react";

import Calculo from "../../../../imovel/dialog/calculo";
import Compartilhar from "../../../../imovel/dialog/compartilhar";
import Proposta from "../../../../imovel/dialog/proposta";

export default (props) => {
	const { imovel, oportunidade } = props.store;
	const [step, setStep] = React.useState("calculo");

	const handleSetStep = (value) => {
		setStep(value);
	};

	const handleSaveProposta = (contato, proposta) => {
		const etapa = {
			acao: "Orcamento",
			cod_opor_fase_etapa: oportunidade.dialogOportunidade.cod_opor_fase_etapa,
		};

		props.setEtapaAcaoOrcamento(
			[imovel.listCalculo[imovel.listCalculo.length - 1]],
			oportunidade.getOportunidade,
			imovel.getImovel,
			proposta,
			etapa
		);
	};

	const renderStep = () => {
		switch (step) {
			case "calculo":
				return renderDefault();
			case "proposta":
				return (
					<Proposta
						{...props}
						setStep={handleSetStep}
						priorStep={"calculo"}
						saveProposta={handleSaveProposta}
						nextStep={"compartilhar"}
					/>
				);
			case "compartilhar":
				return (
					<Compartilhar
						{...props}
						shareText="Proposta de valores gerada com sucesso!"
					/>
				);
			default:
				return renderDefault();
		}
	};

	const renderDefault = () => {
		return <Calculo {...props} setStep={handleSetStep} nextStep={"proposta"} />;
	};

	return <div className="dialog-body__budget">{renderStep()}</div>;
};
