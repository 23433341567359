function tableRowModel(row, tableHeaderArray) {
	let obj = tableHeaderArray.reduce(function (acc, ObjectKey, cont) {
		var objectKey = null;

		if (ObjectKey !== null) {
			objectKey = ObjectKey.toLowerCase();
		} else {
			objectKey = `nd ${cont}`;
		}

		if (row[cont] === null) {
			acc[objectKey] = row[cont];
		} else {
			if (
				objectKey === "area_metro" ||
				objectKey === "area_construida" ||
				objectKey === "frente_quant" ||
				objectKey === "frente_testada" ||
				objectKey === "lado" ||
				objectKey === "fundo" ||
				objectKey === "preco_metro" ||
				objectKey === "preco_total"
			) {
				acc[objectKey] = parseInt(row[cont]);
			} else {
				if (
					objectKey === "posicao" ||
					objectKey === "tipo" ||
					objectKey === "situacao" ||
					objectKey === "estado" ||
					objectKey === "utilizacao" ||
					objectKey === "permite_desmembramento"
				) {
					acc[objectKey] = row[cont].toString().toUpperCase();
				} else {
					acc[objectKey] = row[cont].toString();
				}
			}
		}
		return acc;
	}, {});
	return obj;
}

export default tableRowModel;
