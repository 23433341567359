import React from "react";

import { Button, Grid } from "@material-ui/core";
import { ThumbDown, ThumbUp } from "@material-ui/icons";

import { formatDateToLocaleString } from "utils/funcoes";

import Buyer from "../Buyer";
import Broker from "../Broker";

import "./style.css";

export default (props) => {
	const { oportunidade } = props.store;
	const dados = oportunidade.getOportunidade;
	const prospeccao = oportunidade.getOportunidade.opor_prospeccao;
	// const [selected, setSelected] = useState(false);

	const { fases } = oportunidade.getOportunidade;
	const finalizado = fases.find((value) => value.fase_sigla === "F");

	const colorStatus = (status) => {
		switch (status) {
			case "Aberto":
				return "blue";
			case "Ganho":
				return "green";
			case "Perda":
				return "red";
			default:
				return "";
		}
	};

	// const backgroundColorStatus = (status) => {
	// 	switch (status) {
	// 		case "Aberto":
	// 			return "bg-blue";
	// 		case "Ganho":
	// 			return "bg-green";
	// 		case "Perda":
	// 			return "bg-red";
	// 		default:
	// 			return "";
	// 	}
	// };

	return (
		<Grid container className="resume">
			<Grid item container>
				<Grid item container spacing={3} xs={8}>
					<Grid item>
						<Buyer {...props} />
					</Grid>

					<Grid
						container
						item
						spacing={2}
						direction="row"
						justify="space-between"
					>
						<Grid item container direction="column" xs={3}>
							<span className="resume__label">Situação</span>

							<span className={`resume__value ${colorStatus(dados.situacao)}`}>
								{dados.situacao}
							</span>

							{/* <Grid item className="resume__label">
								Situação
							</Grid>

							<Grid
								item
								className={`resume__value ${colorStatus(dados.situacao)}`}
							>
								<span
									className={`resume__status ${backgroundColorStatus(
										dados.situacao
									)}`}
								></span>
								{dados.situacao}
							</Grid> */}
						</Grid>

						<Grid item container direction="column" xs={5}>
							<span className="resume__label">Cadastro</span>
							<span className="resume__value">
								{dados.data_hora
									? formatDateToLocaleString(dados.data_hora)
									: null}
							</span>
							{/* <Grid item className="resume__label">
						Data de Cadastro
					</Grid>

					<Grid item className="resume__value">
						{dados.data_hora ? formatDateToLocaleString(dados.data_hora) : null}
					</Grid> */}
						</Grid>

						<Grid item container direction="column" xs={4}>
							<span className="resume__label">Prospecção</span>
							<span className="resume__value">
								{prospeccao && prospeccao.nome}
							</span>
							{/* <Grid item className="resume__label">
						Prospecção
					</Grid>

					<Grid item className="resume__value">
						{prospeccao && prospeccao.nome}
					</Grid> */}
						</Grid>
					</Grid>

					<Grid
						container
						item
						xs
						direction="row"
						justify="flex-start"
						alignItems="center"
					>
						<Grid item xs>
							<Button
								className={`${
									dados.situacao === "Ganho"
										? "btn__green"
										: "btn__green--outlined"
								} resume__button`}
								startIcon={<ThumbUp />}
							>
								Ganho
							</Button>
						</Grid>

						<Grid item xs>
							<Button
								className={`${
									dados.situacao === "Perda" ? "btn__red" : "btn__red--outlined"
								}  resume__button`}
								startIcon={<ThumbDown />}
							>
								Perda
							</Button>
						</Grid>
					</Grid>
				</Grid>

				<Grid item container xs={4} direction="column" spacing={2}>
					<Grid item className="blue--light font-secondary bold">
						Corretor(es)
					</Grid>
					<Grid item>
						<Broker {...props} />
					</Grid>
				</Grid>
			</Grid>

			{finalizado ? (
				<>
					<Grid
						container
						item
						xs={6}
						sm
						direction="column"
						spacing={1}
						className="resume__item"
					>
						<Grid item className="resume__label">
							Finalizado
						</Grid>

						<Grid item className="resume__value">
							{formatDateToLocaleString(finalizado.finalizacao_dt)}
						</Grid>
					</Grid>
				</>
			) : null}

			{finalizado ? (
				<Grid item xs={12} className="resume__container">
					<p className="resume__label">Resultado</p>

					<p className="resume__value">
						{finalizado.etapas[0].situacao_descricao}
					</p>
					<p className="resume__value">
						{finalizado.etapas[0].situacao_motivo}
					</p>
				</Grid>
			) : null}
		</Grid>
	);
};
