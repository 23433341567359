import {
	calculateFinanciamento,
	formatNumber,
	removeMaskMoney,
} from "utils/funcoes";

import {
	CHANGEFORMCALCULO,
	CHANGEFORMPLANOPAGAMENTO,
	ERRORSFORMCALCULO,
	GETDIALOGOIMOVELVENDA,
	NEWCALCULO,
	OPENDIALOGOIMOVELVENDA,
	REMOVECALCULO,
	SETEXPANDEDIMOVEL,
	SETLISTCALCULO,
} from "./ImmobileType";

const INITIAL_STATE = {
	getImovel: {},
	showDialogImovel: false,
	expanded: false,
	currentPlanoPagto: {
		cod_planovenda: "",
		cod_empreendimento: "",
		planovenda_nome: "",
		planovenda_entrada_porc: "",
		planovenda_entrada_quant: "",
		planovenda_entrada_reajuste: "",
		planovenda_parcela_quant: "",
		planovenda_parcela_reajuste: "",
		planovenda_obs: "",
		planovenda_entrada_sinal: "",
		planovenda_entrada_reaj_parc: "",
		planovenda_entrada_sinal_obr: "",
		planovenda_financiamento: "",
		planovenda_juros_tipo: "",
		planovenda_fase: "",
		planovenda_mensagem: "",
		planovenda_entrada_sinal_altera: "",
		planovenda_entrada_sinal_min: "",
		id_empresa: "",
		fase: "",
		calc_entrada_minima: "",
		calc_sinal_minimo: "",
	},
	currentCalculo: {
		showQtdeEntrada: false,
		showSinalEntrada: false,
		showQtdeParcela: false,
		sinalObrigatorio: false,
		sinalMinimoObrigatorio: false,
		allQtdeEntrada: [],
		allQtdeParcela: [],
		entradaValor: 0,
		entradaQtde: 0,
		entradaSinal: 0,
		entradaParcelada: 0,
		entradaParceladaDescricao: "",
		parcelaValor: 0,
		parcelaQtde: 0,
		totalEntrada: 0,
		totalValor: 0,
		saldoRestante: 0,
		plano: {},
	},
	validateForm: {
		entradaValor: "",
		entradaSinal: "",
	},
	listCalculo: [],
};

const calculateSinal = (valor, qtde) => {
	return parseFloat((valor / (qtde + 1)).toFixed(2));
};

const calculateEntradaDivisao = (valor, taxa, qtde) => {
	let entrada = parseFloat(calculateFinanciamento(valor, taxa, qtde));
	let descricao = `${qtde} x ${formatNumber(
		parseFloat(calculateFinanciamento(valor, taxa, qtde))
	)}`;

	return {
		entrada: entrada <= 0 ? 0 : entrada,
		descricao: entrada <= 0 ? formatNumber(0) : descricao,
	};
};

const calculateTotalEntrada = (values) => {
	let { entradaSinal, entradaQtde, entradaParcelada } = values;

	if (!Number(entradaSinal)) entradaSinal = 0;
	if (!Number(entradaQtde)) entradaQtde = 0;
	if (!Number(entradaParcelada)) entradaParcelada = 0;

	let totalValor = entradaSinal + entradaQtde * entradaParcelada;
	return totalValor;
};

const calculateSaldo = (values) => {
	let { preco_total, totalEntrada } = values;

	if (!Number(preco_total)) preco_total = 0;
	if (!Number(totalEntrada)) totalEntrada = 0;

	return preco_total - totalEntrada;
};

const calculateParcela = (valor, taxa, qtde) => {
	let parcela = parseFloat(calculateFinanciamento(valor, taxa, qtde));
	return parcela <= 0 ? 0 : parcela;
};

const calculateTotalGeral = (values) => {
	const { totalEntrada, parcelaQtde, allQtdeParcela } = values;

	if (!allQtdeParcela || allQtdeParcela.length <= 0) return 0;

	let totalParcela = allQtdeParcela.find((data) => data.qtde === parcelaQtde);
	let totalValor = totalEntrada + totalParcela.qtde * totalParcela.valor;
	return totalValor;
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GETDIALOGOIMOVELVENDA:
			return {
				...state,
				getImovel: action.payload.data,
				showDialogImovel: action.payload.show,
			};
		case OPENDIALOGOIMOVELVENDA:
			return {
				...state,
				showDialogImovel: action.payload,
				currentPlanoPagto: INITIAL_STATE.currentPlanoPagto,
				currentCalculo: INITIAL_STATE.currentCalculo,
				validateForm: INITIAL_STATE.validateForm,
				listCalculo: INITIAL_STATE.listCalculo,
			};
		case SETEXPANDEDIMOVEL:
			return {
				...state,
				expanded: action.payload,
			};
		case CHANGEFORMPLANOPAGAMENTO: {
			const { value, list, amount } = action.payload;
			const { preco_total } = state.getImovel;
			const planoPagto = list.find((data) => data.cod_planovenda === value);

			let entradaMinima = (amount * planoPagto.planovenda_entrada_porc) / 100;

			let newValues = {
				showQtdeEntrada: false,
				showSinalEntrada: false,
				showQtdeParcela: false,
				sinalObrigatorio: false,
				allQtdeEntrada: [],
				allQtdeParcela: [],
				entradaQtde: 1,
				entradaParcelada: 0,
				entradaParceladaDescricao: "",
				entradaSinal: 0,
				totalEntrada: 0,
				saldoRestante: 0,
				parcelaQtde: 0,
				parcelaValor: 0,
				totalValor: 0,
			};

			if (planoPagto.planovenda_entrada_quant === 1) {
				// Plano a vista 100%
				newValues.showQtdeEntrada = false;
				newValues.showSinalEntrada = false;
				newValues.sinalObrigatorio = false;
				newValues.entradaSinal = entradaMinima;
			} else if (planoPagto.planovenda_entrada_quant > 1) {
				// Entrada parcelada
				newValues.showQtdeEntrada = true;

				if (planoPagto.planovenda_entrada_sinal === "SIM") {
					newValues.entradaSinal = calculateSinal(entradaMinima, 1);
					newValues.showSinalEntrada = true;
				} else {
					newValues.entradaSinal = 0;
					newValues.showSinalEntrada = false;
				}

				planoPagto.planovenda_entrada_sinal_obr === "SIM"
					? (newValues.sinalObrigatorio = true)
					: (newValues.sinalObrigatorio = false);

				planoPagto.planovenda_entrada_sinal_min === "SIM"
					? (newValues.sinalMinimoObrigatorio = true)
					: (newValues.sinalMinimoObrigatorio = false);

				newValues.allQtdeEntrada = [
					...Array(planoPagto.planovenda_entrada_quant).keys(),
				].map((value) => {
					return {
						qtde: value + 1,
						qtdeDescricao: `${
							planoPagto.planovenda_entrada_sinal === "SIM" ? "SINAL + " : ""
						} ${value + 1} x `,
					};
				});

				const { entrada, descricao } = calculateEntradaDivisao(
					entradaMinima - newValues.entradaSinal,
					planoPagto.planovenda_entrada_reajuste,
					newValues.entradaQtde
				);

				newValues.entradaParcelada = entrada;
				newValues.entradaParceladaDescricao = descricao;
			}

			newValues.totalEntrada = calculateTotalEntrada({
				entradaSinal: newValues.entradaSinal,
				entradaQtde: newValues.entradaQtde,
				entradaParcelada: newValues.entradaParcelada,
			});

			// Parcelas
			if (planoPagto.planovenda_parcela_quant > 0) {
				newValues.saldoRestante = calculateSaldo({
					preco_total,
					totalEntrada: newValues.totalEntrada,
				});

				newValues.showQtdeParcela = true;
				newValues.allQtdeParcela = [
					...Array(planoPagto.planovenda_parcela_quant).keys(),
				]
					.map((value) => {
						let parcela = calculateParcela(
							newValues.saldoRestante,
							planoPagto.planovenda_parcela_reajuste,
							value + 1
						);

						return {
							qtde: value + 1,
							valor: parcela,
							qtdeDescricao: `${value + 1} x R$ ${formatNumber(parcela)}`,
						};
					})
					.reverse();

				newValues.parcelaQtde = planoPagto.planovenda_parcela_quant;
				newValues.parcelaValor = newValues.allQtdeParcela.find(
					(data) => data.qtde === newValues.parcelaQtde
				).valor;
			}

			newValues.totalValor = calculateTotalGeral({
				totalEntrada: newValues.totalEntrada,
				parcelaQtde: newValues.parcelaQtde,
				allQtdeParcela: newValues.allQtdeParcela,
			});

			return {
				...state,
				currentCalculo: {
					...state.currentCalculo,
					...newValues,
					entradaValor: entradaMinima,
					plano: {
						...planoPagto,
						calc_entrada_minima: entradaMinima,
						calc_sinal_minimo: newValues.entradaSinal,
					},
				},
				currentPlanoPagto: {
					...planoPagto,
					calc_entrada_minima: entradaMinima,
					calc_sinal_minimo: newValues.entradaSinal,
				},
				validateForm: INITIAL_STATE.validateForm,
			};
		}
		case CHANGEFORMCALCULO: {
			let { name, value } = action.payload;
			const { preco_total } = state.getImovel;
			let { calc_sinal_minimo } = state.currentPlanoPagto;
			const {
				showQtdeParcela,
				entradaSinal,
				entradaParcelada,
				entradaParceladaDescricao,
				entradaQtde,
				entradaValor,
				parcelaQtde,
				allQtdeParcela,
				saldoRestante,
				parcelaValor,
				plano,
			} = state.currentCalculo;

			let newValues = {
				showQtdeParcela,
				entradaSinal,
				entradaQtde,
				entradaParcelada,
				entradaParceladaDescricao,
				parcelaQtde,
				saldoRestante,
				allQtdeParcela,
				parcelaValor,
				totalEntrada: 0,
				totalValor: 0,
			};

			if (name === "entradaSinal") {
				newValues.entradaSinal = value;

				const { entrada, descricao } = calculateEntradaDivisao(
					entradaValor - newValues.entradaSinal,
					plano.planovenda_entrada_reajuste,
					entradaQtde
				);

				newValues.entradaParcelada = entrada;
				newValues.entradaParceladaDescricao = descricao;
			} else if (name === "entradaValor" || name === "entradaQtde") {
				let valor = 0;
				let qtde = 0;

				if (name === "entradaValor") {
					valor = value;
					qtde = entradaQtde;
				} else if (name === "entradaQtde") {
					valor = entradaValor;
					qtde = value;
				}

				newValues.entradaQtde = qtde;

				if (plano.planovenda_entrada_sinal === "SIM") {
					if (plano.planovenda_entrada_porc !== 100) {
						newValues.entradaSinal = calculateSinal(valor, qtde);
					} else if (plano.planovenda_entrada_porc === 100) {
						// Jogar valor da entrada para o sinal caso seja plano avista 100%
						newValues.entradaSinal = valor;
					}
				} else {
					newValues.entradaSinal = 0;
				}

				// Quantidade de entrada maior que 1
				if (plano.planovenda_entrada_quant > 1) {
					const { entrada, descricao } = calculateEntradaDivisao(
						valor,
						plano.planovenda_entrada_reajuste,
						qtde
					);

					newValues.entradaParcelada = entrada;
					newValues.entradaParceladaDescricao = descricao;
					calc_sinal_minimo = newValues.entradaSinal;
				}
			} else if (name === "parcelaQtde") {
				newValues.parcelaQtde = value;
				newValues.parcelaValor = newValues.allQtdeParcela.find(
					(data) => data.qtde === newValues.parcelaQtde
				).valor;
			}

			newValues.totalEntrada = calculateTotalEntrada({
				entradaSinal: newValues.entradaSinal,
				entradaQtde: newValues.entradaQtde,
				entradaParcelada: newValues.entradaParcelada,
			});

			// Parcelas
			if (plano.planovenda_parcela_quant > 0) {
				newValues.saldoRestante = calculateSaldo({
					preco_total,
					totalEntrada: newValues.totalEntrada,
				});

				newValues.showQtdeParcela = true;
				newValues.allQtdeParcela = [
					...Array(plano.planovenda_parcela_quant).keys(),
				]
					.map((value) => {
						let parcela = calculateParcela(
							newValues.saldoRestante,
							plano.planovenda_parcela_reajuste,
							value + 1
						);

						return {
							qtde: value + 1,
							valor: parcela,
							qtdeDescricao: `${value + 1} x R$ ${formatNumber(parcela)}`,
						};
					})
					.reverse();

				// Pega o novo valor da parcela de acordo com quantidade selecionada
				newValues.parcelaValor = newValues.allQtdeParcela.find(
					(data) => data.qtde === newValues.parcelaQtde
				).valor;

				//newValues.parcelaQtde = planoPagto.planovenda_parcela_quant;
			}

			newValues.totalValor = calculateTotalGeral({
				totalEntrada: newValues.totalEntrada,
				parcelaQtde: newValues.parcelaQtde,
				allQtdeParcela: newValues.allQtdeParcela,
			});

			return {
				...state,
				currentCalculo: {
					...state.currentCalculo,
					...newValues,
					[name]: value,
				},
				currentPlanoPagto: {
					...state.currentPlanoPagto,
					calc_sinal_minimo,
				},
			};
		}
		case ERRORSFORMCALCULO: {
			const form = action.payload;
			const { preco_total } = state.getImovel;
			const {
				sinalObrigatorio,
				sinalMinimoObrigatorio,
				entradaValor,
			} = state.currentCalculo;
			const {
				calc_entrada_minima,
				calc_sinal_minimo,
			} = state.currentPlanoPagto;
			let result = "";

			form.forEach(({ name, value }) => {
				switch (name) {
					case "entradaValor":
						if (!value) result = "A entrada não pode ser vazia";
						if (removeMaskMoney(value) === 0)
							result = "A entrada não pode ser 0,00";
						if (removeMaskMoney(value) < calc_entrada_minima)
							result = `Valor mínimo é R$ ${formatNumber(calc_entrada_minima)}`;
						if (removeMaskMoney(value) > preco_total)
							result = "O sinal não pode ultrapassar o valor total do imóvel";

						state.validateForm = {
							...state.validateForm,
							[name]: result,
						};
						break;
					case "entradaSinal":
						if (sinalObrigatorio) {
							if (removeMaskMoney(value) === 0)
								result = "O sinal da entrada é obrigatório e não pode ser 0,00";
						}
						if (sinalMinimoObrigatorio) {
							if (removeMaskMoney(value) < calc_sinal_minimo)
								result = `Valor mínimo é R$ ${formatNumber(calc_sinal_minimo)}`;
						}
						if (removeMaskMoney(value) > entradaValor)
							result = `O sinal não pode ultrapassar R$ ${formatNumber(
								entradaValor
							)}`;

						state.validateForm = {
							...state.validateForm,
							[name]: result,
						};
						break;
					default:
						state.validateForm = { ...state.validateForm };
				}
			});

			return {
				...state,
				validateForm: {
					...state.validateForm,
				},
			};
		}
		case SETLISTCALCULO: {
			return {
				...state,
				listCalculo: [...state.listCalculo, action.payload],
			};
		}
		case NEWCALCULO: {
			return {
				...state,
				currentCalculo: INITIAL_STATE.currentCalculo,
				validateForm: INITIAL_STATE.validateForm,
			};
		}
		case REMOVECALCULO: {
			let array = state.listCalculo;
			array.splice(action.payload, 1);

			return {
				...state,
				listCalculo: array,
			};
		}
		default:
			return state;
	}
};
