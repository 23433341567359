import "./style.css";

import {
	Button,
	CardActions,
	CardContent,
	Collapse,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	Paper,
	Slide,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@material-ui/core";
import { Cancel, Check, Close, Error, HelpOutline } from "@material-ui/icons";
import React from "react";

import { formatDateToLocaleString } from "../../../../utils/funcoes";
import ToastConfirm from "../../../global/Toast";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
	const { oportunidade } = props.store;
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleClose = () => {
		props.openDialogAssinatura({ show: false });
	};

	const colorsSituacao = (value) => {
		switch (value) {
			case "Aguardando Assinatura":
				return "#ffc866";
			case "Assinado Eletronicamente":
				return "#469c47";
			case "Reprovado":
				return "#e93f66";
			default:
				return "#f0f0";
		}
	};

	const iconsSituacao = (value) => {
		switch (value.situacao) {
			case "Aguardando Assinatura":
				return <Error className="icon__error venda__icon--error" />;
			case "Assinado Eletronicamente":
				return <Check className="venda__icon--check" />;
			case "Reprovado":
				return <Cancel className="venda__icon--disapproved" />;
			default:
				return <Error className="icon__error venda__icon--error" />;
		}
	};

	return (
		<>
			<Dialog
				open={oportunidade.dialogAssinatura.show}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
			>
				<div className="venda">
					<DialogTitle className="venda__header">
						<DialogActions
							className="dialog-header__btn-close-container"
							onClick={handleClose}
						>
							<Close className="icon__close" />
						</DialogActions>

						<span className="dialog-header__title">ASSINATURA AUTOMÁTICA</span>

						<div className="venda__subtitle">
							<p>
								Use esta tela para enviar seus documentos para análise.
								<br />
								Basta clicar em cada tipo de documento para poder anexá-lo.
								<br />
								Em caso de dúvidas sobre o envio, clique no botão de ajuda (?)
							</p>
						</div>
					</DialogTitle>

					<TableContainer
						component={Paper}
						className="container-elevation venda__container"
					>
						<div className="venda__title-container">
							<div className="venda__title-wrapper">
								<h3 className="venda__title">{"Proposta"}</h3>
								<CardActions disableSpacing className="venda__title-icon">
									<IconButton
										onClick={handleExpandClick}
										aria-expanded={expanded}
										aria-label="show more"
									>
										<HelpOutline className="venda__icon" />
									</IconButton>
								</CardActions>
							</div>

							<Collapse
								in={expanded}
								timeout="auto"
								unmountOnExit
								className="intro venda-container__collapse"
							>
								<CardContent className="venda-container__card">
									<Typography paragraph>Method:</Typography>
									<Typography paragraph>
										O incentivo ao avanço tecnológico, <br />
										assim como o aumento do diálogo entre os diferentes setores
										<br /> produtivos ainda não demonstrou convincentemente que
										vai
										<br /> participar na mudança das novas proposições.
									</Typography>
								</CardContent>
							</Collapse>
						</div>
						<Table>
							<TableBody>
								{oportunidade.allAssinaturas.map((value, index) => (
									<TableRow hover key={index}>
										<TableCell className="venda__cell">
											{iconsSituacao(value)}
										</TableCell>
										<TableCell className="venda__cell">
											{value.pessoa_nome}

											{value.finalizacao_dt && (
												<>
													<br />
													<span className="venda__date">
														{`Finalizado ${formatDateToLocaleString(
															value.finalizacao_dt
														)}`}
													</span>
												</>
											)}

											{value.situacao && (
												<>
													<br />
													<span
														style={{ color: colorsSituacao(value.situacao) }}
													>
														{value.situacao}
													</span>
												</>
											)}
											{value.situacao_motivo && (
												<>
													<br />
													<span>{value.situacao_motivo}</span>
												</>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<div className="venda__btn-container">
						<Button className="btn__blue" onClick={handleClose}>
							<Check className="icon__check venda__icon--confirm" />
							Confirmar
						</Button>
					</div>
					<ToastConfirm {...props} />
				</div>
			</Dialog>
		</>
	);
};
