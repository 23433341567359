import {
	GETIMOVELSEARCH,
	GETTYPEVIEW,
	SETPAGESEARCH,
	SETTYPEVIEW,
} from "./QueryPropertyType";

const INITIAL_STATE = {
	typeView: "list",
	imovelSearch: [],
	pageSearch: 0,
};

export default function QueryPropertyReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case GETTYPEVIEW:
			return {
				...state,
			};
		case SETTYPEVIEW:
			if (action.payload === state.typeView) {
				return {
					...state,
				};
			}
			return {
				...state,
				typeView: action.payload,
			};
		case GETIMOVELSEARCH:
			if (action.payload !== "clear") {
				return {
					...state,
					imovelSearch: [...action.payload, ...state.imovelSearch],
				};
			} else {
				return {
					...state,
					imovelSearch: [],
				};
			}

		case SETPAGESEARCH:
			return {
				...state,
				pageSearch: action.payload,
			};
		default:
			return state;
	}
}
