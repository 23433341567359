import pessoa from "pages/PersonConsultation/reducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import { loader } from "../../components/Loader/";
import empreendimento from "../Allotment/AllotmentReducer";
import AllotmentSaga from "../Allotment/AllotmentSaga";
import company from "../Company/CompanyReducer";
import CompanySaga from "../Company/CompanySaga";
import contato from "../Contact/ContactReducer";
import ContactSaga from "../Contact/ContactSaga";
import dashboard from "../Dashboard/DashboardReducer";
// Sagas
import DashboardSaga from "../Dashboard/DashboardSaga";
import enterprise from "../Enterprise/EnterpriseReducer";
import EnterpriseSaga from "../Enterprise/EnterpriseSaga";
import global from "../Global/GlobalReducer";
import GlobalSaga from "../Global/GlobalSaga";
//  Reducers
import imovel from "../Immobile/ImmobileReducer";
import ImmobileSaga from "../Immobile/ImmobileSaga";
import modalResponseApi from "../ModalResponseApi/ModalResponseApiReducer";
import ModalResponseApiSaga from "../ModalResponseApi/ModalResponseApiSaga";
import oportunidade from "../Negotiation/NegotiationReducer";
import NegotiationSaga from "../Negotiation/NegotiationSaga";
//import usuario from "../Usuario/UsuarioReducer";
import queryProperty from "../QueryProperty/QueryPropertyReducer";
import QueryPropertySaga from "../QueryProperty/QueryPropertySaga";
import relatorio from "../Report/ReportReducer";
import ReportSaga from "../Report/ReportSaga";
import venda from "../Sale/SaleReducer";
import SaleSaga from "../Sale/SaleSaga";
import selects from "../Selects/SelectsReducer";
import SelectsSaga from "../Selects/SelectsSaga";
import usuario from "../User/UserReducer";
import UserSaga from "../User/UserSaga";

const reducers = combineReducers({
	global,
	dashboard,
	imovel,
	venda,
	pessoa,
	oportunidade,
	usuario,
	relatorio,
	loader,
	contato,
	usuario,
	empreendimento,
	consultaImoveis: queryProperty,
	selects,
	company,
	modalResponseApi,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(DashboardSaga);
sagaMiddleware.run(ContactSaga);
sagaMiddleware.run(UserSaga);
sagaMiddleware.run(AllotmentSaga);
sagaMiddleware.run(ImmobileSaga);
sagaMiddleware.run(SaleSaga);
sagaMiddleware.run(ReportSaga);
sagaMiddleware.run(NegotiationSaga);

sagaMiddleware.run(EnterpriseSaga);
sagaMiddleware.run(GlobalSaga);
sagaMiddleware.run(QueryPropertySaga);
sagaMiddleware.run(SelectsSaga);
sagaMiddleware.run(CompanySaga);
sagaMiddleware.run(ModalResponseApiSaga);

export default store;
