import "./style.css";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import { Check, Error } from "@material-ui/icons";
import React from "react";
import { setShowAlertConfirm } from "redux/Global/GlobalAction";

export default (props) => {
	const { global } = props.store;

	const handleClose = () => {
		props.dispatch(setShowAlertConfirm({ show: false }));
	};

	return (
		<>
			<Dialog
				open={global.showAlertConfirm.show}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className="bg-gray--light">
					<DialogContent>
						<DialogTitle id="alert-dialog-title" className="orange">
							<Error className="alert-question__title-icon" />
							{global.showAlertConfirm.title}
						</DialogTitle>
						<DialogContentText
							id="alert-dialog-description"
							className="alert-confirm__description"
						>
							{global.showAlertConfirm.text}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<div className="alert-confirm__btn-container">
							<Button
								onClick={handleClose}
								autoFocus
								className="btn__blue--medium"
							>
								<Check className="alert-confirm__icon" />
								OK
							</Button>
						</div>
					</DialogActions>
				</div>
			</Dialog>
		</>
	);
};
