export const PAINELEMPREENDIMENTOSAGA = "PAINELEMPREENDIMENTOSAGA";
export const GETPLANOPAGAMENTOSAGA = "GETPLANOPAGAMENTOSAGA";
export const ESPELHOEMPREENDIMENTOSAGA = "ESPELHOEMPREENDIMENTOSAGA";

export const PAINELEMPREENDIMENTO = "PAINELEMPREENDIMENTO";
export const GETPLANOPAGAMENTO = "GETPLANOPAGAMENTO";
export const ESPELHOEMPREENDIMENTO = "ESPELHOEMPREENDIMENTO";
export const SETEXPANDEDIMOVEL = "SETEXPANDEDIMOVEL";
export const UPDATECOLORIMOVEL = "UPDATECOLORIMOVEL";
export const SETEXPANDED = "SETEXPANDED";
