import React from "react";

const Erro = (props) => {
	return (
		<>
			<h1>Algo deu errado!</h1>
			<h5>
				Alguma coisa não está funcionado corretamente. Por favor, tente
				novamente mais tarde.
			</h5>
			<h6>{JSON.stringify(props)}</h6>
		</>
	);
};

export default Erro;
