import "./style.css";

import {
	Button,
	Checkbox,
	IconButton,
	InputBase,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	withStyles,
} from "@material-ui/core";
import {
	Add,
	ChevronRight,
	Edit,
	KeyboardArrowLeft,
	Search,
} from "@material-ui/icons";
import AlertConfirm from "components/global/alert-confirm";
import AlertQuestionContato from "components/global/alert-question-contato";
import CadastroContato from "pages/Contact/Form";
import React from "react";
import {
	searchContatos,
	setCurrentContato,
	setShowDialog,
} from "redux/Contact/ContactAction";
import {
	setShowAlertConfirm,
	setShowAlertQuestionContato,
} from "redux/Global/GlobalAction";
import { handleChangeProposta } from "redux/Sale/SaleAction";

export default (props) => {
	let timeOut = 0;
	const { contato } = props.store;
	const [contatoSelected, setContatoSelected] = React.useState([]);

	const onSelectClick = (value) => {
		setContatoSelected(
			contatoSelected.includes(value)
				? contatoSelected.filter((c) => c !== value)
				: [value]
		);
	};

	const handleSaveForm = () => {
		if (contatoSelected.length === 0) return;
		let currentContato = contatoSelected[0];

		if (props.proposta || props.reserva) {
			let error = "";

			!currentContato.contato_celular
				? (error =
						"É necessário cadastrar um celular para o contato selecionado!")
				: (error = "");

			!currentContato.contato_cpfcnpj
				? (error =
						"É necessário cadastrar um CPF/CNPJ para o contato selecionado!")
				: (error = "");

			if (error) {
				props.dispatch(
					setShowAlertConfirm({
						show: true,
						title: "Atenção",
						text: error,
					})
				);

				return;
			}
		}

		if (props.proposta) {
			props.dispatch(
				handleChangeProposta({ name: "contato", value: currentContato })
			);
			props.setStep(props.nextStep);
		} else if (props.reserva) {
			props.dispatch(
				setShowAlertQuestionContato({
					show: true,
					title: "Atenção",
					text: "Confirma o cadastro da reserva?",
				})
			);

			props.setContato(currentContato);
		} else {
			props.saveOrcamento(currentContato);
			props.setStep(props.nextStep);
		}
	};

	const handleOnClickAnterior = () => {
		props.setStep(props.priorStep);
	};

	const handleOnChange = (e) => {
		var searchText = e.target.value;

		if (timeOut) clearTimeout(timeOut);
		timeOut = setTimeout(() => {
			props.dispatch(searchContatos(searchText));
		}, 500);
	};

	const handleNewContato = () => {
		props.dispatch(setCurrentContato(0));
		props.dispatch(setShowDialog(true));
	};

	const handleEditContato = (value) => {
		props.dispatch(setCurrentContato(value.cod_contato));
		props.dispatch(setShowDialog(true));
	};

	const CustomCheckbox = withStyles({
		root: {
			"&$checked": {
				color: "#0548a3",
			},
		},
		checked: {},
	})(Checkbox);

	return (
		<>
			<div className="contact bg-gray--light">
				<div className="contact__header bg-blue">
					<div className="header__search contact__input">
						<div className="header__icon">
							<Search />
						</div>
						<InputBase
							className="font-input-white"
							placeholder="Buscar…"
							onChange={handleOnChange}
							inputProps={{ "aria-label": "search" }}
						/>
					</div>

					<Button
						className="contact__btn bg-gray--light"
						onClick={handleNewContato}
					>
						<Add className="icon__plus" />
						Novo
					</Button>
				</div>

				<div className="contact__table-container">
					<TableContainer component={Paper} className="contact__table bg-white">
						<Table>
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell className="table-container__table--title">
										Nome
									</TableCell>
									<TableCell />
								</TableRow>
							</TableHead>

							<TableBody>
								{contato.allContatos.map((value, index) => (
									<TableRow hover key={index}>
										<TableCell>
											<CustomCheckbox
												onChange={() => onSelectClick(value)}
												checked={contatoSelected.includes(value)}
											/>
										</TableCell>
										<TableCell>{value.contato_nome}</TableCell>
										<TableCell>
											<IconButton
												onClick={() => handleEditContato(value)}
												className="btn__circular"
											>
												<Edit className="icon__edit" />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>

				<div className="contact__btn-container bg-gray--light">
					{!props.reserva ? (
						<Button onClick={handleOnClickAnterior} className="btn__blue">
							<KeyboardArrowLeft className="icon__back contact__icon-back" />
							Anterior
						</Button>
					) : null}
					<Button
						onClick={handleSaveForm}
						className="btn__blue contact__btn--next"
					>
						Finalizar
						<ChevronRight className="icon__next contact__icon-next" />
					</Button>
				</div>

				<CadastroContato {...props} />

				<AlertConfirm {...props} />
				<AlertQuestionContato
					{...props}
					handleConfirm={props.handleConfirmReserva}
				/>
			</div>
		</>
	);
};
