// import { WhatsappShareButton, WhatsappIcon } from "react-share";
// import { Preview, print } from "react-html2pdf";
// import * as jsPDF from "jspdf";
// import * as html2canvas from "html2canvas";
import "./style.css";

import { Button } from "@material-ui/core";
import { AttachFile, Check, Share } from "@material-ui/icons";
import React from "react";

export default (props) => {
	const { venda } = props.store;
	console.log("compartilahar", props);
	// const { pdf } = venda;
	// const [printPdf, setPrintPdf] = useState(false);

	// const handlePdf = () => {
	// 	var div = document.getElementById("page");
	// 	div.innerHTML = venda.pdf.html;

	// 	html2canvas(div).then((canvas) => {
	// 		const imgData = canvas.toDataURL("image/png");

	// 		const pdf = new jsPDF("p", "px", "a4");
	// 		var width = pdf.internal.pageSize.getWidth();
	// 		var height = pdf.internal.pageSize.getHeight();
	// 		pdf.addImage(imgData, "JPEG", 0, 0, width, height);
	// 		var blob = pdf.output("blob");
	// 		window.open(URL.createObjectURL(blob));
	// 	});
	// };

	// function handlePrint() {
	// 	props.toShare();
	// 	setPrintPdf(true);
	// }

	// useEffect(() => {
	// 	if (printPdf && pdf) {
	// 		if (pdf.html) handlePdf(); //print("a", "html-template");
	// 	}
	// 	// eslint-disable-next-line
	// }, [pdf, printPdf]);

	return (
		<div className="share bg--light">
			<div className="share__container-text bg-white">
				<span className="share__text green">{props.shareText}</span>
				<div className="share__icon-container bg-gray--bluish">
					<Check className="icon__check share__icon green" />
				</div>
			</div>

			{props.toShare && (
				<>
					{/* <Button onClick={() => print("a", "html-template")}> */}
					{/* <Button onClick={handlePrint}>REQUEST CONVERT PRINT</Button> */}
					{/* <Button onClick={handlePdf}>Set DOM</Button> */}

					<Button onClick={props.toShare} className="btn__blue share__btn">
						<Share className="icon__share share__icon--share" />
						Compartilhar
					</Button>

					{/* <WhatsappShareButton url={venda.pdf ? venda.pdf.fileURL : ""}>
						<WhatsappIcon size={32} round />
					</WhatsappShareButton> */}

					{/* <Button
						onClick={() => {
							navigator.share({
								title: "Orçamento",
								text: "Teste",
								url: venda.pdf ? venda.pdf.fileURL : "",
							});
						}}
					>
						Teste
					</Button> */}

					{/* <Preview
						id={"html-template"}
						html={venda.pdf ? venda.pdf.html : ""}
					/> */}
				</>
			)}

			{props.proposta && (
				<Button
					className="btn__gray share__btn"
					onClick={() => props.toAttach(venda.getProposta)}
				>
					<AttachFile className="icon__clips share__icon--clips" />
					Anexar Documentos
				</Button>
			)}

			{/* <div id="page" style={{ width: "210mm", height: "297mm" }}></div> */}
		</div>
	);
};
