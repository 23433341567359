import {
	CHANGEFORMCALCULO,
	CHANGEFORMPLANOPAGAMENTO,
	ERRORSFORMCALCULO,
	GETDIALOGOIMOVELVENDASAGA,
	NEWCALCULO,
	OPENDIALOGOIMOVELVENDA,
	REMOVECALCULO,
	SETEXPANDEDIMOVEL,
	SETLISTCALCULO,
} from "./ImmobileType";

export function getDialogoImovelVenda(cod_imovel, show = true) {
	return {
		type: GETDIALOGOIMOVELVENDASAGA,
		payload: {
			params: { cod_imovel },
			show,
		},
	};
}

export function showDialogImovel(show) {
	return { type: OPENDIALOGOIMOVELVENDA, payload: show };
}

export const setExpanded = (value) => {
	return { type: SETEXPANDEDIMOVEL, payload: value };
};

export function handleChangePlanoPagamento(value) {
	return { type: CHANGEFORMPLANOPAGAMENTO, payload: value };
}

export function handleChangeCalculo(value) {
	return { type: CHANGEFORMCALCULO, payload: value };
}

export function validateFormCalculo(value) {
	return { type: ERRORSFORMCALCULO, payload: value };
}

export function setListCalculo(value) {
	return { type: SETLISTCALCULO, payload: value };
}

export function newCalculo() {
	return { type: NEWCALCULO };
}

export function removeListaCalculo(item) {
	return { type: REMOVECALCULO, payload: item };
}
