import styled from "styled-components";

export const ContainerElevation = styled.div`
	margin: -25px 15px 25px 15px;
	padding: 25px 50px 15px 50px;
	border-radius: 5px;
	background: var(--color-gray-bluish);

	@media (max-width: 1279px) {
		padding: 25px 25px 15px 25px;
	}
`;

export const SideBar = styled.div`
	width: min(300px, 600px);
	border-radius: 5px 0 0 5px;
	background: var(--color-white);
`;

export const ButtonStep = styled.button`
	width: 140px;
	height: 35px;
	margin: 30px 0px 0px 20px !important;
	display: flex;
	justify-content: center;
	border: 1px solid var(--color-blue-younect) !important;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	font-family: "Roboto", sans-serif !important;
	text-transform: uppercase;
	text-align: center !important;
	outline: none;
`;

export const ButtonStepOutlined = styled.button`
	width: 140px;
	height: 35px;
	margin-top: 30px !important;
	border: 1px solid var(--color-blue-younect) !important;
	border-radius: 5px;
	font-family: "Roboto", sans-serif !important;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	outline: none;
	color: var(--color-blue-younect);

	&:hover {
		background: var(--color-blue-younect);
		color: var(--color-white);
	}
`;
