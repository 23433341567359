import {
	GETALLFILTERSSAGA,
	GETALLSELECTSSAGA,
	SETFILTERSSAGA,
} from "./SelectsType";

export function getAllSelects() {
	return { type: GETALLSELECTSSAGA };
}

export function setAllFilters(payload) {
	return { type: SETFILTERSSAGA, payload: payload };
}

export function getAllFilters() {
	return { type: GETALLFILTERSSAGA };
}
