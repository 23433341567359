import "date-fns";
import "./style.css";

import DateFnsUtils from "@date-io/date-fns";
import { MenuItem, TextField } from "@material-ui/core";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import React from "react";

import AutoComplete from "../../../../components/global/text-auto-complete";

export default (props) => {
	const { form } = props;
	const { pessoa } = props.store;

	console.log(form);

	return (
		<>
			<div>
				<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
					<KeyboardDatePicker
						autoOk
						disableToolbar
						format="dd/MM/yyyy"
						disableFuture={true}
						margin="normal"
						label="Data de Nascimento"
						invalidDateMessage="Data em formato inválido"
						maxDateMessage="A data não pode ser maior ou igual a data atual"
						value={form.pessoa_nasc_dt || null}
						onChange={(date) =>
							props.handleOnChangeDate("pessoa_nasc_dt", date)
						}
						cancelLabel={null}
						okLabel={null}
					/>
				</MuiPickersUtilsProvider>

				<TextField
					className="cadastro-container__input"
					autoComplete="off"
					label="RG"
					name="pessoa_rg"
					value={form.pessoa_rg}
					onChange={props.handleOnChange}
				/>

				<TextField
					className="cadastro-container__input"
					autoComplete="off"
					label="RG Orgão"
					name="pessoa_rg_orgao"
					value={form.pessoa_rg_orgao}
					onChange={props.handleOnChange}
				/>
			</div>

			<div>
				<TextField
					className="cadastro-container__input"
					autoComplete="off"
					label="Sexo"
					name="pessoa_sexo"
					value={form.pessoa_sexo}
					onChange={props.handleOnChange}
					select
				>
					<MenuItem value="">
						<em>Nenhum</em>
					</MenuItem>
					{pessoa.arraySexo.map((data, index) => (
						<MenuItem key={index} value={data.value}>
							{data.name}
						</MenuItem>
					))}
				</TextField>

				<TextField
					className="cadastro-container__input"
					autoComplete="off"
					label="Nacionalidade"
					name="cod_nacional"
					value={form.cod_nacional}
					onChange={props.handleOnChange}
				/>

				<TextField
					className="cadastro-container__input"
					autoComplete="off"
					label="Naturalidade"
					name="cod_municipio"
					value={form.cod_municipio}
					onChange={props.handleOnChange}
				/>

				<AutoComplete />
			</div>

			<div>
				<TextField
					className="cadastro-container__input"
					autoComplete="off"
					label="Profissão"
					name="cod_profissao"
					value={form.cod_profissao}
					onChange={props.handleOnChange}
				/>

				<TextField
					className="cadastro-container__input"
					autoComplete="off"
					label="Estado Civil"
					name="cod_estcivil"
					value={form.cod_estcivil}
					onChange={props.handleOnChange}
				/>

				<TextField
					className="cadastro-container__input"
					autoComplete="off"
					label="Regime de Bens"
					name="cod_regime"
					value={form.cod_regime}
					onChange={props.handleOnChange}
				/>
			</div>
		</>
	);
};
