import React from "react";

import {
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Typography,
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { NavLink } from "react-router-dom";
import "./style.css";

export default function DashboardVendaPainel(props) {
	return (
		<Card
			className="card-opportunity"
			component={NavLink}
			to={`/dashboard/${props.painel.url}`}
		>
			<CardHeader
				className="card-opportunity__header"
				action={
					<IconButton
						aria-label="settings"
						className="card-opportunity__container"
					>
						<MoreVertIcon className="black" />
					</IconButton>
				}
				title={props.painel.titulo}
			/>
			<CardContent className="card-opportunity__content">
				<div className="card__legend--urgent bg-red--light"></div>
				<Typography variant="body2" className="black" component="p">
					Preciso Resolver
					<span className="card__value black">
						{props.painel.preciso_resolver}
					</span>
				</Typography>

				<div className="card__wrapper">
					<div className="card__legend--attention bg-yellow"></div>
					<Typography variant="body2" className="black" component="p">
						Aguardando Resposta
						<span className="card__value black">
							{props.painel.aguardando_resposta}
						</span>
					</Typography>
				</div>
			</CardContent>
		</Card>
	);
}
