import {
	CHANGETIPO,
	GETDASHBOARD,
	GETMOREPAINELOPORTUNIDADE,
	GETPAINELOPORTUNIDADE,
	// OPENDIALOGOPAINELVENDA,
	// GETFASEDIALOGO,
} from "./DashboardType";

const INITIAL_STATE = {
	allDashboards: {},
	getPainel: [],
	openDialogoPainelVenda: false,
	getFaseDialogo: {},
	tipo: 0,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CHANGETIPO:
			return { ...state, tipo: action.payload };
		case GETDASHBOARD:
			return {
				...state,
				allDashboards: action.payload,
			};
		case GETPAINELOPORTUNIDADE:
			return {
				...state,
				getPainel: action.payload,
			};
		case GETMOREPAINELOPORTUNIDADE:
			return {
				...state,
				getPainel: [...state.getPainel, ...action.payload],
			};
		// case OPENDIALOGOPAINELVENDA:
		// 	return {
		// 		...state,
		// 		openDialogoPainelVenda: !state.openDialogoPainelVenda,
		// 	};
		// case GETFASEDIALOGO:
		// 	return {
		// 		...state,
		// 		getFaseDialogo: action.payload,
		// 	};
		default:
			return state;
	}
};
