import React, { useState, useRef } from "react";

import {
	Avatar,
	Button,
	Dialog,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Paper,
	Slide,
	DialogTitle,
	DialogActions,
} from "@material-ui/core";

import InfiniteScroll from "react-infinite-scroll-component";
import { Close, Add } from "@material-ui/icons";

import { SearchInput, Checkbox } from "components";

import Pessoa from "./SelectPerson";
import Lead from "./AddLead";

import {
	getLead,
	getMoreLead,
	setSelectedPessoa,
	getCorretores,
	getProspeccao,
} from "redux/Negotiation/NegotiationAction";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
	const { oportunidade } = props.store;
	const [search, setSearch] = useState("");
	const [leadSelected, setLeadSelected] = useState([]);
	const [infiniteActive, setInfiniteActive] = useState(false);
	const [page, setPage] = useState(1);
	const tableRef = useRef(null);
	const [step, setStep] = useState("busca");

	// console.log(oportunidade.allLeads);

	// useEffect(() => {
	// 	console.log("teste");
	// }, []);

	const handleChangeSearch = (filtro) => {
		setPage(1);
		setInfiniteActive(false);

		props.dispatch(getLead({ filtro, page }));

		setSearch(filtro);
		setInfiniteActive(true);
		tableRef.current.scrollTop = 0;
	};

	const fetchMoreData = () => {
		const newPage = page + 1;

		props.dispatch(
			getMoreLead({
				filtro: search,
				page: newPage,
			})
		);

		setPage(newPage);
	};

	const onSelectClick = (value) => {
		setLeadSelected(
			leadSelected.includes(value)
				? leadSelected.filter((c) => c !== value)
				: [value]
		);
	};

	const handleClose = () => {
		props.handleClose();

		setStep("busca");
		setPage(1);
		setInfiniteActive(false);
	};

	const renderStep = () => {
		switch (step) {
			case "busca":
				return renderDefault();
			case "pessoa":
				return <Pessoa {...props} setStep={setStep} />;
			case "lead":
				return <Lead {...props} setStep={setStep} />;
			default:
				return null;
		}
	};

	const handleNext = () => {
		if (leadSelected.length <= 0) {
			return;
		}

		props.dispatch(setSelectedPessoa(leadSelected.shift()));
		props.dispatch(getProspeccao());
		props.dispatch(getCorretores());
		setStep("lead");
	};

	const renderDefault = () => {
		return (
			<>
				{infiniteActive ? (
					<InfiniteScroll
						dataLength={oportunidade.allLeads.length}
						next={fetchMoreData}
						hasMore={true}
						scrollableTarget="tableContainer"
					/>
				) : null}

				<TableContainer
					component={Paper}
					id="tableContainer"
					className="table-container"
					style={{
						overflow: "auto",
						height: "260px",
						width: "100% !important",
					}}
					ref={tableRef}
				>
					<Table>
						<TableBody>
							{oportunidade.allLeads &&
								oportunidade.allLeads.map((value, index) => (
									<TableRow hover key={index}>
										<TableCell>
											<Checkbox
												onChange={() => onSelectClick(value)}
												checked={leadSelected.includes(value)}
											/>
										</TableCell>
										<TableCell>
											<Avatar alt="avatar" src={value.foto} />
										</TableCell>
										<TableCell>{value.nome}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	return (
		<Dialog
			open={props.open}
			TransitionComponent={Transition}
			onClose={handleClose}
		>
			<div className="dialog-container bg-gray--light">
				<DialogTitle className="dialog-container__header">
					<div className="dialog-container__close" onClick={handleClose}>
						<Close className="icon__close" />
					</div>

					<div className="white--bluish">Nova Negociação</div>

					{step === "busca" && (
						<div className="contact__header bg-blue">
							<SearchInput onChangeSearch={handleChangeSearch} />

							<Button
								className="contact__btn bg-white--bluish"
								onClick={() => setStep("pessoa")}
							>
								<Add className="icon__plus" />
								Novo
							</Button>
						</div>
					)}
				</DialogTitle>

				<div className="dialog-container__content">{renderStep()}</div>

				{step === "busca" && (
					<DialogActions>
						<div className="dialog-container__footer">
							<Button className="btn__blue" onClick={handleNext}>
								Próximo
							</Button>
						</div>
					</DialogActions>
				)}
			</div>
		</Dialog>
	);
};
