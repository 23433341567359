import {
	GETASSINATURA,
	GETCORRETORES,
	GETFASES,
	GETLEADS,
	GETMORELEADS,
	GETOPORTUNIDADE,
	GETORCAMENTOBYETAPA,
	GETPROSPECCAO,
	OPENDIALOGOASSINATURA,
	OPENDIALOGOPORTUNIDADE,
	RESETLEADS,
	SETETAPAACAO,
	SETSELECTEDPESSOA,
} from "./NegotiationType";

const INITIAL_STATE = {
	getOportunidade: {},
	dialogOportunidade: {
		show: false,
		tipo: "",
		inclusao: false,
		cod_opor_fase_etapa: null,
	},
	getAllOrcamentos: {},
	dialogAssinatura: {
		show: false,
	},
	allAssinaturas: [],
	allFases: [],
	allLeads: [],
	selectedPessoa: {},
	allCorretores: [],
	allProspeccao: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GETCORRETORES:
			return {
				...state,
				allCorretores: action.payload,
			};
		case GETPROSPECCAO:
			return {
				...state,
				allProspeccao: action.payload,
			};
		case SETSELECTEDPESSOA:
			return {
				...state,
				selectedPessoa: action.payload,
			};
		case RESETLEADS:
			return {
				...state,
				allLeads: action.payload,
			};
		case GETOPORTUNIDADE:
			return {
				...state,
				getOportunidade: action.payload,
			};
		case SETETAPAACAO:
			return {
				...state,
				getOportunidade: action.payload,
			};
		case OPENDIALOGOPORTUNIDADE:
			return {
				...state,
				dialogOportunidade: action.payload,
			};
		case GETASSINATURA:
			return {
				...state,
				dialogAssinatura: { show: true },
				allAssinaturas: action.payload,
			};
		case OPENDIALOGOASSINATURA:
			return {
				...state,
				dialogAssinatura: action.payload,
			};
		case GETFASES:
			return {
				...state,
				allFases: action.payload,
			};
		case GETLEADS:
			return {
				...state,
				allLeads: action.payload,
			};
		case GETMORELEADS:
			return {
				...state,
				allLeads: [...state.allLeads, ...action.payload],
			};
		case GETORCAMENTOBYETAPA:
			let { data, value } = action.payload;
			if (data.length <= 0) value.show = false;

			return {
				...state,
				getAllOrcamentos: data,
				dialogOportunidade: value,
			};
		default:
			return state;
	}
};
