import "./style.css";

import {
	Button,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
} from "@material-ui/core";
import { Check, Close, ExpandMore } from "@material-ui/icons";
import React from "react";

import {
	formatDateToLocaleString,
	formatNumber,
} from "../../../../../utils/funcoes";

export default (props) => {
	const { getAllOrcamentos } = props.store.oportunidade;
	const [expanded, setExpanded] = React.useState(getAllOrcamentos.length);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const parcelas = (value) => {
		const [planos] = value.etapa_orcamento.orcamento.planos;
		return planos.parcelas.sort((a, b) => {
			if (a.ordem < b.ordem) return -1;
			if (a.ordem > b.ordem) return 1;
			return 0;
		});
	};

	const handleClick = (tipo, value) => {
		if (tipo === "aprovar") {
			props.setShowAlertQuestion({
				show: true,
				title: "Atenção",
				text: "Deseja realmente APROVAR esta proposta de valores?",
				dados: {
					tipo: "proposta-valores",
					value,
				},
			});
		} else if (tipo === "reprovar") {
			props.setShowAlertQuestionReason({
				show: true,
				title: "Atenção",
				text: "Deseja realmente REPROVAR esta proposta de valores?",
				dados: {
					tipo: "proposta-valores",
					value,
				},
			});
		}
	};

	const renderSituacaoCorBg = (situacao) => {
		switch (situacao) {
			case "Reprovado":
				return "bg-red--light white";
			case "Aprovado":
				return "bg-green white";
			default:
				return "bg-orange white payment-conditions__status--wait";
		}
	};

	const renderSituacaoCor = (situacao) => {
		switch (situacao) {
			case "Reprovado":
				return "red";
			case "Aprovado":
				return "green";
			default:
				return "orange";
		}
	};

	return (
		<>
			<div className="payment-conditions bg-gray--light">
				{getAllOrcamentos.map((value, index) => (
					<ExpansionPanel
						key={index}
						className="payment-conditions__panel"
						expanded={expanded === index + 1}
						onChange={handleChange(index + 1)}
					>
						<ExpansionPanelSummary expandIcon={<ExpandMore />}>
							<div className="column">
								<span className="payment-conditions__options blue--light">
									{`# ${index + 1}ª Opção`}
								</span>
								<div className="payment-conditions__wrapper">
									<span
										className={`${renderSituacaoCorBg(
											value.etapa_orcamento.situacao
										)} payment-conditions__status`}
									>{`${value.etapa_orcamento.situacao}`}</span>

									<p
										className={`${renderSituacaoCor(
											value.etapa_orcamento.situacao
										)} payment-conditions__time`}
									>{`${formatDateToLocaleString(
										value.etapa_orcamento.finalizacao_dt
									)}`}</p>
								</div>
							</div>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails className="payment-conditions__details">
							{parcelas(value).map((parcela, index) => (
								<div key={index}>
									{parcela.tipo === "SINAL" ? (
										<div className="payment-conditions__container-value">
											<span className="payment-conditions__label">Sinal:</span>
											<span className="payment-conditions__value">{`R$ ${formatNumber(
												parcela.valor
											)}`}</span>
										</div>
									) : null}

									{parcela.tipo === "ENTRADA" || parcela.tipo === "PARCELA" ? (
										<div className="payment-conditions__container-value">
											<span className="payment-conditions__label">
												{parcela.tipo === "ENTRADA" ? "Entrada: " : "Parcela: "}
											</span>
											<span className="payment-conditions__value">{`${
												parcela.quantidade
											} x R$ ${formatNumber(parcela.valor)}`}</span>
										</div>
									) : null}
								</div>
							))}
							<div className="payment-conditions__container-value">
								<span className="payment-conditions__label">Valor Total: </span>
								<span className="payment-conditions__value">
									{` R$ ${formatNumber(
										value.etapa_orcamento.orcamento.planos[0].valor_total
									)}`}
								</span>
							</div>

							{value.etapa_orcamento.situacao === "Aguardando" ? (
								<div className="payment-conditions__btn-container">
									<Button
										className="btn__gray--medium"
										onClick={() =>
											handleClick("reprovar", value.etapa_orcamento)
										}
									>
										<Close className="alert-question__icon" />
										Reprovar
									</Button>
									<Button
										className="btn__blue--medium"
										onClick={() =>
											handleClick("aprovar", value.etapa_orcamento)
										}
									>
										<Check className="alert-question__icon" />
										Aprovar
									</Button>
								</div>
							) : null}

							{value.etapa_orcamento.situacao === "Reprovado" ? (
								<div className="payment-conditions__disapproved">
									<span className="red--light payment-conditions__disapproved--inner">
										<span className="payment-conditions__disapproved--inner red">
											Motivo:
										</span>
										{value.etapa_orcamento.situacao_motivo}
									</span>
								</div>
							) : null}
						</ExpansionPanelDetails>
					</ExpansionPanel>
				))}
			</div>
		</>
	);
};
