import {
	Button,
	CardActions,
	InputBase,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	withStyles,
} from "@material-ui/core";
import {
	Add,
	HelpOutline,
	KeyboardArrowDown,
	Reorder,
	Search,
} from "@material-ui/icons";
import DraftsIcon from "@material-ui/icons/Drafts";
import GridOnIcon from "@material-ui/icons/GridOn";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Loader from "../../components/Loader";
import { getImovelSearch } from "../../redux/QueryProperty/QueryPropertyAction";
import AdvancedFilters from "./AdvancedFilters";
import { ImovelGrid, ImovelList } from "./FormatViewImovel";
import style from "./style.module.css";

function ConsultaImoveis({ typeView, imovelSearch, store, dispatch }) {
	useEffect(() => {
		dispatch(getImovelSearch());
		// eslint-disable-next-line
	}, []);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const StyledMenu = withStyles({
		paper: {
			border: "1px solid #d3d4d5",
		},
	})((props) => (
		<Menu
			elevation={0}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			{...props}
		/>
	));

	const StyledMenuItem = withStyles((theme) => ({
		root: {
			"&:focus": {
				backgroundColor: theme.palette.primary.main,
				"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
					color: theme.palette.common.white,
				},
			},
		},
	}))(MenuItem);

	const s = {
		store,
	};
	return (
		<React.Fragment>
			<Loader {...s} />
			<CardActions disableSpacing className="header-bottom">
				<div>
					<h2 className="header__title">Consulta de Imóveis</h2>

					<div className="header__search">
						<div className="header__icon">
							<Search />
						</div>
						<InputBase
							className="font-input-white"
							placeholder="Buscar…"
							//onChange={handleOnChange}
							inputProps={{ "aria-label": "search" }}
						/>
					</div>
				</div>

				<div className="header__buttons-container">
					<Button
						id="header__btn-help"
						//onClick={handleExpandClick}
						//aria-expanded={expanded}
						aria-label="show more"
					>
						<HelpOutline className="icon__help" />
					</Button>

					<div className="header__wrapper">
						<Button
							className="header__btn-plus"
							//onClick={props.handleClickAdd}
							aria-controls="customized-menu"
							aria-haspopup="true"
							onClick={handleClick}
						>
							<Add className="icon__plus" />
							Novo <KeyboardArrowDown />
						</Button>
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<Link
								className={style.StyledMenuLink}
								to="/manual-property-register"
							>
								<StyledMenuItem>
									<ListItemIcon>
										<GridOnIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Cadastro Manual" />
								</StyledMenuItem>
							</Link>

							<Link className={style.StyledMenuLink} to="/import-excel">
								<StyledMenuItem>
									<ListItemIcon>
										<DraftsIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Importação Excel" />
								</StyledMenuItem>
							</Link>
						</StyledMenu>
						<Button className="header__btn-options">
							<Reorder className="icon__options" />
							Opções
						</Button>
					</div>
				</div>
			</CardActions>
			<div className={style.containerElevation}>
				<AdvancedFilters />

				<div className={style.containerElevationPaddingLR}>
					{typeView === "list" && <ImovelList />}
					{typeView === "grid" && <ImovelGrid />}
				</div>
			</div>
		</React.Fragment>
	);
}

export default connect((store) => ({
	typeView: store.consultaImoveis.typeView,
	imovelSearch: store.consultaImoveis.imovelSearch,
	store: store,
}))(ConsultaImoveis);
