import React, { useState, useEffect } from "react";

import {
	Step,
	Grid,
	Stepper,
	StepLabel,
	StepButton,
	StepConnector,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	makeStyles,
	withStyles,
} from "@material-ui/core";

import {
	ExpandMore,
	PersonAdd,
	DoneAll,
	List,
	TurnedInNotOutlined,
	AssignmentOutlined,
} from "@material-ui/icons";

import AlertConfirm from "components/global/alert-confirm";
import AlertQuestion from "components/global/alert-question";
import AlertAlterTerm from "components/global/alert-alter-term";
import AlertQuestionReason from "components/global/alert-question-reason";

import PropTypes from "prop-types";
import clsx from "clsx";
// import Buyer from "./Buyer";
// import Broker from "./Broker";
import Resumo from "./Resume";
import Qualificacao from "./Qualification";
import Reserva from "./reserva";
import Proposta from "./proposta";

import "./style.css";
// import Finalizado from "./finalizado";

// import QualificacaoCadastro from "./qualificacao/cadastro";
// import ReservaCadastro from "./reserva/cadastro";
// import PropostaCadastro from "./proposta/cadastro";
// import FinalizadoCadastro from "./finalizado/cadastro";

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 48px)",
	},
	active: {
		"& $line": {
			backgroundColor: "#0548a3",
		},
	},
	completed: {
		"& $line": {
			backgroundColor: "#469c47",
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderRadius: 1,
	},
})(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#ccc",
		zIndex: 1,
		color: "#fff",
		width: 40,
		height: 40,
		[theme.breakpoints.down("xs")]: {
			width: 30,
			height: 30,
		},
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	},
	active: {
		backgroundColor: "#0548a3",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	},
	completed: {
		backgroundColor: "#469c47",
	},
	error: {
		backgroundColor: "#f50057",
	},
}));

function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed, error } = props;

	const icons = {
		1: <PersonAdd />,
		2: <List />,
		3: <TurnedInNotOutlined />,
		4: <AssignmentOutlined />,
		5: <DoneAll />,
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
				[classes.error]: error,
			})}
		>
			{icons[String(props.icon)]}
		</div>
	);
}

ColorlibStepIcon.propTypes = {
	active: PropTypes.bool,
	completed: PropTypes.bool,
	error: PropTypes.bool,
	icon: PropTypes.node,
};

export default (props) => {
	const { oportunidade, global } = props.store;
	const { allFases, getOportunidade } = oportunidade;

	const [activeStep, setActiveStep] = useState(0);
	const [steps, setSteps] = useState([]);
	// const [etapas, setEtapas] = useState({
	// 	register: "view",
	// 	qualificacao: "view",
	// 	reserva: "view",
	// 	proposta: "view",
	// 	finalizado: "view",
	// });
	const [expanded, setExpanded] = useState([
		"cliente",
		"corretor",
		"resumo",
		"qualificacao",
		"reserva",
		"proposta",
		"finalizacao",
	]);

	useEffect(() => {
		let positionStep = allFases.findIndex(
			(value) => value.fase_sigla === getOportunidade.fase_sigla
		);
		if (getOportunidade.ativo === "NÃO") positionStep = positionStep + 1;

		setSteps(allFases);
		setActiveStep(positionStep);
	}, [allFases, getOportunidade]);

	const handleChangeExpanded = (panel) => (event, newExpanded) => {
		if (newExpanded) {
			setExpanded([...expanded, panel]);
		} else {
			const array = expanded.filter((item) => item !== panel);
			setExpanded(array);
		}
	};

	const completedStep = (sigla) => {
		let achou = {};

		if (oportunidade.getOportunidade.fases) {
			achou = oportunidade.getOportunidade.fases.find(
				(value) =>
					value.fase_sigla === sigla &&
					value.finalizacao_dt &&
					value.situacao !== "Reprovado"
			);
		}

		return achou ? true : false;
	};

	const errorLabel = (sigla) => {
		let achou = {};

		if (oportunidade.getOportunidade.fases) {
			achou = oportunidade.getOportunidade.fases.find(
				(value) => value.fase_sigla === sigla && value.situacao === "Reprovado"
			);
		}

		return achou ? true : false;
	};

	// const handleEtapa = (name, value) => {
	// 	setEtapas({
	// 		...etapas,
	// 		[name]: value,
	// 	});
	// };

	// const handleSave = (name) => {
	// 	handleEtapa(name, "view");
	// };

	// const handleCancel = (name) => {
	// 	handleEtapa(name, "view");
	// };

	const handleConfirmAlterTerm = () => {
		const { dados, data_completa } = global.showAlertAlterTerm;
		if (dados.tipo === "reserva") {
			props.setEtapaAcao({
				acao: "Prazo",
				cod_opor_fase_etapa: dados.value.cod_opor_fase_etapa,
				vencimento_dt: data_completa,
			});
			props.setShowAlertAlterTerm({ show: false });
		}
	};

	const handleConfirmQuestionReason = () => {
		const { dados, reason } = global.showAlertQuestionReason;

		if (dados.tipo === "proposta-valores") {
			props.openDialogOportunidade({ show: false });
		}

		if (dados.tipo === "documentos") {
			props.editFaseDocumento({
				acao: "Reprovado",
				situacao_motivo: reason,
				cod_opor_fase_etapa_doc: dados.value.cod_opor_fase_etapa_doc,
			});
		} else {
			props.setEtapaAcao({
				acao: "Reprovado",
				cod_opor_fase_etapa: dados.value.cod_opor_fase_etapa,
				situacao_motivo: reason,
			});
		}
		props.setShowAlertQuestionReason({ show: false });
	};

	const handleConfirmQuestion = () => {
		const { dados } = global.showAlertQuestion;

		if (dados.tipo === "proposta-valores") {
			props.openDialogOportunidade({ show: false });
		}

		if (dados.tipo === "documentos") {
			props.editFaseDocumento({
				acao: "Aprovado",
				cod_opor_fase_etapa_doc: dados.value.cod_opor_fase_etapa_doc,
			});
		} else {
			props.setEtapaAcao({
				acao: "Aprovado",
				cod_opor_fase_etapa: dados.value.cod_opor_fase_etapa,
			});
		}
		props.setShowAlertQuestion({ show: false });
	};

	const findFaseBySigla = (sigla) => {
		let achou = false;

		if (oportunidade.getOportunidade.fases) {
			achou = oportunidade.getOportunidade.fases.find(
				(value) => value.fase_sigla === sigla
			);
		}

		return achou ? true : false;
	};

	// const finishFaseBySigla = (sigla) => {
	// 	let achou = false;

	// 	if (oportunidade.getOportunidade.fases) {
	// 		achou = oportunidade.getOportunidade.fases.find(
	// 			(value) => value.fase_sigla === sigla
	// 		);
	// 	}

	// 	return achou.finalizacao_dt ? true : false;
	// };

	return (
		<div className="container-elevation">
			<Grid container spacing={3} className="stepper">
				<Grid item xs={12}>
					<Stepper
						alternativeLabel
						activeStep={activeStep}
						connector={<ColorlibConnector fases={getOportunidade.fases} />}
					>
						{steps.map((value) => (
							<Step key={value.cod_fase}>
								<StepButton completed={completedStep(value.fase_sigla)}>
									<StepLabel
										error={errorLabel(value.fase_sigla)}
										StepIconComponent={ColorlibStepIcon}
									>
										{value.fase_descricao}
									</StepLabel>
								</StepButton>
							</Step>
						))}
					</Stepper>
				</Grid>

				<Grid item container direction="row" spacing={2} xs={12}>
					{/* <Grid item xs={12}>
						<ExpansionPanel
							expanded={expanded.includes("cliente")}
							onChange={handleChangeExpanded("cliente")}
						>
							<ExpansionPanelSummary
								className="blue--light font-secondary bold"
								expandIcon={<ExpandMore />}
							>
								Cliente
							</ExpansionPanelSummary>
							<ExpansionPanelDetails style={{ height: "80px" }}>
								<Buyer {...props} />
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</Grid>

					<Grid item xs={12}>
						<ExpansionPanel
							expanded={expanded.includes("corretor")}
							onChange={handleChangeExpanded("corretor")}
						>
							<ExpansionPanelSummary
								className="blue--light font-secondary bold"
								expandIcon={<ExpandMore />}
							>
								Corretor
							</ExpansionPanelSummary>
							<ExpansionPanelDetails style={{ height: "80px" }}>
								<Broker {...props} />
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</Grid> */}

					<Grid item xs={7}>
						{/* Resumo */}
						<ExpansionPanel
							expanded={expanded.includes("resumo")}
							onChange={handleChangeExpanded("resumo")}
						>
							<ExpansionPanelSummary
								className="blue--light font-secondary bold"
								expandIcon={<ExpandMore />}
							>
								{`Resumo do Negócio # ${getOportunidade.cod_opor}`}
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<Resumo {...props} />
							</ExpansionPanelDetails>
						</ExpansionPanel>

						<ExpansionPanel
							expanded={expanded.includes("qualificacao")}
							onChange={handleChangeExpanded("qualificacao")}
						>
							<ExpansionPanelSummary
								className="blue--light font-secondary bold"
								expandIcon={<ExpandMore />}
							>
								Qualificação
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className="qualification">
								<Qualificacao {...props} />
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</Grid>
					<Grid item container xs={5}>
						{findFaseBySigla("R") ? (
							<Grid item xs={12}>
								<ExpansionPanel
									expanded={expanded.includes("reserva")}
									onChange={handleChangeExpanded("reserva")}
								>
									<ExpansionPanelSummary
										className="blue--light font-secondary bold"
										expandIcon={<ExpandMore />}
									>
										Reserva
									</ExpansionPanelSummary>
									<ExpansionPanelDetails>
										<Reserva {...props} />
									</ExpansionPanelDetails>
								</ExpansionPanel>
							</Grid>
						) : null}

						{findFaseBySigla("P") ? (
							<Grid item xs={12}>
								<ExpansionPanel
									expanded={expanded.includes("proposta")}
									onChange={handleChangeExpanded("proposta")}
								>
									<ExpansionPanelSummary
										className="blue--light font-secondary bold"
										expandIcon={<ExpandMore />}
									>
										Proposta
									</ExpansionPanelSummary>
									<ExpansionPanelDetails>
										<Proposta {...props} />
									</ExpansionPanelDetails>
								</ExpansionPanel>
							</Grid>
						) : null}
					</Grid>
				</Grid>

				{/* <Grid item xs={12} sm={12} md={8}>
					<ExpansionPanel
						expanded={expanded.includes("resumo")}
						onChange={handleChangeExpanded("resumo")}
					>
						<ExpansionPanelSummary
							className="blue--light font-secondary bold"
							expandIcon={<ExpandMore />}
						>
							Resumo do Negócio
						</ExpansionPanelSummary>
						<ExpansionPanelDetails className="stepper__card-content">
							<Resumo {...props} />
						</ExpansionPanelDetails>
					</ExpansionPanel>
				</Grid> */}

				{/* <Grid item xs={12}>
					<ExpansionPanel
						expanded={expanded.includes("qualificacao")}
						onChange={handleChangeExpanded("qualificacao")}
					>
						<ExpansionPanelSummary
							className="blue--light font-secondary bold"
							expandIcon={<ExpandMore />}
						>
							Qualificação
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<Qualificacao {...props} />
						</ExpansionPanelDetails>
					</ExpansionPanel>
				</Grid> */}

				{/* {findFaseBySigla("R") ? (
					<Grid item xs={12} sm={6}>
						<ExpansionPanel
							expanded={expanded.includes("reserva")}
							onChange={handleChangeExpanded("reserva")}
						>
							<ExpansionPanelSummary
								className="blue--light font-secondary bold"
								expandIcon={<ExpandMore />}
							>
								Reserva
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<Reserva {...props} />
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</Grid>
				) : null} */}

				{/* {findFaseBySigla("P") ? (
					<Grid item xs={12} sm={6}>
						<ExpansionPanel
							expanded={expanded.includes("proposta")}
							onChange={handleChangeExpanded("proposta")}
						>
							<ExpansionPanelSummary
								className="blue--light font-secondary bold"
								expandIcon={<ExpandMore />}
							>
								Proposta
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<Proposta {...props} />
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</Grid>
				) : null} */}
			</Grid>

			<AlertConfirm {...props} />
			<AlertQuestion {...props} handleConfirm={handleConfirmQuestion} />
			<AlertAlterTerm {...props} handleConfirm={handleConfirmAlterTerm} />
			<AlertQuestionReason
				{...props}
				handleConfirm={handleConfirmQuestionReason}
			/>
		</div>
	);
};
