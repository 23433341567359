import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Erro from "../components/Erro";
import ToolBar from "../components/Toolbar";
import Contato from "../pages/Contact";
import Dashboard from "../pages/Dashboard";
import PainelGestorCorretor from "../pages/Dashboard/OpportunityPanel";
import EnterpriseEdit from "../pages/EnterpriseEdit";
import EnterpriseRegister from "../pages/EnterpriseRegister";
import EnterpriseSgi from "../pages/EnterpriseSgi";
import ExcelUpload from "../pages/ExcelUpload";
import GeneralConfig from "../pages/GeneralConfig";
import ManualPropertyRegister from "../pages/ManualPropertyRegister";
import Oportunidade from "../pages/OpportunityConsultation";
import CadastroOportunidade from "../pages/OpportunityRegister";
import Empreendimento from "../pages/PanelAllotment";
import Pessoa from "../pages/PersonConsultation";
import CadastroPessoa from "../pages/PersonRegister";
import QueryProperty from "../pages/QueryProperty";
import Report from "../pages/Report";
import EmpreendimentoMapa from "../pages/SalesMap";
import EmpreendimentoEspelho from "../pages/SalesMirror";
import Usuario from "../pages/UserConsultation";
import UsuarioCadastro from "../pages/UserRegister";

export default (props) => {
	return (
		<>
			<ToolBar />

			<Switch>
				<Route path="/" exact={true} component={Dashboard} />

				<Route path="/dashboard" exact={true} component={Dashboard} />

				<Route path="/empreendimento" exact={true} component={Empreendimento} />

				<Route
					path="/dashboard/:url"
					exact={true}
					component={PainelGestorCorretor}
				/>

				<Route
					path="/empreendimento/espelho/:id"
					exact
					component={EmpreendimentoEspelho}
				/>
				<Route
					path="/empreendimento/espelho/:id/:cod_opor"
					exact
					component={EmpreendimentoEspelho}
				/>

				<Route
					path="/empreendimento/mapa/:id"
					exact={true}
					component={EmpreendimentoMapa}
				/>

				<Route path="/contato" exact={true} component={Contato} />

				<Route path="/pessoa" exact={true} component={Pessoa} />

				<Route
					path="/pessoa/cadastro"
					exact={true}
					component={CadastroPessoa}
				/>

				<Route path="/oportunidade" exact={true} component={Oportunidade} />

				<Route
					path="/oportunidade/cadastro"
					exact={true}
					component={CadastroOportunidade}
				/>

				<Route
					path="/oportunidade/register/:id"
					exact={true}
					component={CadastroOportunidade}
				/>

				<Route path="/usuario" exact={true} component={Usuario} />
				<Route
					path="/usuario/register"
					exact={true}
					component={UsuarioCadastro}
				/>
				<Route
					path="/usuario/register/:id"
					exact={true}
					component={UsuarioCadastro}
				/>

				<Route path="/erro" exact={true} component={Erro} />
				<Route path="/report" exact={true} component={Report} />
				<Route
					path="/empreendimentosgi"
					exact={true}
					component={EnterpriseSgi}
				/>
				<Route
					path="/empreendimentosgi/cadastroempreendimento"
					exact={true}
					component={EnterpriseRegister}
				/>
				<Route
					path="/empreendimentosgi/editarempreendimento"
					exact={true}
					component={EnterpriseEdit}
				/>
				<Route path="/import-excel" component={ExcelUpload} />
				<Route
					path="/manual-property-register"
					component={ManualPropertyRegister}
				/>

				<Route path="/query-property" component={QueryProperty} />

				<Route path="/configuration" component={GeneralConfig} />
				<Route path="*">
					<Redirect to="/" />
				</Route>
			</Switch>
		</>
	);
};
