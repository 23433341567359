import "./style.css";

import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Collapse,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import { Apps, ExpandMore, Room } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { EmpreendimentoSlider } from "../../../routes/empreendimento/styles";
// import ChartPie from "../../../components/graphics/pie";
import { NavLink } from "react-router-dom";

import RadialChart from "../../../components/graphics/radial-chart";

export const useStyles = makeStyles((theme) => ({
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	expandText: {
		display: "flex",
		justifyContent: "flex-end",
	},
}));

function Panel({ graphic, data, cod_opor, store }) {
	const { empreendimento } = store;
	const classes = useStyles();

	const [situacoes, setSituacoes] = useState([]);

	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const logo = `data:image/jpeg;base64,${data.empreend_logo}`;

	useEffect(() => {
		const values = data.situacoes
			? data.situacoes
					.map((value) => {
						return {
							id: value.nome,
							label: value.nome,
							value: value.qtde,
							color: value.cor,
						};
					})
					.sort((a, b) => b.value - a.value)
			: [];

		setSituacoes(values);
	}, [data.situacoes]);

	return (
		<Card className="painel">
			<div>
				<CardHeader
					avatar={
						<div className="painel__avatar">
							<img className="painel__image" alt="logo" src={logo} />
						</div>
					}
					title={data.empreend_nome}
					subheader={data.empreend_tipo}
				/>

				<CardContent className="painel__container">
					<Typography
						className="painel__qtde-lotes gray--younect"
						variant="body2"
						component="p"
					>
						{data.lotes_disponiveis} lotes disponíveis
					</Typography>

					{situacoes.length > 0 && graphic ? (
						<RadialChart data={situacoes} />
					) : null}

					{/* <Typography component="div">
						{data.situacoes.map((value) => (
							<Typography
								variant="body2"
								key={value.sigla}
								className="painel__situation"
								component="div"
							>
								<Typography
									className="painel__label-graphic"
									variant="body2"
									color="textSecondary"
								>
									{value.nome}
								</Typography>

								<div className="painel__container-graphic">
									<p
										className="painel__graphic"
										style={{ color: `${value.cor_fonte}` }}
									>
										{value.qtde}
									</p>
									<EmpreendimentoSlider
										valueLabelDisplay="off"
										value={value.qtde}
										style={{ color: `${value.cor}` }}
										max={parseInt(data.lotes_totais)}
									/>
								</div>
							</Typography>
						))}
					</Typography> */}
				</CardContent>

				<CardActions disableSpacing>
					<IconButton
						component={NavLink}
						to={`/empreendimento/mapa/${data.cod_empreendimento}${
							cod_opor ? "/" + cod_opor : ""
						}`}
					>
						<Room className="icon__location" />
					</IconButton>

					<IconButton
						component={NavLink}
						to={`/empreendimento/espelho/${data.cod_empreendimento}${
							cod_opor ? "/" + cod_opor : ""
						}`}
					>
						<Apps className="icon__apps" />
					</IconButton>

					<IconButton
						className={clsx(classes.expand, {
							[classes.expandOpen]: empreendimento.expanded,
						})}
						onClick={handleExpandClick}
						aria-expanded={empreendimento.expanded}
						aria-label="show more"
					>
						<ExpandMore />
					</IconButton>
				</CardActions>
			</div>

			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent>
					<Typography paragraph className="painel__expand--text">
						<Room /> {data.municipio_desc}
					</Typography>
				</CardContent>
			</Collapse>
		</Card>
	);
}

export default Panel = connect((store) => ({ store: store }))(Panel);
