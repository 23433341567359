import "./style.css";

import { Button, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useContext, useState } from "react";

import YouNectLogo from "../../assets/logos/logo.png";
import AuthContext from "../../contexts/auth";
import { emailIsValid } from "../../utils/funcoes";
import DialogEmpresa from "./CompanySelect";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default (props) => {
	const { logIn } = useContext(AuthContext);
	const [form, setForm] = useState({
		email: "",
		senha: "",
	});
	const [formValidade, setFormValidate] = useState({
		email: "",
		senha: "",
	});
	const [open, setOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [empresas, setEmpresas] = useState([]);
	const [messageError, setMessageError] = useState("");
	const [empresaSelected, setEmpresaSelected] = React.useState([]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		let error = await validate([
			{ name: "email", value: form.email },
			{ name: "senha", value: form.senha },
		]);

		if (error) return;

		const result = await logIn(form);

		if (Array.isArray(result)) {
			setEmpresas(result);
			setOpenDialog(true);
		} else {
			if (result !== "success") {
				setMessageError(result);
				setOpen(true);
			}
		}
	};

	const handleLoginEmpresa = async () => {
		if (empresaSelected.length <= 0) return;

		const empresa = empresaSelected.shift();
		const result = await logIn({ ...form, ...empresa });

		if (result) {
			setMessageError(result);
			setOpen(true);
		}
	};

	const handleOnChange = (e) => {
		const { name, value } = e.target;
		validate([{ name, value }]);
		setForm({ ...form, [name]: value });
	};

	const handleOnBlur = (e) => {
		const { name, value } = e.target;
		validate([{ name, value }]);
	};

	const validate = async (form) => {
		let error = false;
		let formErros = formValidade;

		form.forEach((data) => {
			switch (data.name) {
				case "email": {
					let email = "";

					if (!data.value) {
						email = "Preencha um e-mail válido";
						formErros = { ...formErros, email };
						error = email ? true : false;
					}

					if (data.value.length >= 1) {
						email = emailIsValid(data.value) ? "" : "E-Mail Inválido";
						formErros = { ...formErros, email };
						error = email ? true : false;
					}

					break;
				}
				case "senha": {
					if (!data.value) {
						formErros = {
							...formErros,
							senha: "Preencha a senha corretamente",
						};
						error = true;
					}

					if (data.value.length >= 1) {
						formErros = { ...formErros, senha: "" };
						error = false;
					}

					break;
				}
				default:
					error = false;
			}
		});

		setFormValidate(formErros);
		return error;
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleSelectEmpresa = (value) => {
		setEmpresaSelected(
			empresaSelected.includes(value)
				? empresaSelected.filter((c) => c !== value)
				: [value]
		);
	};

	return (
		<>
			<div className="login">
				<div className="login__main">
					<div className="login__logo-container">
						<img src={YouNectLogo} alt="logo" className="login__logo" />
					</div>

					<h1 className="login__title">Bem vindo ao YouNect - Imob!</h1>
					<p className="login__paragraph">
						Acima de tudo, é fundamental ressaltar que o desenvolvimento
						contínuo de distintas formas de atuação é uma das consequências dos
						conhecimentos estratégicos para atingir a excelência.
					</p>
				</div>

				<form onSubmit={handleSubmit} className="login__form">
					<h3 className="login__title-form gray--younect">
						FAÇA LOGIN NA SUA CONTA
					</h3>
					<TextField
						className="input__primary login__input"
						required
						label="E-mail"
						name="email"
						value={form.nome}
						onChange={handleOnChange}
						onBlur={handleOnBlur}
						error={formValidade.email ? true : false}
						helperText={formValidade.email}
					/>

					<TextField
						className="input__primary login__input"
						type="password"
						required
						label="Senha"
						name="senha"
						value={form.senha}
						onChange={handleOnChange}
						onBlur={handleOnBlur}
						error={formValidade.senha ? true : false}
						helperText={formValidade.senha}
					/>

					<Button type="submit" className="login__btn">
						Login
					</Button>

					<p className="login__paragraph-form">
						Não possui uma conta?
						<a href="teste" className="login__create-acount">
							Criar uma conta
						</a>
					</p>
				</form>
			</div>

			<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error">
					{messageError}
				</Alert>
			</Snackbar>

			<DialogEmpresa
				open={openDialog}
				empresas={empresas}
				handleClose={handleCloseDialog}
				handleSelected={handleSelectEmpresa}
				empresaSelected={empresaSelected}
				handleLoginEmpresa={handleLoginEmpresa}
			/>
		</>
	);
};
