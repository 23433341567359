import {
	Button,
	CardActions,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextareaAutosize,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	CheckBoxOutlined,
	CropOutlined,
	HelpOutline,
	MonetizationOnOutlined,
	QueueOutlined,
	StorageOutlined,
} from "@material-ui/icons";
import StarsOutlinedIcon from "@material-ui/icons/StarsOutlined";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

import ModalResponseApi from "../../components/global/ModalResponseApi";
import { openModalResponseApiSaga } from "../../redux/ModalResponseApi/ModalResponseApiAction";
import { getAllSelects } from "../../redux/Selects/SelectsActions";
import { API } from "../../utils/API";
import style from "./style.module.css";

function CepMask(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			isNumericString
			format="#####-###"
		/>
	);
}

function ManualPropertyregister({ selects, dispatch }) {
	const { handleSubmit, register, errors, control } = useForm();

	useEffect(() => {
		dispatch(getAllSelects()); // eslint-disable-next-line
	}, []);

	const [state, setState] = useState({
		cod_estado: "",
		cod_imoveltipo: "",
		cod_municipio: "",
		cod_empreendimento: null,
		cod_situacao: "",
	});

	function onChange({ target }) {
		setState({ ...state, [target.name]: target.value });
	}
	function onSubmit(data) {
		data.utilizacao = data.utilizacao.toUpperCase();

		API.post("/imovel", JSON.stringify(data))
			.then((response) => {
				if (response.status === 200) {
					dispatch(
						openModalResponseApiSaga({
							tpye: "sucess",
							title: response.data.result.message,
							body: response.data.result.result,
						})
					);
				} else {
					dispatch(
						openModalResponseApiSaga({
							type: "error",
							title: response.data.result,
							body: response.data.errors,
						})
					);
				}
			})
			.catch((error) => console.log("error api", error));
	}
	return (
		<React.Fragment>
			<ModalResponseApi />

			<CardActions disableSpacing className="header-bottom">
				<div>
					<h2 className="header__title">Contatos</h2>
				</div>

				<div className="header__buttons-container">
					<Button
						id="header__btn-help"
						//onClick={handleExpandClick}
						//aria-expanded={expanded}
						aria-label="show more"
					>
						<HelpOutline className="icon__help" />
					</Button>
				</div>
			</CardActions>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={style.containerElevation}>
					<div className={style.CadastroManualDiv}>
						<div className={style.CadastroManualHeader}>
							<span className={style.CadastroManualHeaderIcon}>
								<StarsOutlinedIcon />
							</span>
							<Typography className={style.CadastroManualTitle}>
								Geral
							</Typography>
						</div>

						<div className={style.CadastroManualDivInputBlock}>
							<FormControl
								error={errors.cod_empreendimento !== undefined ? true : false}
							>
								<Controller
									name="cod_empreendimento"
									rules={{ required: true }}
									defaultValue=""
									control={control}
									as={
										<Select variant="filled" onChange={onChange}>
											{selects.empreendimento.map((emp, index) => (
												<MenuItem value={emp.cod_empreendimento} key={index}>
													{emp.empreend_nome}
												</MenuItem>
											))}
										</Select>
									}
								/>
								<InputLabel id="demo-simple-select-label">
									Empreendimento
								</InputLabel>
							</FormControl>
						</div>

						<div className={style.CadastroManualDivInputBlock}>
							<FormControl
								error={errors.cod_imoveltipo !== undefined ? true : false}
							>
								<InputLabel id="demo-simple-select-label">
									Tipo do Imóvel
								</InputLabel>

								<Controller
									name="cod_imoveltipo"
									rules={{ required: true }}
									defaultValue=""
									control={control}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									as={
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={state.cod_imoveltipo}
											variant="filled"
											onChange={onChange}
										>
											{selects.tipo.map((emp, index) => (
												<MenuItem value={emp.cod_imoveltipo} key={index}>
													{emp.nome}
												</MenuItem>
											))}
										</Select>
									}
								/>
							</FormControl>
							<TextField
								error={errors.quadra !== undefined ? true : false}
								//onChange={onChange}
								inputRef={register({ required: true })}
								label="Quadra"
								variant="filled"
								name="quadra"
							/>
							<TextField
								error={errors.lote !== undefined ? true : false}
								//onChange={onChange}
								label="Lote"
								variant="filled"
								name="lote"
								inputRef={register({ required: true })}
							/>
							<FormControl
								error={errors.cod_situacao !== undefined ? true : false}
							>
								<InputLabel id="demo-simple-select-label">Situação</InputLabel>

								<Controller
									name="cod_situacao"
									rules={{ required: true }}
									defaultValue=""
									control={control}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									as={
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={state.cod_situacao}
											variant="filled"
											onChange={onChange}
											name="cod_situacao"
										>
											{selects.situacao.map((emp, index) => (
												<MenuItem value={emp.cod_situacao} key={index}>
													{emp.situacao_nome}
												</MenuItem>
											))}
										</Select>
									}
								/>
							</FormControl>
						</div>
					</div>

					<div className={style.CadastroManualDiv}>
						<div className={style.CadastroManualHeader}>
							<span className={style.CadastroManualHeaderIcon}>
								<CheckBoxOutlined />
							</span>
							<Typography className={style.CadastroManualTitle}>
								Áreas e metragens
							</Typography>
						</div>

						<div className={style.CadastroManualDivInputBlock}>
							<TextField
								error={errors.area !== undefined ? true : false}
								//onChange={onChange}
								label="Área total"
								variant="filled"
								name="area"
								inputRef={register({ required: true })}
								type="number"
								min={0}
								// InputProps={{
								// 	inputComponent: NumberFormatArea,
								// }}
							/>

							<TextField
								error={errors.area_construida !== undefined ? true : false}
								//onChange={onChange}
								label="Área Construida"
								variant="filled"
								name="area_construida"
								inputRef={register({ required: true })}
								type="number"
								min={0}
							/>
							<TextField
								error={errors.area_metro !== undefined ? true : false}
								//onChange={onChange}
								label="Área Metro"
								variant="filled"
								name="area_metro"
								inputRef={register({ required: true })}
								type="number"
								min={0}
							/>

							<TextField
								error={errors.frente_quant !== undefined ? true : false}
								//onChange={onChange}
								label="Quantidade de frente"
								variant="filled"
								name="frente_quant"
								inputRef={register({ required: true })}
								type="number"
								min={0}
							/>
							<TextField
								error={errors.lado !== undefined ? true : false}
								//onChange={onChange}
								label="Lado metro linear"
								variant="filled"
								name="lado"
								inputRef={register({ required: true })}
								type="number"
								min={0}
							/>
							<TextField
								error={errors.fundo !== undefined ? true : false}
								//onChange={onChange}
								label="Fundo metro linear"
								variant="filled"
								name="fundo"
								inputRef={register({ required: true })}
								type="number"
								min={0}
							/>
							<TextField
								error={errors.frente_testada !== undefined ? true : false}
								//onChange={onChange}
								label="Testada metro linear"
								variant="filled"
								name="frente_testada"
								inputRef={register({ required: true })}
								type="number"
								min={0}
							/>
						</div>
					</div>

					<div className={style.CadastroManualDiv}>
						<div className={style.CadastroManualHeader}>
							<span className={style.CadastroManualHeaderIcon}>
								<QueueOutlined />
							</span>
							<Typography className={style.CadastroManualTitle}>
								Valores
							</Typography>
						</div>

						<div className={style.CadastroManualDivInputBlock}>
							<TextField
								//onChange={onChange}
								error={errors.preco_metro !== undefined ? true : false}
								label="Preço por m"
								variant="filled"
								name="preco_metro"
								inputRef={register({ required: true })}
								type="number"
								min={0}
							/>
							<TextField
								error={errors.preco_total !== undefined ? true : false}
								label="Preço total"
								variant="filled"
								name="preco_total"
								inputRef={register({ required: true })}
								type="number"
								min={0}
							/>
						</div>
					</div>

					<div className={style.CadastroManualDiv}>
						<div className={style.CadastroManualHeader}>
							<span className={style.CadastroManualHeaderIcon}>
								<StorageOutlined />
							</span>
							<Typography className={style.CadastroManualTitle}>
								Situação do imovel
							</Typography>
						</div>

						<div className={style.CadastroManualDivInputBlock}>
							<FormControl
								error={errors.localizacao !== undefined ? true : false}
								component="fieldset"
							>
								<FormLabel component="legend">Localização</FormLabel>
								<Controller
									name="localizacao"
									control={control}
									defaultValue="Urbano"
									rules={{ required: true }}
									as={
										<RadioGroup
											row
											aria-label="position"
											defaultValue="Urbano"
											onChange={onChange}
											//value={state.permite_desmenbramento}
											name="localizacao"
										>
											<FormControlLabel
												value="Urbano"
												control={<Radio color="primary" />}
												label="Urbano"
											/>
											<FormControlLabel
												value="Rural"
												control={<Radio color="primary" />}
												label="Rural"
											/>
										</RadioGroup>
									}
								/>
							</FormControl>

							<FormControl
								error={errors.posicao !== undefined ? true : false}
								component="fieldset"
							>
								<FormLabel component="legend">Posição da quadra</FormLabel>
								<Controller
									defaultValue="MEIO DE QUADRA"
									name="posicao"
									control={control}
									rules={{ required: true }}
									as={
										<RadioGroup
											row
											onChange={onChange}
											//value={state.permite_desmenbramento}
										>
											<FormControlLabel
												value="MEIO DE QUADRA"
												control={<Radio color="primary" />}
												label="Meio de Quadra"
											/>
											<FormControlLabel
												value="ESQUINA"
												control={<Radio color="primary" />}
												label="Esquina"
											/>
										</RadioGroup>
									}
								/>
							</FormControl>

							<FormControl
								error={
									errors.permite_desmenbramento !== undefined ? true : false
								}
								component="fieldset"
							>
								<FormLabel component="legend">Permite Desmembramento</FormLabel>
								<Controller
									defaultValue="Sim"
									name="permite_desmenbramento"
									control={control}
									rules={{ required: true }}
									as={
										<RadioGroup row onChange={onChange}>
											<FormControlLabel
												value="Sim"
												control={<Radio color="primary" />}
												label="Sim"
											/>
											<FormControlLabel
												value="Não"
												control={<Radio color="primary" />}
												label="Não"
											/>
										</RadioGroup>
									}
								/>
							</FormControl>

							<FormControl
								error={errors.cod_estado !== undefined ? true : false}
							>
								<InputLabel id="demo-simple-select-label">
									Estado do imovel
								</InputLabel>

								<Controller
									name="cod_estado"
									rules={{ required: true }}
									defaultValue=""
									control={control}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									as={
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={state.cod_estado}
											variant="filled"
											onChange={onChange}
										>
											{selects.estado.map((emp, index) => (
												<MenuItem value={emp.cod_imovelestado} key={index}>
													{emp.nome}
												</MenuItem>
											))}
										</Select>
									}
								/>
							</FormControl>

							<TextField
								//onChange={onChange}
								error={errors.utilizacao !== undefined ? true : false}
								label="Utilização"
								variant="filled"
								name="utilizacao"
								inputRef={register({ required: true })}
							/>
						</div>
					</div>

					<div className={style.CadastroManualDiv}>
						<div className={style.CadastroManualHeader}>
							<span className={style.CadastroManualHeaderIcon}>
								<MonetizationOnOutlined />
							</span>
							<Typography className={style.CadastroManualTitle}>
								Dados complementares
							</Typography>
						</div>

						<div className={style.CadastroManualDivInputBlock}>
							<TextField
								//onChange={onChange}
								error={errors.proprietario !== undefined ? true : false}
								label="Proprietario"
								variant="filled"
								name="proprietario"
								inputRef={register({ required: true })}
							/>
							<TextField
								//onChange={onChange}
								error={errors.matricula !== undefined ? true : false}
								label="Numero matricula"
								variant="filled"
								name="matricula"
								inputRef={register({ required: true })}
							/>

							<TextField
								//onChange={onChange}
								error={errors.logradouro !== undefined ? true : false}
								label="Logradouro"
								variant="filled"
								name="logradouro"
								inputRef={register({ required: true })}
							/>
							<TextField
								//onChange={onChange}
								error={errors.bairro !== undefined ? true : false}
								label="Bairro"
								variant="filled"
								name="bairro"
								inputRef={register({ required: true })}
							/>
							<TextField
								//onChange={onChange}
								error={errors.cep !== undefined ? true : false}
								label="CEP"
								variant="filled"
								name="cep"
								inputRef={register({ required: true })}
								InputProps={{
									inputComponent: CepMask,
								}}
							/>
							{/* <TextField
							onChange={onChange}
							error={errors.precoTotal}
							label="Numero prefeitura"
							variant="filled"
							name="precoTotal"
						/>
						<TextField
							onChange={onChange}
							error={errors.precoTotal}
							label="Numero IPTU"
							variant="filled"
							name="precoTotal"
						/>
						<TextField
							onChange={onChange}
							error={errors.precoTotal}
							label="Plano de conta"
							variant="filled"
							name="precoTotal"
						/>
						<TextField
							onChange={onChange}
							error={errors.precoTotal}
							label="Codigo Contabil"
							variant="filled"
							name="precoTotal"
						/> */}
						</div>
					</div>

					<div className={style.CadastroManualDiv}>
						<div className={style.CadastroManualHeader}>
							<span className={style.CadastroManualHeaderIcon}>
								<CropOutlined />
							</span>
							<Typography className={style.CadastroManualTitle}>
								Confrontações
							</Typography>
						</div>

						<div className={style.CadastroManualDivInputBlockFlex}>
							<Controller
								defaultValue=""
								name="confrontacoes"
								control={control}
								rules={{ required: false }}
								as={
									<TextareaAutosize
										onChange={onChange}
										className={style.CadastroManualDivTextArea}
									/>
								}
							/>
						</div>
					</div>

					<div className={clsx(style.CadastroManualDiv, style.buttonsSave)}>
						<Button
							className={style.buttonsSaveBtn}
							variant="outlined"
							color="primary"
						>
							CANCELAR
						</Button>
						<Button
							className={style.buttonsSaveBtn}
							variant="contained"
							color="primary"
							type="submit"
						>
							SALVAR
						</Button>
					</div>
				</div>
			</form>
		</React.Fragment>
	);
}

export default connect((state) => ({ selects: state.selects }))(
	ManualPropertyregister
);
