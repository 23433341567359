const arrayEstadoCivil = [
	{ name: "Solteiro (a)", value: "Solteiro (a)" },
	{ name: "Casado (a)", value: "Casado (a)" },
	{ name: "Divorciado (a)", value: "Divorciado (a)" },
	{ name: "Viúvo (a)", value: "Viúvo (a)" },
	{ name: "Separado judicialmente", value: "Separado judicialmente" },
];

const arraySexo = [
	{ name: "Masculino", value: "M" },
	{ name: "Feminino", value: "F" },
];

export { arrayEstadoCivil, arraySexo };
