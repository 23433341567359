import { hideLoading, showLoading } from "components/Loader";
import { call, put, takeEvery } from "redux-saga/effects";
import { API_REL } from "utils/API";

import { history } from "../../App";
import { GETRELATORIO, GETRELATORIOSAGA } from "./ReportType";

function* getRelatorio({ data }) {
	try {
		yield put(showLoading());

		const response = yield call(
			API_REL.post,
			"relatorio",
			JSON.stringify(data)
		);

		const file = new Blob([response.data], { type: "application/pdf" });
		const fileURL = URL.createObjectURL(file);

		put({ type: GETRELATORIO, payload: fileURL });
		put(hideLoading());

		window.open(fileURL);
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

export default function* AllotmentSaga() {
	yield takeEvery(GETRELATORIOSAGA, getRelatorio);
}
