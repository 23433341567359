import Collapse from "@material-ui/core/Collapse";
import React from "react";

export default (props) => {
	return (
		<div>
			<Collapse
				in={props.expanded}
				timeout="auto"
				unmountOnExit
				className="intro"
				style={{ marginBottom: "30px" }}
			>
				<div>
					<p>Method:</p>
					<p>
						O incentivo ao avanço tecnológico, assim como o aumento do diálogo
						entre os diferentes setores produtivos ainda não demonstrou
						convincentemente que vai participar na mudança das novas
						proposições.
					</p>
				</div>
			</Collapse>
		</div>
	);
};
