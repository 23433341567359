import React from "react";

import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	IconButton,
	Avatar,
} from "@material-ui/core";

import { StarBorder, Edit, Delete } from "@material-ui/icons";

import "./style.css";

export default (props) => {
	const { contato } = props.store;

	return (
		<>
			<div className="container-elevation">
				<TableContainer component={Paper} className="table-container">
					<Table className="table-container__table">
						<TableHead className="table-container__table--header">
							<TableRow className="hide-xs">
								<TableCell className="hide-sm" />
								<TableCell />
								<TableCell className="table-container__table--title">
									Nome
								</TableCell>
								<TableCell className="table-container__table--title">
									Email
								</TableCell>
								<TableCell className="table-container__table--title">
									Celular
								</TableCell>
								<TableCell className="hide-sm" />
								<TableCell className="hide-sm" />
								<TableCell />
							</TableRow>
						</TableHead>

						<TableBody>
							{contato.allContatos.map((value, index) => (
								<TableRow hover key={index}>
									<TableCell className="hide-sm"></TableCell>

									<TableCell
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										<Avatar alt="avatar" src={value.contato_foto} />
									</TableCell>

									<TableCell
										component="th"
										className="table-container__table--show contact__data"
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										<span>{value.contato_nome}</span>
										<span className="table-container__info">
											{value.contato_email}
										</span>
										<span className="table-container__info">
											{value.contato_celular}
										</span>
									</TableCell>

									<TableCell
										className="hide-xs"
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										{value.contato_email}
									</TableCell>

									<TableCell
										className="hide-xs"
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										{value.contato_celular}
									</TableCell>

									<TableCell className="hide-sm">
										<StarBorder
											style={{
												color:
													value.contato_favorito === "S"
														? "#ffb300"
														: "#bdbdbd",
											}}
										/>
									</TableCell>

									<TableCell
										className="hide-sm"
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										<IconButton className="btn__circular">
											<Edit className="icon__edit" />
										</IconButton>
									</TableCell>

									<TableCell className="contact__delete">
										<IconButton
											className="btn__circular"
											onClick={() =>
												props.handleClickConfirmDelete &&
												props.handleClickConfirmDelete(value)
											}
										>
											<Delete className="icon__delete" />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
};
