import { all, call, put, takeEvery } from "redux-saga/effects";

import { API } from "../../utils/API";
import {
	GETALLFILTERS,
	GETALLFILTERSSAGA,
	GETALLSELECTS,
	GETALLSELECTSSAGA,
	SETFILTERS,
	SETFILTERSSAGA,
} from "./SelectsType";

function* getAllSelectsSaga(action) {
	try {
		const [empreendimento, tipo, estado, situacao] = yield all([
			call(API.get, `empreendimento`, {
				params: {
					fields: "cod_empreendimento,empreend_nome",
				},
			}),
			call(API.get, `imovel/tipo`),
			call(API.get, `imovel/estado`),
			call(API.get, `situacoes`, {
				params: {
					situacao_tipo: "Mapa de Venda",
				},
			}),
		]);

		yield put({
			type: GETALLSELECTS,
			payload: {
				empreendimento: empreendimento.data.result,
				tipo: tipo.data.result,
				estado: estado.data.result,
				situacao: situacao.data.result,
			},
		});
	} catch (error) {
		console.log("error", error);
	}
}

function* setFiltersSaga(action) {
	yield put({ type: SETFILTERS, payload: action.payload });
}
function* getAllFiltersSaga() {
	yield put({ type: GETALLFILTERS });
}

export default function* EmpreendimentoSaga() {
	yield takeEvery(GETALLSELECTSSAGA, getAllSelectsSaga);
	yield takeEvery(SETFILTERSSAGA, setFiltersSaga);
	yield takeEvery(GETALLFILTERSSAGA, getAllFiltersSaga);
}
