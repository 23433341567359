import "./style.css";

import { Button, MenuItem, TextField } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import {
	handleChangeCalculo,
	handleChangePlanoPagamento,
	setListCalculo,
	validateFormCalculo,
} from "redux/Immobile/ImmobileAction";

import { formatNumber } from "../../../../utils/funcoes";

export default (props) => {
	const { empreendimento } = props.store;
	const { imovel } = props.store;
	const { cod_opor } = props.match.params;

	const handleSaveForm = () => {
		let error = false;
		let formErrors = Object.entries(imovel.currentCalculo).map(
			([key, value]) => {
				return {
					name: key,
					value,
				};
			}
		);

		props.dispatch(validateFormCalculo(formErrors));

		Object.values(imovel.validateForm).forEach((value) => {
			if (value !== "") error = true;
		});

		if (error) return;

		props.dispatch(setListCalculo(imovel.currentCalculo));

		if (cod_opor) props.setStep(props.nextStepNegotiation);
		else props.setStep(props.nextStep);
	};

	const handleOnChange = (name) => (e) => {
		const { value } = e.target;
		props.dispatch(validateFormCalculo([{ name, value }]));
		props.dispatch(handleChangeCalculo({ name, value }));
	};

	const handleOnBlur = (e) => {
		const { name, value } = e.target;
		props.dispatch(validateFormCalculo([{ name, value }]));
	};

	const handleOnChangePlanoPagamento = (e) => {
		props.dispatch(
			handleChangePlanoPagamento({
				name: e.target.name,
				value: e.target.value,
				list: empreendimento.allPlanoPagamentos,
				amount: imovel.getImovel.preco_total,
			})
		);
	};

	return (
		<>
			<div className="calculo-container">
				<TextField
					className="input__primary calculo__input-margin calculo__input-margin-up"
					autoComplete="off"
					label="Plano de Pagamento"
					name="cod_planovenda"
					value={imovel.currentCalculo.plano.cod_planovenda || ""}
					onChange={handleOnChangePlanoPagamento}
					select
				>
					{empreendimento.allPlanoPagamentos &&
						empreendimento.allPlanoPagamentos.map((data, index) => (
							<MenuItem key={index} value={data.cod_planovenda}>
								{data.planovenda_nome}
							</MenuItem>
						))}
				</TextField>

				{imovel.currentCalculo.plano.cod_planovenda && (
					<>
						<TextField
							className="input__primary calculo__input-margin calculo__input-margin--bottom calculo__input-margin-up"
							autoComplete="off"
							label={`Entrada (Mínimo de R$ ${formatNumber(
								imovel.currentPlanoPagto.calc_entrada_minima
							)})`}
							margin="normal"
							name="entradaValor"
							InputProps={{
								inputComponent: NumberFormatCustom,
								value: imovel.currentCalculo.entradaValor || 0,
								onChange: handleOnChange("entradaValor"),
							}}
							inputProps={{
								style: { textAlign: "right", paddingRight: "6px" },
							}}
							onBlur={handleOnBlur}
							error={imovel.validateForm.entradaValor ? true : false}
							helperText={imovel.validateForm.entradaValor}
						/>

						{imovel.currentCalculo.showQtdeEntrada && (
							<TextField
								className="input__primary calculo__input-margin calculo__align-text calculo__input-margin--bottom"
								autoComplete="off"
								label="Qtde da Entrada"
								name="entradaQtde"
								value={imovel.currentCalculo.entradaQtde || ""}
								onChange={handleOnChange("entradaQtde")}
								select
							>
								{imovel.currentCalculo.allQtdeEntrada.map((data, index) => (
									<MenuItem key={index} value={data.qtde}>
										{data.qtdeDescricao}
									</MenuItem>
								))}
							</TextField>
						)}

						{imovel.currentCalculo.showSinalEntrada && (
							<TextField
								className="input__primary calculo__input-margin calculo__input-margin--bottom calculo__input-margin--top"
								autoComplete="off"
								label={`Sinal da Entrada (Mínimo de R$ ${formatNumber(
									imovel.currentPlanoPagto.calc_sinal_minimo
								)})`}
								margin="normal"
								name="entradaSinal"
								InputProps={{
									inputComponent: NumberFormatCustom,
									value: imovel.currentCalculo.entradaSinal || 0,
									onChange: handleOnChange("entradaSinal"),
								}}
								inputProps={{
									style: { textAlign: "right", paddingRight: "6px" },
								}}
								onBlur={handleOnBlur}
								error={imovel.validateForm.entradaSinal ? true : false}
								helperText={imovel.validateForm.entradaSinal}
							/>
						)}

						{imovel.currentCalculo.showQtdeEntrada && (
							<TextField
								className="input__primary calculo__input-margin"
								autoComplete="off"
								label="Qtde e Valor das Entradas"
								name="entradaParceladaDescricao"
								value={imovel.currentCalculo.entradaParceladaDescricao || ""}
								onChange={handleOnChange("entradaParceladaDescricao")}
								inputProps={{
									style: { textAlign: "right", paddingRight: "6px" },
									readOnly: true,
								}}
							/>
						)}

						<div className="calculo__value-container blue">
							<span> {"Total da Entrada: "}</span>
							<span>{formatNumber(imovel.currentCalculo.totalEntrada)}</span>
						</div>

						{imovel.currentCalculo.saldoRestante > 0 && (
							<div className="calculo__value-container--negative red">
								<span> {"Saldo Restante: "}</span>
								<span>{formatNumber(imovel.currentCalculo.saldoRestante)}</span>
							</div>
						)}
						{imovel.currentCalculo.showQtdeParcela && (
							<TextField
								className="input__primary calculo__input-margin calculo__last-input"
								autoComplete="off"
								label="Qtde de Parcelas"
								name="parcelaQtde"
								value={imovel.currentCalculo.parcelaQtde || ""}
								onChange={handleOnChange("parcelaQtde")}
								select
							>
								{imovel.currentCalculo.allQtdeParcela.map((data, index) => (
									<MenuItem key={index} value={data.qtde}>
										{data.qtdeDescricao}
									</MenuItem>
								))}
							</TextField>
						)}

						{imovel.currentCalculo.totalValor > 0 && (
							<div className="calculo__value-container blue">
								<span>{"Valor total: "}</span>
								<span>{formatNumber(imovel.currentCalculo.totalValor)}</span>
							</div>
						)}

						<div className="calculo-body__btn-container bg-gray--light">
							<Button
								className="calculo-body__btn btn__blue"
								onClick={handleSaveForm}
							>
								<Save className="icon__save calculo-body__icon" />
								Salvar
							</Button>
						</div>
					</>
				)}
			</div>
		</>
	);
};

function NumberFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						value: values.floatValue,
					},
				});
			}}
			fixedDecimalScale
			isNumericString
			thousandSeparator="."
			decimalScale={2}
			decimalSeparator=","
			prefix="R$ "
		/>
	);
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

// function currencyFormatter(value) {
// 	if (!Number(value)) return "";

// 	const amount = new Intl.NumberFormat("pt-BR", {
// 		style: "currency",
// 		currency: "BRL",
// 	}).format(value);

// 	console.log(amount, value);

// 	return amount;
// }
