import React from "react";

import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	IconButton,
	InputBase,
	Icon,
} from "@material-ui/core";

import "./style.css";

export default (props) => {
	return (
		<>
			<TableContainer component={Paper} className="owner-table__container">
				<h3 className="blue--light owner-table__title">Proprietários</h3>
				<div className="owner-table__search">
					<div className="owner-table__icon">
						<Icon>search</Icon>
					</div>
					<InputBase
						className="font-input-black"
						placeholder="Buscar…"
						// onChange={handleOnChange}
						inputProps={{ "aria-label": "search" }}
					/>
				</div>
				<Table className="table-container__table">
					<TableHead>
						<TableRow></TableRow>
						<TableRow className="hide-xs">
							<TableCell className="table-container__table--title">
								<span className="owner-table__label">Nome</span>
							</TableCell>

							<TableCell
								className="table-container__table--title"
								align="right"
							>
								Porcentagem
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow hover>
							<TableCell className="table-container__table--show">
								<span className="hide-xs owner-table__value blue--greyish">
									Fernando Garcia
								</span>
								<br />
								<span className="table-container__info">Fernando Garcia</span>
								<span className="table-container__info">4455-2222</span>
							</TableCell>
							<TableCell className="hide-xs" align="right">
								<span className="blue--greyish" align="right">
									100%
								</span>
							</TableCell>

							<TableCell align="right">
								<IconButton className="btn__circular owner-table__delete">
									<Icon className="icon__delete">delete</Icon>
								</IconButton>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell></TableCell>
							<TableCell align="right">
								<span className="blue--greyish">Total</span>
								<span className="green bold owner-table__total">100%</span>
							</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
