import React from "react";

import { Card, CardHeader, CardContent, IconButton } from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Funnel from "../../../components/graphics/funnel";
import "./style.css";

export default (props) => {
	const { dashboard } = props.store;
	const { allDashboards } = dashboard;

	return (
		<Card className="card-funnel">
			<CardHeader
				className="card-funnel__header"
				action={
					<IconButton aria-label="settings" className="card-funnel__container">
						<MoreVertIcon className="black" />
					</IconButton>
				}
				title={"Funil de Vendas"}
			/>
			<CardContent className="card-funnel__content">
				<div>
					<Funnel data={allDashboards.funil} />
				</div>
			</CardContent>
		</Card>
	);
};
