import {
	SHOWALERT,
	SHOWALERTALTERTERM,
	SHOWALERTCONFIRM,
	SHOWALERTQUESTION,
	SHOWALERTQUESTIONCONTATO,
	SHOWALERTQUESTIONREASON,
	SHOWTOAST,
} from "./GlobalType";

export function setShowAlertDialog(value) {
	return { type: SHOWALERT, payload: value };
}

export function setShowToast(value) {
	return { type: SHOWTOAST, payload: value };
}

export function setShowAlertConfirm(value) {
	return { type: SHOWALERTCONFIRM, payload: value };
}

export function setShowAlertQuestion(value) {
	return { type: SHOWALERTQUESTION, payload: value };
}

export function setShowAlertQuestionContato(value) {
	return { type: SHOWALERTQUESTIONCONTATO, payload: value };
}

export function setShowAlertQuestionReason(value) {
	return { type: SHOWALERTQUESTIONREASON, payload: value };
}

export function setShowAlertAlterTerm(value) {
	return { type: SHOWALERTALTERTERM, payload: value };
}
