import React, { Component, useEffect } from "react";
import { connect } from "react-redux";

import {
	getDashboard,
	openDialogPainelVenda,
	getFaseDialogo,
} from "redux/Dashboard/DashboardAction";

import Oportunidade from "./OpportunityCard";
import FunilVendas from "./SalesFunnel";
import Titulo from "components/titulo";
import Loader from "components/Loader";

import "./style.css";

class Dashboard extends Component {
	componentDidMount() {
		this.props.dispatch(getDashboard());
		console.log("isso é o caralho de uma prop", this.props);
		this.props.dispatch(openDialogPainelVenda());
		this.props.dispatch(getFaseDialogo());
	}

	render() {
		const { dashboard } = this.props.store;
		const { allDashboards } = dashboard;

		return (
			<>
				<Loader {...this.props} />
				<Titulo dashboard />
				<div className="dashboard">
					<div className="dashboard-container">
						<div className="card-container">
							{allDashboards.funil && <FunilVendas {...this.props} />}
							{allDashboards.oportunidade && (
								<Oportunidade
									{...this.props}
									painel={allDashboards.oportunidade}
								/>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({ store: state });
export default Dashboard = connect(mapStateToProps)(Dashboard);
