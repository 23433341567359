import React from "react";

import { Avatar, Grid } from "@material-ui/core";

import "./style.css";

export default (props) => {
	const { oportunidade } = props.store;
	const pessoa = oportunidade.getOportunidade.opor_pessoa;

	return (
		<div className="attendance-step">
			<div className="attendance-step__content">
				<Grid container spacing={2}>
					<Grid
						container
						item
						wrap="nowrap"
						spacing={2}
						className="attendance-step__avatar-wrapper"
					>
						<Grid item>
							<Avatar
								className="attendance-step__avatar"
								alt="avatar"
								src={pessoa && pessoa.pessoa_foto}
								style={{ width: "60px", height: "60px" }}
							/>
						</Grid>

						<Grid container item direction="column" justify="center">
							<Grid item>{pessoa && pessoa.pessoa_nome}</Grid>
							<Grid item>
								{pessoa.pessoa_telefones.map((value, index) => (
									<span
										key={index}
										className="attendance-step__value"
									>{`${value.pessoa_telefone_tipo}: ${value.pessoa_telefone_numero}`}</span>
								))}
							</Grid>
							<Grid item>
								<span style={{ color: "#9d9c9c", fontSize: "12px" }}>
									{pessoa && pessoa.pessoa_email}
								</span>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};
