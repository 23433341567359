import "./style.css";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	TextField,
} from "@material-ui/core";
import { Check, Close, Error } from "@material-ui/icons";
import React from "react";

export default (props) => {
	const { global } = props.store;
	const [formValidade, setFormValidate] = React.useState("");

	const handleClose = () => {
		props.setShowAlertQuestionReason({ show: false });
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		const data = {
			...global.showAlertQuestionReason,
			[name]: value,
		};

		props.setShowAlertQuestionReason(data);
		validate(data);
	};

	const validate = (data) => {
		let reason = "";
		setFormValidate("");

		if (data) reason = data.reason;
		else reason = global.showAlertQuestionReason.reason;

		if (reason.length <= 0) {
			setFormValidate("Preencha o motivo para continuar");
			return false;
		}

		return true;
	};

	const handleClick = () => {
		if (validate()) props.handleConfirm();
	};

	return (
		<>
			<Dialog
				open={global.showAlertQuestionReason.show}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className="bg-gray--light ">
					<DialogContent className="alert-disapprove-reservation__content">
						<DialogTitle
							id="alert-dialog-title"
							className="orange alert-disapprove-reservation__title"
						>
							<Error className="alert-disapprove-reservation__title-icon" />
							{global.showAlertQuestionReason.title}
							<IconButton
								className="alert-disapprove-reservation__close-container"
								onClick={handleClose}
							>
								<Close className="gray--younect" />
							</IconButton>
						</DialogTitle>
						<DialogContentText
							id="alert-dialog-description"
							className="alert-disapprove-reservation__description"
						>
							{global.showAlertQuestionReason.text}
						</DialogContentText>

						<TextField
							className="alert-disapprove-reservation__input bg-white"
							label="Motivo da reprovação"
							multiline
							rows={5}
							name="reason"
							value={global.showAlertQuestionReason.reason}
							onChange={handleChange}
							error={formValidade ? true : false}
							helperText={formValidade}
						/>
					</DialogContent>
					<DialogActions>
						<div className="alert-disapprove-reservation__btn-container">
							<Button
								onClick={handleClose}
								autoFocus
								className="btn__gray--medium"
							>
								<Close className="alert-disapprove-reservation__icon" />
								Não
							</Button>
							<Button
								onClick={handleClick}
								autoFocus
								className="btn__blue--medium"
							>
								<Check className="alert-disapprove-reservation__icon" />
								Sim
							</Button>
						</div>
					</DialogActions>
				</div>
			</Dialog>
		</>
	);
};
