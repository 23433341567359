import React from "react";

import {
	Stepper,
	Step,
	StepLabel,
	Button,
	Typography,
	StepConnector,
	makeStyles,
	withStyles,
	StepButton,
	Icon,
	Paper,
} from "@material-ui/core";

import {
	DnsOutlined as DnsOutlinedIcon,
	VpnKeyOutlined as VpnKeyOutlinedIcon,
	PrintOutlined as PrintOutlinedIcon,
} from "@material-ui/icons";

import { ContainerElevation, ButtonStep, ButtonStepOutlined } from "./styles";

import Form from "./Form";
import GridOwners from "./GridOwners";
import GridPlans from "./GridPlans";
import ProposalTemplates from "./ProposalTemplates";
import GridBrokers from "./GridBrokers";

import Titulo from "../../components/titulo";
import clsx from "clsx";
import { history } from "../../App";

const useStyles = makeStyles((theme) => ({
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		boxSizing: "border-box",
	},
}));

function getSteps() {
	return [
		"Dados Básicos",
		"Proprietários",
		"Planos de Pagamento",
		"Modelos de Impressos",
		"Corretores",
	];
}

function getStepContent(stepIndex) {
	switch (stepIndex) {
		case 0:
			return <Form />;
		case 1:
			return <GridOwners />;
		case 2:
			return <GridPlans />;
		case 3:
			return <ProposalTemplates />;
		case 4:
			return <GridBrokers />;
		default:
			return "Unknown stepIndex";
	}
}

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 48px)",
	},
	active: {
		"& $line": {
			backgroundColor: "#0548a3",
		},
	},
	completed: {
		"& $line": {
			backgroundColor: "#469c47",
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderRadius: 1,
	},
})(StepConnector);

function EnterpriseRegister(props) {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const steps = getSteps();

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleClickBack = () => {
		history.push("/empreendimentosgi");
	};

	const useColorlibStepIconStyles = makeStyles((theme) => ({
		root: {
			backgroundColor: "#ccc",
			zIndex: 1,
			color: "#fff",
			width: 40,
			height: 40,
			[theme.breakpoints.down("xs")]: {
				width: 30,
				height: 30,
			},
			display: "flex",
			borderRadius: "50%",
			justifyContent: "center",
			alignItems: "center",
		},
		active: {
			backgroundColor: "#0548a3",
			boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
		},
		completed: {
			backgroundColor: "#469c47",
		},
		error: {
			backgroundColor: "#f50057",
		},
	}));

	function ColorlibStepIcon(props) {
		const classes = useColorlibStepIconStyles();
		const { active, completed, error } = props;

		const icons = {
			1: <DnsOutlinedIcon />,
			2: <VpnKeyOutlinedIcon />,
			3: <Icon>credit_card</Icon>,
			4: <PrintOutlinedIcon />,
			5: <Icon>support_agent</Icon>,
		};

		return (
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
					[classes.completed]: completed,
					[classes.error]: error,
				})}
			>
				{icons[String(props.icon)]}
			</div>
		);
	}

	return (
		<>
			<Titulo
				{...props}
				title="Empreendimento - Cadastro"
				standardHelper
				back
				onClickBack={handleClickBack}
			/>
			<ContainerElevation>
				<Stepper
					activeStep={activeStep}
					alternativeLabel
					connector={<ColorlibConnector />}
				>
					{steps.map((label) => (
						<Step key={label}>
							<StepButton>
								<StepLabel StepIconComponent={ColorlibStepIcon}>
									{label}
								</StepLabel>
							</StepButton>
						</Step>
					))}
				</Stepper>

				<div>
					{activeStep === steps.length ? (
						<div>
							<Typography className={classes.instructions}>
								All steps completed
							</Typography>
							<Button onClick={handleReset}>Reset</Button>
						</div>
					) : (
						<div>
							<Paper className={classes.instructions}>
								{getStepContent(activeStep)}
							</Paper>
							<div className="end">
								<ButtonStepOutlined
									disabled={activeStep === 0}
									onClick={handleBack}
								>
									Anterior
								</ButtonStepOutlined>
								<ButtonStep className="btn__blue" onClick={handleNext}>
									{activeStep === steps.length - 1 ? "Finish" : "Próximo"}
								</ButtonStep>
							</div>
						</div>
					)}
				</div>
			</ContainerElevation>
		</>
	);
}
export default EnterpriseRegister;
