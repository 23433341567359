import { API } from "../../utils/API";
import {
	ADDUSUARIO,
	ADDUSUARIOSAGA,
	EDITUSUARIO,
	EDITUSUARIOSAGA,
	ERROUSUARIO,
	ERROUSUARIOSAGA,
	GETFUNCAOSAGA,
	GETFUNCAOUSUARIOSAGA,
	GETPESSOABYCPFSAGA,
	GETUSUARIOBYIDSAGA,
	GETUSUARIOSSAGA,
	SELECTEDUSUARIOSAGA,
} from "./UserType";

export function initialValues() {
	return (dispatch) => {
		let payload = {};
		dispatch({ type: ERROUSUARIO, payload });
		dispatch({ type: ADDUSUARIO, payload });
		dispatch({ type: EDITUSUARIO, payload });
	};
}

export function getAllUsuarios() {
	return { type: GETUSUARIOSSAGA };
}

export function getPessoaByCPF(params) {
	return { type: GETPESSOABYCPFSAGA, payload: params };
}

export function getFuncoes() {
	return { type: GETFUNCAOSAGA };
}

export function addUsuario(data) {
	return { type: ADDUSUARIOSAGA, payload: data };
}

export function editUsuario(data) {
	return { tpye: EDITUSUARIOSAGA, payload: data };
}

export function getUsuarioByID(params) {
	return { type: GETUSUARIOBYIDSAGA, payload: params };
}
