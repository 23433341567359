import "./style.css";

import { Button, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import jwtDecode from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";

import YouNectLogoNegative from "../../assets/logos/logo-negative.png";
import YouNectLogo from "../../assets/logos/logo.png";
import AuthContext from "../../contexts/auth";
import { API } from "../../utils/API";

const initialErrors = {
	senha: "",
	confirma_senha: "",
};

const initialForm = {
	senha: "",
	confirma_senha: "",
};

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default (props) => {
	const { logIn } = useContext(AuthContext);
	const { token } = props.match.params;
	const [form, setForm] = useState(initialForm);
	const [formValidade, setFormValidate] = useState(initialErrors);
	const [user, setUser] = useState({});
	const [open, setOpen] = useState(false);
	const [messageError, setMessageError] = useState(false);

	useEffect(() => {
		setUser(jwtDecode(token));
	}, [token]);

	const validate = (form) => {
		let error = false;
		let formErros = formValidade;

		form.forEach((data) => {
			switch (data.name) {
				case "senha": {
					let senha = "";

					if (!data.value) {
						senha = "Preencha uma senha válida";
					} else if (data.value.length <= 5) {
						senha = "A senha precisa ter no mínimo 6 caracteres";
					} else if (data.value.length >= 6) {
						let str = data.value.toString();

						if (!str.match("\\d"))
							senha = "A senha precisa ter no mínimo um número";

						if (!str.match("[a-zA-Z]"))
							senha = "A senha precisa ter no mínimo uma letra";
					}

					formErros = { ...formErros, senha };
					error = senha ? true : false;

					break;
				}
				case "confirma_senha": {
					let confirma_senha = "";

					if (!data.value) {
						confirma_senha = "Preencha uma senha válida";
					} else if (data.value.length <= 5) {
						confirma_senha = "A senha precisa ter no mínimo 6 caracteres";
					} else if (data.value.length >= 6) {
						let str = data.value.toString();

						if (!str.match("\\d"))
							confirma_senha = "A senha precisa ter no mínimo um número";

						if (!str.match("[a-zA-Z]"))
							confirma_senha = "A senha precisa ter no mínimo uma letra";
					}

					formErros = { ...formErros, confirma_senha };
					error = confirma_senha ? true : false;

					break;
				}
				default:
					error = false;
			}
		});

		setFormValidate(formErros);
		return error;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let error = validate([
			{ name: "senha", value: form.senha },
			{ name: "confirma_senha", value: form.confirma_senha },
		]);

		if (error) return;

		if (form.senha !== form.confirma_senha) {
			setFormValidate({
				...formValidade,
				confirma_senha: "As senhas não correspondem",
			});
			return;
		}

		const body = { token, senha: form.senha };
		const resp = await API.post(
			`usuario/redefinicao_senha`,
			JSON.stringify(body)
		);

		const { data } = resp;
		console.log(data);
		if (data.message === "success") {
			await logIn({
				email: user.pessoa_email,
				senha: form.senha,
			});
		} else {
			if (data.message === "error") {
				setMessageError(data.errors);
				setOpen(true);
			}
		}
	};

	const handleOnChange = (e) => {
		let { name, value } = e.target;
		validate([{ name, value }]);
		setForm({ ...form, [name]: value });
	};

	const handleOnBlur = (e) => {
		const { name, value } = e.target;
		validate([{ name, value }]);
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	return (
		<div className="activation">
			<div className="activation__container">
				<div className="activation__inner">
					<div className="activation__logo-container">
						<img
							src={YouNectLogoNegative}
							alt="logo"
							className="activation__logo-negative"
						/>
						<img
							src={YouNectLogo}
							alt="logo"
							className="activation__logo hide"
						/>
					</div>

					<div className="activation__text">
						<p>
							Olá <span className="activation__user">{user.pessoa_nome}</span>,
						</p>
						{/* <br /> */}
						<p>
							Use esta tela para cadastrar uma nova senha e ativar seu usuário.
						</p>
						<p>
							Seu email de acesso é
							<span className="activation__email">{user.pessoa_email}</span>
						</p>
					</div>
					<form
						className="activation__form"
						noValidate
						autoComplete="off"
						onSubmit={handleSubmit}
					>
						<TextField
							className="input__primary"
							autoComplete="off"
							type="password"
							label="Senha"
							name="senha"
							inputProps={{ maxLength: 20 }}
							value={form.senha}
							onChange={handleOnChange}
							onBlur={handleOnBlur}
							error={formValidade.senha ? true : false}
							helperText={formValidade.senha}
						/>

						<TextField
							className="input__primary"
							autoComplete="off"
							type="password"
							label="Confirmar Senha"
							name="confirma_senha"
							inputProps={{ maxLength: 20 }}
							value={form.confirma_senha}
							onChange={handleOnChange}
							onBlur={handleOnBlur}
							error={formValidade.confirma_senha ? true : false}
							helperText={formValidade.confirma_senha}
						/>

						<Button className="btn__blue activation__btn" type="submit">
							Confirmar
						</Button>
					</form>
				</div>

				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={open}
					autoHideDuration={5000}
					onClose={handleClose}
				>
					<Alert onClose={handleClose} severity="error">
						{messageError}
					</Alert>
				</Snackbar>
			</div>
		</div>
	);
};
