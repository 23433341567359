import React from "react";
import Loading from "react-loader-spinner";

import { LOADING } from "../../constants";

const Loader = (props) => {
	const { loader } = props.store;
	return (
		<>
			{loader.isLoading && (
				<div className="loader">
					<div className="loading">
						<Loading type="ThreeDots" color="#0548a3" height={80} width={80} />
					</div>
				</div>
			)}
		</>
	);
};

export default Loader;

export const showLoading = () => {
	return {
		type: LOADING,
		payload: true,
	};
};

export const hideLoading = () => {
	return {
		type: LOADING,
		payload: false,
	};
};

const INITIAL_STATE = {
	isLoading: false,
};

export const loader = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOADING:
			return { ...state, isLoading: action.payload };
		default:
			return state;
	}
};
