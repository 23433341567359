import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Paper,
	Slide,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Checkbox } from "components";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
	const { empresas } = props;

	return (
		<Dialog
			open={props.open}
			TransitionComponent={Transition}
			onClose={props.handleClose}
		>
			<DialogTitle className="dialog-container__header">
				<DialogActions
					className="dialog-container__close"
					onClick={props.handleClose}
				>
					<Close className="icon__close" />
				</DialogActions>

				<div className="white--bluish">Selecione abaixo a empresa desejada</div>
			</DialogTitle>

			<div>
				<TableContainer
					component={Paper}
					id="tableContainer"
					className="table-container"
					ref={props.tableRef}
				>
					<Table stickyHeader className="table-container__table">
						<TableBody>
							{empresas &&
								empresas.map((value, index) => (
									<TableRow key={index} hover>
										<TableCell>
											<Checkbox
												onChange={() => props.handleSelected(value)}
												checked={props.empresaSelected.includes(value)}
											/>
										</TableCell>

										<TableCell>{value.empresa_nome}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>

			<div className="center">
				<Button onClick={props.handleLoginEmpresa} className="login__btn">
					ENTRAR
				</Button>
			</div>
		</Dialog>
	);
};
