import React from "react";

import {
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./style.css";

export default (props) => {
	const { imovel } = props;

	return (
		<>
			<ExpansionPanel
				className="quadra-panel"
				defaultExpanded
				TransitionProps={{ unmountOnExit: true }}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography className="quadra-panel__heading gray--younect">
						Quadra: {imovel.quadra}
					</Typography>
				</ExpansionPanelSummary>

				<ExpansionPanelDetails className="quadra-panel__imovel">
					{imovel.quadra_itens.map((value) => (
						<div
							key={value.cod_imovel}
							className="quadra-panel__item"
							style={{ backgroundColor: `${value.situacao_venda_cor_html}` }}
							onClick={() => props.click(value.cod_imovel)}
						>
							<Typography
								style={{ color: `${value.situacao_venda_cor_html_fonte}` }}
							>
								{value.lote}
							</Typography>
						</div>
					))}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</>
	);
};
