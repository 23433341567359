import "./style.css";

import {
	Button,
	CardActions,
	CardContent,
	Collapse,
	Typography,
} from "@material-ui/core";
import { ChevronLeft, HelpOutline } from "@material-ui/icons";
import React from "react";

import { history } from "../../../../App";

export default (props) => {
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleClickBack = () => {
		history.push("/pessoa");
	};

	return (
		<div>
			<CardActions disableSpacing className="header-bottom--simple">
				<div className="center">
					<div onClick={handleClickBack} className="header-person__btn">
						<ChevronLeft className="header-person__back" />
					</div>

					<h2 className="header__title">Cadastro de Pessoa</h2>
				</div>

				<div className="header__container">
					<Button
						id="header__button-help"
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<HelpOutline className="header__icon-help" />
					</Button>
				</div>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit className="intro">
				<CardContent>
					<Typography paragraph>Method:</Typography>
					<Typography paragraph>
						O incentivo ao avanço tecnológico, assim como o aumento do diálogo
						entre os diferentes setores produtivos ainda não demonstrou
						convincentemente que vai participar na mudança das novas
						proposições.
					</Typography>
				</CardContent>
			</Collapse>
		</div>
	);
};
