import "./style.css";

import React from "react";
import {
	addOrcamento,
	addProposta,
	shareProposta,
} from "redux/Sale/SaleAction";

import AnexarDocumento from "../../../venda/anexar-documento";
import Calculo from "../calculo";
import Compartilhar from "../compartilhar";
import Contato from "../contato";
import Proposta from "../proposta";

export default (props) => {
	const { imovel, venda } = props.store;
	const [step, setStep] = React.useState("calculo");

	const handleSetStep = (value) => {
		setStep(value);
	};

	const handleSaveProposta = (contato, proposta, cod_opor = 0) => {
		props.dispatch(
			addProposta(
				imovel.listCalculo,
				contato,
				imovel.getImovel,
				proposta,
				cod_opor
			)
		);
	};

	const handleSaveOrcamento = (contato) => {
		console.log("orcamento");
		props.dispatch(addOrcamento(imovel.listCalculo, contato, imovel.getImovel));
	};

	const handleToShare = () => {
		console.log(venda.getProposta);
		props.dispatch(shareProposta(venda.getProposta));
	};

	const handleToAttach = (proposta) => {
		const { cod_opor, cod_fase } = proposta;
		props.getFaseDocumentos({ cod_fase, cod_opor });
	};

	const renderStep = () => {
		switch (step) {
			case "calculo":
				return renderDefault();
			case "contato":
				return (
					<Contato
						{...props}
						setStep={handleSetStep}
						priorStep={"calculo"}
						nextStep={"proposta"}
						saveOrcamento={handleSaveOrcamento}
						proposta
					/>
				);
			case "proposta":
				return (
					<Proposta
						{...props}
						setStep={handleSetStep}
						priorStep={"contato"}
						saveProposta={handleSaveProposta}
						nextStep={"compartilhar"}
					/>
				);
			case "compartilhar":
				return (
					<Compartilhar
						{...props}
						toShare={handleToShare}
						toAttach={handleToAttach}
						shareText="Proposta gerada com sucesso!"
						proposta
					/>
				);
			default:
				return renderDefault();
		}
	};

	const renderDefault = () => {
		return (
			<Calculo
				{...props}
				setStep={handleSetStep}
				nextStep={"contato"}
				nextStepNegotiation={"proposta"}
			/>
		);
	};

	return (
		<div className="dialog-body__budget">
			{renderStep()}

			<AnexarDocumento {...props} />
		</div>
	);
};
