import { hideLoading, showLoading } from "components/Loader";
import { call, put, takeEvery } from "redux-saga/effects";
import { GETPESSOABYCPF } from "redux/Person/PersonType";
import { API } from "utils/API";

import { history } from "../../App";
import {
	ADDUSUARIO,
	ADDUSUARIOSAGA,
	EDITUSUARIO,
	EDITUSUARIOSAGA,
	ERROUSUARIO,
	ERROUSUARIOSAGA,
	GETFUNCAOSAGA,
	GETFUNCAOUSUARIO,
	GETFUNCAOUSUARIOSAGA,
	GETPESSOABYCPFSAGA,
	GETUSUARIOBYIDSAGA,
	GETUSUARIOS,
	GETUSUARIOSSAGA,
	SELECTEDUSUARIO,
	SELECTEDUSUARIOSAGA,
} from "./UserType";

function* getAllUsuarios() {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "usuario");
		console.log(response);
		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETUSUARIOS, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getPessoaByCPF(action) {
	yield put(showLoading());

	try {
		const response = yield call(API.get, `pessoa`, {
			...action.payload.params,
		});

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = {};

		yield put({ type: GETPESSOABYCPF, payload });
		yield put(hideLoading());
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response.data } });
		yield put(hideLoading());
	}
}

function* getFuncoes() {
	yield put(showLoading());
	try {
		const response = yield call(API.get, `funcao`);
		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];
		yield put({ type: GETFUNCAOUSUARIO, payload });
		yield put(hideLoading());
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response.data } });
		yield put(hideLoading());
	}
}
function* addUsuario(action) {
	yield put(showLoading());
	try {
		const response = yield call(
			API.post,
			`usuario`,
			JSON.stringify(action.payload.data)
		);
		let payload = response.data;

		if (payload.message === "error") yield put({ type: ERROUSUARIO, payload });
		else yield put({ type: ADDUSUARIO, payload: payload.result });

		yield put(hideLoading());
	} catch (error) {
		console.log(error);
		history.push("/erro", { state: { erro: error.response.data } });
		yield put(hideLoading());
	}
}

function* editUsuario(action) {
	console.log("edituser", action);
	yield put(showLoading());
	try {
		const response = yield call(
			API.put,
			`usuario/${action.payload.data.cod_usuario}`,
			JSON.stringify(action.payload.data)
		);
		let payload = response.data;

		console.log(payload);

		if (payload.message === "error") yield put({ type: ERROUSUARIO, payload });
		else yield put({ type: EDITUSUARIO, payload: payload.result });

		yield put(hideLoading());
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response.data } });
		yield put(hideLoading());
	}
}

function* getUsuarioByID(action) {
	yield put(showLoading());
	try {
		const response = yield call(API.get, `usuario`, {
			params: { cod_usuario: action.payload.cod_usuario },
		});

		let payload = response.data.result;
		yield put({ type: SELECTEDUSUARIO, payload });
		yield put(hideLoading());
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response.data } });
		yield put(hideLoading());
	}
}

export default function* UserSaga() {
	yield takeEvery(GETUSUARIOSSAGA, getAllUsuarios);
	yield takeEvery(GETPESSOABYCPFSAGA, getPessoaByCPF);
	yield takeEvery(GETFUNCAOSAGA, getFuncoes);
	yield takeEvery(ADDUSUARIOSAGA, addUsuario);
	yield takeEvery(EDITUSUARIOSAGA, editUsuario);
	yield takeEvery(GETUSUARIOBYIDSAGA, getUsuarioByID);
}
