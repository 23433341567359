export const SETEXPANDEDIMOVEL = "SETEXPANDEDIMOVEL";

export const SETEXPANDED = "SETEXPANDED";

export const LOADING = "LOADING";

export const ERRORSFORMFIELD = "ERRORSFORMFIELD";

export const SELECTEDCONTATO = "SELECTEDCONTATO";

export const SEARCHPESSOAS = "SEARCHPESSOAS";
export const SEARCHMOREPESSOAS = "SEARCHMOREPESSOAS";
export const SHOWDIALOGPESSOA = "SHOWDIALGPESSOA";
// export const GETPESSOABYCPF = "GETPESSOABYCPF";
export const ADDPESSOA = "ADDPESSOA";

export const LOGIN = "LOGIN";
