import React from "react";

import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	InputBase,
	Icon,
} from "@material-ui/core";

import "./style.css";

export default (props) => {
	return (
		<>
			<TableContainer component={Paper} className="plan-table__container">
				<h3 className="blue--light plan-table__title">Planos de Pagamento</h3>
				<div className="plan-table__search">
					<div className="plan-table__icon">
						<Icon>search</Icon>
					</div>
					<InputBase
						className="font-input-black"
						placeholder="Buscar…"
						// onChange={handleOnChange}
						inputProps={{ "aria-label": "search" }}
					/>
				</div>

				<Table className="table-container__table">
					<TableHead>
						<TableRow className="hide-xs">
							<TableCell className="table-container__table--title">
								<span className="plan-table__label">Código do Plano</span>
							</TableCell>

							<TableCell className="table-container__table--title">
								Plano
							</TableCell>

							<TableCell className="table-container__table--title">
								Entrada Mínima
							</TableCell>

							<TableCell className="table-container__table--title">
								Entrada Reajuste
							</TableCell>

							<TableCell className="table-container__table--title">
								Entrada Quant.
							</TableCell>

							<TableCell className="table-container__table--title">
								Parc. Reajuste
							</TableCell>

							<TableCell className="table-container__table--title">
								Parc. Quantidade
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow hover>
							<TableCell className="table-container__table--show">
								<span className="hide-xs blue--greyish plan-table__value">
									13
								</span>
								<br />
								<span className="table-container__info">Fernando Garcia</span>
								<span className="table-container__info">4455-2222</span>
							</TableCell>
							<TableCell className="hide-xs blue--greyish">
								<span className="blue--greyish">à vista</span>
							</TableCell>

							<TableCell>
								<span className="hide-xs blue--greyish">20.000</span>
							</TableCell>

							<TableCell className="hide-xs">
								<span className="hide-xs blue--greyish">0,0000</span>
							</TableCell>

							<TableCell className="hide-xs">
								<span className="hide-xs blue--greyish">01</span>
							</TableCell>

							<TableCell className="hide-xs">
								<span className="hide-xs blue--greyish">1,0000</span>
							</TableCell>

							<TableCell className="hide-xs">
								<span className="hide-xs blue--greyish">72</span>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
