import { call, put, takeEvery } from "redux-saga/effects";

import { hideLoading, showLoading } from "../../components/Loader";
import { API } from "../../utils/API";
import {
	GETIMOVELSEARCH,
	GETIMOVELSEARCHSAGA,
	GETTYPEVIEW,
	GETTYPEVIEWSAGA,
	SETPAGESEARCH,
	SETPAGESEARCHSAGA,
	SETTYPEVIEW,
	SETTYPEVIEWSAGA,
} from "./QueryPropertyType";

function* getTypeView() {
	yield put({ type: GETTYPEVIEW });
}
function* setTypeView(action) {
	yield put({ type: SETTYPEVIEW, payload: action.payload });
}
function* getImovelSaga(action) {
	try {
		yield put(showLoading());
		var response = null;
		if (action.payload !== undefined) {
			response = yield call(API.get, `imovel?${action.payload.join("&")}`);
		} else {
			response = yield call(API.get, `imovel`);
		}
		if (response.data.result !== "Não possui dados") {
			yield put({ type: GETIMOVELSEARCH, payload: response.data.result });
		} else {
			yield put({ type: GETIMOVELSEARCH, payload: "clear" });
		}
		yield put(hideLoading());
	} catch (error) {
		console.log("getImovelSaga api errror", error);
	}
}

function* setPageSearchSaga(action) {
	yield put({ type: SETPAGESEARCH, payload: action.payload });
}

export default function* QueryPropertySaga() {
	yield takeEvery(GETTYPEVIEWSAGA, getTypeView);
	yield takeEvery(SETTYPEVIEWSAGA, setTypeView);
	yield takeEvery(GETIMOVELSEARCHSAGA, getImovelSaga);
	yield takeEvery(SETPAGESEARCHSAGA, setPageSearchSaga);
}
