import {
	Button,
	CardActions,
	makeStyles,
	Step,
	StepConnector,
	StepLabel,
	Stepper,
	withStyles,
} from "@material-ui/core";
import { Check, HelpOutline } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import { addEmpreendimento } from "../../redux/Enterprise/EnterpriseAction";
import DownloadUpload from "./DownloadUpload";
import JsonToTable from "./JsonToTable";
import style from "./style.module.css";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		display: "flex",
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	stepper: {
		backgroundColor: "transparent",
	},
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		"& $line": {
			backgroundColor: "#469C47",
		},
	},
	completed: {
		"& $line": {
			backgroundColor: "#469C47",
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderRadius: 1,
	},
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: "#ccc",
		zIndex: 1,
		color: "#fff",
		width: 50,
		height: 50,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	},
	active: {
		backgroundColor: "#469C47",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	},
	completed: {
		backgroundColor: "#469C47",
	},
});

function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons = {
		1: "1",
		2: "2",
		3: "3",
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{completed ? (
				<Check className={classes.completed} />
			) : (
				icons[String(props.icon)]
			)}
		</div>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

function ExcelUpload({ dispatch }) {
	const [tableJson, setTableJson] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [teste, setTeste] = useState(null);

	function handleTable(table) {
		setTableJson(table);
		handleNext();
	}
	function receiveData(data) {
		setTeste(data);
	}
	function EnviarEmpreendimento() {
		dispatch(addEmpreendimento(teste));
	}

	function getStepContent(step) {
		switch (step) {
			case 0:
				return <DownloadUpload returnJson={handleTable} />;
			case 1:
				return (
					<JsonToTable
						header={tableJson[0]}
						body={tableJson[1]}
						json={tableJson[2]}
						returnData={receiveData}
					/>
				);
			default:
				return "Unknown step";
		}
	}

	function getSteps() {
		return ["Importação", "Verificação"];
	}

	const classes = useStyles();

	const steps = getSteps();

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// const handleReset = () => {
	//     setActiveStep(0);
	// };

	return (
		<React.Fragment>
			<CardActions disableSpacing className="header-bottom">
				<div>
					<h2 className="header__title">Empreendimentos</h2>
				</div>

				<div className="header__buttons-container">
					<Button id="header__btn-help" aria-label="show more">
						<HelpOutline className="icon__help" />
					</Button>
				</div>
			</CardActions>

			<div className={style.containerElevation}>
				<div className={style.ten}>
					<Stepper
						alternativeLabel
						className={classes.stepper}
						activeStep={activeStep}
						connector={<ColorlibConnector />}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>
									{label}
								</StepLabel>
							</Step>
						))}
					</Stepper>

					<div>
						<div>{getStepContent(activeStep)}</div>
					</div>
				</div>
			</div>

			{activeStep === 1 && (
				<div className={style.btnStep}>
					<Button
						disabled={activeStep === 0}
						onClick={handleBack}
						className={classes.button}
					>
						Cancelar
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={EnviarEmpreendimento}
						className={classes.button}
						disabled={teste === null ? true : false}
					>
						Salvar
					</Button>
				</div>
			)}
		</React.Fragment>
	);
}

export default connect()(ExcelUpload);
