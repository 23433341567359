import React from "react";

import { Avatar, Grid } from "@material-ui/core";

export default (props) => {
	const { oportunidade } = props.store;
	const corretores = oportunidade.getOportunidade.opor_participantes;

	return (
		<>
			{corretores &&
				corretores.map((value, index) => (
					<Grid key={index} container spacing={2}>
						<Grid
							container
							item
							wrap="nowrap"
							spacing={2}
							xs={12}
							// className="attendance-step__avatar-wrapper"
						>
							<Grid item>
								<Avatar
									// className="attendance-step__avatar"
									alt="avatar"
									src={value.usuario_pessoa.pessoa_foto}
									style={{ width: "50px", height: "50px" }}
								/>
							</Grid>

							<Grid item container direction="row" alignItems="center">
								<Grid item style={{ fontSize: "14px" }}>
									<span style={{ whiteSpace: "nowrap" }}>
										{value.usuario_pessoa.pessoa_nome}
									</span>
								</Grid>
								{/* <Grid item>
									<p className="attendance-step__value">
										{value.usuario_pessoa.fone_celular1}
									</p>
								</Grid> */}
								<Grid item style={{ color: "#9d9c9c", fontSize: "12px" }}>
									{value.usuario_pessoa.pessoa_email}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				))}
		</>
	);
};
