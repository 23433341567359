import {
	EDITCOMPANYSAGA,
	GETCOMPANYSAGA,
	GETRESPONSIBLESSAGA,
} from "./CompanyType";

export function getCompany() {
	return { type: GETCOMPANYSAGA };
}

export function editCompany(data) {
	return { type: EDITCOMPANYSAGA, payload: data };
}

export function getResponsibles() {
	return { type: GETRESPONSIBLESSAGA };
}
