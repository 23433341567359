import {
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";

import AlertConfirm from "../../../components/global/alert-confirm-luper";
import { getMapasEmpreendimento } from "../../../redux/Enterprise/EnterpriseAction";
import EmpreendimentoJsonModel from "../model/empreendimentoJsonModel";
import style from "./style.module.css";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 300,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const empreendimentoJsonModel = EmpreendimentoJsonModel();

function JsonToTable({ header, body, json, mapas, dispatch, returnData }) {
	useEffect(() => {
		console.log(mapas);
		dispatch(getMapasEmpreendimento());
		setEmpreendimento(null); // eslint-disable-next-line
	}, [dispatch]); 

	const classes = useStyles();
	const [empreendimento, setEmpreendimento] = useState("");

	let schema = yup.object().shape({
		cod_empreendimento: yup.number().positive().required(),
		unidades: yup.array(),
	});

	function handleChange({ target }) {
		empreendimentoJsonModel.cod_empreendimento = target.value;
		empreendimentoJsonModel.unidades = json;

		schema.isValid(empreendimentoJsonModel).then(function (valid) {
			if (valid === true) returnData(empreendimentoJsonModel);
		});
		setEmpreendimento(target.value);
	}

	return (
		<React.Fragment>
			<AlertConfirm />
			<FormControl variant="outlined" className={classes.formControl}>
				<InputLabel id="demo-simple-select-outlined-label">
					Selecione o empreendimento
				</InputLabel>
				<Select
					labelId="demo-simple-select-outlined-label"
					id="demo-simple-select-outlined"
					value={empreendimento}
					onChange={handleChange}
					label="Empreendimento"
				>
					{mapas.map((mapa) => (
						<MenuItem
							value={mapa.cod_empreendimento}
							key={mapa.cod_empreendimento}
						>
							{mapa.empreend_nome}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<TableContainer className={style.tableContainer} component={Paper}>
				<Table className={style.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							{header.map((headerItem, cont) => (
								<TableCell key={cont}>{headerItem}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{body.map((row, cont) => (
							<TableRow key={cont}>
								{body[cont].map((item, key) => (
									<TableCell align="justify" key={key}>
										{item === null ? "n/D" : item}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</React.Fragment>
	);
}

export default connect((state) => ({
	mapas: state.empreendimento.mapasEmpreendimento,
}))(JsonToTable);
