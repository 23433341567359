import "./style.css";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import React from "react";
import { setShowAlertDialog } from "redux/Global/GlobalAction";

export default (props) => {
	const { global } = props.store;

	const handleClose = () => {
		props.dispatch(
			setShowAlertDialog({
				show: false,
				value: 0,
			})
		);
	};

	return (
		<>
			<Dialog
				open={global.showAlert}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" className="alert-delete__title">
					Deletar
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						id="alert-dialog-description"
						className="alert-delete__description"
					>
						Deseja deletar o contato selecionado?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<div className="btn-container">
						<Button
							onClick={handleClose}
							className="btn__gray alert-delete__btn"
						>
							<Close className="alert-delete__icon" />
							Não
						</Button>
						<Button
							onClick={() =>
								props.handleClickDelete &&
								props.handleClickDelete(global.valueToDelete)
							}
							autoFocus
							className="btn__blue alert-delete__btn"
						>
							<Check className="alert-delete__icon" />
							Sim
						</Button>
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
};
