import {
	CLOSEMODALRESPONSEAPISAGA,
	OPENMODALRESPONSEAPISAGA,
} from "./ModalResponseApiType";

export function openModalResponseApiSaga(action) {
	console.log("openModalResponseApiSaga", action);
	return { type: OPENMODALRESPONSEAPISAGA, payload: action };
}
export function closeModalResponseApiSaga() {
	return { type: CLOSEMODALRESPONSEAPISAGA };
}
