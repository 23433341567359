import "./style.css";

import {
	Avatar,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";

import { history } from "../../../App";

export default (props) => {
	const { usuario } = props.store;

	const renderSituacaoCorBg = (situacao) => {
		switch (situacao) {
			case "Inativo":
				return "bg-red white";
			case "Ativo":
				return "bg-green white";
			case "Pendente":
				return "bg-orange white";
			default:
				return "";
		}
	};

	const handleEdit = (value) => {
		const { cod_usuario } = value;
		history.push(`/usuario/register/${cod_usuario}`);
	};

	return (
		<>
			<div className="container-elevation">
				<TableContainer component={Paper} className="table-container">
					<Table className="table-container__table">
						<TableHead className="table-container__table--header">
							<TableRow className="hide-xs">
								<TableCell className="hide-sm" />
								<TableCell />
								<TableCell className="table-container__table--title">
									Nome
								</TableCell>
								<TableCell className="table-container__table--title">
									Email
								</TableCell>
								<TableCell className="table-container__table--title">
									Celular
								</TableCell>
								<TableCell className="hide-sm" />
								<TableCell />
							</TableRow>
						</TableHead>

						<TableBody>
							{usuario.allUsuarios.map((value, index) => (
								<TableRow hover key={index} onClick={() => handleEdit(value)}>
									<TableCell className="hide-sm"></TableCell>

									<TableCell className="user__avatar">
										<Avatar alt="avatar" src={value.foto} />
									</TableCell>

									<TableCell
										component="th"
										className="table-container__table--show user__data"
									>
										<span>{value.nome}</span>
										<span className="table-container__info">{value.email}</span>
										<span className="table-container__info">
											{value.celular}
										</span>
									</TableCell>

									<TableCell className="hide-xs">{value.email}</TableCell>

									<TableCell className="hide-xs">{value.celular}</TableCell>

									<TableCell className="user__status">
										<span
											className={`${renderSituacaoCorBg(
												value.situacao
											)} status`}
										>
											{value.situacao}
										</span>
									</TableCell>

									<TableCell
										className="hide-sm"
										onClick={() =>
											props.handleClickEdit && props.handleClickEdit(value)
										}
									>
										<IconButton className="btn__circular">
											<EditIcon className="icon__edit" />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
};
