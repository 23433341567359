export const GETOPCAOVENDASAGA = "GETOPCAOVENDASAGA";
export const SETORCAMENTOSAGA = "SETORCAMENTOSAGA";
export const SETPROPOSTASAGA = "SETPROPOSTASAGA";
export const SETRESERVASAGA = "SETRESERVASAGA";
export const SHAREORCAMENTOSAGA = "SHAREORCAMENTOSAGA";
export const SHAREPROPOSTASAGA = "SHAREPROPOSTASAGA";
export const GETPLANOPAGAMENTOSAGA = "GETPLANOPAGAMENTOSAGA";
export const GETFASEDOCUMENTOSSAGA = "GETFASEDOCUMENTOSSAGA";
export const GETFASEDOCUMENTOIMAGEMSAGA = "GETFASEDOCUMENTOIMAGEMSAGA";
export const ADDFASEDOCUMENTOSAGA = "ADDFASEDOCUMENTOSAGA";
export const EDITFASEDOCUMENTOSAGA = "EDITFASEDOCUMENTOSAGA";

export const GETOPCAOVENDA = "GETOPCAOVENDA";
export const SETOPCAOVENDA = "SETOPCAOVENDA";
export const GETPLANOPAGAMENTO = "GETPLANOPAGAMENTO";
export const SETORCAMENTO = "SETORCAMENTO";
export const SETPROPOSTA = "SETPROPOSTA";
export const SETRESERVA = "SETRESERVA";
export const SHAREORCAMENTO = "SHAREORCAMENTO";
export const SHAREPROPOSTA = "SHAREPROPOSTA";
export const CHANGEPROPOSTA = "CHANGEPROPOSTA";
export const ERRORSFORMDATAPROPOSTA = "ERRORSFORMDATAPROPOSTA";
export const GETFASEDOCUMENTOS = "GETFASEDOCUMENTOS";
export const EDITFASEDOCUMENTO = "EDITFASEDOCUMENTO";
export const GETFASEDOCUMENTOIMAGEM = "GETFASEDOCUMENTOIMAGEM";
export const CLOSEFASEDOCUMENTOIMAGEM = "CLOSEFASEDOCUMENTOIMAGEM";
export const CLOSEFASEDOCUMENTOS = "CLOSEFASEDOCUMENTOS";
