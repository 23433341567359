import "./style.css";

import Button from "@material-ui/core/Button";
import { Check, Close } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";

import AlertDelete from "../../components/global/AlertDelete";
import Loader from "../../components/Loader";
import Cadastro from "./Form";
import Titulo from "./Form/titulo-cadastro";

// import GridEnderecos from "../../../components/pessoa/cadastro/grid-enderecos-cadastro";
// import GridContatos from "../../../components/pessoa/cadastro/grid-contatos-cadastro";
// import GridDadosBancarios from "../../../components/pessoa/cadastro/grid-dados-bancarios-cadastro";
// import GridTelefones from "../../../components/pessoa/cadastro/grid-telefones-cadastro";
// import GridSocios from "../../../components/pessoa/cadastro/grid-socios-cadastro";

class CadastroPessoa extends Component {
	componentDidMount() {
		// this.props.getAllContatos();
	}

	render() {
		const { global } = this.props.store;

		// const handleClickOpen = (value) => {
		// 	this.props.setShowDialog(true);
		// };

		// const handleClickConfirmDelete = (value) => {
		// 	this.props.setShowAlertDialog({
		// 		show: true,
		// 		value: "",
		// 	});
		// };

		const handleClickDelete = () => {
			this.props.deleteContato(global.valueToDelete);

			this.props.setShowAlertDialog({
				show: false,
				value: 0,
			});
		};

		return (
			<>
				<Loader {...this.props} />
				<div>
					<Titulo {...this.props} />
					<div className="cadastro-container">
						<Cadastro {...this.props} />

						{/* <GridEnderecos
							{...this.props}
							handleClickEdit={handleClickOpen}
							handleClickConfirmDelete={handleClickConfirmDelete}
						/>

						<GridContatos
							{...this.props}
							handleClickEdit={handleClickOpen}
							handleClickConfirmDelete={handleClickConfirmDelete}
						/>

						<GridTelefones
							{...this.props}
							handleClickEdit={handleClickOpen}
							handleClickConfirmDelete={handleClickConfirmDelete}
						/>

						<GridDadosBancarios
							{...this.props}
							handleClickEdit={handleClickOpen}
							handleClickConfirmDelete={handleClickConfirmDelete}
						/>

						<GridSocios
							{...this.props}
							handleClickEdit={handleClickOpen}
							handleClickConfirmDelete={handleClickConfirmDelete}
						/> */}

						<div className="btn-container m-top">
							<Button
								className="btn__blue btn__blue--outlined"
								component={NavLink}
								to={`/pessoa`}
							>
								<Close className="dialog-register__icon-close" />
								Cancelar
							</Button>
							<Button className="btn__blue">
								<Check className="btn__icon" />
								Salvar
							</Button>
						</div>
					</div>
					<AlertDelete {...this.props} handleClickDelete={handleClickDelete} />
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { store: state };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default CadastroPessoa = connect(
	mapStateToProps,
	mapDispatchToProps
)(CadastroPessoa);
