import "./style.css";

import React from "react";

import HelperContent from "./components/helper";
import Dashboard from "./dashboard";
import Espelho from "./espelho";
import StandardAddOption from "./standard-add-option";
import StandardHelper from "./standard-helper";

export default (props) => {
	const { dashboard, espelho, standardHelper, standardAddOption } = props;
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			<div>
				{dashboard && <Dashboard {...props} onClickHelp={handleExpandClick} />}
				{espelho && <Espelho {...props} onClickHelp={handleExpandClick} />}
				{standardHelper && (
					<StandardHelper {...props} onClickHelp={handleExpandClick} />
				)}
				{standardAddOption && (
					<StandardAddOption {...props} onClickHelp={handleExpandClick} />
				)}
			</div>

			<HelperContent expanded={expanded} />
		</>
	);
};
