import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const initialChart = {
	series: [76, 67, 61, 90],
	options: {
		chart: {
			height: 340,
			type: "radialBar",
		},
		plotOptions: {
			radialBar: {
				offsetY: 0,
				startAngle: 0,
				endAngle: 270,
				hollow: {
					margin: 5,
					size: "30%",
					background: "transparent",
					image: undefined,
				},
				track: {
					strokeColor: "#000000",
					strokeWidth: "90%",
					margin: 2, // margin is in pixels
					dropShadow: {
						enabled: true,
						top: 2,
						left: 0,
						color: "#c0c0c0",
						opacity: 1,
						blur: 1,
					},
				},
				stroke: {
					show: true,
					width: 0,
					colors: ["#000"],
				},
				dataLabels: {
					name: {
						show: true,
					},
					value: {
						show: false,
					},
				},
			},
		},
		// stroke: {
		// 	show: true,
		// 	width: 1,
		// 	// colors: ["#000"],
		// },
		fill: {
			type: "solid",
			// type: "gradient",
			// gradient: {
			// 	shade: "light",
			// 	type: "horizontal",
			// 	shadeIntensity: 0.25,
			// 	// gradientToColors: undefined,
			// 	inverseColors: true,
			// 	opacityFrom: 0.85,
			// 	opacityTo: 0.85,
			// 	stops: [50, 0, 100],
			// },
		},
		colors: ["", "", "", "", "", "", ""],
		labels: ["", "", "", "", "", "", ""],
		legend: {
			show: true,
			floating: true,
			fontSize: "11px",
			position: "left",
			offsetX: 0,
			offsetY: 0,
			labels: {
				useSeriesColors: true,
			},
			markers: {
				size: 0,
				width: 10,
				height: 10,
			},
			// formatter: function (seriesName, opts) {
			// 	return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
			// },
			itemMargin: {
				vertical: 0,
			},
		},
		responsive: [
			{
				breakpoint: 480,
				options: {
					legend: {
						show: true,
					},
				},
			},
		],
	},
};

export default ({ data }) => {
	const [chart, setChart] = useState(initialChart);

	useEffect(() => {
		const max_series = data[0].value;
		const series = data.map((value) =>
			parseInt((value.value / max_series) * 100)
		);
		const colors = data.map((value) => value.color);
		const labels = data.map((value) => value.label + ": " + value.value);

		// const colors = data.map(
		// 	(value) =>
		// 		// value.color === "#ffffff" ? "#f0f0f0" : value.color
		// 		value.color
		// );
		// const series = data.map((value) => value.value);
		// const labels = data.map((value) => value.label);

		setChart({
			...initialChart,
			series,
			options: {
				...initialChart.options,
				colors,
				labels,
			},
		});
	}, [data]);

	return (
		<div>
			<div id="chart">
				{chart && (
					<Chart
						options={chart.options}
						series={chart.series}
						type="radialBar"
						height={340}
					/>
				)}
			</div>
		</div>
	);
};
