import { hideLoading, showLoading } from "components/Loader";
import { call, put, takeEvery } from "redux-saga/effects";
import { API } from "utils/API";

import { history } from "../../App";
import {
	GETDASHBOARD,
	GETDASHBOARDSAGA,
	GETFASEDIALOGO,
	GETFASEDIALOGOSAGA,
	GETMOREPAINELOPORTUNIDADE,
	GETMOREPAINELOPORTUNIDADESAGA,
	GETPAINELOPORTUNIDADE,
	GETPAINELOPORTUNIDADESAGA,
	OPENDIALOGOPAINELVENDA,
	OPENDIALOGOPAINELVENDASAGA,
} from "./DashboardType";

//import { OPENDIALOGOIMOVELVENDA } from "redux/Immobile/ImmobileType";

function* getDashboard() {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "dashboard");

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETDASHBOARD, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getPainelOportunidade({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "painel_oportunidade", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];
		if (response.data.message && response.data.message === "error")
			payload = [];

		yield put({ type: GETPAINELOPORTUNIDADE, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getMorePainelOportunidade({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "painel_oportunidade", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: GETMOREPAINELOPORTUNIDADE, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}
function* openDialogPainelVenda() {
	yield put({ type: OPENDIALOGOPAINELVENDA });
}

function* getFaseDialogo(action) {
	yield put(showLoading());
	try {
		const response = yield call(API.get, `fasedialogo`, {
			params: {
				...action.payload.cod_opor_fase,
			},
		});

		console.log("dashboardSaga", response);

		yield put({ type: GETFASEDIALOGO, payload: response.data.result });
		yield put(hideLoading());
	} catch (error) {
		console.log("response-error", error);
		yield put(hideLoading());
	}
}

export default function* DashboardSaga() {
	yield takeEvery(GETDASHBOARDSAGA, getDashboard);
	yield takeEvery(GETPAINELOPORTUNIDADESAGA, getPainelOportunidade);
	yield takeEvery(GETMOREPAINELOPORTUNIDADESAGA, getMorePainelOportunidade);
	yield takeEvery(OPENDIALOGOPAINELVENDASAGA, openDialogPainelVenda);
	yield takeEvery(GETFASEDIALOGOSAGA, getFaseDialogo);
}
