export const GETDIALOGOIMOVELVENDASAGA = "GETDIALOGOIMOVELVENDASAGA";

export const GETDIALOGOIMOVELVENDA = "GETDIALOGOIMOVELVENDA";
export const OPENDIALOGOIMOVELVENDA = "OPENDIALOGOIMOVELVENDA";
export const SETEXPANDEDIMOVEL = "SETEXPANDEDIMOVEL";
export const CHANGEFORMPLANOPAGAMENTO = "CHANGEFORMPLANOPAGAMENTO";
export const CHANGEFORMCALCULO = "CHANGEFORMCALCULO";
export const ERRORSFORMCALCULO = "ERRORSFORMCALCULO";
export const SETLISTCALCULO = "SETLISTCALCULO";
export const NEWCALCULO = "NEWCALCULO";
export const REMOVECALCULO = "REMOVECALCULO";
