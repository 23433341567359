import {
	CLOSEMODALRESPONSEAPI,
	OPENMODALRESPONSEAPI,
} from "./ModalResponseApiType";

const INITIAL_STATE = {
	isOpen: false,
	title: "",
	body: "",
	type: "",
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case OPENMODALRESPONSEAPI:
			return {
				...state,
				isOpen: true,
				title: action.payload.title,
				body: action.payload.body,
				type: action.payload.type,
			};
		case CLOSEMODALRESPONSEAPI:
			return {
				...state,
				isOpen: false,
			};

		default:
			return state;
	}
};
