// Sagas
export const GETDASHBOARDSAGA = "GETDASHBOARDSAGA";
export const GETPAINELOPORTUNIDADESAGA = "GETPAINELOPORTUNIDADESAGA";
export const GETMOREPAINELOPORTUNIDADESAGA = "GETMOREPAINELOPORTUNIDADESAGA";

// Reducer
export const GETDASHBOARD = "GETDASHBOARD";
export const GETPAINELOPORTUNIDADE = "GETPAINELOPORTUNIDADE";
export const GETMOREPAINELOPORTUNIDADE = "GETMOREPAINELOPORTUNIDADE";
export const CHANGETIPO = "CHANGETIPO";

export const OPENDIALOGOPAINELVENDA = "OPENDIALOGOPAINELVENDA";
export const OPENDIALOGOPAINELVENDASAGA = "OPENDIALOGOPAINELVENDASAGA";

export const GETFASEDIALOGO = "GETFASEDIALOGO";
export const GETFASEDIALOGOSAGA = "GETFASEDIALOGOGETFASEDIALOGO";
