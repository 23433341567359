import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
	getFases,
	getOportunidadeByID,
} from "redux/Negotiation/NegotiationAction";

import Loader from "components/Loader";

import Titulo from "./Register/titulo";
import Cadastro from "./Register";

import "./style.css";

function OpportunityRegister(props) {
	const { id } = props.match.params;
	const { dispatch } = props;

	useEffect(() => {
		dispatch(getFases());
		dispatch(getOportunidadeByID(id));
	}, [dispatch, id]);

	return (
		<>
			<Loader {...props} />

			<Titulo {...props} />

			<div className="container-elevation opportunity-container">
				{props.store.oportunidade.getOportunidade.cod_opor && (
					<Cadastro {...props} textInfoShow={true} />
				)}
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({ store: state });
export default connect(mapStateToProps)(OpportunityRegister);
