import "./style.css";

import { Dialog, DialogActions, DialogTitle, Slide } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import { getPlanoPagamento } from "redux/Allotment/AllotmentAction";
import { getDialogoImovelVenda } from "redux/Immobile/ImmobileAction";
import { openDialogOportunidade } from "redux/Negotiation/NegotiationAction";

import IncluirValores from "./incluir-valores";
import VisualizarValores from "./visualizar-valores";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
	const { show, tipo, inclusao } = props.store.oportunidade.dialogOportunidade;
	const { opor_imovel, getOrcamentoByEtapa, dispatch } = props;

	useEffect(() => {
		dispatch(
			getPlanoPagamento({ cod_empreendimento: opor_imovel.cod_empreendimento })
		);
		dispatch(getDialogoImovelVenda(opor_imovel.cod_imovel, false));
	}, [opor_imovel, dispatch, getOrcamentoByEtapa]);

	const handleClose = () => {
		dispatch(openDialogOportunidade({ show: false }));
	};

	const renderType = () => {
		switch (tipo) {
			case "CONDICOES DE PAGAMENTO":
				if (inclusao) return <IncluirValores {...props} />;
				else return <VisualizarValores {...props} />;
			default:
				return null;
		}
	};

	return (
		<>
			<Dialog
				open={show}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
			>
				<div className="bg-gray--light">
					<DialogTitle className="dialog-header">
						<DialogActions
							className="dialog-header__btn-close-container"
							onClick={handleClose}
						>
							<Close className="icon__close" />
						</DialogActions>

						<span className="dialog-header__title">{tipo}</span>
					</DialogTitle>

					{renderType()}
				</div>
			</Dialog>
		</>
	);
};
