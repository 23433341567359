import { put, takeEvery } from "redux-saga/effects";

import {
	SHOWALERTALTERTERM,
	SHOWALERTCONFIRM,
	SHOWALERTQUESTION,
	SHOWALERTQUESTIONCONTATO,
	SHOWALERTQUESTIONREASON,
	//SHOWALERT,
	SHOWTOAST,
} from "../constants";
import {
	SHOWALERTALTERTERMSAGA,
	SHOWALERTCONFIRMSAGA,
	SHOWALERTQUESTIONCONTATOSAGA,
	SHOWALERTQUESTIONREASONSAGA,
	SHOWALERTQUESTIONSAGA,
	SHOWALERTSAGA,
	SHOWTOASTSAGA,
} from "../constants";

function* setShowAlertDialog(action) {
	yield put({ type: SHOWALERTSAGA, payload: action.payload });
}

function* setShowToast(action) {
	yield put({ type: SHOWTOAST, payload: action.payload });
}

function* setShowAlertConfirm(action) {
	console.log("action setshowalertconfirm", action);
	yield put({ type: SHOWALERTCONFIRM, payload: action.payload });
}

function* setShowAlertQuestion(action) {
	yield put({ type: SHOWALERTQUESTION, payload: action.payload });
}

function* setShowAlertQuestionContato(action) {
	yield put({ type: SHOWALERTQUESTIONCONTATO, payload: action.payload });
}

function* setShowAlertQuestionReason(action) {
	yield put({ type: SHOWALERTQUESTIONREASON, payload: action.payload });
}

function* setShowAlertAlterTerm(action) {
	yield put({ type: SHOWALERTALTERTERM, payload: action.payload });
}

export default function* GlobalSaga() {
	yield takeEvery(SHOWALERTSAGA, setShowAlertDialog);
	yield takeEvery(SHOWTOASTSAGA, setShowToast);
	yield takeEvery(SHOWALERTCONFIRMSAGA, setShowAlertConfirm);
	yield takeEvery(SHOWALERTQUESTIONSAGA, setShowAlertQuestion);
	yield takeEvery(SHOWALERTQUESTIONCONTATOSAGA, setShowAlertQuestionContato);
	yield takeEvery(SHOWALERTQUESTIONREASONSAGA, setShowAlertQuestionReason);
	yield takeEvery(SHOWALERTALTERTERMSAGA, setShowAlertAlterTerm);
}
