import { call, put, takeEvery } from "redux-saga/effects";

import { history } from "../../App";
import { hideLoading, showLoading } from "../../components/Loader";
import { API } from "../../utils/API";
import { openModalResponseApiSaga } from "../ModalResponseApi/ModalResponseApiAction";
import {
	EDITCOMPANY,
	EDITCOMPANYSAGA,
	GETCOMPANY,
	GETCOMPANYSAGA,
	GETRESPONSIBLES,
	GETRESPONSIBLESSAGA,
} from "./CompanyType";

function* getCompany() {
	yield put(showLoading());
	try {
		const response = yield call(API.get, `configuracao/empresa`);
		let [payload] = response.data.result;
		if (payload === "Não possui dados") payload = {};

		yield put({ type: GETCOMPANY, payload });
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* editCompany(data) {
	const { payload: company } = data;

	yield put(showLoading());
	delete company.data;
	delete company["usuario_dt"];

	try {
		const response = yield call(
			API.put,
			`configuracao/empresa/${company.cod_empresa}`,
			data.payload
		);
		console.log("response", response);

		yield put(
			openModalResponseApiSaga({
				title: response.data.result.message,
				body: response.data.result.result,
				type: response.data.result.message,
			})
		);
		if (response.data.result.message !== "success") {
			yield put(hideLoading());
			yield put(
				openModalResponseApiSaga({
					title: response.data.result.result,
					body: response.data.result.errors,
					type: response.data.result.result,
				})
			);
			return;
		}

		yield put({ type: EDITCOMPANY, payload: response.data.result.data });
		yield put(hideLoading());
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}
function* getResponsibles() {
	let resposta = null;
	var cont = 1;
	var dados = [];

	yield put(showLoading());
	while (resposta !== "Não possui dados") {
		try {
			const { data } = yield call(API.get, `pessoa`, {
				params: { page: cont },
			});

			cont++;

			//REMOVER QUANDO FOR PARA PRODUCAO
			console.log(cont);

			if (data.result !== "Não possui dados") {
				dados = [...dados, ...data.result];
			} else {
				resposta = "Não possui dados";
			}
		} catch (error) {
			console.log(error);
		}
	}
	yield put(hideLoading());
	yield put({ type: GETRESPONSIBLES, payload: dados });
}

export default function* CompanySaga() {
	yield takeEvery(GETCOMPANYSAGA, getCompany);
	yield takeEvery(EDITCOMPANYSAGA, editCompany);
	yield takeEvery(GETRESPONSIBLESSAGA, getResponsibles);
}
