import { history } from "../../App";
import { hideLoading, showLoading } from "../../components/Loader";
import { API } from "../../utils/API";
import { generateOrcamento } from "../../utils/calculo-plano-pagamento";
import {
	ADDOPORTUNIDADESAGA,
	GETASSINATURA,
	GETCORRETORESSAGA,
	GETFASESSAGA,
	GETLEADSSAGA,
	GETMORELEADSSAGA,
	GETOPORTUNIDADESAGA,
	GETORCAMENTOBYETAPA,
	GETPROSPECCAOSAGA,
	OPENDIALOGOASSINATURA,
	OPENDIALOGOPORTUNIDADE,
	RESETLEADS,
	SETETAPAACAO,
	SETSELECTEDPESSOA,
} from "./NegotiationType";

export function getCorretores() {
	return { type: GETCORRETORESSAGA, params: { cod_funcao: 3 } };
	// return (dispatch) => {
	// 	dispatch(showLoading());
	// 	API.get(`usuario`, { params: { cod_funcao: 3 } })
	// 		.then((response) => {
	// 			let payload = response.data.result;
	// 			if (payload === "Não possui dados") payload = [];
	// 			dispatch({ type: GETCORRETORES, payload });
	// 			dispatch(hideLoading());
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response);
	// 			history.push("/erro", { state: { erro: error.response.data } });
	// 			dispatch(hideLoading());
	// 		});
	// };
}

export function getProspeccao() {
	return { type: GETPROSPECCAOSAGA };
	// return (dispatch) => {
	// 	dispatch(showLoading());
	// 	API.get(`prospeccao`)
	// 		.then((response) => {
	// 			let payload = response.data.result;
	// 			if (payload === "Não possui dados") payload = [];
	// 			dispatch({ type: GETPROSPECCAO, payload });
	// 			dispatch(hideLoading());
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response);
	// 			history.push("/erro", { state: { erro: error.response.data } });
	// 			dispatch(hideLoading());
	// 		});
	// };
}

export function getLead(params) {
	return { type: GETLEADSSAGA, params };
	// return (dispatch) => {
	// 	dispatch(showLoading());
	// 	API.get(`lead`, { params })
	// 		.then((response) => {
	// 			let payload = response.data.result;
	// 			if (payload === "Não possui dados") payload = [];
	// 			dispatch({ type: GETLEADS, payload });
	// 			dispatch(hideLoading());
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response);
	// 			history.push("/erro", { state: { erro: error.response.data } });
	// 			dispatch(hideLoading());
	// 		});
	// };
}

export function getMoreLead(params) {
	return { type: GETMORELEADSSAGA, params };
	// return (dispatch) => {
	// 	dispatch(showLoading());
	// 	API.get(`lead`, { params })
	// 		.then((response) => {
	// 			let payload = response.data.result;
	// 			if (payload === "Não possui dados") payload = [];
	// 			dispatch({ type: GETMORELEADS, payload });
	// 			dispatch(hideLoading());
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response);
	// 			history.push("/erro", { state: { erro: error.response.data } });
	// 			dispatch(hideLoading());
	// 		});
	// };
}

export function resetLead() {
	let payload = [];
	return { type: RESETLEADS, payload };
}

export function setSelectedPessoa(payload) {
	return { type: SETSELECTEDPESSOA, payload };
}

export function getOportunidadeByID(cod_opor) {
	return { type: GETOPORTUNIDADESAGA, params: { cod_opor } };
	// return (dispatch) => {
	// 	dispatch(showLoading());
	// 	API.get(`oportunidade`, { params: { cod_opor } })
	// 		.then((response) => {
	// 			let payload = response.data.result;

	// 			// if (payload.message !== "success") {
	// 			// 	dispatch(hideLoading());
	// 			// 	return;
	// 			// }
	// 			console.log(payload);

	// 			dispatch({ type: GETOPORTUNIDADE, payload });
	// 			dispatch(hideLoading());
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response);
	// 			history.push("/erro", { state: { erro: error.response.data } });
	// 			dispatch(hideLoading());
	// 		});
	// };
}

export function addOportunidade(data) {
	return { type: ADDOPORTUNIDADESAGA, data };
	// return (dispatch) => {
	// 	dispatch(showLoading());
	// 	API.post(`oportunidade`, JSON.stringify(data))
	// 		.then((response) => {
	// 			let payload = response.data.result;

	// 			// if (payload.message !== "success") {
	// 			// 	dispatch(hideLoading());
	// 			// 	return;
	// 			// }
	// 			console.log(payload);

	// 			dispatch({ type: GETOPORTUNIDADE, payload });
	// 			dispatch(hideLoading());
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response);
	// 			history.push("/erro", { state: { erro: error.response.data } });
	// 			dispatch(hideLoading());
	// 		});
	// };
}

export function getFases() {
	return { type: GETFASESSAGA };
	// return (dispatch) => {
	// 	dispatch(showLoading());
	// 	API.get(`fase`)
	// 		.then((response) => {
	// 			let payload = response.data.result;
	// 			dispatch({ type: GETFASES, payload });
	// 			dispatch(hideLoading());
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response);
	// 			history.push("/erro", { state: { erro: error.response.data } });
	// 			dispatch(hideLoading());
	// 		});
	// };
}

export function setEtapaAcao(data) {
	return (dispatch) => {
		dispatch(showLoading());
		API.post(`etapa_acao`, JSON.stringify(data))
			.then((response) => {
				let payload = response.data.result;

				dispatch({ type: SETETAPAACAO, payload });
				dispatch(hideLoading());
			})
			.catch((error) => {
				console.log(error.response);
				history.push("/erro", { state: { erro: error.response.data } });
				dispatch(hideLoading());
			});
	};
}

export function setEtapaAcaoOrcamento(
	listOrcamento,
	oportunidade,
	imovel,
	proposta,
	etapa
) {
	const newProposta = {
		acao: etapa.acao,
		cod_opor_fase_etapa: etapa.cod_opor_fase_etapa,
		orcamento: generateOrcamento(listOrcamento, oportunidade, imovel, proposta),
	};

	return (dispatch) => {
		dispatch(showLoading());
		API.post(`etapa_acao`, JSON.stringify(newProposta))
			.then((response) => {
				let payload = response.data.result;

				dispatch({ type: SETETAPAACAO, payload });
				dispatch(hideLoading());
			})
			.catch((error) => {
				console.log(error.response);
				history.push("/erro", { state: { erro: error.response.data } });
				dispatch(hideLoading());
			});
	};
}

export function openDialogAssinatura(payload) {
	return (dispatch) => {
		dispatch({ type: OPENDIALOGOASSINATURA, payload });
	};
}

export function openDialogOportunidade(payload) {
	return (dispatch) => {
		dispatch({ type: OPENDIALOGOPORTUNIDADE, payload });
	};
}

export function getAssinaturas(params) {
	return (dispatch) => {
		dispatch(showLoading());
		API.get(`minuta_assinaturas_etapa`, { params })
			.then((response) => {
				let payload = response.data.result;

				if (payload === "Não possui dados") payload = [];
				dispatch({ type: GETASSINATURA, payload });
				dispatch(hideLoading());
			})
			.catch((error) => {
				console.log(error.response);
				history.push("/erro", { state: { erro: error.response.data } });
				dispatch(hideLoading());
			});
	};
}

export function getOrcamentoByEtapa(value) {
	return (dispatch) => {
		dispatch(showLoading());
		API.get(`etapa_orcamentos`, {
			params: {
				cod_opor_fase_etapa: value.cod_opor_fase_etapa,
			},
		})
			.then((response) => {
				let payload = response.data.result;

				dispatch({
					type: GETORCAMENTOBYETAPA,
					payload: {
						data: payload,
						value,
					},
				});
				dispatch(hideLoading());
			})
			.catch((error) => {
				console.log(error.response);
				history.push("/erro", { state: { erro: error.response.data } });
				dispatch(hideLoading());
			});
	};
}
