// Sagas
export const GETALLCONTATOSSAGA = "GETALLCONTATOSSAGA";
export const CURRENTCONTATOSAGA = "CURRENTCONTATOSAGA";
export const ADDCONTATOSAGA = "ADDCONTATOSAGA";
export const EDITCONTATOSAGA = "EDITCONTATOSAGA";
export const DELETECONTATOSAGA = "DELETECONTATOSAGA";
export const SEARCHCONTATOSSAGA = "SEARCHCONTATOSSAGA";

// Reducer
export const GETALLCONTATOS = "GETALLCONTATOS";
export const CURRENTCONTATO = "CURRENTCONTATO";
export const ADDCONTATO = "ADDCONTATO";
export const EDITCONTATO = "EDITCONTATO";
export const DELETECONTATO = "DELETECONTATO";
export const SHOWDIALOG = "SHOWDIALOG";
export const CHANGEFORMCONTATO = "CHANGEFORMCONTATO";
export const ERRORSFORMCONTATO = "ERRORSFORMCONTATO";
export const SEARCHCONTATOS = "SEARCHCONTATOS";
export const CHANGESEARCH = "CHANGESEARCH";
