import React from "react";

import { ContainerElevation, ButtonStep, ButtonStepOutlined } from "./styles";

import GridOwners from "pages/EnterpriseRegister/GridOwners";
import GridBrokers from "pages/EnterpriseRegister/GridBrokers";
import GridPlans from "pages/EnterpriseRegister/GridPlans";
import Form from "pages/EnterpriseRegister/Form";
import ProposalTemplates from "pages/EnterpriseRegister/ProposalTemplates";

import { Paper } from "@material-ui/core";
import Titulo from "../../components/titulo";
import { history } from "../../App";

function EnterpriseEdit(props) {
	const handleClickBack = () => {
		history.push("/empreendimentosgi");
	};

	return (
		<>
			<Titulo
				{...props}
				title="Editar - Empreendimento"
				standardHelper
				back
				onClickBack={handleClickBack}
			/>

			<ContainerElevation>
				<Paper style={{ marginBottom: "35px" }}>
					<Form />
				</Paper>

				<Paper style={{ marginBottom: "25px" }}>
					<GridOwners />
				</Paper>

				<Paper style={{ marginBottom: "25px" }}>
					<GridPlans />
				</Paper>

				<Paper style={{ marginBottom: "32px" }}>
					<ProposalTemplates />
				</Paper>

				<Paper style={{ marginBottom: "0px" }}>
					<GridBrokers />
				</Paper>

				<div className="end">
					<ButtonStepOutlined>Cancelar</ButtonStepOutlined>
					<ButtonStep className="btn__blue">Salvar</ButtonStep>
				</div>
			</ContainerElevation>
		</>
	);
}

export default EnterpriseEdit;
