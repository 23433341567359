import { hideLoading, showLoading } from "components/Loader";
import { call, put, takeEvery } from "redux-saga/effects";
import { API } from "utils/API";

import { history } from "../../App";
import {
	ADDOPORTUNIDADESAGA,
	GETCORRETORES,
	GETCORRETORESSAGA,
	GETFASES,
	GETFASESSAGA,
	GETLEADS,
	GETLEADSSAGA,
	GETMORELEADS,
	GETMORELEADSSAGA,
	GETOPORTUNIDADE,
	GETOPORTUNIDADESAGA,
	GETPROSPECCAO,
	GETPROSPECCAOSAGA,
} from "./NegotiationType";

function* getFases() {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "fase");

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETFASES, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getOportunidadeByID({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "oportunidade", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETOPORTUNIDADE, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getLead({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "lead", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETLEADS, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getMoreLead({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "lead", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETMORELEADS, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getCorretores({ params }) {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "usuario", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETCORRETORES, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* getProspeccao() {
	try {
		yield put(showLoading());

		const response = yield call(API.get, "prospeccao");

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = [];

		yield put({ type: GETPROSPECCAO, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* addOportunidade({ data }) {
	try {
		yield put(showLoading());

		const response = yield call(API.post, "oportunidade", JSON.stringify(data));

		let payload = response.data.result;

		// if (payload.message !== "success") {
		// 	dispatch(hideLoading());
		// 	return;
		// }

		yield put({ type: GETOPORTUNIDADE, payload });
		yield put(hideLoading());

		history.push(`/oportunidade/register/${payload.cod_opor}`);
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

export default function* NegotiationSaga() {
	yield takeEvery(GETFASESSAGA, getFases);
	yield takeEvery(GETOPORTUNIDADESAGA, getOportunidadeByID);
	yield takeEvery(GETLEADSSAGA, getLead);
	yield takeEvery(GETMORELEADSSAGA, getMoreLead);
	yield takeEvery(GETCORRETORESSAGA, getCorretores);
	yield takeEvery(GETPROSPECCAOSAGA, getProspeccao);
	yield takeEvery(ADDOPORTUNIDADESAGA, addOportunidade);
}
