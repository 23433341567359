import {
	Button,
	Dialog,
	IconButton,
	Typography,
	withStyles,
} from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { connect } from "react-redux";

import { history } from "../../../App";
import { closeModalResponseApiSaga } from "../../../redux/ModalResponseApi/ModalResponseApiAction";

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

function ModalResponseApi({ modal, dispatch }) {
	const handleClose = () => {
		dispatch(closeModalResponseApiSaga());
		if (modal.type !== "error") {
			history.push("/");
		}
	};

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={modal.isOpen}
			>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					{modal.title}
				</DialogTitle>
				<DialogContent dividers>
					<Typography gutterBottom>
						{modal.body || "Not have message"}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color="primary">
						Voltar para Home
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
export default connect((store) => ({ modal: store.modalResponseApi }))(
	ModalResponseApi
);
