import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setShowAlertDialog } from "../../components/global/action";
import AlertDelete from "../../components/global/AlertDelete";
import Loader from "../../components/Loader";
import Grid from "./Grid";
import Titulo from "./Title";

class Pessoa extends Component {
	// componentDidMount() {
	// 	this.props.getAllContatos();
	// }

	render() {
		const { global } = this.props.store;

		const handleClickOpen = (value) => {
			// this.props.setShowDialog(true);
		};

		const handleClickConfirmDelete = (value) => {
			this.props.setShowAlertDialog({
				show: true,
				value: "",
			});
		};

		const handleClickDelete = () => {
			this.props.deleteContato(global.valueToDelete);

			this.props.setShowAlertDialog({
				show: false,
				value: 0,
			});
		};

		return (
			<>
				<Loader {...this.props} />
				<div>
					<Titulo {...this.props} />

					<Grid
						{...this.props}
						handleClickEdit={handleClickOpen}
						handleClickConfirmDelete={handleClickConfirmDelete}
					/>

					<AlertDelete {...this.props} handleClickDelete={handleClickDelete} />
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { store: state };
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ setShowAlertDialog }, dispatch);

export default Pessoa = connect(mapStateToProps, mapDispatchToProps)(Pessoa);
