import { GETALLFILTERS, GETALLSELECTS, SETFILTERS } from "./SelectsType";

const INITIAL_STATE = {
	empreendimento: [],
	tipo: [],
	estado: [],
	situacao: [],
	filters: [],
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GETALLSELECTS:
			return {
				...state,
				...action.payload,
			};
		case SETFILTERS:
			return {
				...state,
				filters: action.payload,
			};
		case GETALLFILTERS:
			return state;
		default:
			return state;
	}
}
