import { GETPESSOABYCPF } from "../Person/PersonType";
import {
	ADDUSUARIO,
	EDITUSUARIO,
	ERROUSUARIO,
	GETFUNCAOUSUARIO,
	GETUSUARIOS,
	SELECTEDUSUARIO,
} from "./UserType";

const INITIAL_STATE = {
	allUsuarios: [],
	pessoa: {},
	funcoes: [],
	resposta: {},
	currentUser: {},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GETUSUARIOS:
			console.log(action);
			return {
				...state,
				allUsuarios: action.payload,
			};
		case GETPESSOABYCPF:
			return {
				...state,
				pessoa: action.payload,
			};
		case ADDUSUARIO:
			return {
				...state,
				resposta: action.payload,
			};
		case EDITUSUARIO:
			return {
				...state,
				resposta: action.payload,
			};
		case GETFUNCAOUSUARIO:
			return {
				...state,
				funcoes: action.payload,
			};
		case ERROUSUARIO:
			return {
				...state,
				resposta: action.payload,
			};
		case SELECTEDUSUARIO:
			return {
				...state,
				currentUser: action.payload,
			};
		default:
			return state;
	}
};
