import {
	ADDCONTATOSAGA,
	CHANGEFORMCONTATO,
	CHANGESEARCH,
	CURRENTCONTATOSAGA,
	DELETECONTATOSAGA,
	EDITCONTATOSAGA,
	ERRORSFORMCONTATO,
	GETALLCONTATOSSAGA,
	SEARCHCONTATOSSAGA,
	SHOWDIALOG,
} from "./ContactType";

export function getAllContatos() {
	return { type: GETALLCONTATOSSAGA };
}

export function addContato(data) {
	return { type: ADDCONTATOSAGA, data };
}

export function editContato(data) {
	return { type: EDITCONTATOSAGA, data };
}

export function deleteContato(id) {
	return { type: DELETECONTATOSAGA, id };
}

export function setShowDialog(value) {
	return { type: SHOWDIALOG, payload: value };
}

export function setCurrentContato(params) {
	return { type: CURRENTCONTATOSAGA, params };
}

export function handleChangeForm(value) {
	return { type: CHANGEFORMCONTATO, payload: value };
}

export function validateForm(value) {
	return { type: ERRORSFORMCONTATO, payload: value };
}

export function handleChangeSearch(value) {
	return { type: CHANGESEARCH, payload: value };
}

export function searchContatos(params) {
	return { type: SEARCHCONTATOSSAGA, params };
}
