import React from "react";

import {
	InputBase,
	Icon,
	Paper,
	Button,
	Collapse,
	CardContent,
	Typography,
} from "@material-ui/core";

import Titulo from "../../components/titulo";
import TreeView from "../../components/TreeView";
import HelpOutline from "@material-ui/icons/HelpOutline";

import "./style.css";

function Report(props) {
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			<Titulo {...props} title="Relatórios" standardHelper />

			<Paper className="container-elevation">
				<div className="report__container">
					<Paper className="report__side-bar">
						<div className="report__search">
							<div className="report__icon">
								<Icon>search</Icon>
							</div>
							<InputBase
								className="font-input-black"
								placeholder="Buscar…"
								// onChange={handleOnChange}
								inputProps={{ "aria-label": "search" }}
							/>
						</div>

						<Button className="btn__blue--outlined report__btn">
							BUSCA AVANÇADA
						</Button>

						<TreeView />
					</Paper>

					<div className="report__doc-show">
						<Paper className="report__header">
							<h3 className="report__title blue--light font-secondary">
								Vendas - [0031]
							</h3>
							<Button
								className="report__help"
								onClick={handleExpandClick}
								aria-expanded={expanded}
								aria-label="show more"
							>
								<HelpOutline className="icon__help blue--light" />
							</Button>
						</Paper>

						<Collapse
							in={expanded}
							timeout="auto"
							unmountOnExit
							className="report__intro"
						>
							<CardContent>
								<Typography paragraph>Method:</Typography>
								<Typography paragraph>
									O incentivo ao avanço tecnológico, assim como o aumento do
									diálogo entre os diferentes setores produtivos ainda não
									demonstrou convincentemente que vai participar na mudança das
									novas proposições.
								</Typography>
							</CardContent>
						</Collapse>

						<Paper className="report__wrapper">
							<div className="report__report-show"></div>
						</Paper>
					</div>
				</div>
			</Paper>
		</>
	);
}

export default Report;
