import "./style.css";

import {
	Button,
	CardActions,
	CardContent,
	Collapse,
	Typography,
} from "@material-ui/core";
import HelpOutline from "@material-ui/icons/HelpOutline";
import React from "react";

export default (props) => {
	const { oportunidade } = props.store;
	const dados = oportunidade.getOportunidade;
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<div>
			<CardActions disableSpacing className="header-bottom--simple">
				<div>
					{dados.cod_opor && (
						<h2 className="header__title">{`Negociação Nº${dados.cod_opor}`}</h2>
					)}
				</div>

				<div className="header__container">
					<Button
						className="focus"
						id="header__button-help"
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<HelpOutline className="header__icon-help" />
					</Button>
				</div>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit className="intro">
				<CardContent>
					<Typography paragraph>Method:</Typography>
					<Typography paragraph>
						O incentivo ao avanço tecnológico, assim como o aumento do diálogo
						entre os diferentes setores produtivos ainda não demonstrou
						convincentemente que vai participar na mudança das novas
						proposições.
					</Typography>
				</CardContent>
			</Collapse>
		</div>
	);
};
