const setOrcamentoParcelas = (value) => {
	let array = [];

	// Plano a vista
	if (value.plano.planovenda_entrada_porc === 100) {
		array.push({
			ordem: 1,
			tipo: "SINAL",
			saldo_calcular: 0,
			saldo_restante: 0,
			valor: value.totalEntrada,
			taxa: value.plano.planovenda_entrada_reajuste,
			quantidade: 1,
		});
	} else {
		if (value.entradaSinal) {
			array.push({
				ordem: 1,
				tipo: "SINAL",
				saldo_calcular: 0,
				saldo_restante: 0,
				valor: value.entradaSinal,
				taxa: 0,
				quantidade: 1,
			});
		}

		if (value.entradaQtde > 0) {
			array.push({
				ordem: 2,
				tipo: "ENTRADA",
				saldo_calcular: 0,
				saldo_restante: 0,
				valor: value.entradaParcelada,
				taxa: value.plano.planovenda_entrada_reajuste,
				quantidade: value.entradaQtde,
			});
		}

		if (value.parcelaQtde > 0) {
			array.push({
				ordem: 3,
				tipo: "PARCELA",
				saldo_calcular: 0,
				saldo_restante: 0,
				valor: value.parcelaValor,
				taxa: value.plano.planovenda_parcela_reajuste,
				quantidade: value.parcelaQtde,
			});
		}
	}

	return array;
};

const generateOrcamento = (listOrcamento, contato, imovel, proposta) => {
	let newOrcamento = {};
	let planos = [];

	listOrcamento.forEach((value, index) => {
		let parcelas = [];
		parcelas.push(...setOrcamentoParcelas(value));

		planos.push(
			...[
				{
					cod_planovenda: value.plano.cod_planovenda,
					imovel_preco: imovel.preco_total,
					imovel_preco_desconto: 0,
					imovel_preco_final: imovel.preco_total,
					entrada_total: value.totalEntrada,
					entrada_sinal_obrigatorio: value.plano.planovenda_entrada_sinal_obr,
					entrada_sinal_valor: value.entradaSinal,
					entrada_minimo_permitido: value.plano.calc_entrada_minima,
					saldo_restante: value.saldoRestante,
					parcelas,
				},
			]
		);
	});

	newOrcamento = {
		cod_contato: contato.cod_contato,
		cod_imovel: imovel.cod_imovel,
		data_entrada_inicial: proposta ? proposta.data_entrada_inicial : null,
		data_parcela_inicial: proposta ? proposta.data_parcela_inicial : null,
		planos,
	};

	return newOrcamento;
};

export { setOrcamentoParcelas, generateOrcamento };
