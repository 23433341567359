import { GETRELATORIO } from "./ReportType";

const INITIAL_STATE = {
	pdf: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GETRELATORIO:
			return {
				...state,
				pdf: action.payload,
			};
		default:
			return state;
	}
};
