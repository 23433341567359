import "./style.css";

import { Button, Dialog, Slide } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
	const { venda } = props.store;

	const handleClose = () => {
		props.closeFaseDocumentoImagem();
	};

	return (
		<>
			<Dialog
				open={venda.showFaseDocumentoImagem}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				className="vizualize"
			>
				<img
					className="painel__image bg-blue"
					alt="logo"
					src={venda.getFaseDocumentoImagem}
				/>

				<div className="visualize__container bg-gray--light">
					<Button className="btn__blue visualize__btn" onClick={handleClose}>
						<Check className="icon__check visualize__icon" />
						OK
					</Button>
				</div>
			</Dialog>
		</>
	);
};
