import { history } from "../../App";
import { hideLoading, showLoading } from "../../components/Loader";
import { API } from "../../utils/API";
import { generateOrcamento } from "../../utils/calculo-plano-pagamento";
import {
	ADDFASEDOCUMENTOSAGA,
	CHANGEPROPOSTA,
	CLOSEFASEDOCUMENTOIMAGEM,
	CLOSEFASEDOCUMENTOS,
	EDITFASEDOCUMENTO,
	EDITFASEDOCUMENTOSAGA,
	ERRORSFORMDATAPROPOSTA,
	GETFASEDOCUMENTOIMAGEM,
	GETFASEDOCUMENTOIMAGEMSAGA,
	GETFASEDOCUMENTOS,
	GETFASEDOCUMENTOSSAGA,
	GETOPCAOVENDASAGA,
	SETOPCAOVENDA,
	SETORCAMENTOSAGA,
	SETPROPOSTASAGA,
	SETRESERVASAGA,
	SHAREORCAMENTOSAGA,
	SHAREPROPOSTASAGA,
} from "./SaleType";

export function getFaseDocumentos(params) {
	return { type: GETFASEDOCUMENTOSSAGA, payload: params };
}

export function getFaseDocumentoImagem(params) {
	return { type: GETFASEDOCUMENTOIMAGEMSAGA, payload: params };
}

export function addFaseDocumento(data) {
	return { type: ADDFASEDOCUMENTOSAGA, payload: data };
}

export function editFaseDocumento(data) {
	return { type: EDITFASEDOCUMENTOSAGA, payload: data };
}

export function getOpcaoVenda(cod_imovel) {
	console.log("getOpcaoVenda", cod_imovel);
	return { type: GETOPCAOVENDASAGA, params: cod_imovel };
}

export function setOpcaoVenda(opcao) {
	return { type: SETOPCAOVENDA, opcao };
}

// export function getPlanoPagamento(cod_empreendimento) {
// 	console.log(cod_empreendimento);
// 	return {
// 		type: GETPLANOPAGAMENTOSAGA,
// 		cod_empreendimento,
// 	};

// 	// return (dispatch) => {
// 	// 	API.get(`planopagamento`, {
// 	// 		params: {
// 	// 			cod_empreendimento,
// 	// 		},
// 	// 	})
// 	// 		.then((response) => {
// 	// 			let payload = response.data.result;

// 	// 			dispatch({ type: GETPLANOPAGAMENTO, payload });
// 	// 		})
// 	// 		.catch((error) => {
// 	// 			console.log("response-error", error);
// 	// 		});
// 	// };
// }

export function addOrcamento(listOrcamento, contato, imovel) {
	const newOrcamento = generateOrcamento(listOrcamento, contato, imovel);
	return { type: SETORCAMENTOSAGA, data: newOrcamento };
}

export function shareOrcamento(orcamento) {
	const { cod_orcamento } = orcamento;

	let data = {
		nome: "VENDA.ORCAMENTO",
		tipo: "pdf",
		parametros: [{ Name: "cod_orcamento", Value: cod_orcamento }],
	};

	return { type: SHAREORCAMENTOSAGA, data };
}

export function handleChangeProposta(value) {
	return { type: CHANGEPROPOSTA, payload: value };
}

export function closeFaseDocumentoImagem() {
	return { type: CLOSEFASEDOCUMENTOIMAGEM, payload: false };
}

export function closeFaseDocumentos() {
	return { type: CLOSEFASEDOCUMENTOS, payload: false };
}

export function addProposta(
	listOrcamento,
	contato,
	imovel,
	proposta,
	cod_opor
) {
	const data = {
		[cod_opor > 0 ? "cod_opor" : "cod_contato"]:
			cod_opor > 0 ? cod_opor : contato.cod_contato,
		cod_imovel: imovel.cod_imovel,
		orcamento: generateOrcamento(listOrcamento, contato, imovel, proposta),
	};

	console.log(data);

	return { type: SETPROPOSTASAGA, data };

	// return (dispatch) => {
	// 	dispatch(showLoading());
	// 	API.post(`proposta`, JSON.stringify(newProposta))
	// 		.then((response) => {
	// 			let payload = response.data.result;

	// 			dispatch({ type: SETPROPOSTA, payload: payload });
	// 			dispatch(hideLoading());
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response);
	// 			history.push("/erro", { state: { erro: error.response.data } });
	// 			dispatch(hideLoading());
	// 		});
	// };
}

export function addReserva(contato, imovel) {
	const data = {
		cod_contato: contato.cod_contato,
		cod_imovel: imovel.cod_imovel,
	};

	return { type: SETRESERVASAGA, data };
}

export function addReservaNegociacao(imovel, cod_opor) {
	const data = {
		cod_imovel: imovel.cod_imovel,
		cod_opor,
	};

	return { type: SETRESERVASAGA, data };
}

export function validateFormProposta(value) {
	return { type: ERRORSFORMDATAPROPOSTA, payload: value };
}

export function shareProposta(proposta) {
	const { id_relatorio, cod_empreendvenda } = proposta;
	let data = {
		id_relatorio,
		parametros: {
			cod_empreendvenda,
		},
	};

	return { type: SHAREPROPOSTASAGA, data };

	// return (dispatch) => {
	// 	dispatch(showLoading());
	// 	API_REL.post(`relatorio`, JSON.stringify(body))
	// 		.then((response) => {
	// 			const file = new Blob([response.data], { type: "application/pdf" });
	// 			const fileURL = URL.createObjectURL(file);

	// 			dispatch({ type: SHAREPROPOSTA, payload: fileURL });
	// 			dispatch(hideLoading());

	// 			window.open(fileURL);
	// 		})
	// 		.catch((error) => {
	// 			console.log(error.response);
	// 			history.push("/erro", { state: { erro: error.response.data } });
	// 			dispatch(hideLoading());
	// 		});
	// };
}
