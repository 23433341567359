import {
	AppBar,
	Avatar,
	Badge,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Menu,
	MenuItem,
	SwipeableDrawer,
	Toolbar,
} from "@material-ui/core";
import {
	AccountCircle,
	Inbox,
	Mail,
	Menu as MenuIcon,
	More,
	Notifications,
} from "@material-ui/icons";
import { BadgeUserOnline } from "components";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { BASE_URL_WEBSOCKET } from "utils/API";

import YouNectLogo from "../../assets/logos/logo-horizontal.png";
import YouNectLogoSmall from "../../assets/logos/logo-small.png";
import AuthContext from "../../contexts/auth";
let socket;

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	color: {
		background: "#0548A3",
	},
	containerImg: {
		background: "transparent",
		maxWidth: "100%",
		height: "auto",
	},
	img: {
		width: "130px",
		marginTop: "5px",
		opacity: "0.5",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	imgSmall: {
		display: "none",
		[theme.breakpoints.down("xs")]: {
			width: "40px",
			display: "block",
			opacity: "0.5",
		},
	},
	menuButton: {
		marginRight: theme.spacing(1),
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},

	// Classes do menu lateral
	list: {
		width: 250,
	},
	fullList: {
		width: "auto",
	},
}));

export default function ToolBar() {
	const classes = useStyles();
	const [user, setUser] = useState({ foto: "" });
	const { logOut, signed } = useContext(AuthContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
	const [state, setState] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
		menu: [],
	});
	const [menu] = useState([
		{ id: 0, name: "Dashboard", page: "/dashboard" },
		{ id: 1, name: "Contato", page: "/contato" },
		{ id: 2, name: "Empreendimento", page: "/empreendimento" },
		{ id: 3, name: "Pessoa", page: "/pessoa" },
		{ id: 4, name: "Oportunidade", page: "/oportunidade" },
		{ id: 5, name: "Usuario", page: "/usuario" },
		{ id: 6, name: "Relatórios", page: "/report" },
		{ id: 7, name: "Empreendimento SGI", page: "/empreendimentosgi" },
		{ id: 8, name: "Consulta", page: "/query-property" },
		{ id: 9, name: "Configuracao", page: "/configuration" },
	]);

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleLogout = () => {
		logOut();
		handleMenuClose();
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const notificationsSocket = async (empresa) => {
		if (socket) {
			socket.disconnect();
			socket = {};
		}

		socket = await socketIOClient(BASE_URL_WEBSOCKET, {
			transports: ["websocket"],
		});

		socket.on("notificacao", function (data) {
			console.log(data);
			// Bernardo, aqui voce coloca um console, mostrando o data so pra ver se funciona
		});

		socket.on("connect", function () {
			console.log(empresa);
			socket.emit("notificacao_sala", empresa);
			setTimeout(() => {
				console.log("entrou na sala notificacao_sala");
				// Bernardo, aqui voce coloca um console, mostrando que entrou na sala
			}, 400);
		});
	};

	useEffect(() => {
		if (signed) {
			const userLogado = JSON.parse(localStorage.getItem("@YouNectImob:user"));
			setUser(userLogado);

			console.log(userLogado);
			notificationsSocket(userLogado.cod_empresa);
		}
	}, [signed]);

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={handleMenuClose}>Profile</MenuItem>
			<MenuItem onClick={handleMenuClose}>My account</MenuItem>
			<MenuItem onClick={handleLogout}>Logout</MenuItem>
		</Menu>
	);

	const mobileMenuId = "primary-search-account-menu-mobile";
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<IconButton aria-label="show 4 new mails" color="inherit">
					<Badge badgeContent={4} color="secondary">
						<Mail />
					</Badge>
				</IconButton>
				<p>Messages</p>
			</MenuItem>
			<MenuItem>
				<IconButton aria-label="show 11 new notifications" color="inherit">
					<Badge badgeContent={11} color="secondary">
						<Notifications />
					</Badge>
				</IconButton>
				<p>Notifications</p>
			</MenuItem>
			<MenuItem onClick={handleProfileMenuOpen}>
				<IconButton
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p>Profile</p>
			</MenuItem>
		</Menu>
	);

	// Menu lateral
	const toggleDrawer = (side, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [side]: open });
	};

	const sideList = (side) => (
		<div
			className={classes.list}
			role="presentation"
			onClick={toggleDrawer(side, false)}
			onKeyDown={toggleDrawer(side, false)}
		>
			<List>
				{menu.map((value, index) => (
					<ListItem button key={value.id} component={NavLink} to={value.page}>
						<ListItemIcon>
							{index % 2 === 0 ? <Inbox /> : <Mail />}
						</ListItemIcon>
						<ListItemText primary={value.name} />
					</ListItem>
				))}
			</List>
		</div>
	);

	return (
		<div className={classes.grow}>
			<AppBar position="static" elevation={0}>
				<Toolbar className={classes.color}>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer("left", true)}
					>
						<MenuIcon />
					</IconButton>

					<div className={classes.containerImg}>
						<img src={YouNectLogo} alt="logo" className={classes.img} />
						<img
							src={YouNectLogoSmall}
							alt="logo"
							className={classes.imgSmall}
						/>
					</div>
					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<IconButton aria-label="show 4 new mails" color="inherit">
							<Badge badgeContent={4} color="secondary">
								<Mail />
							</Badge>
						</IconButton>
						<IconButton aria-label="show 17 new notifications" color="inherit">
							<Badge badgeContent={17} color="secondary">
								<Notifications />
							</Badge>
						</IconButton>
						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							color="inherit"
						>
							<BadgeUserOnline>
								<Avatar alt="user" src={user.foto || ""} />
							</BadgeUserOnline>
						</IconButton>
					</div>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="inherit"
						>
							<More />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}

			<SwipeableDrawer
				open={state.left}
				onClose={toggleDrawer("left", false)}
				onOpen={toggleDrawer("left", true)}
			>
				{sideList("left")}
			</SwipeableDrawer>
		</div>
	);
}
