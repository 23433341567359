import React from "react";

import { Button, Grid } from "@material-ui/core";
import { Check } from "@material-ui/icons";

export default (props) => {
	const { cod_opor } = props.match.params;

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			justify="space-evenly"
		>
			<Grid item>
				<span>{`Confirma esta reserva para a Negociação # ${cod_opor} ?`}</span>
			</Grid>

			<Grid item>
				<Button
					onClick={props.handleSave}
					className="btn__blue"
					startIcon={<Check />}
				>
					Confirmar
				</Button>
			</Grid>
		</Grid>
	);
};
