import "./style.css";

import React from "react";
import { addOrcamento, shareOrcamento } from "redux/Sale/SaleAction";

import Calculo from "../calculo";
import CalculoLista from "../calculo-lista";
import Compartilhar from "../compartilhar";
import Contato from "../contato";

export default (props) => {
	const { imovel, venda } = props.store;
	const [step, setStep] = React.useState("calculo");

	const handleSetStep = (value) => {
		setStep(value);
	};

	const handleSaveOrcamento = (contato) => {
		props.dispatch(addOrcamento(imovel.listCalculo, contato, imovel.getImovel));
	};

	const handleToShare = () => {
		props.dispatch(shareOrcamento(venda.getOrcamento));
	};

	const renderStep = () => {
		switch (step) {
			case "calculo":
				return renderDefault();
			case "list":
				return (
					<CalculoLista
						{...props}
						setStep={handleSetStep}
						priorStep={"calculo"}
						nextStep={"contato"}
					/>
				);
			case "contato":
				return (
					<Contato
						{...props}
						setStep={handleSetStep}
						priorStep={"list"}
						nextStep={"compartilhar"}
						saveOrcamento={handleSaveOrcamento}
					/>
				);
			case "compartilhar":
				return (
					<Compartilhar
						{...props}
						toShare={handleToShare}
						shareText="Orçamento realizado com sucesso!"
					/>
				);
			default:
				return renderDefault();
		}
	};

	const renderDefault = () => {
		return <Calculo {...props} setStep={handleSetStep} nextStep={"list"} />;
	};

	return <div className="dialog-body__budget">{renderStep()}</div>;
};
