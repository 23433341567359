import React from "react";
import { Redirect } from "react-router-dom";
import { call, put, takeEvery } from "redux-saga/effects";

import { API } from "../../utils/API";
import {
	ADDEMPREENDIMENTOSAGA,
	//PAINELEMPREENDIMENTO,
	//ESPELHOEMPREENDIMENTO,
	MAPAEMPREENDIMENTO,
	//ADDEMPREENDIMENTO,
	MAPAEMPREENDIMENTOSAGA,
} from "../constants";
import { setShowAlertConfirm } from "../Global/GlobalAction";

function* getMapasEmpreendimento(action) {
	try {
		const response = yield call(API.get, `empreendimento`, {
			params: {
				fields: "cod_empreendimento,empreend_nome",
			},
		});

		let mapas = response.data.result;
		if (response.statusText !== "OK") {
			// dispatch(hideLoading())
			console.log("error");
			return;
		}
		yield put({
			type: MAPAEMPREENDIMENTO,
			mapas: mapas,
		});
	} catch (error) {
		console.log(error);
		console.log("error action empreendimento", error.response);
	}
}

function* addEmpreendimento(action) {
	console.log("action in action ", action);
	try {
		const response = yield call(API.post, `imovel/importacao`, action.payload);

		console.log("addEmpreendimento", response);
		console.log("response.data.result.message", response.data.result.message);
		console.log("response.data.result.message", response.data.result.message);

		if (response.data.result.message === "error") {
			yield put(
				setShowAlertConfirm({
					show: true,
					title: response.data.result,
					text: response.data.errors,
					callback: <Redirect to="/upload" />,
					icon: "error",
				})
			);
		}
		if (response.data.result.message === "success") {
			yield put(
				setShowAlertConfirm({
					show: true,
					title: response.data.result.result,
					text: "",
					callback: <Redirect to="/upload" />,
					icon: "check",
				})
			);
			yield console.log("cadastro feito com sucesso");
		}
	} catch (error) {
		console.log("error", error);
	}
}

export default function* EnterpriseSaga() {
	yield takeEvery(MAPAEMPREENDIMENTOSAGA, getMapasEmpreendimento);
	yield takeEvery(ADDEMPREENDIMENTOSAGA, addEmpreendimento);
}
