import "./style.css";

import {
	Button,
	CardContent,
	Collapse,
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Typography,
} from "@material-ui/core";
import {
	BathtubOutlined,
	DirectionsCarOutlined,
	ExpandMore,
	FilterNone,
	KingBedOutlined,
} from "@material-ui/icons";
import React from "react";

import House from "../../../assets/images/pool.jpg";
import { formatNumber } from "../../../utils/funcoes";

const useStyles = makeStyles((theme) => ({
	containerImg: {
		display: "flex",
		justifyContent: "center",
		background: "var(--color-gray-bluish)",
	},
	img: {
		maxWidth: "100%",
		minHeight: "336px",
		borderRight: "5px solid #0548A3",
		[theme.breakpoints.down("sm")]: {
			minHeight: "auto",
			borderRight: "0px",
			borderBottom: "5px solid #0548A3",
		},
		display: "block",
	},
}));

export default (props) => {
	const { oportunidade } = props.store;
	const imovel = oportunidade.getOportunidade.opor_imovel;
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Paper elevation={3} className="imovel-card__paper">
			<Grid container direction="row">
				<Grid item xs={12} md={4} className={classes.containerImg}>
					<img src={House} alt="imovel" className={classes.img} />
				</Grid>

				<Grid item xs={12} md={8} className="imovel-card__content">
					<div className="row space-between imovel-card__container-title">
						<p className="imovel-card__title bold">Centro - Franca/SP</p>
						<Button className="btn__blue">Abrir</Button>
					</div>

					<Grid item xs={12}>
						<p className="imovel-card__label">
							<span className="imovel-card__type">Terreno</span>
						</p>
					</Grid>

					<Grid
						item
						xs={12}
						className="imovel-card__label imovel-card__location"
					>
						Localização:
						<span className="imovel-card__value">
							{imovel && imovel.localizacao}
						</span>
					</Grid>

					<Grid item xs={12}>
						<p className="imovel-card__owner">
							{imovel && imovel.imovel_empreendimento.empreend_nome}
						</p>
					</Grid>

					<Grid
						item
						xs={12}
						className="row space-between imovel-card__container-mirror"
					>
						<p className="imovel-card__label">
							Lote:
							<span className="imovel-card__value--mirror">
								{imovel && `${imovel.lote}`}
							</span>
							/ Quadra:
							<span>{imovel && `${imovel.quadra}`}</span>
						</p>
					</Grid>

					<Grid item xs={12} sm={6} className="space-between">
						<span className="imovel-card__value">
							<FilterNone className="imovel-card__features" />
							{imovel && `${formatNumber(imovel.area_metro)} m²`}
						</span>

						<span className="imovel-card__value">
							<KingBedOutlined className="imovel-card__features" />2
						</span>

						<span className="imovel-card__value">
							<BathtubOutlined className="imovel-card__features" />2
						</span>

						<span className="imovel-card__value">
							<DirectionsCarOutlined className="imovel-card__features" />1
						</span>
					</Grid>

					<Grid item xs={12} className="space-between">
						<p className="imovel-card__value--price bold">
							{imovel && imovel.preco_total
								? ` R$ ${formatNumber(imovel.preco_total)}`
								: null}
						</p>

						<IconButton
							className="imovel-card__icon"
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<ExpandMore />
						</IconButton>
					</Grid>

					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<CardContent>
							<Typography paragraph>
								A certificação de metodologias que nos auxiliam a lidar com o
								acompanhamento das preferências de consumo assume importantes
								posições no estabelecimento do sistema de participação geral.
							</Typography>
						</CardContent>
					</Collapse>
				</Grid>
			</Grid>
		</Paper>
	);
};
