import {
	ESPELHOEMPREENDIMENTOSAGA,
	GETPLANOPAGAMENTOSAGA,
	PAINELEMPREENDIMENTOSAGA,
	SETEXPANDED,
	UPDATECOLORIMOVEL,
} from "./AllotmentType";

export const getPainelEmpreendimentos = () => {
	return { type: PAINELEMPREENDIMENTOSAGA };
};

export const getEspelhoByEmpreendimento = (params) => {
	return { type: ESPELHOEMPREENDIMENTOSAGA, params };
};

export const setExpanded = (value) => {
	return { type: SETEXPANDED, payload: value };
};

export function getPlanoPagamento(cod_empreendimento) {
	return { type: GETPLANOPAGAMENTOSAGA, params: cod_empreendimento };
}

export function updateColorImovel(value) {
	return { type: UPDATECOLORIMOVEL, payload: value };
}
