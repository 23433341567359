import React from "react";

import { SearchInput } from "../../../components";
import Buttons from "../components/buttons";

export default (props) => {
	return (
		<div className="header-bottom header-bottom-component">
			<div>
				<h2 className="header__title">{props.title}</h2>

				<SearchInput onChangeSearch={props.onChangeSearch} />
			</div>

			<Buttons
				help
				add
				option
				onClickHelp={props.onClickHelp}
				onClickAdd={props.onClickAdd}
				onClickEdit={props.onClickEdit}
			/>
		</div>
	);
};
