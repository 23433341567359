import React from "react";

import {
	CardContent,
	CardActions,
	Collapse,
	Typography,
	InputBase,
	Button,
} from "@material-ui/core";

import { HelpOutline, Add, Reorder, Search } from "@material-ui/icons";
import { searchContatos } from "redux/Contact/ContactAction";

import "./style.css";

export default (props) => {
	let timeOut = 0;

	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleOnChange = (e) => {
		var searchText = e.target.value;

		if (timeOut) clearTimeout(timeOut);
		timeOut = setTimeout(() => {
			props.dispatch(searchContatos({ filtro: searchText }));
		}, 500);
	};

	return (
		<div>
			<CardActions disableSpacing className="header-bottom">
				<div>
					<h2 className="header__title">Contatos</h2>

					<div className="header__search">
						<div className="header__icon">
							<Search />
						</div>
						<InputBase
							className="font-input-white"
							placeholder="Buscar…"
							onChange={handleOnChange}
							inputProps={{ "aria-label": "search" }}
						/>
					</div>
				</div>

				<div className="header__buttons-container">
					<Button
						id="header__btn-help"
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<HelpOutline className="icon__help" />
					</Button>

					<div className="header__wrapper">
						<Button className="header__btn-plus" onClick={props.handleClickAdd}>
							<Add className="icon__plus" />
							Novo
						</Button>
						<Button className="header__btn-options">
							<Reorder className="icon__options" />
							Opções
						</Button>
					</div>
				</div>
			</CardActions>

			<Collapse in={expanded} timeout="auto" unmountOnExit className="intro">
				<CardContent>
					<Typography paragraph>Method:</Typography>
					<Typography paragraph>
						O incentivo ao avanço tecnológico, assim como o aumento do diálogo
						entre os diferentes setores produtivos ainda não demonstrou
						convincentemente que vai participar na mudança das novas
						proposições.
					</Typography>
				</CardContent>
			</Collapse>
		</div>
	);
};
