import "date-fns";
import "./style.css";

import DateFnsUtils from "@date-io/date-fns";
import {
	CardActions,
	CardContent,
	Collapse,
	IconButton,
	MenuItem,
	TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import React from "react";

export default (props) => {
	// const { contato } = props.store;
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleOnChangeDate = (name, date) => {
		if (!date) return;
		if (date && date.toString() === "Invalid Date") return;

		const data = {
			name,
			value: date.toISOString(),
		};

		props.validateForm([data]);
		props.handleChangeForm(data);
	};

	const handleOnChange = (e) => {
		handleOnBlur(e);

		props.handleChangeForm({
			name: e.target.name,
			value: e.target.value,
		});
	};

	const handleOnBlur = (e) => {
		props.validateForm([
			{
				name: e.target.name,
				value: e.target.value,
			},
		]);
	};

	return (
		<div className="container-elevation opportunity-container">
			<h3 className="title-filters">Filtro Avançado</h3>
			<CardActions
				disableSpacing
				className="opportunity-container__card-actions"
			>
				<div className="opportunity-container__container-icon">
					<IconButton
						// className={clsx(classes.expand, {
						// 	[classes.expandOpen]: expanded,
						// })}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon className="opportunity-container__icon" />
					</IconButton>
				</div>
			</CardActions>

			<Collapse
				in={expanded}
				timeout="auto"
				unmountOnExit
				className="intro opportunity-container__collapse"
			>
				<CardContent className="opportunity-container__card">
					<form
						className="opportunity-container__form"
						noValidate
						autoComplete="off"
					>
						<div className="opportunity-container__container-input">
							<TextField
								className="input__primary opportunity-container__input"
								autoComplete="off"
								label="Pretensão"
								name="contato_estado_civil"
								value=""
								onChange={handleOnChange}
								select
							>
								<MenuItem value="">
									<em>Nenhum</em>
								</MenuItem>
							</TextField>
						</div>

						<div className="opportunity-container__container-input">
							<TextField
								className="input__primary opportunity-container__input"
								autoComplete="off"
								label="Fase"
								name="contato_estado_civil"
								value=""
								onChange={handleOnChange}
								select
							>
								<MenuItem value="">
									<em>Nenhum</em>
								</MenuItem>
								{/* {contato.arrayEstadoCivil.map((data, index) => ( */}
								<MenuItem /*key={index} value={data.value}*/></MenuItem>
								{/* ))} */}
							</TextField>
						</div>

						<div className="opportunity-container__container-input">
							<TextField
								className="input__primary opportunity-container__input"
								autoComplete="off"
								label="Situação"
								name="contato_estado_civil"
								value=""
								onChange={handleOnChange}
								select
							>
								<MenuItem value="">
									<em>Nenhum</em>
								</MenuItem>
								{/* {contato.arrayEstadoCivil.map((data, index) => ( */}
								<MenuItem /*key={index} value={data.value}*/></MenuItem>
								{/* ))} */}
							</TextField>
						</div>

						<div className="opportunity-container__wrapper-input--date">
							<div className="opportunity-container__container-input--date">
								<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
									<KeyboardDatePicker
										className="input__primary opportunity-container__input--date"
										autoOk
										disableToolbar
										format="dd/MM/yyyy"
										disableFuture={true}
										margin="normal"
										label="Período Inicial"
										invalidDateMessage="Data em formato inválido"
										maxDateMessage="A data não pode ser maior ou igual a data atual"
										// value={contato.currentContato.contato_nasc_dt || null}
										onChange={(date) =>
											handleOnChangeDate("contato_nasc_dt", date)
										}
										cancelLabel={null}
										okLabel={null}
									/>
								</MuiPickersUtilsProvider>
							</div>

							<p>até</p>
							<div className="opportunity-container__container-input--date">
								<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
									<KeyboardDatePicker
										className="input__primary opportunity-container__input--date"
										autoOk
										disableToolbar
										format="dd/MM/yyyy"
										disableFuture={true}
										margin="normal"
										label="Período Final"
										invalidDateMessage="Data em formato inválido"
										maxDateMessage="A data não pode ser maior ou igual a data atual"
										// value={contato.currentContato.contato_nasc_dt || null}
										onChange={(date) =>
											handleOnChangeDate("contato_nasc_dt", date)
										}
										cancelLabel={null}
										okLabel={null}
									/>
								</MuiPickersUtilsProvider>
							</div>
						</div>

						<div className="opportunity-container__container-input">
							<TextField
								className="input__primary opportunity-container__input"
								autoComplete="off"
								label="Responsável"
								name="contato_estado_civil"
								value=""
								onChange={handleOnChange}
								select
							>
								<MenuItem value="">
									<em>Nenhum</em>
								</MenuItem>
								{/* {contato.arrayEstadoCivil.map((data, index) => ( */}
								<MenuItem /*key={index} value={data.value}*/></MenuItem>
								{/* ))} */}
							</TextField>
						</div>
					</form>
				</CardContent>
			</Collapse>
		</div>
	);
};
