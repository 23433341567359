// Sagas
export const GETUSUARIOSSAGA = "GETUSUARIOSSAGA";
export const POSTUSUARIOSAGA = "POSTUSUARIOSAGA";
export const PUTUSUARIOSAGA = "PUTUSUARIOSAGA";
export const GETFUNCAOUSUARIOSAGA = "GETFUNCAOUSUARIOSAGA";
export const ERROUSUARIOSAGA = "ERROUSUARIOSAGA";
export const ADDUSUARIOSAGA = "ADDUSUARIOSAGA";
export const EDITUSUARIOSAGA = "EDITUSUARIOSAGA";
export const SELECTEDUSUARIOSAGA = "SELECTEDUSUARIOSAGA";
export const GETPESSOABYCPFSAGA = "GETPESSOABYCPFSAGA";
export const GETFUNCAOSAGA = "GETFUNCAOSAGA";
export const GETUSUARIOBYIDSAGA = "GETUSUARIOBYIDSAGA";

// Reducer
export const GETUSUARIOS = "GETUSUARIOS";
export const POSTUSUARIO = "POSTUSUARIO";
export const PUTUSUARIO = "PUTUSUARIO";
export const GETFUNCAOUSUARIO = "GETFUNCAOUSUARIO";
export const ERROUSUARIO = "ERROUSUARIO";
export const ADDUSUARIO = "ADDUSUARIO";
export const EDITUSUARIO = "EDITUSUARIO";
export const SELECTEDUSUARIO = "SELECTEDUSUARIO";
