import { hideLoading, showLoading } from "components/Loader";
import { call, put, takeEvery } from "redux-saga/effects";
import { API } from "utils/API";

import { history } from "../../App";
import {
	GETDIALOGOIMOVELVENDA,
	GETDIALOGOIMOVELVENDASAGA,
} from "./ImmobileType";

function* getDialogoImovelVenda({ payload }) {
	const { params, show } = payload;
	try {
		yield put(showLoading());

		const response = yield call(API.get, "imovel_painel", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = {};

		payload = { data: response.data.result, show };

		yield put({ type: GETDIALOGOIMOVELVENDA, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

export default function* AllotmentSaga() {
	yield takeEvery(GETDIALOGOIMOVELVENDASAGA, getDialogoImovelVenda);
}
