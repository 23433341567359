import "./style.css";
import "./style.css";

import {
	CardActions,
	FormControlLabel,
	Switch,
	withStyles,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React from "react";

import { history } from "../../../App";
import Buttons from "../components/buttons";

const Espelho = (props) => {
	const { id } = props.match.params;
	const { empreendimento } = props.store;
	const [checked, setChecked] = React.useState(true);

	const handleChange = (e) => {
		if (e.target.checked) {
			props.getEspelhoByEmpreendimento({
				cod_empreendimento: id,
				disponivel: "SIM",
			});
		} else props.getEspelhoByEmpreendimento({ cod_empreendimento: id });

		setChecked(e.target.checked);
	};

	const CustomSwitch = withStyles({
		switchBase: {
			color: "white",
			"&$checked": {
				color: "#52d869",
			},
			"&$checked + $track": {
				backgroundColor: "#52d869",
			},
		},
		checked: {},
		track: {},
	})(Switch);

	const handleClickBack = () => {
		history.push("/empreendimento");
	};

	return (
		<>
			<CardActions disableSpacing className="header-bottom">
				<div className="row">
					<div onClick={handleClickBack} className="header-mirror__btn">
						<ChevronLeft className="header-mirror__back" />
					</div>

					<div className="header-mirror__container">
						<h2 className="header-mirror__title gray--light">
							Painel de Vendas
						</h2>

						<h2 className="header-mirror__title--bold">
							{empreendimento.espelhoEmpreendimento.empreend_nome}
						</h2>
					</div>
				</div>

				<div className="header-mirror__buttons-container">
					<div>
						<Buttons help onClickHelp={props.onClickHelp} />
					</div>

					<div className="header__wrapper">
						<FormControlLabel
							className="header-mirror__form"
							control={
								<CustomSwitch
									checked={checked}
									onChange={handleChange}
									className="header-mirror__switch"
								/>
							}
							label="Disponíveis"
						/>
					</div>
				</div>
			</CardActions>
		</>
	);
};
export default Espelho;
