import "./style.css";

import { ChevronLeft } from "@material-ui/icons";
import React from "react";

import Buttons from "../components/buttons";

export default (props) => {
	const { back } = props;

	return (
		<div className="header-bottom header-bottom-component--small">
			<div className="header-standard__container">
				{back && (
					<div onClick={props.onClickBack} className="header-standard__btn">
						<ChevronLeft className="header-standard__back" />
					</div>
				)}

				<h2 className="header-standard__title">{props.title}</h2>
			</div>

			<div className="header-standard__helper-container">
				<Buttons help onClickHelp={props.onClickHelp} />
			</div>
		</div>
	);
};
