import {
	CHANGEPROPOSTA,
	CLOSEFASEDOCUMENTOIMAGEM,
	CLOSEFASEDOCUMENTOS,
	EDITFASEDOCUMENTO,
	ERRORSFORMDATAPROPOSTA,
	GETFASEDOCUMENTOIMAGEM,
	GETFASEDOCUMENTOS,
	GETOPCAOVENDA,
	GETPLANOPAGAMENTO,
	SETOPCAOVENDA,
	SETORCAMENTO,
	SETPROPOSTA,
	SETRESERVA,
	SHAREORCAMENTO,
	SHAREPROPOSTA,
} from "./SaleType";

const INITIAL_STATE = {
	getFaseDocumentoImagem: "",
	showFaseDocumentoImagem: false,
	getFaseDocumentos: [],
	showFaseDocumentos: false,
	getOpcaoVenda: [],
	getPlanoPagamento: [],
	setOpcaoVenda: "",
	getOrcamento: {},
	getProposta: {},
	getReserva: {},
	pdf: null,
	pdfProposta: null,
	proposta: {
		data_entrada_inicial: "",
		data_parcela_inicial: "",
		contato: {},
	},
	validateForm: {
		data_entrada_inicial: "",
		data_parcela_inicial: "",
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GETFASEDOCUMENTOS:
			return {
				...state,
				getFaseDocumentos: action.payload,
				showFaseDocumentos: true,
			};

		case EDITFASEDOCUMENTO:
			let current = action.payload;
			let array = state.getFaseDocumentos;

			array.forEach((data) => {
				data.doc_grupo_descricao_itens.map((value) => {
					if (value.cod_fase_etapa_doc === current.cod_fase_etapa_doc) {
						value.cod_opor_fase_etapa_doc = current.cod_opor_fase_etapa_doc;
						value.data_envio = current.data_envio;
						value.situacao = current.situacao;
						value.situacao_motivo = current.situacao_motivo;
						value.finalizacao_dt = current.finalizacao_dt;
					}
					return value;
				});
			});

			return {
				...state,
				getFaseDocumentos: array,
			};
		case GETFASEDOCUMENTOIMAGEM:
			return {
				...state,
				getFaseDocumentoImagem: action.payload,
				showFaseDocumentoImagem: true,
			};
		case CLOSEFASEDOCUMENTOIMAGEM:
			return {
				...state,
				showFaseDocumentoImagem: action.payload,
			};
		case CLOSEFASEDOCUMENTOS:
			return {
				...state,
				showFaseDocumentos: action.payload,
			};
		case GETOPCAOVENDA:
			return {
				...state,
				getOpcaoVenda: action.payload,
			};
		case SETOPCAOVENDA:
			return {
				...state,
				setOpcaoVenda: action.opcao,
			};
		case GETPLANOPAGAMENTO:
			return {
				...state,
				getPlanoPagamento: action.payload,
			};
		case SETORCAMENTO:
			return {
				...state,
				getOrcamento: action.payload,
			};
		case SETPROPOSTA:
			return {
				...state,
				getProposta: action.payload,
			};
		case SETRESERVA:
			return {
				...state,
				getReserva: action.payload,
			};
		case SHAREORCAMENTO:
			return {
				...state,
				pdf: action.payload,
			};
		case SHAREPROPOSTA:
			return {
				...state,
				pdfProposta: action.payload,
			};
		case CHANGEPROPOSTA:
			const { name, value, calculo } = action.payload;

			let proposta = {
				[name]: value,
			};

			if (name === "data_entrada_inicial") {
				let entradaMes = new Date(value).getMonth();

				let parcelaDate = new Date(value);
				let qtdeMeses = entradaMes + calculo.entradaQtde + 1;
				parcelaDate.setMonth(qtdeMeses);

				proposta = {
					...proposta,
					data_parcela_inicial: parcelaDate.toISOString(),
				};
			}

			return {
				...state,
				proposta: {
					...state.proposta,
					...proposta,
				},
			};
		case ERRORSFORMDATAPROPOSTA:
			const form = action.payload;

			form.forEach((data) => {
				let newDate = new Date(data.value);

				switch (data.name) {
					case "data_entrada_inicial":
						state.validateForm = {
							...state.validateForm,
							[data.name]: data.value
								? ""
								: "Preencha a data de vencto da primeira entrada",
						};
						break;
					case "data_parcela_inicial":
						state.validateForm = {
							...state.validateForm,
							[data.name]: data.value
								? ""
								: "Preencha a data de vencto da primeira parcela",
						};
						break;
					default:
						state.validateForm = { ...state.validateForm };
				}

				if ([0, 6].indexOf(newDate.getDay()) !== -1) {
					state.validateForm = {
						...state.validateForm,
						[data.name]: "Selecione uma data diferente de sábado/domingo",
					};
				}
			});

			return {
				...state,
				validateForm: {
					...state.validateForm,
				},
			};
		default:
			return state;
	}
};
