import React from "react";

import {
	ListItem,
	ListItemAvatar,
	Avatar,
	LinearProgress,
	Collapse,
	CardContent,
	CardActions,
	lighten,
	withStyles,
	Divider,
} from "@material-ui/core";

import { ExpandMore, Error } from "@material-ui/icons";

import { NavLink } from "react-router-dom";
// import Dialog from "../Dialog";
import { formatDateToLocaleString } from "utils/funcoes";
import "./style.css";

export default (props) => {
	const { painel } = props;

	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const BorderLinearProgress = withStyles({
		root: {
			backgroundColor: ({ cor }) => lighten(`${cor}`, 0.5),
		},
		bar: {
			backgroundColor: ({ cor }) => `${cor}`,
		},
	})(LinearProgress);

	return (
		<>
			{/* <Dialog /> */}

			<ListItem
				className="list"
				dense
				button
				// component={NavLink}
				// to={`/oportunidade/register/${painel.cod_opor}`}
			>
				<div className="list__container">
					<div className="list__content">
						<NavLink
							to={`/oportunidade/register/${painel.cod_opor}`}
							className="list__names"
						>
							<div className="list__wrapper">
								<ListItemAvatar style={{ marginTop: "-1px	" }}>
									<Avatar style={{ backgroundColor: `${painel.fase_cor}` }}>
										{painel.fase_sigla}
									</Avatar>
								</ListItemAvatar>

								<div>
									<label className="hide-xs list__label">Cliente:</label>
									<p className="list__owner">{painel.cliente_nome}</p>
								</div>

								<div className="hide-xs">
									<label className="hide-xs list__label">Empreendimento:</label>
									<p className="list__enterprise">{painel.empreend_nome}</p>
								</div>
							</div>

							<div className="hide-xs list__unit-container">
								<label className="hide-xs list__label">Unidade:</label>
								<p className="list__unit">{painel.unidade}</p>
							</div>

							<div className="hide-xs list__dateandhour-container">
								<label className="hide-xs list__label">Data e Hora:</label>
								<p className="list__dateandhour">
									{formatDateToLocaleString(painel.data_hora)}
								</p>
							</div>

							<div className="hide list__inner--show">
								<div>
									<label className="hide-xs list__label">Data e Hora:</label>
									<p className="list__dateandhour">
										{formatDateToLocaleString(painel.data_hora)}
									</p>
								</div>
							</div>
							<div className="hide-sm list__wrapper-broker">
								<label className="list__label">Corretor:</label>
								{painel.usuario_nome && (
									<p className="list__broker">{painel.usuario_nome}</p>
								)}
							</div>
						</NavLink>

						<div className="list__container--icons column">
							<div>
								<CardActions disableSpacing>
									<div className="list__icon-step bg-yellow">
										<p>{painel.etapas_quant}</p>
									</div>
								</CardActions>
							</div>

							<div className="hide-sm">
								<CardActions disableSpacing>
									<ExpandMore
										className="list__icon-expand"
										onClick={handleExpandClick}
										aria-expanded={expanded}
										aria-label="show more"
									/>
								</CardActions>
							</div>
						</div>
					</div>
				</div>

				<NavLink
					className="hide list__enterprise--container"
					to={`/oportunidade/register/${painel.cod_opor}`}
				>
					<div className="list__enterprise--show">
						<label className="hide-xs list__label">Empreendimento:</label>
						<p className="list__enterprise">{painel.empreend_nome}</p>
					</div>

					<div>
						<label className="hide-xs list__label">Unidade:</label>
						<p className="list__unit">{painel.unidade}</p>
					</div>
				</NavLink>

				<div className="list__container--step">
					<div className="list__container--inner">
						<NavLink
							className="hide list__broker-container"
							to={`/oportunidade/register/${painel.cod_opor}`}
						>
							<label className="hide-xs list__label">Corretor:</label>
							{painel.usuario_nome && (
								<p className="list__broker">{painel.usuario_nome}</p>
							)}
						</NavLink>

						<div className="hide list__icon-expand--sm">
							<CardActions disableSpacing>
								<ExpandMore
									className="list__icon-expand"
									onClick={handleExpandClick}
									aria-expanded={expanded}
									aria-label="show more"
								/>
							</CardActions>
						</div>
					</div>

					<NavLink
						to={`/oportunidade/register/${painel.cod_opor}`}
						className="list__expanded"
					>
						<Collapse
							in={expanded}
							timeout="auto"
							unmountOnExit
							className="intro list__collapse"
						>
							<div>
								<h4 className="blue--light list__title-steps">
									As etapas abaixo estão aguardando sua resolução.
								</h4>
								<CardContent className="list__card-content">
									<div
										to={`/oportunidade/register/${painel.cod_opor}`}
										className="list__phase"
									>
										{painel.painel_etapas &&
											painel.painel_etapas.map((value, index) => (
												<div key={index} className="list__steps">
													<div className="list__review">
														<div className="list__container--proposal">
															<Error className="list__proposal-icon" />
															{value.fase_etapa_descricao}
														</div>
														<p className="list__wait-proposal">
															{value.situacao}
															{value.situacao_descricao && (
																<span
																	style={{
																		marginLeft: "10px",
																		fontStyle: "normal",
																	}}
																>
																	{`(${value.situacao_descricao})`}
																</span>
															)}
														</p>
													</div>

													{value.tempo_restante && (
														<div className="list__time list__time-container">
															<span
																style={{
																	color: `${value.tempo_cor}`,
																	fontSize: "14px",
																}}
															>
																{value.tempo_restante}
															</span>
															<BorderLinearProgress
																className="list__time"
																variant="determinate"
																value={value.porc_restante}
																cor={`${value.tempo_cor}`}
															/>
														</div>
													)}
												</div>
											))}
									</div>
								</CardContent>
							</div>
						</Collapse>
					</NavLink>
				</div>
			</ListItem>

			<Divider />
		</>
	);
};
