import { arrayEstadoCivil } from "utils/arrays";
import {
	CpfCnpjIsValid,
	emailIsValid,
	maskCelular,
	maskCpfCnpj,
} from "utils/funcoes";

import {
	ADDCONTATO,
	CHANGEFORMCONTATO,
	CHANGESEARCH,
	CURRENTCONTATO,
	DELETECONTATO,
	EDITCONTATO,
	ERRORSFORMCONTATO,
	GETALLCONTATOS,
	SEARCHCONTATOS,
	SHOWDIALOG,
} from "./ContactType";

const INITIAL_STATE = {
	allContatos: [],
	showDialog: false,
	arrayEstadoCivil,
	searchContato: "",
	currentContato: {
		contato_nome: "",
		contato_celular: "",
	},
	validateForm: {
		contato_nome: "",
		contato_email: "",
		contato_cpfcnpj: "",
		contato_celular: "",
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GETALLCONTATOS:
			return {
				...state,
				allContatos: action.payload,
			};
		case CURRENTCONTATO:
			return {
				...state,
				currentContato: action.payload || INITIAL_STATE.currentContato,
			};
		case SHOWDIALOG:
			return {
				...state,
				showDialog: action.payload,
				validateForm: INITIAL_STATE.validateForm,
				currentContato: INITIAL_STATE.currentContato,
			};
		case ADDCONTATO:
			return {
				...state,
				allContatos: [...state.allContatos, action.payload],
			};
		case EDITCONTATO:
			state.allContatos[
				state.allContatos.findIndex(
					(value) => value.cod_contato === action.payload.cod_contato
				)
			] = action.payload;

			return {
				...state,
				allContatos: [...state.allContatos],
			};
		case DELETECONTATO:
			return {
				...state,
				allContatos: state.allContatos.filter(
					(value) => value.cod_contato !== action.payload
				),
			};
		case CHANGEFORMCONTATO:
			const { name, value } = action.payload;
			let result = value;

			switch (name) {
				case "contato_cpfcnpj":
					result = maskCpfCnpj(value);
					break;
				case "contato_celular":
					result = maskCelular(value);
					break;
				default:
					result = value;
			}

			return {
				...state,
				currentContato: {
					...state.currentContato,
					[name]: result,
				},
			};
		case ERRORSFORMCONTATO:
			const form = action.payload;

			form.forEach((data) => {
				switch (data.name) {
					case "contato_nome":
						state.validateForm = {
							...state.validateForm,
							[data.name]: data.value ? "" : "Preencha o nome do contato",
						};
						break;
					case "contato_celular":
						state.validateForm = {
							...state.validateForm,
							[data.name]: data.value
								? data.value.length >= 17
									? ""
									: "Preencha um celular válido"
								: "Preencha o celular do contato",
						};
						break;
					case "contato_email":
						state.validateForm = {
							...state.validateForm,
							[data.name]:
								data.value && data.value.length >= 1
									? emailIsValid(data.value)
										? ""
										: "E-Mail Inválido"
									: "",
						};
						break;
					case "contato_cpfcnpj":
						state.validateForm = {
							...state.validateForm,
							[data.name]:
								data.value &&
								(data.value.length === 14 || data.value.length === 18)
									? CpfCnpjIsValid(data.value) === true
										? ""
										: data.value.length === 14
										? "CPF Inválido"
										: "CNPJ Inválido"
									: "",
						};
						break;
					default:
						state.validateForm = { ...state.validateForm };
				}
			});

			return {
				...state,
				validateForm: {
					...state.validateForm,
				},
			};
		case SEARCHCONTATOS:
			return {
				...state,
				allContatos: action.payload,
			};
		case CHANGESEARCH:
			return {
				...state,
				searchContato: action.payload,
			};
		default:
			return state;
	}
};
