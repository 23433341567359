import { hideLoading, showLoading } from "components/Loader";
import { call, put, takeEvery } from "redux-saga/effects";
import { API, API_BLOB } from "utils/API";

import { history } from "../../App";
import {
	ADDFASEDOCUMENTOSAGA,
	EDITFASEDOCUMENTO,
	EDITFASEDOCUMENTOSAGA,
	GETFASEDOCUMENTOIMAGEM,
	GETFASEDOCUMENTOIMAGEMSAGA,
	GETFASEDOCUMENTOS,
	GETFASEDOCUMENTOSSAGA,
	GETOPCAOVENDA,
	GETOPCAOVENDASAGA,
	SETORCAMENTO,
	SETORCAMENTOSAGA,
	SETPROPOSTA,
	SETPROPOSTASAGA,
	SETRESERVA,
	SETRESERVASAGA,
	SHAREORCAMENTO,
	SHAREORCAMENTOSAGA,
	SHAREPROPOSTA,
	SHAREPROPOSTASAGA,
	// GETPLANOPAGAMENTOSAGA,
	// GETPLANOPAGAMENTO,
} from "./SaleType";

function* getOpcaoVenda({ params }) {
	console.log("getOpcaoVendasaga", params);
	try {
		yield put(showLoading());

		const response = yield call(API.get, "opcaovenda", { params });

		let payload = response.data.result;
		if (payload === "Não possui dados") payload = {};

		yield put({ type: GETOPCAOVENDA, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* addProposta({ data }) {
	try {
		yield put(showLoading());

		const response = yield call(API.post, "proposta", JSON.stringify(data));

		console.log(response);

		let payload = response.data.result;
		if (payload.message !== "success") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: SETPROPOSTA, payload: payload.data });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* addOrcamento({ data }) {
	try {
		yield put(showLoading());

		const response = yield call(API.post, "orcamento", JSON.stringify(data));

		let payload = response.data.result;
		if (payload.message !== "success") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: SETORCAMENTO, payload: payload.data });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* addReserva({ data }) {
	try {
		yield put(showLoading());

		const response = yield call(API.post, "reserva", JSON.stringify(data));

		let payload = response.data.result;
		if (payload.message !== "success") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: SETRESERVA, payload });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* shareOrcamento({ data }) {
	try {
		yield put(showLoading());

		const response = yield call(
			API_BLOB.post,
			"relatorio",
			JSON.stringify(data)
		);
		console.log("shareOrcamento", response);
		const file = new Blob([response.data], { type: "application/pdf" });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);

		yield put({ type: SHAREORCAMENTO, payload: fileURL });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

function* shareProposta({ data }) {
	try {
		yield put(showLoading());

		const response = yield call(
			API_BLOB.post,
			"relatorio",
			JSON.stringify(data)
		);

		const file = new Blob([response.data], { type: "application/pdf" });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);

		yield put({ type: SHAREPROPOSTA, payload: fileURL });
		yield put(hideLoading());
	} catch (error) {
		history.push("/erro", { state: { erro: error.response } });
		yield put(hideLoading());
	}
}

// function* getPlanoPagamento({ cod_empreendimento }) {
// 	try {
// 		yield put(showLoading());

// 		console.log(cod_empreendimento);

// 		const response = yield call(API.get, "planopagamento", {
// 			params: { cod_empreendimento: cod_empreendimento },
// 		});
// 		console.log(response);

// 		let payload = response.data.result;
// 		if (payload === "Não possui dados") payload = [];

// 		yield put({ type: GETPLANOPAGAMENTO, payload });
// 		yield put(hideLoading());
// 	} catch (error) {
// 		history.push("/erro", { state: { erro: error.response } });
// 		yield put(hideLoading());
// 	}
// }

function* getFaseDocumentos(action) {
	console.log("action", action);
	yield put(showLoading());
	try {
		const response = yield call(API.get, `fase_documentos`, {
			...action.payload,
		});
		let payload = response.data.result;

		yield put({ type: GETFASEDOCUMENTOS, payload });
		yield put(hideLoading());
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response.data } });
		yield put(hideLoading());
	}
}

function* getFaseDocumentoImagem(action) {
	yield put(showLoading());
	try {
		const response = yield call(API.get, `fase_documentos_imagem`, {
			...action.payload,
		});
		let payload = response.data.result;
		const imagem = payload.imagem.split(";");

		if (imagem[0].match(/pdf/)) {
			const byteCharacters = atob(imagem[1].split(",")[1]);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);

			const file = new Blob([byteArray], { type: "application/pdf" });
			const fileURL = URL.createObjectURL(file);

			window.open(fileURL);
		} else {
			yield put({ type: GETFASEDOCUMENTOIMAGEM, payload: payload.imagem });
		}
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response.data } });
		yield put(hideLoading());
	}
}

function* addFaseDocumento(action) {
	yield put(showLoading());
	try {
		const response = yield call(
			API.post,
			`fase_documentos`,
			JSON.stringify(action.payload)
		);
		let payload = response.data.result;

		if (payload.message !== "success") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: EDITFASEDOCUMENTO, payload: payload.data });
		yield put(hideLoading());
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response.data } });
		yield put(hideLoading());
	}
}
function* editFaseDocumento(action) {
	yield put(showLoading());
	try {
		const response = yield call(
			API.put,
			`fase_documentos/${action.payload.cod_opor_fase_etapa_doc}`,
			JSON.stringify(action.payload)
		);
		let payload = response.data.result;

		if (payload.message !== "success") {
			yield put(hideLoading());
			return;
		}

		yield put({ type: EDITFASEDOCUMENTO, payload: payload.data });
		yield put(hideLoading());
	} catch (error) {
		console.log(error.response);
		history.push("/erro", { state: { erro: error.response.data } });
		yield put(hideLoading());
	}
}

export default function* SaleSaga() {
	yield takeEvery(GETOPCAOVENDASAGA, getOpcaoVenda);
	yield takeEvery(SETPROPOSTASAGA, addProposta);
	yield takeEvery(SETORCAMENTOSAGA, addOrcamento);
	yield takeEvery(SETRESERVASAGA, addReserva);
	yield takeEvery(SHAREORCAMENTOSAGA, shareOrcamento);
	yield takeEvery(SHAREPROPOSTASAGA, shareProposta);
	yield takeEvery(GETFASEDOCUMENTOSSAGA, getFaseDocumentos);
	yield takeEvery(GETFASEDOCUMENTOIMAGEMSAGA, getFaseDocumentoImagem);
	yield takeEvery(ADDFASEDOCUMENTOSAGA, addFaseDocumento);

	yield takeEvery(EDITFASEDOCUMENTOSAGA, editFaseDocumento);
	// yield takeEvery(GETPLANOPAGAMENTOSAGA, getPlanoPagamento);
}
