import React from "react";

import { TextField, Divider, Grid, Icon } from "@material-ui/core";

import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import StarsOutlinedIcon from "@material-ui/icons/StarsOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import "./style.css";

export default (props) => {
	return (
		<>
			<Grid
				container
				spacing={3}
				className="enterprise-registration__container"
			>
				<form
					noValidate
					autoComplete="off"
					className="enterprise-registration__form"
				>
					<div className="row start blue--light">
						<StarsOutlinedIcon />
						<h3 className="enterprise-registration__title-section">Geral</h3>
					</div>

					<TextField
						className="enterprise-registration__input input__secondary"
						autoComplete="off"
						required
						label="Empreendimento"
						name="empreendimento_nome"
					/>

					<TextField
						className="enterprise-registration__input input__secondary"
						autoComplete="off"
						label="Administradora"
						name="empreendimento_administradora"
					/>
					<Divider className="enterprise-registration__divider " />

					<div className="row start blue--light">
						<CheckBoxOutlinedIcon className="blue--light" />
						<h3 className="enterprise-registration__title-section">Status</h3>
					</div>
					<TextField
						className="enterprise-registration__input input__secondary"
						autoComplete="off"
						label="Tipo"
						name="empreendimento_tipo"
					/>

					<TextField
						className="enterprise-registration__input input__secondary"
						autoComplete="off"
						label="Situação"
						name="empreendimento_situacao"
						inputProps={{ maxLength: 18 }}
					/>

					<Divider className="enterprise-registration__divider " />

					<div className="row start blue--light">
						<Icon className="blue--light">filter_none</Icon>
						<h3 className="enterprise-registration__title-section">Áreas</h3>
					</div>
					<TextField
						className="enterprise-registration__input input__secondary"
						autoComplete="off"
						label="Área Total"
						name="empreendimento_areatotal"
						inputProps={{ maxLength: 17 }}
					/>

					<TextField
						className="enterprise-registration__input input__secondary"
						autoComplete="off"
						label="Município"
						name="empreendimento_municipio"
					/>

					<TextField
						className="enterprise-registration__input input__secondary"
						autoComplete="off"
						label="UF"
						name="empreendimento_uf"
					/>
					<Divider className="enterprise-registration__divider " />

					<div className="row start blue--light">
						<DnsOutlinedIcon className="blue--light" />
						<h3 className="enterprise-registration__title-section">Dados</h3>
					</div>
					<TextField
						className="enterprise-registration__input input__secondary"
						autoComplete="off"
						label="Matrícula"
						name="empreendimento_matricula"
					/>

					<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
						<KeyboardDatePicker
							className="enterprise-registration__input--date input__secondary"
							autoOk
							disableToolbar
							format="dd/MM/yyyy"
							disableFuture={true}
							margin="normal"
							label="Data Matrícula"
							invalidDateMessage="Data em formato inválido"
							maxDateMessage="A data não pode ser maior ou igual a data atual"
							cancelLabel={null}
							okLabel={null}
						/>
					</MuiPickersUtilsProvider>

					<TextField
						className="enterprise-registration__input input__secondary"
						autoComplete="off"
						label="Cartório"
						name="empreendimento_cartorio"
					/>
				</form>
			</Grid>
		</>
	);
};
