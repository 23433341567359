import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../pages/Login";
import UsuarioAtivacao from "../pages/UserActivation";

export default (props) => {
	return (
		<Switch>
			<Route path="/login" exact={true} component={Login} />
			<Route
				path="/novo-usuario/:token"
				exact={true}
				component={UsuarioAtivacao}
			/>
			<Route path="/" exact={true} component={Login} />
		</Switch>
	);
};
