import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogTitle,
	Slide,
	Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { history } from "App";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
	getPlanoPagamento,
	updateColorImovel,
} from "redux/Allotment/AllotmentAction";
import { getAllContatos } from "redux/Contact/ContactAction";
import {
	setShowAlertQuestion,
	setShowAlertQuestionReason,
} from "redux/Global/GlobalAction";
import { showDialogImovel } from "redux/Immobile/ImmobileAction";
import { editFaseDocumento } from "redux/Sale/SaleAction";

import { formatNumber } from "../../../utils/funcoes";
import AlertQuestion from "../../global/alert-question";
import AlertQuestionReason from "../../global/alert-question-reason";
import ImovelDados from "./imovel-dados";
import ImovelOpcaoVenda from "./imovel-opcao-venda";
import ImovelOpcaoVendaOrcamento from "./imovel-opcao-venda-orcamento";
import ImovelOpcaoVendaProposta from "./imovel-opcao-venda-proposta";
import ImovelOpcaoVendaReserva from "./imovel-opcao-venda-reserva";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ImovelDialog extends Component {
	constructor(props) {
		super(props);

		this.state = {
			step: props.step ? props.step : "dados",
		};

		this.handleClickNegotiate = this.handleClickNegotiate.bind(this);
		this.handleClickOption = this.handleClickOption.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.renderStep = this.renderStep.bind(this);
	}

	componentDidMount() {
		const { step } = this.props;
		const { id } = this.props.match.params;
		this.setState({ step });

		this.props.dispatch(getPlanoPagamento({ cod_empreendimento: id }));
	}

	handleClickNegotiate = () => {
		this.props.dispatch(getAllContatos());
		this.setState({ step: "opcao" });
	};

	handleClickOption = (value) => {
		this.setState({ step: value });
	};

	handleClose = () => {
		const { venda } = this.props.store;
		const { cod_opor } = this.props.match.params;

		if (cod_opor) {
			this.props.dispatch(showDialogImovel(false));
			history.push(`/oportunidade/register/${cod_opor}`);
		} else {
			if (!this.props.etapas) {
				this.setState({ step: "dados" });

				if (!this.props.mapa) {
					if (venda.getProposta.cod_empreendvenda) {
						this.updateColor(venda.getProposta);
					}

					if (venda.getReserva.cod_opor) {
						this.updateColor(venda.getReserva);
					}
				}
			}

			this.props.dispatch(showDialogImovel(false));
		}
	};

	updateColor = (imovel) => {
		const { empreendimento } = this.props.store;

		this.props.dispatch(
			updateColorImovel({
				array: empreendimento.espelhoEmpreendimento,
				imovel,
			})
		);
	};

	renderStep = () => {
		switch (this.state.step) {
			case "dados":
				console.log("imovesados", this.props);
				return (
					<ImovelDados {...this.props} click={this.handleClickNegotiate} />
				);
			case "opcao":
				return (
					<ImovelOpcaoVenda {...this.props} click={this.handleClickOption} />
				);
			case "orçamento":
				return <ImovelOpcaoVendaOrcamento {...this.props} />;
			case "proposta":
				return <ImovelOpcaoVendaProposta {...this.props} />;
			case "reserva":
				return <ImovelOpcaoVendaReserva {...this.props} />;
			default:
				return (
					<ImovelDados {...this.props} click={this.handleClickNegotiate} />
				);
		}
	};

	handleConfirmQuestion = () => {
		const { dados } = this.props.store.global.showAlertQuestion;

		if (dados.tipo === "documentos") {
			this.props.dispatch(
				editFaseDocumento({
					acao: "Aprovado",
					cod_opor_fase_etapa_doc: dados.value.cod_opor_fase_etapa_doc,
				})
			);
		} else if (dados.tipo === "reserva") {
			console.log(dados);
		}

		this.props.dispatch(setShowAlertQuestion({ show: false }));
	};

	handleConfirmQuestionReason = () => {
		const { dados, reason } = this.props.store.global.showAlertQuestionReason;

		if (dados.tipo === "documentos") {
			this.props.dispatch(
				editFaseDocumento({
					acao: "Reprovado",
					situacao_motivo: reason,
					cod_opor_fase_etapa_doc: dados.value.cod_opor_fase_etapa_doc,
				})
			);
		}

		this.props.dispatch(setShowAlertQuestionReason({ show: false }));
	};

	render() {
		const { imovel } = this.props.store;

		return (
			<>
				<Dialog
					open={imovel.showDialogImovel}
					TransitionComponent={Transition}
					keepMounted
					onClose={this.handleClose}
				>
					<div className="dialog-container bg-gray--light">
						<DialogTitle className="dialog-container__header dialog-header bg-blue">
							<DialogActions
								className="dialog-container__close"
								onClick={this.handleClose}
							>
								<Close className="icon__close" />
							</DialogActions>

							<span className="dialog-header__title white">
								{imovel.getImovel.empreend_nome}
							</span>

							<div className="dialog-header__subtitle-container gray--light">
								<Typography className="dialog-header__container-value">
									tipo:
									<span className="dialog-header__value white">
										{imovel.getImovel.imovel_tipo}
									</span>
								</Typography>
								<Typography className="dialog-header__container-value">
									quadra:
									<span className="dialog-header__value white">
										{imovel.getImovel.quadra}
									</span>
								</Typography>
								<Typography className="dialog-header__container-value">
									lote:
									<span className="dialog-header__value white">
										{imovel.getImovel.lote}
									</span>
								</Typography>
							</div>

							<Typography className="dialog-header__price">
								<span className="dialog-header__price--label gray--light">
									VALOR:
								</span>
								<span className="dialog-header__price--value white">
									{` R$ ${formatNumber(imovel.getImovel.preco_total)}`}
								</span>
							</Typography>
						</DialogTitle>

						{this.renderStep()}
					</div>
				</Dialog>

				<AlertQuestion
					{...this.props}
					handleConfirm={this.handleConfirmQuestion}
				/>
				<AlertQuestionReason
					{...this.props}
					handleConfirm={this.handleConfirmQuestionReason}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => ({ store: state });
export default ImovelDialog = connect(mapStateToProps)(ImovelDialog);
