import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Loader from "../../components/Loader";
import Titulo from "../../components/titulo";
import {
	addUsuario,
	editUsuario,
	getFuncoes,
	getPessoaByCPF,
	getUsuarioByID,
	initialValues,
} from "../../redux/User/UserAction";
import Cadastro from "./Form";

class Usuario extends Component {
	componentDidMount() {
		console.log("usuario, resgister", this.props);
		this.props.getFuncoes();
	}

	render() {
		return (
			<>
				<Loader {...this.props} />
				<Titulo {...this.props} title="Usuários" standardHelper />
				<Cadastro {...this.props} />
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { store: state };
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getPessoaByCPF,
			getFuncoes,
			addUsuario,
			editUsuario,
			getUsuarioByID,
			initialValues,
		},
		dispatch
	);

export default Usuario = connect(mapStateToProps, mapDispatchToProps)(Usuario);
