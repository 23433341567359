import React, { Component } from "react";

import { API, API_BLOB, BASE_URL_WEBSOCKET } from "../../utils/API";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import socketIOClient from "socket.io-client";

import DialogImovel from "components/imovel/dialog";
import { getPlanoPagamento } from "redux/Allotment/AllotmentAction";
import { getAllContatos } from "redux/Contact/ContactAction";
import { getDialogoImovelVenda } from "redux/Immobile/ImmobileAction";

import Loader from "components/Loader";

import "./style.css";

// -- Mapa -----------------------------------------------------------------------------------
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";

import { FullScreen, defaults as DefaultControls } from "ol/control";

import {
	defaults as defaultInteractions,
	DragRotateAndZoom,
} from "ol/interaction";

import { Fill, Style } from "ol/style.js";

import { fromLonLat, transformExtent } from "ol/proj.js";

import { Vector as VectorSource } from "ol/source.js";

import Select from "ol/interaction/Select.js";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import BingMaps from "ol/source/BingMaps.js";
import GeoJSON from "ol/format/GeoJSON";
import OlImageLayer from "ol/layer/Image";
import ImageStatic from "ol/source/ImageStatic";

let map;
let geo_JSON;
let pol_source1;
let pol_vector1;
let pol_source2;
let pol_vector2;
let socket;

async function ByEmpreendimento(cod_empreendimento) {
	const resposta = await API.get(`mapa_empreendimento`, {
		params: {
			cod_empreendimento,
		},
	});
	if (resposta) {
		return resposta;
	}
}

async function MapaByEmpreendimento(cod_empreendimento) {
	const resposta = await API.get(`mapa_json`, {
		params: {
			cod_empreendimento,
		},
	});
	if (resposta) {
		return resposta;
	}
}

async function MapaImagemByEmpreendimento(cod_empreendimento) {
	const resposta = await API_BLOB.get(`mapa_imagem`, {
		params: {
			cod_empreendimento,
		},
	});
	if (resposta) {
		const file = new Blob([resposta.data], { type: "image/png" });
		const url = URL.createObjectURL(file);
		return url;
	}
}

function getHeight() {
	return window.innerHeight - 64;
}

class EmpreendimentoMapa extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentWillUnmount() {
		if (socket) socket.disconnect();
	}

	async componentDidMount() {
		const { id } = this.props.match.params;
		this.state.ls_CodEmpreendimento = id;
		this.props.getPlanoPagamento({ cod_empreendimento: id });
		this.props.getAllContatos();
		this.mapEmp = {};

		await ByEmpreendimento(id).then((resultado) => {
			let mapEmp = resultado.data.result;

			this.setState({
				imageBounds: {
					bottom: parseFloat(mapEmp.empreend_mapa_sw_bound_lat), // lat
					left: parseFloat(mapEmp.empreend_mapa_sw_bound_lng), // lng
					top: parseFloat(mapEmp.empreend_mapa_ne_bound_lat), // lat
					right: parseFloat(mapEmp.empreend_mapa_ne_bound_lng), // lng
				},
				img_opacity: 1,
				imageSize: {
					width: parseFloat(mapEmp.empreend_mapa_width),
					height: parseFloat(mapEmp.empreend_mapa_height),
				},
				lat: parseFloat(mapEmp.empreend_mapa_latitude),
				lng: parseFloat(mapEmp.empreend_mapa_longitude),
				zoom: parseFloat(mapEmp.empreend_mapa_zoom),
				rotacao: parseFloat(mapEmp.empreend_mapa_rotacao),
				pol_lote: 0,
			});
		});

		// -- Bing Layer -------------------------------------------------------------
		this.layer_Bing = new TileLayer({
			visible: true,
			preload: Infinity,
			source: new BingMaps({
				key: "AjqLNOOSzrQfPcNyK5safWtF7DI41xjmTF4Q7jUWyAAd-rX-Zu2Cn2HptKEJIte8",
				imagerySet: "AerialWithLabels",
				maxZoom: 19,
			}),
		});

		// -- Mapa --------------------------------------------------------------------
		map = new Map({
			controls: DefaultControls().extend([new FullScreen()]),
			interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
			target: "map",
			view: new View({
				zoom: this.state.zoom,
				center: fromLonLat([this.state.lng, this.state.lat]),
			}),
			layers: [this.layer_Bing],
		});

		// -- Loteamento -------------------------------------------------------------
		MapaImagemByEmpreendimento(id).then((resultado) => {
			this.ImgMapa = new OlImageLayer({
				opacity: 1,
				source: new ImageStatic({
					imageSize: [this.state.imageSize.width, this.state.imageSize.height],
					url: resultado,
					projection: "EPSG:3857", // pixel
					imageExtent: this.transform([
						this.state.imageBounds.left,
						this.state.imageBounds.bottom,
						this.state.imageBounds.right,
						this.state.imageBounds.top,
					]),
				}),
			});

			map.addLayer(this.ImgMapa);
			geo_JSON = {};

			pol_source1 = new VectorSource({
				projection: "EPSG:3857",
				format: new GeoJSON({}),
			});
			pol_vector1 = new VectorLayer({
				source: pol_source1,
				style: drawStyle,
			});

			map.addLayer(pol_vector1);
		});

		// Selecoes --------------------------------------------------
		this.pol_select = null; // ref to currently selected interaction
		this.pol_selectSingleClick = new Select();
		this.pol_select = this.pol_selectSingleClick;
		map.addInteraction(this.pol_select);

		this.pol_select.on("select", (e) => {
			if (e.selected.length > 0) {
				this.handleOpenDialog(
					e.target.getFeatures().array_[0].values_.cod_imovel
				);
				e.target.getFeatures().clear();
			}
		});

		map.getView().setRotation(this.state.rotacao);
		map.once("rendercomplete", function (event) {});

		window.addEventListener("resize", function (e) {
			if (e) {
				e.stopImmediatePropagation();
				clearTimeout(window.updateSizeTimer);
				window.updateSizeTimer = setTimeout(async function () {
					var mapDiv = document.getElementById("map");

					var orgSize = map.getSize();

					var newSize = [orgSize[0] + 100, getHeight() + 100];

					map.setSize(newSize);
					mapDiv.style.height = getHeight() + "px";
					const view = map.getView();

					view.calculateExtent(map.getSize());
					//map.getView().fit(extent, {size: newSize});

					//console.log(map.getTargetElement().style.height);
					//const li_Height = getHeight() + "px";
					//map.getTargetElement().style.height = li_Height;
					//map.setSize([map.getTargetElement().style.width + "px", li_Height]);
					//map.setSize([300, 400]);
				}, 500);
			}
		});

		///+++ Descomentar se precisar ficar atualizando mapa
		// Usado para Atualizar mapa (tentar usar como Socket.io)
		// const subscribe = sourceInterval.subscribe(val => this.atualizaPoligonos(this.state.ls_CodEmpreendimento));

		// Selecoes ---------------------------------------------------
		function drawStyle(feature, resolution) {
			var color = feature.values_.cor;
			return [
				new Style({
					fill: new Fill({
						color:
							"rgba(" +
							(color = color.replace("#", ""))
								.match(new RegExp("(.{" + color.length / 3 + "})", "g"))
								.map(function (l) {
									return parseInt(color.length % 2 ? l + l : l, 16);
								})
								.concat(0.5 || 1)
								.join(",") +
							")",
					}),
				}),
			];
		}

		async function atualizaPoligonosDB(data) {
			await MapaByEmpreendimento(id).then((resultado) => {
				geo_JSON = resultado.data.result;
				//geo_JSON = data;
				pol_source2 = new VectorSource({
					projection: "EPSG:3857",
					features: new GeoJSON().readFeatures(geo_JSON, {
						featureProjection: "EPSG:3857",
					}),
					format: new GeoJSON({}),
				});
				pol_vector2 = new VectorLayer({
					source: pol_source2,
					style: drawStyle,
				});
			});

			await map.removeLayer(pol_vector1);
			await map.addLayer(pol_vector2);
			pol_vector1 = pol_vector2;
			pol_vector2 = {};
			pol_source2 = {};
		}
		async function atualizaPoligonos(data) {
			geo_JSON = data;
			pol_source2 = new VectorSource({
				projection: "EPSG:3857",
				features: new GeoJSON().readFeatures(geo_JSON, {
					featureProjection: "EPSG:3857",
				}),
				format: new GeoJSON({}),
			});
			pol_vector2 = new VectorLayer({
				source: pol_source2,
				style: drawStyle,
			});

			await map.removeLayer(pol_vector1);
			await map.addLayer(pol_vector2);
			pol_vector1 = pol_vector2;
			pol_vector2 = {};
			pol_source2 = {};
		}

		// Com SOCKET.IO -----------------------------------
		const ls_CodEmp = this.state.ls_CodEmpreendimento;
		if (socket) {
			socket.disconnect();
			socket = {};
		}

		socket = await socketIOClient(BASE_URL_WEBSOCKET, {
			transports: ["websocket"],
		});

		socket.on("mapa", function (data) {
			if (JSON.parse(data).mapa) atualizaPoligonos(JSON.parse(data).mapa);
		});

		socket.on("connect", function () {
			socket.emit("mapa_sala", ls_CodEmp);
			setTimeout(() => {
				atualizaPoligonosDB(ls_CodEmp);
			}, 400);
		});
		// -------------------------------------------------
	}

	// transforma coordenadas
	transform(extent) {
		return transformExtent(extent, "EPSG:4326", "EPSG:3857");
	}

	updateMap() {
		document.getElementById("map").style.height = getHeight();
	}

	handleOpenDialog = (codImovel) => {
		this.props.getDialogoImovelVenda(codImovel);
	};

	render() {
		return (
			<>
				<Loader {...this.props} />
				<div id="map" style={{ width: "100%", height: getHeight() }}></div>
				<DialogImovel {...this.props} mapa />
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { store: state };
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getDialogoImovelVenda,
			getPlanoPagamento,
			getAllContatos,
		},
		dispatch
	);

export default EmpreendimentoMapa = connect(
	mapStateToProps,
	mapDispatchToProps
)(EmpreendimentoMapa);
