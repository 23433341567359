import "./style.css";

import { Button } from "@material-ui/core";
import {
	AssignmentOutlined,
	FlagOutlined,
	MonetizationOnOutlined,
	TurnedInNotOutlined,
} from "@material-ui/icons";
import React from "react";

export default (props) => {
	console.log("opcoes", props.store.venda);
	const opcoes = props.store.venda;

	const setIconBtn = (texto) => {
		switch (texto) {
			case "Gerar orçamento":
				return (
					<MonetizationOnOutlined className="dialog-body-negotiate__icon" />
				);
			case "Tenho interesse":
				return (
					<FlagOutlined className="dialog-body-negotiate__icon dialog-body-negotiate__icon--flag" />
				);
			case "Desejo reservar":
				return (
					<TurnedInNotOutlined className="dialog-body-negotiate__icon dialog-body-negotiate__icon--tag" />
				);
			case "Efetuar Proposta":
				return <AssignmentOutlined className="dialog-body-negotiate__icon" />;
			default:
				return null;
		}
	};

	return (
		<div className="dialog-body-negotiate bg-gray--light">
			<div className="dialog-body-negotiate__container bg-gray--light">
				{opcoes.getOpcaoVenda &&
					opcoes.getOpcaoVenda.map((value) => (
						<Button
							className="dialog-body-negotiate__btn"
							key={value.opcao}
							variant="contained"
							color="primary"
							style={{
								backgroundColor: `${value.color}`,
							}}
							onClick={() => props.click(value.opcao)}
						>
							{setIconBtn(value.texto)}
							{value.texto}
						</Button>
					))}
			</div>
		</div>
	);
};
