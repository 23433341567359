import "./style.css";

import React from "react";

import Buttons from "../components/buttons";

export default (props) => {
	const user = JSON.parse(localStorage.getItem("@YouNectImob:user"));

	return (
		<div className="header-bottom header-bottom-component--small">
			<h2 className="header-bottom-component__title">
				Bem vindo, <br className="hide header-bottom-component__title--break" />
				<span className="header-dashboard__title">{`${
					user ? user.pessoa_nome : ""
				}`}</span>
			</h2>

			<div className="header-dashboard__container">
				<Buttons help onClickHelp={props.onClickHelp} />
			</div>
		</div>
	);
};
