import React, { Component } from "react";

import {
	getAllContatos,
	setShowDialog,
	setCurrentContato,
	deleteContato,
} from "redux/Contact/ContactAction";

import { setShowAlertDialog } from "redux/Global/GlobalAction";

// import { setShowAlertDialog } from "../../components/global/action";
import { connect } from "react-redux";

import AlertDelete from "components/global/AlertDelete";
import Loader from "components/Loader";

import Titulo from "./Title";
import Grid from "./Grid";
import Cadastro from "./Form";

class Contato extends Component {
	componentDidMount() {
		this.props.dispatch(getAllContatos());
	}

	render() {
		const { global } = this.props.store;

		const handleClickOpen = (value) => {
			this.props.dispatch(
				setCurrentContato({
					cod_contato: value.cod_contato || "0",
				})
			);
			this.props.dispatch(setShowDialog(true));
		};

		const handleClickConfirmDelete = (value) => {
			this.props.dispatch(
				setShowAlertDialog({
					show: true,
					value: value.cod_contato,
				})
			);
		};

		const handleClickDelete = () => {
			this.props.dispatch(deleteContato(global.valueToDelete));

			this.props.dispatch(
				setShowAlertDialog({
					show: false,
					value: 0,
				})
			);
		};

		return (
			<>
				<Loader {...this.props} />

				<Titulo {...this.props} handleClickAdd={handleClickOpen} />

				<Grid
					{...this.props}
					handleClickEdit={handleClickOpen}
					handleClickConfirmDelete={handleClickConfirmDelete}
				/>

				<Cadastro {...this.props} />

				<AlertDelete {...this.props} handleClickDelete={handleClickDelete} />
			</>
		);
	}
}

const mapStateToProps = (state) => ({ store: state });
export default Contato = connect(mapStateToProps)(Contato);
